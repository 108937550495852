import React, { useCallback, useEffect, useState } from 'react';
import DynamicModal from '../../../DynamicModal';
import { Container, Form, Row, Col, Button, Image } from 'react-bootstrap';
import s from './style.scss';
import style from '../style.scss';
import ActivateSeal from '../ActivateSeal';
import Cropper from '../../../Cropper/Cropper';
import { getCroppedImg } from '../../../Cropper/cropImage';
import { appEasyState } from '../../../../easyStateStore';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';

const SealActivationModal = ({
  selectedSeal,
  isSealInfo,
  showModal,
  hideModal,
   cancelModal,
  confirmActivation,
  firebase,
  sealImageCropped,
  setSealImageCropped
}) => {
  const getTitle = (type) => {
    if (type === 'submittalReviewStamp') {
      return 'Update Approval Status';
    } else if (type === 'stamp') {
      const title = isSealInfo
        ? 'Update seal expiration date'
        : 'Activate Seal';
      return title;
    }
  };

  const [sealData, setSealData] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cropperLoading, setCropperLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);

  const [date, setDate] = useState(new Date());
  const [value, setValue] = useState(null);
  const [licenseNo, setLicenseNo] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [state, setState] = useState(null);

  console.log('selectedSeal ===> ', selectedSeal);
  useEffect(() => {
    if (showModal) {
      console.log('modal selectedSeal', selectedSeal)
      setSealData(selectedSeal.data || null)
      setValue(selectedSeal.sealName || null);
      setLicenseNo(selectedSeal.newLicenseNo || null);
      setDate(selectedSeal.expirationDate ? new Date(selectedSeal.expirationDate) : new Date());
      setApprovalStatus(selectedSeal.approvalStatus || null);
      setState(selectedSeal.state || null);
    }
    else {
      setShowCropper(false)
      setLoading(false)
    }
  }, [showModal]);

  const onCropComplete = (croppedImg) => {
    console.log('croppedImage: ', croppedImg)
    console.log('selectedSeal.data before', sealData)
    // selectedSeal.data = croppedImg;
    setSealData(croppedImg)
    console.log('selectedSeal.data after', sealData)
    setSealImageCropped(true);
    setShowCropper(false);
    setRotation(0);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      if (appEasyState.cropperWidthTextValue === '' || appEasyState.cropperHeightTextValue === '') {
        store.addNotification({
          title: 'Error',
          message: 'Size fields cannot be empty.',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return
      }

      appEasyState.cropperWidthTextValue = ''
      appEasyState.cropperHeightTextValue = ''

      setCropperLoading(true)
      const croppedImage = await getCroppedImg(
        sealData,
        croppedAreaPixels,
        rotation,
      );
      console.log('done', { croppedImage });
      onCropComplete(croppedImage);
      setCropperLoading(false)
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const handleSubmit = async (e) => {
    setLoading(true);
    selectedSeal.data = sealData;
    selectedSeal.expirationDate = date.getTime();
    selectedSeal.lisenceNumber = licenseNo;
    selectedSeal.state = state;
    selectedSeal.approvalStatus = approvalStatus;
    await confirmActivation(e);
    // setLoading(false);
    hideModal();
  }

  const handleCancelModal = () => {
    setSealData('');
    setRotation(0);
    setSealImageCropped(false);
    cancelModal();
    setShowCropper(true);
  }

  // function toDataUrl(url, callback) {
  //   let xhr = new XMLHttpRequest();
  //   xhr.onload = function() {
  //     let reader = new FileReader();
  //     reader.onloadend = function() {
  //       callback(reader.result);
  //     }
  //     reader.readAsDataURL(xhr.response);
  //   };
  //   xhr.open('GET', url);
  //   xhr.responseType = 'blob';
  //   xhr.send();
  // }

  // function getBase64Image(img) {
  //   let canvas = document.createElement("canvas");
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   let ctx = canvas.getContext("2d");
  //   ctx.drawImage(img, 0, 0);
  //   let dataURL = canvas.toDataURL("image/png");
  //   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  // }
  //
  // useEffect(() => {
  //   if (document.getElementById("sealImageId")) {
  //     if (document.getElementById("sealImageId") != null) {
  //       console.log('image64: ', getBase64Image(document.getElementById("sealImageId")))
  //       setBase64Image(getBase64Image(document.getElementById("sealImageId")));
  //     }
  //   }
  // }, [document.getElementById("sealImageId")])
  //
  // useEffect(() => {
  //   console.log('base64Image: ', base64Image)
  // }, [base64Image])

  return (
    <DynamicModal
      show={showModal}
      title={getTitle(selectedSeal.type)}
      hideModal={handleCancelModal}
      size="xl"
    >
      <Container>
        <Form onSubmit={handleSubmit}>
          <div className={s.wrapper}>
            <Row>
              <Col
                sm={6}
                md={6}
                lg={{ span: 5, offset: 1 }}
                xl={4}
                className={s.alignCenter}
              >
                {!sealImageCropped ? (
                  <Cropper
                    image={sealData === '' ? selectedSeal.data : sealData}
                    handleComplete={onCropComplete}
                    isMandatory={false}
                    isSignature={false}
                    isStamp={selectedSeal.type === 'submittalReviewStamp'}
                    setShowCropper={setShowCropper}
                    setCroppedAreaPixels={setCroppedAreaPixels}
                    rotation={rotation}
                    setRotation={setRotation}
                    welcomeScreen={false}
                  />
                ) : (
                  <Image
                  alt="seal-pic"
                  src={sealData === '' ? selectedSeal.data : sealData}
                  // id={"sealImageId"}
                  // className={s.sealRadius}
                />
                )}
              </Col>
              <Col sm={6} md={6} lg={{ span: 5, offset: 1 }} xl={4}>
                <ActivateSeal
                  selectedSeal={selectedSeal}
                  date={date}
                  setDate={setDate}
                  value={value}
                  setValue={setValue}
                  licenseNo={licenseNo}
                  setLicenseNo={setLicenseNo}
                  approvalStatus={approvalStatus}
                  setApprovalStatus={setApprovalStatus}
                  state={state}
                  setState={setState}
                />
              </Col>
            </Row>
          </div>
          <div className={style.footerBtn}>
            <Button
              variant="outline-dark"
              onClick={handleCancelModal}
              className={s.btnBorder}
            >
              Cancel
            </Button>
            {showCropper ? (
              <>
                {cropperLoading ? (
                  <Button className="btn btn-primary">
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                    Cropping
                  </Button>
                ): (
                  <Button onClick={showCroppedImage}>Crop</Button>
                )}
              </>
            ): (
              <>
                {loading ? (
                  <Button className="btn btn-primary">
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                    Updating
                  </Button>
                ) : (
                  <Button type="submit">Confirm</Button>
                )
                }
              </>
            )}
          </div>
        </Form>
      </Container>
    </DynamicModal>
  );
};

export default SealActivationModal;
