import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import s from './style.scss';
import { UNSUBSCRIBE_EMAIL_API_URL } from '../../../utils/constants';

const Unsubscribe = () => {
  useEffect(() => {
    console.log('testing', window.location.href);
    const docData = window.location.href.split('?');
    const docDetails = docData[1].split('&');
    console.log(`docDetails ===> `, docDetails);

    axios
      .get(UNSUBSCRIBE_EMAIL_API_URL, {
        params: {
          userId: docDetails[0],
          documentId: parseInt(docDetails[1]),
          emailToDelete: docDetails[2],
        },
      })
      .then(function (response) {
        console.log('Unsubscribed ===> ', response.data.message);
        return;
      })
      .catch((error) => {
        console.log(`error in updating records ===>  `, error);
        return;
      });
  }, []);

  return (
    <div>
      <p className={s.text}>
        You have unsubscribed from updates from this document. By scanning the
        QR code, you should be able to subscribe again.
      </p>
    </div>
  );
};

export default withRouter(Unsubscribe);
