import React from 'react';
import { Row, Col, Form, FormCheck } from 'react-bootstrap';
import s from './style.scss';

const SharedEmails = ({ item }) => {
  return (
    <Row noGutters>
      <Col
        xs={12}
        sm={6}
        md={12}
        lg={{ span: 4, offset: 2 }}
        xl={{ span: 4, offset: 2 }}
      >
        <Form.Group className={s.inputBg} controlId={`emailNo${item.id}`}>
          <Form.Control disabled placeholder={item.email} type="text" />
        </Form.Group>
      </Col>
      <Col xs={12} sm={6} md={12} lg={6} xl={6}>
        <div className={s.radioBtns}>
          <FormCheck>
            <FormCheck.Input
              name={item.id}
              type="radio"
              checked={item.sealPacterType === 'primary' ? true : false}
            />
            <FormCheck.Label>Primary SealPacter</FormCheck.Label>
          </FormCheck>
          <FormCheck>
            <FormCheck.Input
              name={item.id}
              type="radio"
              checked={item.sealPacterType === 'secondary' ? true : false}
            />
            <FormCheck.Label>Secondary SealPacter</FormCheck.Label>
          </FormCheck>
          <FormCheck>
            <FormCheck.Input
              name={item.id}
              type="radio"
              checked={item.sealPacterType === 'viewer' ? true : false}
            />
            <FormCheck.Label>Viewer</FormCheck.Label>
          </FormCheck>
        </div>
      </Col>
    </Row>
  );
};

export default SharedEmails;
