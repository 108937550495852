import React from 'react';
import DynamicModal from '../../../DynamicModal';
import { Button } from 'react-bootstrap';
import s from '../style.scss';

const DeleteSeal = ({
  hideModal,
  confirmDelete,
  showModal,
  deleteionType,
  //   activateSignature,
}) => {
  // const action = 'Delete';
  const assetText =
    deleteionType === 'SubmittalReviewStamp'
      ? 'Submittal Review Stamp'
      : deleteionType;
  const modelDesc = `Are you sure you want to delete this ${assetText}? Once a ${assetText} has been deleted,
   there is no way to undo this action. You can re-create a new ${assetText}, if needed.`;
  return (
    <DynamicModal
      show={showModal}
      title="SealPact - Confirmation"
      hideModal={hideModal}
      size="lg"
    >
      <div className={s.modalContent}>
        <p>{modelDesc}</p>
      </div>
      <div className={s.btnStyle}>
        <Button variant="secondary" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="text" onClick={confirmDelete}>
          Confirm
        </Button>
      </div>
    </DynamicModal>
  );
};

export default DeleteSeal;
