import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../firebase';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../Components/Layout/Spinner';
import moment from 'moment';
import {
  CREATE_CUSTOMER_CODE,
  NOTIFICATION_PROPS,
  NOTIFICATION_PROPS_LONGER,
  STRIPE_API_KEY,
  UPDATE_INVITATION,
  SEND_LAST_SUBSCRIPTION_MONTH_REMINDER_EMAIL,
  user,
} from '../../utils/constants';
import { useIdleTimer } from 'react-idle-timer';
import { store } from 'react-notifications-component';
import { view } from 'react-easy-state';
import { appEasyState } from '../../easyStateStore';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      console.log('props ===> ', props);
      this.state = {
        authUser: null,
        loading: true,
      };
    }
    reloadUser = async () => {
      try {
        console.log('this.state.authUser.uid', this.state.authUser.uid);
        await this.props.firebase
          .user(this.state.authUser.uid)
          .then((userObject) => {
            if (userObject) {
              console.log('userObject', userObject);
              debugger;
              this.setState({ authUser: userObject, loading: false });
              appEasyState.userObject = userObject;
            }
          });
      } catch (err) {
        console.log('withAuth reload user err', err);
      }
    };
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        async (authUser) => {
          if (authUser && authUser.email) {
            await this.props.firebase
              .user(authUser.uid)
              .then(async (userObject) => {
                // alert(userObject)
                if (userObject) {
                  if (authUser.emailVerified) {
                    console.log(`userObject ===> `, userObject);
                    console.log(`userObject.email ===> `, userObject.email);

                    // if (userObject.referrerCustomerId) {
                    //   if (!userObject.isReferrerSignupCouponCreated || !userObject.isReferrerSubscriptionCouponCreated) {
                    //
                    //     await this.props.firebase.getConstants()
                    //       .then(async result => {
                    //         console.log('getConstants result', result)
                    //         if (result) {
                    //           if (result.signupCouponAllowed && !userObject.isReferrerSignupCouponCreated && userObject.subscriptionDetails && userObject.subscriptionDetails.status === 'trialing') {
                    //             await axios.post(CREATE_CUSTOMER_CODE, {
                    //               customerId: userObject.referrerCustomerId,
                    //               valueOff: result.signupCouponValueOff,
                    //               isPercentOff: result.isSignupCouponPercentOff,
                    //             }, {
                    //               headers: {
                    //                 'Content-Type': 'application/json'
                    //               }
                    //             }).then(async result => {
                    //
                    //               if (result && result.data && result.data.promotionCode) {
                    //                 console.log('result.data.promotionCode', result.data.promotionCode)
                    //                 debugger
                    //                 await this.props.firebase.updateUser(userObject.uid, {
                    //                   isReferrerSignupCouponCreated: true
                    //                 })
                    //
                    //                 const currentDate = new Date();
                    //                 const unixTimestamp = Math.floor(currentDate.getTime() / 1000);
                    //                 debugger
                    //
                    //                 await axios.post(UPDATE_INVITATION, {
                    //                   uid: userObject.referrerUid,
                    //                   email: userObject.email,
                    //                   signUpDate: unixTimestamp,
                    //                   signupPromotionCode: result.data.promotionCode,
                    //                 }, {
                    //                   headers: {
                    //                     'Content-Type': 'application/json'
                    //                   }
                    //                 })
                    //
                    //               }
                    //             }).catch(err => {
                    //               console.log('signup CREATE_CUSTOMER_CODE err', err)
                    //             })
                    //
                    //           }
                    //
                    //           else if (result.subscriptionCouponAllowed && !userObject.isReferrerSubscriptionCouponCreated && userObject.subscriptionDetails && userObject.subscriptionDetails.status === 'active')  {
                    //
                    //             await axios.post(CREATE_CUSTOMER_CODE, {
                    //               customerId: userObject.referrerCustomerId,
                    //               valueOff: result.subscriptionCouponValueOff,
                    //               isPercentOff: result.isSubscriptionCouponPercentOff,
                    //             }, {
                    //               headers: {
                    //                 'Content-Type': 'application/json'
                    //               }
                    //             }).then(async result => {
                    //               if (result && result.data && result.data.promotionCode) {
                    //                 console.log('result.data.promotionCode', result.data.promotionCode)
                    //                 debugger
                    //                 await this.props.firebase.updateUser(userObject.uid, {
                    //                   isReferrerSubscriptionCouponCreated: true
                    //                 })
                    //
                    //                 await axios.post(UPDATE_INVITATION, {
                    //                   uid: userObject.referrerUid,
                    //                   email: userObject.email,
                    //                   subscriptionPromotionCode: result.data.promotionCode
                    //                 }, {
                    //                   headers: {
                    //                     'Content-Type': 'application/json'
                    //                   }
                    //                 })
                    //
                    //
                    //               }
                    //             }).catch(err => {
                    //               console.log('subscription CREATE_CUSTOMER_CODE err', err)
                    //             })
                    //
                    //           }
                    //
                    //         }
                    //
                    //       })
                    //       .catch(err => {
                    //         console.log('getConstants err', err)
                    //       })
                    //
                    //
                    //   }
                    // }

                    // alert(userObject.email)

                    // const docRef = this.props.firebase.userRef(authUser.uid)
                    // this.updatedSubscription = docRef.onSnapshot(async (doc) => {
                    //   if (doc.exists) {
                    //     const subscriptionUpdated = doc.data().subscriptionUpdated
                    //     if (userObject.subscriptionUpdated !== subscriptionUpdated) {
                    //       await this.reloadUser()
                    //       window.location.reload(false);
                    //     }
                    //   }
                    // })

                    // if (userObject.subscriptionDetails) {
                    //   console.log(`userObject.subscriptionDetails ===> `, userObject.subscriptionDetails);
                    //   console.log('userObject date check: ', moment
                    //     .unix(userObject.subscriptionDetails.current_period_end)
                    //     .isAfter(moment(new Date())))
                    // }

                    console.log('userObject href', window.location.href);
                    debugger;
                    // alert(window.location.href)

                    if (
                      !userObject.subscriptionDetails &&
                      !window.location.href.includes('/subscription') &&
                      !window.location.href.includes('/sealpactedDetails')
                    ) {
                      // alert('redirection 1')

                      console.log('in redirection 1');
                      localStorage.setItem('userId', userObject.uid);
                      localStorage.setItem(
                        'isFirstSubscriptionRedirection',
                        'true',
                      );
                      window.location.href = 'subscription';
                    }
                    //  else if (
                    //   userObject.subscriptionDetails &&
                    //   userObject.subscriptionDetails.status !== 'trialing' &&
                    //   userObject.subscriptionDetails.status !== 'active' &&
                    //   !window.location.href.includes('/subscription')
                    // ) {
                    //   window.location.href = 'subscription';
                    // }
                    else if (
                      userObject.subscriptionDetails &&
                      userObject.subscriptionDetails.status === 'trialing' &&
                      //   userObject.subscriptionDetails.status === 'active') &&
                      !moment
                        .unix(userObject.subscriptionDetails.current_period_end)
                        .isAfter(moment(new Date())) &&
                      !window.location.href.includes('/subscription') &&
                      !window.location.href.includes('/sealpactedDetails')
                    ) {
                      // alert(Object.keys( userObject.subscriptionDetails).length)
                      let customerId;

                      if (
                        userObject.subscriptionDetails.customer &&
                        typeof userObject.subscriptionDetails.customer !==
                          'object'
                      ) {
                        customerId = userObject.subscriptionDetails.customer;
                      } else if (
                        userObject.subscriptionDetails.customer &&
                        typeof userObject.subscriptionDetails.customer ===
                          'object'
                      ) {
                        customerId = userObject.subscriptionDetails.customer.id;
                      } else if (userObject.subscriptionDetails.id) {
                        customerId = userObject.subscriptionDetails.id;
                      } else {
                        customerId = userObject.subscriptionDetails.customer.id;
                      }

                      appEasyState.customerId = customerId;
                      appEasyState.subscriptionId =
                        userObject.subscriptionDetails.id;

                      console.log('customerId ====> ', customerId);
                      await axios
                        .get(
                          'https://api.stripe.com/v1/customers/' + customerId,
                          {
                            headers: {
                              Authorization: `Bearer ${STRIPE_API_KEY}`,
                            },
                          },
                        )
                        .then((res) => {
                          console.log('res ==> stripe', res.data);
                          debugger;

                          if (res.data.subscriptions.data.length > 0) {
                            if (
                              moment
                                .unix(
                                  res.data.subscriptions.data[0]
                                    .current_period_end,
                                )
                                .isAfter(moment(new Date())) &&
                              res.data.subscriptions.data[0].status === 'active'
                            ) {
                              this.props.firebase
                                .addUserSubscriptionDetails(
                                  userObject.uid,
                                  res.data.subscriptions.data[0],
                                )
                                .then(async (res) => {
                                  // window.location.href = '/';
                                  if (window.location.href.includes('/login')) {
                                    console.log('withauth =======> in 1');
                                    if (
                                      !window.location.href.includes(
                                        'account',
                                      ) &&
                                      userObject.subscriptionDetails &&
                                      userObject.isOnboardingCompleted !==
                                        undefined
                                    ) {
                                      localStorage.setItem(
                                        'onboardingCompleted',
                                        userObject.isOnboardingCompleted,
                                      );
                                      if (
                                        userObject.isOnboardingCompleted ===
                                        false
                                      ) {
                                        localStorage.setItem(
                                          'userId',
                                          userObject.uid,
                                        );
                                        appEasyState.onboardingCompleted =
                                          'false';
                                        await this.reloadUser();
                                        window.location.href =
                                          window.location.href.replace(
                                            'login',
                                            'account',
                                          );
                                      } else {
                                        await this.reloadUser();
                                        window.location.href =
                                          window.location.href.replace(
                                            'login',
                                            'dashboard',
                                          );
                                      }
                                    } else {
                                      await this.reloadUser();
                                      window.location.href =
                                        window.location.href.replace(
                                          'login',
                                          'dashboard',
                                        );
                                    }
                                    // alert('rep 1')
                                  }
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            } else if (
                              res.data.subscriptions.data[0].status !==
                                'active' &&
                              res.data.subscriptions.data[0].status !==
                                'trialing'
                            ) {
                              window.location.href = 'subscription';
                            }
                          } else {
                            this.props.firebase
                              .deleteUserSubscriptionDetails(userObject.uid)
                              .then((res) => {
                                let _customer =
                                  userObject.subscriptionDetails.customer;
                                userObject.subscriptionDetails = {};
                                if (_customer) {
                                  userObject.subscriptionDetails.customer =
                                    _customer;
                                } else {
                                  userObject.subscriptionDetails.id =
                                    customerId;
                                }
                                store.addNotification({
                                  title: 'Subscription Suspended!',
                                  message:
                                    'Your previous subscription has been suspended. ' +
                                    'Please start new subscription in order to use this account.',
                                  type: 'warning',
                                  ...NOTIFICATION_PROPS_LONGER,
                                });
                                setTimeout(() => {
                                  window.location.href = 'subscription';
                                }, 10000);
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }
                        })
                        .catch((error) => {
                          console.log('err ====> ', error);
                          debugger;

                          // window.location.href = 'subscription';
                        });

                      // axios
                      //   .get(
                      //     'https://api.stripe.com/v1/customers/cus_IaUepUgHgvXoES',
                      //     bodyParameters,
                      //     config,
                      //   )
                      //   .then((subRes) => {
                      //     console.log('subRes ==> ', subRes);
                      //     debugger;
                      //   })
                      //   .catch((error) => {
                      //     console.log('error ===>', error);
                      //     debugger;
                      //   });
                      // window.location.href = 'subscription';
                    } else if (
                      userObject.subscriptionDetails &&
                      userObject.subscriptionDetails.status !== 'trialing' &&
                      userObject.subscriptionDetails.status !== 'active' &&
                      //   userObject.subscriptionDetails.status === 'active') &&
                      // !moment
                      //   .unix(userObject.subscriptionDetails.current_period_end)
                      //   .isAfter(moment(new Date())) &&
                      !window.location.href.includes('/subscription') &&
                      !window.location.href.includes('/sealpactedDetails')
                    ) {
                      // alert('in not active')

                      let customerId;

                      if (
                        userObject.subscriptionDetails.customer &&
                        typeof userObject.subscriptionDetails.customer !==
                          'object'
                      ) {
                        customerId = userObject.subscriptionDetails.customer;
                      } else if (
                        userObject.subscriptionDetails.customer &&
                        typeof userObject.subscriptionDetails.customer ===
                          'object'
                      ) {
                        customerId = userObject.subscriptionDetails.customer.id;
                      } else if (userObject.subscriptionDetails.id) {
                        customerId = userObject.subscriptionDetails.id;
                      } else {
                        customerId = userObject.subscriptionDetails.customer.id;
                      }

                      appEasyState.customerId = customerId;
                      appEasyState.subscriptionId =
                        userObject.subscriptionDetails.id;

                      // axios.get('https://api.stripe.com/v1/customers/' + customerId + '/subscriptions', {
                      //   headers: { Authorization: `Bearer ${STRIPE_API_KEY}` },
                      // }).then(res => {
                      //   console.log('subscriptions res: ', res)
                      // }).catch(error => {
                      //   console.log(error);
                      // })

                      console.log('customerId ====> ', customerId);
                      axios
                        .get(
                          'https://api.stripe.com/v1/customers/' + customerId,
                          {
                            headers: {
                              Authorization: `Bearer ${STRIPE_API_KEY}`,
                            },
                          },
                        )
                        .then(async (res) => {
                          debugger;
                          console.log('res ==> stripe', res.data);
                          // alert('not trialing not active stripe data')
                          debugger;

                          if (res.data.subscriptions.data.length === 0) {
                            // alert(' lenght = 0')
                            store.addNotification({
                              title: 'Subscription Cancelled!',
                              message:
                                'Your subscription has been cancelled. Please renew your subscription.',
                              type: 'danger',
                              ...NOTIFICATION_PROPS,
                            });
                            store.addNotification({
                              title: 'Redirection',
                              message: 'Redirecting to Subscription',
                              type: 'danger',
                              ...NOTIFICATION_PROPS,
                            });

                            setTimeout(() => {
                              window.location.href = 'subscription';
                              return;
                            }, 5000);
                          }

                          if (res.data.subscriptions.data.length > 0) {
                            if (
                              res.data.subscriptions.data[0].status ===
                                'active' ||
                              res.data.subscriptions.data[0].status ===
                                'trialing'
                            ) {
                              this.props.firebase
                                .addUserSubscriptionDetails(
                                  userObject.uid,
                                  res.data.subscriptions.data[0],
                                )
                                .then(async (res) => {
                                  // window.location.href = '/';
                                  console.log('withauth =======> in 2');
                                  if (window.location.href.includes('/login')) {
                                    if (
                                      !window.location.href.includes(
                                        'account',
                                      ) &&
                                      userObject.subscriptionDetails &&
                                      userObject.isOnboardingCompleted !==
                                        undefined
                                    ) {
                                      localStorage.setItem(
                                        'onboardingCompleted',
                                        userObject.isOnboardingCompleted,
                                      );
                                      if (
                                        userObject.isOnboardingCompleted ===
                                        false
                                      ) {
                                        localStorage.setItem(
                                          'userId',
                                          userObject.uid,
                                        );
                                        appEasyState.onboardingCompleted =
                                          'false';
                                        await this.reloadUser();
                                        window.location.href =
                                          window.location.href.replace(
                                            'login',
                                            'account',
                                          );
                                      } else {
                                        await this.reloadUser();
                                        window.location.href =
                                          window.location.href.replace(
                                            'login',
                                            'dashboard',
                                          );
                                      }
                                    } else {
                                      await this.reloadUser();
                                      window.location.href =
                                        window.location.href.replace(
                                          'login',
                                          'dashboard',
                                        );
                                    }
                                    // alert('rep 2')
                                  }
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            } else {
                              window.location.href = 'subscription';
                            }
                          }
                          // else {
                          //   window.location.href = 'subscription';
                          // }
                        })
                        .catch((error) => {
                          console.log('err ====> ', error);
                          debugger;

                          // window.location.href = 'subscription';
                        });

                      // axios
                      //   .get(
                      //     'https://api.stripe.com/v1/customers/cus_IaUepUgHgvXoES',
                      //     bodyParameters,
                      //     config,
                      //   )
                      //   .then((subRes) => {
                      //     console.log('subRes ==> ', subRes);
                      //     debugger;
                      //   })
                      //   .catch((error) => {
                      //     console.log('error ===>', error);
                      //     debugger;
                      //   });
                      // window.location.href = 'subscription';
                    } else if (
                      userObject.subscriptionDetails &&
                      (userObject.subscriptionDetails.status === 'active' ||
                        userObject.subscriptionDetails.status === 'trialing') &&
                      !window.location.href.includes('/subscription') &&
                      !window.location.href.includes('/sealpactedDetails')
                    ) {
                      let customerId;

                      if (
                        userObject.subscriptionDetails.customer &&
                        typeof userObject.subscriptionDetails.customer !==
                          'object'
                      ) {
                        customerId = userObject.subscriptionDetails.customer;
                      } else if (
                        userObject.subscriptionDetails.customer &&
                        typeof userObject.subscriptionDetails.customer ===
                          'object'
                      ) {
                        customerId = userObject.subscriptionDetails.customer.id;
                      } else if (userObject.subscriptionDetails.id) {
                        customerId = userObject.subscriptionDetails.id;
                      } else {
                        customerId = userObject.subscriptionDetails.customer.id;
                      }

                      appEasyState.customerId = customerId;
                      appEasyState.subscriptionId =
                        userObject.subscriptionDetails.id;

                      // axios.get('https://api.stripe.com/v1/customers/' + customerId + '/subscriptions', {
                      //   headers: { Authorization: `Bearer ${STRIPE_API_KEY}` },
                      // }).then(res => {
                      //   console.log('subscriptions res: ', res)
                      // }).catch(error => {
                      //   console.log(error);
                      // })

                      console.log('customerId ====> ', customerId);
                      axios
                        .get(
                          'https://api.stripe.com/v1/customers/' + customerId,
                          {
                            headers: {
                              Authorization: `Bearer ${STRIPE_API_KEY}`,
                            },
                          },
                        )
                        .then(async (res) => {
                          console.log('res ==> stripe', res.data);
                          // alert('not trialing but active stripe data')
                          debugger;

                          if (res.data.subscriptions.data.length === 0) {
                            // alert(' lenght = 0')
                            store.addNotification({
                              title: 'Subscription Cancelled!',
                              message:
                                'Your subscription has been cancelled. Please renew your subscription.',
                              type: 'danger',
                              ...NOTIFICATION_PROPS,
                            });
                            store.addNotification({
                              title: 'Redirection',
                              message: 'Redirecting to Subscription',
                              type: 'danger',
                              ...NOTIFICATION_PROPS,
                            });

                            setTimeout(() => {
                              window.location.href = 'subscription';
                              return;
                            }, 5000);
                          }

                          if (res.data.subscriptions.data.length > 0) {
                            if (
                              res.data.subscriptions.data[0].status !==
                                'active' &&
                              res.data.subscriptions.data[0].status !==
                                'trialing'
                            ) {
                              this.props.firebase
                                .addUserSubscriptionDetails(
                                  userObject.uid,
                                  res.data.subscriptions.data[0],
                                )
                                .then(async (res) => {
                                  await this.reloadUser();
                                  window.location.href = 'subscription';
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            } else if (
                              res.data.subscriptions.data[0].status ===
                                'active' ||
                              res.data.subscriptions.data[0].status ===
                                'trialing'
                            ) {
                              debugger;
                              if (
                                res.data.subscriptions.data[0].status ===
                                  'active' &&
                                res.data.subscriptions.data[0].plan.interval ==
                                  'year' &&
                                moment
                                  .unix(
                                    res.data.subscriptions.data[0]
                                      .current_period_end,
                                  )
                                  .diff(moment(new Date()), 'days') <= 30
                              ) {
                                if ('lastReminderEmailDate' in userObject) {
                                  if (
                                    moment(
                                      userObject.lastReminderEmailDate,
                                    ).diff(moment(), 'hours') > 24
                                  ) {
                                    axios
                                      .post(
                                        SEND_LAST_SUBSCRIPTION_MONTH_REMINDER_EMAIL,
                                        {
                                          email: userObject.email,
                                          name: [
                                            userObject.firstName,
                                            userObject.middleName,
                                            userObject.lastName,
                                          ]
                                            .filter(Boolean)
                                            .join(' '),
                                        },
                                        {
                                          headers: {
                                            'Content-Type': 'application/json',
                                          },
                                        },
                                      )
                                      .then((res) => {
                                        console.log(res);
                                        this.props.firebase
                                          .addLastReminderEmailDate(
                                            userObject.uid,
                                            new Date().getTime(),
                                          )
                                          .then((res) => console.log(res))
                                          .catch((err) => console.log(err));
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }
                                } else {
                                  axios
                                    .post(
                                      SEND_LAST_SUBSCRIPTION_MONTH_REMINDER_EMAIL,
                                      {
                                        email: userObject.email,
                                        name: [
                                          userObject.firstName,
                                          userObject.middleName,
                                          userObject.lastName,
                                        ]
                                          .filter(Boolean)
                                          .join(' '),
                                      },
                                      {
                                        headers: {
                                          'Content-Type': 'application/json',
                                        },
                                      },
                                    )
                                    .then((res) => {
                                      console.log(res);
                                      this.props.firebase
                                        .addLastReminderEmailDate(
                                          userObject.uid,
                                          new Date().getTime(),
                                        )
                                        .then((res) => console.log(res))
                                        .catch((err) => console.log(err));
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }
                              }

                              if (
                                moment
                                  .unix(
                                    res.data.subscriptions.data[0]
                                      .current_period_end,
                                  )
                                  .isAfter(moment(new Date())) &&
                                userObject.subscriptionDetails
                                  .current_period_end !=
                                  res.data.subscriptions.data[0]
                                    .current_period_end
                              ) {
                                this.props.firebase
                                  .addUserSubscriptionDetails(
                                    userObject.uid,
                                    res.data.subscriptions.data[0],
                                  )
                                  .then(async (res) => {
                                    // window.location.href = '/';
                                    console.log('withauth =======>  in 3');
                                    if (
                                      window.location.href.includes('/login')
                                    ) {
                                      if (
                                        !window.location.href.includes(
                                          'account',
                                        ) &&
                                        userObject.subscriptionDetails &&
                                        userObject.isOnboardingCompleted !==
                                          undefined
                                      ) {
                                        localStorage.setItem(
                                          'onboardingCompleted',
                                          userObject.isOnboardingCompleted,
                                        );
                                        if (
                                          userObject.isOnboardingCompleted ===
                                          false
                                        ) {
                                          localStorage.setItem(
                                            'userId',
                                            userObject.uid,
                                          );
                                          appEasyState.onboardingCompleted =
                                            'false';
                                          await this.reloadUser();
                                          window.location.href =
                                            window.location.href.replace(
                                              'login',
                                              'account',
                                            );
                                        } else {
                                          await this.reloadUser();
                                          window.location.href =
                                            window.location.href.replace(
                                              'login',
                                              'dashboard',
                                            );
                                        }
                                      } else {
                                        await this.reloadUser();
                                        window.location.href =
                                          window.location.href.replace(
                                            'login',
                                            'dashboard',
                                          );
                                      }

                                      // alert('rep 3')
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              } else if (
                                window.location.href.includes('/login')
                              ) {
                                console.log('withauth =======> in 4');
                                console.log('in 4 userObject', userObject);
                                debugger;

                                if (
                                  !window.location.href.includes('account') &&
                                  userObject.subscriptionDetails &&
                                  userObject.isOnboardingCompleted !== undefined
                                ) {
                                  localStorage.setItem(
                                    'onboardingCompleted',
                                    userObject.isOnboardingCompleted,
                                  );
                                  if (
                                    userObject.isOnboardingCompleted === false
                                  ) {
                                    localStorage.setItem(
                                      'userId',
                                      userObject.uid,
                                    );
                                    appEasyState.onboardingCompleted = 'false';
                                    window.location.href =
                                      window.location.href.replace(
                                        'login',
                                        'account',
                                      );
                                  } else {
                                    window.location.href =
                                      window.location.href.replace(
                                        'login',
                                        'dashboard',
                                      );
                                  }
                                } else {
                                  window.location.href =
                                    window.location.href.replace(
                                      'login',
                                      'dashboard',
                                    );
                                }

                                // alert('rep 4')
                              } else if (
                                window.location.href.includes('/signup')
                              ) {
                                window.location.href =
                                  window.location.href.replace(
                                    'signup',
                                    'dashboard',
                                  );
                              }
                            }
                          }
                          // else {
                          //   window.location.href = 'subscription';
                          // }
                        })
                        .catch((error) => {
                          console.log('err ====> ', error);
                          debugger;

                          // window.location.href = 'subscription';
                        });
                    } else if (window.location.href.includes('/login')) {
                      console.log('withauth =======> in 6');
                      if (
                        !window.location.href.includes('account') &&
                        userObject.subscriptionDetails &&
                        userObject.isOnboardingCompleted !== undefined
                      ) {
                        localStorage.setItem(
                          'onboardingCompleted',
                          userObject.isOnboardingCompleted,
                        );
                        if (userObject.isOnboardingCompleted === false) {
                          localStorage.setItem('userId', userObject.uid);
                          appEasyState.onboardingCompleted = 'false';
                          window.location.href = window.location.href.replace(
                            'login',
                            'account',
                          );
                        } else {
                          window.location.href = window.location.href.replace(
                            'login',
                            'dashboard',
                          );
                        }
                      } else {
                        window.location.href = window.location.href.replace(
                          'login',
                          'dashboard',
                        );
                      }

                      // alert('rep 5')
                    } else if (window.location.href.includes('/signup')) {
                      window.location.href = window.location.href.replace(
                        'signup',
                        'dashboard',
                      );
                    }
                    // else if (
                    //   window.location.href.includes('/subscription') &&
                    //   userObject.subscriptionDetails &&
                    //   userObject.subscriptionDetails.plan.active === true
                    // ) {
                    //   window.location.href = window.location.href.replace(
                    //     'subscription',
                    //     'dashboard',
                    //   );
                    // }
                  }

                  this.setState({ authUser: userObject, loading: false });
                  appEasyState.userObject = userObject;
                } else {
                  console.log('USER NOT FOUND', userObject);
                }
              });
          } else {
            this.setState({ authUser: null, loading: false });
            localStorage.setItem('userId', 'null');
            localStorage.setItem('encryptedString', 'null');

            // // alert('in else')
            // const userId = localStorage.getItem('userId') || 'null'
            // const profileUpdated = localStorage.getItem('profileUpdatedSuccessfully') || 'null'
            // const subscriptionRedirected = localStorage.getItem('isFirstSubscriptionRedirection') || 'null'
            // const planSubscribed =localStorage.getItem('planSubscribed') || 'null'
            // console.log({ profileUpdated, userId })
            // console.log('typeof subscriptionRedirected', typeof subscriptionRedirected)
            // console.log('subscriptionRedirected', subscriptionRedirected)
            // if (profileUpdated === 'true' || subscriptionRedirected === 'true' || planSubscribed === 'true') {
            //   console.log('in 1')
            //   await this.props.firebase.user(userId).then((userObject) => {
            //     if (userObject) {
            //       console.log('userObject', userObject)
            //       this.setState({ authUser: userObject, loading: false });
            //       appEasyState.userObject = userObject
            //       if (subscriptionRedirected === 'true') localStorage.setItem('isFirstSubscriptionRedirection', 'null')
            //       else if (planSubscribed === 'true') localStorage.setItem('planSubscribed', 'null')
            //     }
            //   });
            // }
            // else if (userId && userId !== 'null') {
            //   console.log('in 2')
            //   await this.props.firebase.user(userId).then((userObject) => {
            //     if (userObject) {
            //       console.log('userObject withAuth', userObject)
            //       this.setState({ authUser: userObject, loading: false });
            //       appEasyState.userObject = userObject
            //
            //       if (!window.location.href.includes('account') && userObject.subscriptionDetails && userObject.isOnboardingCompleted !== undefined) {
            //         localStorage.setItem('onboardingCompleted', userObject.isOnboardingCompleted)
            //         if (userObject.isOnboardingCompleted === false) {
            //           localStorage.setItem('userId', userObject.uid)
            //           appEasyState.onboardingCompleted = 'false'
            //           window.location.href = 'account'
            //         }
            //       }
            //
            //     }
            //   });
            // }
            // else {
            //   console.log('in 3')
            //   this.setState({ authUser: null, loading: false });
            // }
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
      if (this.updatedSubscription) this.updatedSubscription();
    }

    render() {
      return (
        <AuthUserContext.Provider
          value={{ user: this.state.authUser, reloadUser: this.reloadUser }}
        >
          {this.state.loading && <Spinner />}
          {!this.state.loading && <Component {...this.props} />}
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(view(WithAuthentication));
};

export default withAuthentication;
