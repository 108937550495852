import React from 'react';
import s from '../style.scss';
import { Row, Col } from 'react-bootstrap';
import { DISCIPLINE, USERADVANCEDINFO } from '../../../../utils/constants';

const getDiscipline = (dis) => {
  if (dis) {
    const disciplineObj = DISCIPLINE.filter(
      (disciplineObj) => disciplineObj.value === dis,
    );
    return disciplineObj[0].label;
  }
  return dis;
};

const AdvancedTabInfo = ({ confirmationValues }) => {
  console.log('confirmationValues in advance tab ====> ', confirmationValues);
  // const {
  //   clientName,
  //   clientEmail,
  //   clientType,
  //   ownerType,
  //   natureOfWork,
  //   generalScope,
  //   projectType,
  //   discipline,
  //   concentration,
  //   projectRole,
  //   dateOfIssuance,
  //   scopeInclusions,
  //   scopeExclusions,
  // } = USERADVANCEDINFO;
  return (
    <Row>
      <Col>
        <div className={s.wrapper}>
          <p>Client Name</p>
          <span>
            {confirmationValues.clientName || 'N/A'}
          </span>
          <p>Client Email</p>
          <span>
            {confirmationValues.clientEmail || 'N/A'}
          </span>
          <p>Client Type</p>
          <span>
            {confirmationValues.clientType || 'N/A'}
          </span>
          <p>Owner Type</p>
          <span>
            {confirmationValues.ownerType || 'N/A'}
          </span>
          <p>Nature of Work</p>
          <span>
            {confirmationValues.workNature
              ? confirmationValues.workNature === 'Other'
                ? confirmationValues.otherWorkNature
                : confirmationValues.workNature
              : 'N/A'}
          </span>

          {/* <p>General Scope/Task</p> */}
          {/* <p>Project Type</p> */}

          <p>Discipline</p>
          <span>{getDiscipline(confirmationValues.discipline) || 'N/A'}</span>
          <p>Concentration</p>
          <span>
            {confirmationValues.discipline
              ? confirmationValues.discipline !== 'landSurveyor' && confirmationValues.concentration
                ? confirmationValues.concentration
                : 'N/A'
              : 'N/A'}
          </span>
          <p>Project Role</p>
          <span>
            {confirmationValues.projectRole || 'N/A'}
          </span>
          {/* <p>Date of Issuance</p>
          <span>
            {confirmationValues.dateOfIssuance || new Date().toUTCString()}
          </span> */}
        </div>
      </Col>
      <Col>
        <div className={s.wrapper}>
          <p>Scope Inclusions</p>
          <span>
            {confirmationValues.scopeInclusions || 'N/A'}
          </span>
          <p>Scope Exclusions</p>
          <span>
            {confirmationValues.scopeExclusions || 'N/A'}
          </span>
        </div>
      </Col>
      <Col />
    </Row>
  );
};

export default AdvancedTabInfo;
