import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { getCroppedImg } from './cropImage';
import ReactCropper from 'react-easy-crop';
import s from './style.scss';
import Switch from 'react-switch';
import MaskedDropDown from '../AppComponents/MaskedDropdown';
import { appEasyState } from '../../easyStateStore';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../utils/constants';

const uncheckedIcon = (
  <div className={[s.label, s.offLabel].join(' ')}>OFF</div>
);
const checkedIcon = <div className={[s.label, s.onLabel].join(' ')}>ON</div>;

export default function Cropper({
  image,
  handleComplete,
  isMandatory,
  isSignature,
  isStamp,
  setShowCropper,
  setCroppedAreaPixels,
  rotation,
  setRotation,
  welcomeScreen,
}) {
  const [cropperLoading, setCropperLoading] = useState(false);
  const [defaultImg, setdefaultImg] = useState(true);
  const [imgCropper, setimgCropper] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState({ width: 500, height: 500 });
  const [rotationCropper, setRotationCropper] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixelsCropper, setCroppedAreaPixelsCropper] =
    useState(null);
  const [showEditbtn, setshowEditbtn] = useState(true);
  const [editControlers, setEditControlers] = useState(false);
  // const [croppedImage, setCroppedImage] = useState(null);
  const [cropShape, setCropShape] = useState(
    isSignature || isStamp ? 'rect' : 'round',
  );
  const [widthRatio, setWidthRatio] = useState(isSignature ? 3 : 2);
  const [widthRatioTextValue, setWidthRatioTextValue] = useState(
    isSignature ? '3' : '2',
  );
  const [heightRatio, setHeightRatio] = useState(
    isSignature ? 1 : isStamp ? 3 : 2,
  );
  const [heightRatioTextValue, setHeightRatioTextValue] = useState(
    isSignature ? '1' : isStamp ? '3' : '2',
  );

  const [selectFullImage, setSelectFullImage] = useState(false);

  useEffect(() => {
    appEasyState.circularCropShape = cropShape !== 'rect';
  }, [cropShape]);

  useEffect(() => {
    appEasyState.cropperWidthTextValue = widthRatioTextValue;
    appEasyState.cropperHeightTextValue = heightRatioTextValue;
    appEasyState.selectFullImage = selectFullImage;
  }, [widthRatioTextValue, heightRatioTextValue, selectFullImage]);

  const handleCropShapeChange = () => {
    if (cropShape === 'rect') setCropShape('round');
    else setCropShape('rect');
  };

  const handleWidthRatio = (value) => {
    setSelectFullImage(false);
    let number = parseInt(value);
    if (value === '' || (/^(?!0+$)\d+$/.test(value) && number <= 10)) {
      setWidthRatioTextValue(value);
      appEasyState.cropperWidthTextValue = value;
    }

    if (number) {
      if (number < 1) number = 1;

      if (number > 10) number = 10;

      setWidthRatio(number);
    } else setWidthRatio(1);
  };

  const handleHeightRatio = (value) => {
    setSelectFullImage(false);
    let number = parseInt(value);
    if (value === '' || (/^(?!0+$)\d+$/.test(value) && number <= 10)) {
      setHeightRatioTextValue(value);
      appEasyState.cropperHeightTextValue = value;
    }

    if (number) {
      if (number < 1) number = 1;

      if (number > 10) number = 10;

      setHeightRatio(number);
    } else setHeightRatio(1);
  };

  const showCroppedImage = async () => {
    try {
      if (widthRatioTextValue !== '' && heightRatioTextValue !== '') {
        setCropperLoading(true);
        const croppedImage = await getCroppedImg(
          image,
          croppedAreaPixelsCropper,
          rotation,
          true,
        );
        // console.log('done', { croppedImage });
        console.log('selectFullImage', selectFullImage);

        handleComplete(croppedImage);
        setCropperLoading(false);
      } else {
        store.addNotification({
          title: 'Error',
          message: 'Size fields cannot be empty.',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return;
      }
      // else {
      //   setCropperLoading(true);
      //   const croppedImage = await getCroppedImg(
      //     image,
      //     croppedAreaPixelsCropper,
      //     rotation,
      //   );
      //   console.log('done', { croppedImage });
      //   handleComplete(croppedImage);
      //   setCropperLoading(false);
      // }
    } catch (e) {
      console.error(e);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log('croppedArea', croppedArea);
    console.log('croppedAreaPixels', croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
    setCroppedAreaPixelsCropper(croppedAreaPixels);
  }, []);

  const EditorHandler = () => {
    if (!editControlers && !imgCropper) {
      setRotation(0);
      setRotationCropper(0);
      setZoom(1);
      setimgCropper(true);
      setdefaultImg(false);
      setEditControlers(true);
      setshowEditbtn(false);
      setShowCropper(true);
    }
    // else {
    //   setimgCropper(true);
    //   setdefaultImg(false);
    //   setEditControlers(false);
    //   setshowEditbtn(true);
    // }
  };

  useEffect(() => {
    if (isMandatory) {
      EditorHandler();
    }
  }, [isMandatory]);

  const cancelCropHandler = () => {
    if (editControlers) {
      setRotation(0);
      setZoom(1);
      setimgCropper(false);
      setdefaultImg(true);
      setEditControlers(false);
      setshowEditbtn(true);
      setShowCropper(false);
    }
  };

  const handleRotation = (value) => {
    setRotation(value);
    setRotationCropper(value);
  };

  return (
    <div>
      {showEditbtn && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button onClick={EditorHandler}>Edit</Button>
        </div>
      )}
      {editControlers && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button onClick={cancelCropHandler}>Cancel</Button>
        </div>
      )}
      <div
        style={{
          position: 'relative',
          margin: '10px auto',
          width: '300px',
          height: '250px',
        }}
      >
        {defaultImg && (
          <img
            className={s.imageViewer}
            style={{
              objectFit: 'contain',
              position: 'relative',
              margin: '10px auto',
              width: '300px',
              height: '250px',
            }}
            src={image}
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 4}
            onCropChange={setCrop}
            onRotationChange={handleRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        )}
        {imgCropper && (
          <>
            {!isSignature ? (
              <ReactCropper
                cropShape={cropShape}
                objectFit="contain"
                image={image}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={widthRatio / heightRatio}
                onCropChange={setCrop}
                onRotationChange={handleRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                setCropSize={cropSize}
                // cropSize={{ width: 700, height: 700 }}
                // minWidth={500}
                // minHeight={500}
              />
            ) : (
              <ReactCropper
                cropShape={cropShape}
                objectFit="contain"
                image={image}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={widthRatio / heightRatio}
                onCropChange={setCrop}
                onRotationChange={handleRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                setCropSize={cropSize}
                cropSize={{ width: 300, height: 300 }}
                // minWidth={500}
                // minHeight={500}
              />
            )}
          </>
        )}
      </div>

      {editControlers && (
        <div>
          <Row
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col style={{ display: 'flex', justifyContent: 'start' }}>
              <b>Circular Shape</b>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'end' }}>
              <div className="pr-1 text-right">
                <Switch
                  disabled={isSignature}
                  checked={cropShape === 'round'}
                  height={22}
                  onChange={handleCropShapeChange}
                  uncheckedIcon={uncheckedIcon}
                  checkedIcon={checkedIcon}
                  className="react-switch"
                  id="icon-switch"
                  offColor="#e64767"
                />
              </div>
            </Col>
          </Row>

          <Row
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col style={{ display: 'flex', justifyContent: 'start' }}>
              <b>Select Full Image</b>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'end' }}>
              <div className="pr-1 text-right">
                <Switch
                  disabled={isSignature}
                  checked={selectFullImage}
                  height={22}
                  onChange={() => {
                    if (selectFullImage) {
                      setSelectFullImage(false);
                      setZoom(1);
                    } else {
                      setSelectFullImage(true);
                      setZoom(0.7);
                    }
                  }}
                  uncheckedIcon={uncheckedIcon}
                  checkedIcon={checkedIcon}
                  className="react-switch"
                  id="icon-switch"
                  offColor="#e64767"
                />
              </div>
            </Col>
          </Row>

          <Row
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col style={{ display: 'flex', justifyContent: 'start' }}>
              <b>Zoom</b>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'end' }}>
              <input
                type={'range'}
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.currentTarget.value);
                  setSelectFullImage(false);
                }}
              />
            </Col>
          </Row>
          <Row
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col style={{ display: 'flex', justifyContent: 'start' }}>
              <b>Rotation</b>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'end' }}>
              <input
                type="range"
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                onChange={(e) => setRotation(e.currentTarget.value)}
              />
            </Col>
          </Row>
          <Row
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <Col style={{ display: 'flex', justifyContent: 'start' }}>
              <b>Size</b>
              <span style={{ margin: '5px 0 0 3px', fontSize: '11px' }}>
                (Width x Length)
              </span>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'end' }}>
              <Form.Control
                type="text"
                value={widthRatioTextValue}
                onChange={(e) => handleWidthRatio(e.target.value)}
                style={{ width: '50px' }}
              />
              <b style={{ marginTop: '5px' }}>&nbsp; x &nbsp;</b>
              <Form.Control
                type="text"
                value={heightRatioTextValue}
                onChange={(e) => handleHeightRatio(e.target.value)}
                style={{ width: '50px' }}
              />
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            {welcomeScreen && (
              <div>
                {cropperLoading ? (
                  <Button className="btn btn-primary">
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                    Cropping
                  </Button>
                ) : (
                  <div className="mt-3">
                    <Button onClick={showCroppedImage}>Crop</Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
