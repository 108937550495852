import { store } from 'react-notifications-component';
import { AUTH_DOMAIN, APP_URL, SEND_FLORIDA_SEAL_INFO_EMAIL } from '../utils/constants';
import { NOTIFICATION_PROPS } from '../utils/constants';
import axios from 'axios';

const sendSignupRequest = (
  userParams,
  password,
  sealParam,
  signParam,
  sealDataUrl,
  signDataUrl,
  props,
  isSealSkip,
) => {
  const { firebase } = props;
  const {
    history: { push },
  } = props;
  // event.preventDefault();
  // const {
  //   email,
  //   password,
  //   adminEmail,
  //   phoneNumber,
  //   firstName,
  //   middleName,
  //   lastName,
  //   firmName,
  //   signature,
  //   sealName,
  //   homeState,
  //   otherState,
  //   licenseNo,
  //   discipline,
  //   concentration,
  //   otherConcentration,
  //   liability,
  //   expirationDate,
  //   skipSeal,
  // } = event.target.elements;

  // const userParams = {
  //   email: email.value,
  //   adminEmail: adminEmail.value,
  //   phoneNumber: phoneNumber.value,
  //   firstName: firstName.value,
  //   lastName: lastName.value,
  //   createdAt: new Date().getTime(),
  // };
  // console.log('userParams === > ', userParams);
  // if (otherConcentration) {
  //   userParams.otherConcentration = otherConcentration.value;
  // }
  // if (firmName) {
  //   userParams.firmName = firmName.value;
  // }
  // if (discipline) {
  //   userParams.discipline = discipline.value;
  // }
  // if (concentration) {
  //   userParams.concentration = concentration.value;
  // }
  // if (liability) {
  //   userParams.liability = liability.value;
  // }
  // if (middleName) {
  //   userParams.middleName = middleName.value;
  // }

  // const sealParams = {
  //   sealName: sealName.value,
  //   state: homeState.value,
  //   licenseNo: licenseNo.value,
  //   skipSeal: skipSeal.value,
  //   status: true,
  //   createdAt: new Date().getTime(),
  // };

  // console.log('sealParams === > ', sealParams);

  // const signatureParams = {
  //   signatureName: signature.value,
  //   status: true,
  //   createdAt: new Date().getTime(),
  // };

  // console.log('signatureParams === > ', signatureParams);

  // if (otherState) {
  //   sealParams.state = otherState.value;
  // }
  // if (expirationDate) {
  //   sealParams.expirationDate = expirationDate.value;
  // }
  firebase
    .doCreateUserWithEmailAndPassword(userParams.email, password.value)
    .then((authUser) => {
      const uid = authUser.user.uid;
      authUser.user
        .sendEmailVerification({
          url: APP_URL,
        })
        .then((emailsent) => {
          firebase
            .addNewUser({
              uid: uid,
              ...userParams,
            })
            .then((newUser) => {
              store.addNotification({
                title: 'Registered!',
                message:
                  'Please validate your user information by clicking the link in the email we just sent you. If you do not receive an email within a minute or two, please check your spam folder.',
                type: 'default',
                ...NOTIFICATION_PROPS,
              });
              signParam.data = signDataUrl;
              signParam.uid = uid;

              if (isSealSkip) {
                firebase
                  .createNewSignature(uid, signParam)
                  .then((SealRes) => {
                    firebase
                      .doSignOut()
                      .then((signoutRes) => {
                        localStorage.setItem('encryptedString', 'null')
                        push('/login');

                        return;
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    console.log('error ===> ', error);
                  });
              } else {
                sealParam.data = sealDataUrl;
                sealParam.uid = uid;
                firebase
                  .createNewSeal(uid, sealParam)
                  .then((SealRes) => {
                    if (sealParam.state.toLowerCase() === 'florida') {
                      axios.post(
                        SEND_FLORIDA_SEAL_INFO_EMAIL,
                        {
                          userName: userParams.firstName + " " + userParams.lastName,
                          email: userParams.email,
                          phoneNumber: userParams.phoneNumber,
                          // sealName: params.sealName,
                          // sealState: params.state,
                          // sealLicenseNumber: params.newLicenseNo,
                          // sealExpirationDate: params.expirationDate
                        }).then(resp => {
                        console.log('florida seal created and email sent')
                      }).catch((err) => console.log('error in email for CreateNewSeal', err));
                    }

                    //create sign here
                    firebase
                      .createNewSignature(uid, signParam)
                      .then((SealRes) => {
                        firebase
                          .doSignOut()
                          .then((signoutRes) => {
                            localStorage.setItem('encryptedString', 'null')
                            push('/login');

                            return;
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      })
                      .catch((error) => {
                        console.log('error ===> ', error);
                      });
                  })
                  .catch((error) => {
                    console.log('error ===> ', error);
                  });
              }

              return;
            })
            .catch((error) => {
              console.log('error === > ', error);
              store.addNotification({
                title: 'Error',
                message: error.message,
                type: 'danger',
                ...NOTIFICATION_PROPS,
              });
            });
        })
        .catch((error) => {
          console.log('error2  ===> ', error);
        });

      // firebase.createNewSignature(uid, signatureParams);

      // if (sealParams.skipSeal === 'false') {
      //   const { skipSeal, ...rest } = sealParams;
      //   firebase.createNewSeal(uid, ...rest).then(a => {
      //     props.history.push('/dashboard');
      //   });
      // }
    })
    .catch((error) => {
      console.log('error in sign up', error);
      store.addNotification({
        title: 'Error',
        message: error.message,
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    });
};

const sendOnboardingRequest = async (
  userParams,
  password,
  props,
) => {
  const { firebase } = props;
  const {
    history: { push },
  } = props;
  await firebase
    .doCreateUserWithEmailAndPassword(userParams.email, password)
    .then((authUser) => {
      const uid = authUser.user.uid;
      console.log('sendOnboardingRequest authUser.user.uid', uid)
      console.log('sendOnboardingRequest authUser.user', authUser.user)
      console.log('sendOnboardingRequest authUser', authUser)
      authUser.user
        .sendEmailVerification({
          url: APP_URL,
        })
        .then(async (emailSent) => {
          console.log('sendOnboardingRequest emailSent', emailSent)
          debugger
          console.log('add new user userParams', userParams)
          console.log('add new user firebase', firebase)
          console.log('add new user obj', {
            uid: uid,
            ...userParams
          })
          await firebase
            .addNewUser({
              uid: uid,
              ...userParams,
            })
            .then((newUser) => {
              console.log('sendOnboardingRequest newUser', newUser)
              firebase
                .doSignOut()
                .then((signoutRes) => {
                  return;
                })
                .catch((error) => {
                  console.log(error);
                });
              // store.addNotification({
              //   title: 'Registered!',
              //   message:
              //     'Please validate your user information by clicking the link in the email we just sent you. If you do not receive an email within a minute or two, pleas e check your spam folder.',
              //   type: 'default',
              //   ...NOTIFICATION_PROPS,
              // });
            })
            .catch((error) => {
              console.log('sendOnboardingRequest error === > ', error);
              store.addNotification({
                title: 'Error',
                message: error.message,
                type: 'danger',
                ...NOTIFICATION_PROPS,
              });
            });
        })
        .catch((error) => {
          console.log('sendOnboardingRequest error2  ===> ', error);
        });

    })
    .catch((error) => {
      console.log('sendOnboardingRequest error in onboarding', error);
      store.addNotification({
        title: 'Error',
        message: error.message,
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    });
}

const sendLoginRequest = (props) => {};

export { sendSignupRequest, sendLoginRequest, sendOnboardingRequest };
