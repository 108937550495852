import React from 'react';
import ColHeading from './ColHeading';
import s from './style.scss';
const headerStyle = { justifyContent: 'space-between', border: 0 };
const cellStyle = { border: 0, justify_content: 'center', display: 'flex' };

const formatDate = (date) => {
  //console.log('date == = = = =  >', date ? date.toDate() : date);
  const dateObj = new Date(date);
  //console.log('dateObj =============>', dateObj);
  var year = dateObj.getFullYear();
  var month = (1 + dateObj.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = dateObj.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
};

const COLUMNS = [
  {
    Header: () => <ColHeading heading="Seal Name" />,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'primarySealPactSigner',
    style: cellStyle,
    id: 'primarySealPactSigner',
  },
  {
    Header: () => <ColHeading heading="Type" />,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'sealPacterType',
    style: cellStyle,
    id: 'sealPacterType',
  },
  // {
  //   Header: () => <ColHeading heading="Secondary SealPact Signer" />,
  //   Cell: props => <div className={s.tblCell}> {props.value}</div>,
  //   headerStyle,
  //   accessor: 'secondarySealPactSigner',
  //   style: cellStyle,
  //   id: 'secondarySealPactSigner',
  // },
  // {
  //   Header: () => <ColHeading heading="SealPact Originator" />,
  //   Cell: props => <div className={s.tblCell}> {props.value}</div>,
  //   headerStyle,
  //   accessor: 'sealPactOriginator',
  //   style: cellStyle,
  //   id: 'sealPactOriginator',
  // },
  {
    Header: () => <ColHeading heading="Project Number" />,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    headerStyle,
    accessor: 'projectNumber',
    style: cellStyle,
    id: 'projectNo',
  },
  {
    Header: () => <ColHeading heading="Project Address" />,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    headerStyle,
    accessor: 'projectAddress',
    style: cellStyle,
    id: 'projectAddress',
  },
  {
    Header: () => <ColHeading heading="Project Name" />,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    headerStyle,
    accessor: 'projectName',
    style: cellStyle,
    id: 'projectName',
  },
  {
    Header: () => <ColHeading heading="Date Uploaded" showDivider={false} />,
    Cell: (props) => (
      <div className={s.tblCell}>
        {props.value ? formatDate(props.value) : null}
      </div>
    ),
    headerStyle,
    accessor: 'dateUploaded',
    style: cellStyle,
    id: 'dateUploaded',
  },
  {
    Header: () => <ColHeading heading="Date SealPacted" showDivider={false} />,
    Cell: (props) => (
      <div className={s.tblCell}>
        {props.value ? formatDate(props.value) : null}
      </div>
    ),
    headerStyle,
    accessor: 'dateSealPacted',
    style: cellStyle,
    id: 'dateSealPacted',
  },
];
export default COLUMNS;
