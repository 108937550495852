import React from 'react';
import DynamicModal from '../../DynamicModal';
import { Row, Button, Col } from 'react-bootstrap';
import s from './style.scss';
import { Formik, Form } from 'formik';
import { ChangePasswordValidationSchema } from '../../../config/FormSchemas';
import MaskedInput from '../../AppComponents/MaskedInput';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';

const ChangePassword = ({ show, handleModal, firebase }) => {
  return (
    <DynamicModal
      title="Change your password"
      show={show}
      hideModal={handleModal}
    >
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          verifyPassword: '',
        }}
        validationSchema={ChangePasswordValidationSchema}
        onSubmit={(_values, { setSubmitting }) => {
          const { newPassword, currentPassword } = _values;
          if (currentPassword.match(newPassword)) {
            store.addNotification({
              title: 'Error',
              message: 'New password should not contain current password.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
          }
          else {
            firebase
              .doPasswordUpdate(currentPassword, newPassword)
              .then((res) => {
                handleModal();
                store.addNotification({
                  title: 'Updated!',
                  message: 'Password updated successfully.',
                  type: 'default',
                  ...NOTIFICATION_PROPS,
                });
              })
              .catch((e) => {
                store.addNotification({
                  title: 'Error',
                  message: e.message,
                  type: 'danger',
                  ...NOTIFICATION_PROPS,
                });
              });
          }
        }}
      >
        {(props) => {
          return (
            <Form onSubmit={props.handleSubmit}>
              <Row className={`d-flex flex-column ${s.padderContainer}`}>
                <MaskedInput
                  name="currentPassword"
                  formikProps={props}
                  type="password"
                  label="Confirm your current password"
                />
                <MaskedInput
                  name="newPassword"
                  formikProps={props}
                  type="password"
                  label="New password"
                />
                <MaskedInput
                  name="verifyPassword"
                  formikProps={props}
                  type="password"
                  label="Verify password"
                />
              </Row>
              <Row className="pt-4 mt-4">
                <Col
                  xs={12}
                  className={`d-flex justify-content-between ${s.btnStyle}`}
                >
                  <Button variant="outline-dark" onClick={handleModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Confirm
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </DynamicModal>
  );
};

export default ChangePassword;
