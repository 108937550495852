/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import s from './style.scss';
import { Formik } from 'formik';
import { OnboardingCompletionValidationSchema } from '../../../../config/FormSchemas';
import { withFirebase } from '../../../../auth/firebase';
import {
  sendOnboardingRequest,
  sendSignupRequest,
} from '../../../../services/Auth';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS, LIABILITY } from '../../../../utils/constants';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../../easyStateStore';
import MaskedInput from '../../../AppComponents/MaskedInput';
import MaskedDropDown from '../../../AppComponents/MaskedDropdown';
import { CONCENTRATION } from '../../../../utils/constants';

const OnboardingCompletion = (props) => {
  const { firebase, user, reloadUser } = props;
  const [loading, setLoading] = useState(false);
  const [disciplines, setDisciplines] = useState([]);
  const [discipline, setDiscipline] = useState('');
  const [concentration, setConcentration] = useState('');
  const [concentrationList, setConcentrationList] = useState([]);
  const [otherConcentration, setOtherConcentration] = useState(false);
  const [otherConcentrationValue, setOtherConcentrationValue] = useState('');
  const [professionalLiabilityInsurer, setProfessionalLiabilityInsurer] =
    useState('');
  const [
    otherProfessionalLiabilityInsurer,
    setOtherProfessionalLiabilityInsurer,
  ] = useState('');
  const [
    showOtherProfessionalLiabilityInsurer,
    setShowOtherProfessionalLiabilityInsurer,
  ] = useState(false);

  useEffect(() => {
    console.log('detailsTable user', user);

    firebase.getDisciplines().then((querySnapshot) => {
      if (querySnapshot) {
        const disciplinesArr = [];
        querySnapshot.forEach((doc) => {
          disciplinesArr.push({ label: doc.label, value: doc.value });
        });
        setDisciplines(disciplinesArr);
        console.log('disciplinesArr', disciplinesArr);
      }
    });
  }, [firebase]);

  useEffect(() => {
    if (discipline) {
      firebase.getConcentrations(discipline).then((concentrations) => {
        console.log('concentrations', concentrations);
        setConcentrationList(concentrations);
      });
    }
  }, [discipline]);

  const onFormSubmit = async (event) => {
    event.preventDefault();

    const {
      adminEmail,
      firstName,
      middleName,
      lastName,
      website,
      firmName,
      discipline,
      concentration,
      otherConcentration,
      liability,
      otherLiability,
    } = event.target.elements;

    console.log('firstName.value', firstName.value);

    if (
      firstName.value === '' ||
      lastName.value === '' ||
      adminEmail.value === ''
    ) {
      store.addNotification({
        title: 'Error!',
        message: 'Please fill all the mandatory fields.',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    } else if (
      !/^[A-Za-z ]+$/.test(firstName.value) ||
      firstName.value === ''
    ) {
      store.addNotification({
        title: 'Error!',
        message: 'Invalid first name.',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    } else if (!/^[A-Za-z ]+$/.test(lastName.value) || lastName.value === '') {
      store.addNotification({
        title: 'Error!',
        message: 'Invalid last name.',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    } else if (
      !/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/.test(adminEmail.value) ||
      adminEmail.value === ''
    ) {
      store.addNotification({
        title: 'Error!',
        message: 'Invalid administrator email.',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    }
    // else if (liability.value == '') {
    //   store.addNotification({
    //     title: 'Error!',
    //     message: 'Invalid professional liability insurer.',
    //     type: 'danger',
    //     ...NOTIFICATION_PROPS,
    //   });
    // }
    else {
      setLoading(true);

      let liability_value = null,
        other_liability_value = null;
      if (liability && liability.value) {
        liability_value = liability.value;

        if (liability.value === 'Other')
          other_liability_value = otherLiability.value;
      }

      console.log('liability.value', liability.value);
      console.log('liability_value', liability_value);
      console.log('other_liability_value', other_liability_value);

      const updatedValues = {
        adminEmail: adminEmail.value,
        firstName: firstName.value,
        middleName: middleName ? middleName.value : null,
        lastName: lastName.value,
        website: website ? website.value : null,
        firmName: firmName ? firmName.value : null,
        discipline: discipline ? discipline.value : null,
        concentration: concentration ? concentration.value : null,
        otherConcentration: otherConcentration
          ? otherConcentration.value
          : null,
        liability: liability_value,
        otherLiability: other_liability_value,
        isOnboardingCompleted: true,
      };

      await firebase
        .updateUser(user.uid, updatedValues)
        .then(async () => {
          await reloadUser();
          // appEasyState.onboardingCompleted = true
          localStorage.setItem('onboardingCompleted', 'true');
          localStorage.setItem('userId', user.uid);
          localStorage.setItem('showOnboardingCompletionMessage', 'true');
          // window.location.reload(false);
          window.location.href = 'seals';
          appEasyState.showOnboardingCompletionMessage = 'true';
        })
        .catch((error) => {
          setLoading(false);
          store.addNotification({
            title: 'Error',
            message: 'Onboarding completed unsuccessfully.',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
          console.log('error', error);
        });
    }
  };

  const handleDisciplineChange = (value) => {
    setDiscipline(value);
    // user.discipline = value;
    if (value === 'landSurveyor') {
      // user.concentration = 'N/A'
      setConcentration('N/A');
      setOtherConcentration(false);
      setOtherConcentrationValue(null);
    }
    console.log('disciplines  ===> ', disciplines);
    console.log('value ====> ', value);
    let concentrationList = CONCENTRATION.filter(function (item) {
      return item.id === value;
    });
    console.log('concentrationList ===> ', concentrationList);
    setConcentrationList(concentrationList[0].items);
  };

  const handleConcentrationChange = (value) => {
    setOtherConcentration(false);
    setOtherConcentrationValue(null);

    if (value === 'Other') {
      setOtherConcentration(true);
    }
    setConcentration(value);
    // user.concentration = value;
  };

  const handleOtherConcentrationChange = (e) => {
    setOtherConcentrationValue(e.target.value);
    // user.concentration = e.target.value;
  };

  const handleLiabilityChange = (value) => {
    setProfessionalLiabilityInsurer(value);
    // setOtherProfessionalLiabilityInsurer('')
    setShowOtherProfessionalLiabilityInsurer(value === 'Other');
  };

  const handleOtherLiabilityChange = (e) => {
    setOtherProfessionalLiabilityInsurer(e.target.value);
  };

  return (
    <Formik
      initialErrors={{}}
      initialValues={{
        adminEmail: '',
        firstName: '',
        middleName: '',
        lastName: '',
        website: '',
        firmName: '',
        discipline: '',
        concentration: '',
        otherConcentration: '',
        liability: '',
        otherLiability: '',
      }}
      validationSchema={OnboardingCompletionValidationSchema}
    >
      {(formikProps) => {
        const { errors } = formikProps;
        return (
          <Form onSubmit={onFormSubmit}>
            <Container>
              <div className={s.wrapper}>
                <div className={s.centerClass}>
                  <div className={`${s.marginForm}`}>
                    <div
                      id="Signup"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Card className={s.onboardingCard}>
                        <div className={s.wrapper}>
                          <Card.Body style={{ padding: '0' }}>
                            <Row className={'mt-3'}>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <MaskedInput
                                  name="firstName"
                                  focus={true}
                                  formikProps={formikProps}
                                  label="First Name"
                                  placeholder="Enter First Name"
                                  isMandatory={true}
                                />
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <MaskedInput
                                  name="middleName"
                                  formikProps={formikProps}
                                  label="Middle Name"
                                  placeholder="Enter Middle Name"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <MaskedInput
                                  name="lastName"
                                  formikProps={formikProps}
                                  label="Last Name"
                                  placeholder="Enter Last Name"
                                  isMandatory={true}
                                />
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <MaskedInput
                                  name="firmName"
                                  formikProps={formikProps}
                                  label="Firm Name"
                                  placeholder="Enter Firm Name"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <MaskedInput
                                  name="website"
                                  formikProps={formikProps}
                                  label="Website"
                                  placeholder="Enter URL"
                                  content="Adding your company website is completely optional, and a chance for you to market your own firm for users who verify this SealPact in the future."
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <MaskedInput
                                  name="adminEmail"
                                  formikProps={formikProps}
                                  label="Administrator Email"
                                  placeholder="example@email.com"
                                  isMandatory={true}
                                  content="This is the person at your firm who heads up SealPact coordination. If you are not sure who this is, use your own email for now."
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <MaskedDropDown
                                  controlId={'discipline'}
                                  label={'Discipline'}
                                  onChange={handleDisciplineChange}
                                  dataArray={disciplines}
                                  defaultValue={discipline}
                                  user={user}
                                  props={formikProps}
                                  edit={false}
                                />
                              </Col>
                              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <MaskedDropDown
                                  controlId={'concentration'}
                                  label={'Concentration'}
                                  onChange={handleConcentrationChange}
                                  dataArray={concentrationList || []}
                                  defaultValue={concentration || null}
                                  user={user}
                                  props={formikProps}
                                  edit={false}
                                />
                              </Col>
                            </Row>
                            {otherConcentration && (
                              <Row>
                                <Col>
                                  <MaskedInput
                                    name="otherConcentration"
                                    formikProps={formikProps}
                                    label="Enter Concentration"
                                    placeholder="Enter Other Concentration"
                                    onChange={handleOtherConcentrationChange}
                                  />
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col>
                                <MaskedDropDown
                                  controlId={'liability'}
                                  label={'Professional Liability Insurer'}
                                  onChange={handleLiabilityChange}
                                  dataArray={LIABILITY}
                                  defaultValue={professionalLiabilityInsurer}
                                  user={user}
                                  props={formikProps}
                                  edit={false}
                                  content={
                                    'This is optional, don’t worry if you don’t know it right now. There may be some benefits that apply, and if so, you can always add this later.'
                                  }
                                />
                                {showOtherProfessionalLiabilityInsurer && (
                                  <MaskedInput
                                    name="otherLiability"
                                    formikProps={formikProps}
                                    label="Enter Professional Liability Insurer"
                                    placeholder="Enter Other Professional Liability Insurer"
                                  />
                                )}
                              </Col>
                            </Row>
                            <Button
                              variant="primary"
                              className={s.marginDbl}
                              block
                              type="submit"
                            >
                              {loading ? (
                                <div>
                                  <span className="spinner-border spinner-border-sm mr-2" />
                                  Saving
                                </div>
                              ) : (
                                <div>Save</div>
                              )}
                            </Button>
                          </Card.Body>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withFirebase(view(OnboardingCompletion));
