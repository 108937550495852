/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import s from './style.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MaskedInput from '../../../AppComponents/MaskedInput';
import { withFirebase } from '../../../../auth/firebase';

const CreateNewSealForm = ({
  formikProps,
  handleOtherState,
  getName,
  getLicense,
  getApprovalStatus,
  getExpirationDate,
  getState,
  firebase,
  createNewSeal,
  isStamp,
}) => {
  const [otherState, setOtherState] = useState(false);
  const [expirationDate, setExpirationDate] = useState(Date.now());
  const [states, setStates] = useState([]);
  const [canadianStates, setCanadianStates] = useState([]);

  useEffect(() => {
    firebase.getStates().then((usStates) => {
      setStates(usStates);
    });
  }, []);
  useEffect(() => {
    firebase.getCanadianStates().then((caStates) => {
      setCanadianStates(caStates);
    });
  }, []);

  const handleChange = (date) => {
    console.log('date', date);
    setExpirationDate(date);
    getExpirationDate(date);
  };

  const handleNewState = (value) => {
    if (value === 'Other') {
      setOtherState(true);
     handleOtherState(true);
    } else {
      getState(value);
      setOtherState(false);
      handleOtherState(false);
      // isOtherState(false);
    }
  };

  return (
    <div className={s.formStyle}>
      <MaskedInput
        name="sealName"
        style={s.formLabel}
        formikProps={formikProps}
        label={isStamp ? 'Reviewer Name' : 'Name'}
        onTextChange={getName}
        maxLength={30}
        content={
          'This is how your name appears on your seal. Don’t worry about “.” or other punctuation here.'
        }
      />
      {!isStamp && (
        <Form.Group controlId="state">
          <Form.Label>State</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => handleNewState(e.target.value)}
            onBlur={formikProps.handleChange}
          >
            <option value="" selected disabled className={s.fwBold}>
              US States & Territories
            </option>
            {states.map((item, i) => (
              <option key={i} value={item.label}>
                {item.label}
              </option>
            ))}
            <option value="" disabled className={s.fwBold}>
              Canadian Provinces & Territories
            </option>

            {canadianStates.map((item, i) => (
              <option key={i} value={item.label}>
                {item.label}
              </option>
            ))}
            <option value="" disabled className={s.fwBold}>
              Other State
            </option>
            <option value="Other">
              Other
            </option>
          </Form.Control>
          {formikProps.errors.state && (
            <p className={`input-feedback ${s.error}`}>
              {formikProps.errors.state}
            </p>
          )}
        </Form.Group>
      )}
      {otherState && (
        <Form.Group className={s.inputGroup} controlId="otherState">
          <Form.Label className={s.boldLabel}>Enter State</Form.Label>
          <Form.Control
            type="text"
            autoFocus
            onChange={(e) => {
              console.log('e.target.value: ', e.target.value)
              getState(e.target.value);
            }}
            onBlur={formikProps.handleChange}
          />
          <p className={`input-feedback ${s.error}`}>
            {formikProps.errors.otherState}
          </p>
        </Form.Group>
      )}
      {!isStamp && (
        <MaskedInput
          name="newLicenseNo"
          style={s.formLabel}
          formikProps={formikProps}
          label="License Number"
          onTextChange={getLicense}
          maxLength={25}
        />
      )}

      {isStamp && (
        <MaskedInput
          name="approvalStatus"
          style={s.formLabel}
          formikProps={formikProps}
          label="Approval Status"
          onTextChange={getApprovalStatus}
          maxLength={25}
        />
      )}

      {!isStamp && (
        <>
          <Form.Label>Expiration Date</Form.Label>
          <DatePicker
            selected={expirationDate}
            onChange={handleChange}
            placeholderText=""
            name="expirationDate"
            startDate={expirationDate}
            minDate={Date.now()}
          />
        </>
      )}
    </div>
  );
};

export default withFirebase(CreateNewSealForm);
