/* eslint-disable indent */
import React, { useEffect } from 'react';
import DynamicModal from '../../DynamicModal';
import { Button, Image } from 'react-bootstrap';
import s from './style.scss';
import ButtonCheck from '../../../assets/check-mark.png';
import DangerButton from '../../../assets/error-mark.png';
import { view } from 'react-easy-state'
import { appEasyState } from '../../../easyStateStore';

const SealPactDetails = ({
  confirmationValues,
  documentInformation,
  sealPactDownloadUrl,
  documentStatus,
  hideModal,
  show,
  history: { push },
  firebase,
  user,
}) => {
  console.log('firebase', firebase);
  console.log('documentInformation', documentInformation);
  console.log('confirmationValues testing', confirmationValues);

  let imgStyle =
    documentStatus === 'pacted'
      ? ButtonCheck
      : documentStatus === 'invalidated'
      ? DangerButton
      : null;

  useEffect(() => {
    appEasyState.firebase = firebase;
    appEasyState.user = user;
    appEasyState.documentInformation = documentInformation;
  }, [])

  return (
    <DynamicModal
      show={show}
      title="SealPact Validation Status"
      hideModal={hideModal}
      size="lg"
    >
      {documentStatus === 'pacted' ? (
        <div className={s.content}>
          {/* <p>
          This document has not be modified after being digitally seal. SealPact
          is validated.
        </p> */}
          <p className={s.sealPactIdStyle}>
            <Image width={35} className={s.icon} src={imgStyle} /> SealPact id:{' '}
            {confirmationValues ? confirmationValues.sealpactId : null}
          </p>
          <ul>
            <li>The digital signature keys are unique to the licensee</li>
            {/* <li>
              The document has not been altered subsequent to the SealPact
            </li> */}
            <li>
              The private key used for signing electronic documents is under the
              licensee’s direct and exclusive control and has not been manually
              invalidated by the licensee
            </li>
            <li>
              <a href={'https://originstamp.com/verify/'} target={'_'}>
                Click Here to see if this document has been modified
              </a>
            </li>
          </ul>
          <p>
            Note: the checkmark above confirms that this document is valid and
            active per the <br />
            signer. Clicking this link will allow you to verify that the
            document has not been <br />
            tampered with after it was digitally signed with SealPact. No
            additional software <br />
            downloads or special readers are needed to verify - it's totally
            free! Any browser <br />
            with an internet connection should work.
          </p>
        </div>
      ) : (
        <div className={s.content}>
          {/* <p>
            <Image className={s.icon} src={imgStyle} /> - The document SealPact
            has been either manually or automatically invalidated by the
            licensee.
          </p> */}
          <p className={s.sealPactIdStyle}>
            <Image width={35} className={s.icon} src={imgStyle} /> SealPact id:{' '}
            {confirmationValues ? confirmationValues.sealpactId : null}
          </p>
          <ul>
            <li>The digital signature keys are unique to the licensee</li>
            {/* <li>
              The document has not been altered subsequent to the SealPact
            </li> */}
            <li>
              <b style={{ color: 'red' }}>
                The private key used for signing electronic documents is under
                the licensee’s direct and exclusive control and has been
                manually invalidated by the licensee
              </b>
            </li>
            <li className={s.reasonText}>
              <b style={{ color: 'red' }}>
                Reason: {confirmationValues.invalidationReason}
              </b>
            </li>
          </ul>
        </div>
      )}

      <div className={s.footer}>
        <Button variant="secondary" onClick={hideModal}>
          Back
        </Button>
        <Button
          onClick={() => {
            push({
              pathname: '/sealpactDetails',
              state: {
                confirmationValues: confirmationValues,
                documentInformation: documentInformation,
              },
            });
            // if (sealPactDownloadUrl) {

            // } else {
            //   push({
            //     pathname: '/sealpactDetails',
            //     state: {
            //       confirmationValues: confirmationValues,
            //       documentInformation: documentInformation,
            //     },
            //   });
            // }
          }}
          variant="text"
        >
          SealPact Details
        </Button>
      </div>
    </DynamicModal>
  );
};

export default view(SealPactDetails);
