import React, { Fragment, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import s from '../style.scss';

const ActivateSeal = ({ selectedSeal, date, setDate, value, setValue, licenseNo, setLicenseNo, approvalStatus, setApprovalStatus, state, setState }) => {

  const handleChange = (date) => {
    console.log('date type ===> ', typeof date);
    console.log('date ===> ', date);
    setDate(date);
    // selectedSeal.expirationDate = date.getTime();
  };

  const handleLicenseChange = (e) => {
    setLicenseNo(e.target.value);
    // selectedSeal.lisenceNumber = e.target.value;
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    // selectedSeal.state = e.target.value;
  };

  const handleApprovalChange = (e) => {
    setApprovalStatus(e.target.value);
    // selectedSeal.approvalStatus = e.target.value;
  };

  return (
    <Fragment>
      <Form.Group controlId="newName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          className={s.back}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={true} //{isSealInfo}
        />
      </Form.Group>
      {selectedSeal.type !== String('submittalReviewStamp') && (
        <Form.Group controlId="newState">
          <Form.Label>State</Form.Label>
          <Form.Control
            className={s.back}
            type="text"
            value={state !== 'Other' ? state : selectedSeal.otherState ? state + ', ' + selectedSeal.otherState : state}
            onChange={handleStateChange}
            disabled={true} //{isSealInfo}
          />
        </Form.Group>
      )}
      {selectedSeal.type !== String('submittalReviewStamp') && (
        <Form.Group controlId="newLicenseNo">
          <Form.Label>License Number</Form.Label>
          <Form.Control
            type="text"
            value={licenseNo}
            onChange={handleLicenseChange}
            disabled={true} //{isSealInfo}
          />
        </Form.Group>
      )}
      {selectedSeal.type === String('submittalReviewStamp') && (
        <Form.Group controlId="approvalStatus">
          <Form.Label>Approval Status</Form.Label>
          <Form.Control
            type="text"
            value={approvalStatus}
            onChange={handleApprovalChange}
            // disabled={true} //{isSealInfo}
          />
        </Form.Group>
      )}
      {selectedSeal.type !== String('submittalReviewStamp') && (
        <>
          <Form.Label>Expiration Date</Form.Label>
          <DatePicker
            selected={date}
            onChange={handleChange}
            placeholderText=""
            startDate={date}
            minDate={Date.now()}
          />
        </>
      )}
    </Fragment>
  );
};

export default ActivateSeal;
