import React, { useState, useEffect } from 'react';
import DynamicModal from '../../DynamicModal';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import s from './style.scss';
import MaskedInput from '../../AppComponents/MaskedInput';
import { Formik } from 'formik';
import { SUBSCRIBE_SCHEMA } from '../../../config/FormSchemas';

const GetUserEmailModal = ({
  handleSubscribe,
  show,
  // saveUserDetails,
  history: { push },
  firebase,
  user,
}) => {
  return (
    <DynamicModal
      title="Subscribe"
      size="lg"
      show={show}
      hideModal={handleSubscribe}
    >
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={SUBSCRIBE_SCHEMA}
        onSubmit={(_values, { setSubmitting }) => {
          setSubmitting(true);
          console.log('_values before submitting ==>', _values);
          // saveUserDetails(_values.email);
          handleSubscribe(_values.email);
        }}
      >
        {(formikProps) => {
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <Container>
                <Row className="d-flex flex-column align-items-center">
                  <Col xs={12} className="mt-3">
                    <div className={s.modalContent}>
                      <p>
                        Would you like updates on edits to this
                        document/project?
                      </p>
                      <p>
                        If so, please provide your email below. If not, please
                        press "No Thanks".
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <MaskedInput
                      name="email"
                      formikProps={formikProps}
                      label="Email"
                      onTextChange={formikProps.handleChange}
                      maxLength={50}
                    />
                  </Col>
                </Row>
                <Row className="pt-4 mt-4">
                  <Col
                    xs={12}
                    className={`d-flex justify-content-between ${s.btnStyle}`}
                  >
                    <Button
                      variant="outline-dark"
                      disabled={formikProps.isSubmitting}
                      onClick={() => {
                        handleSubscribe();
                      }}
                    >
                      No Thanks
                    </Button>
                    <Button
                      disabled={formikProps.isSubmitting}
                      variant="primary"
                      type="submit"
                    >
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </DynamicModal>
  );
};
export default GetUserEmailModal;
