import React from 'react';
import { Tab, Row, Col } from 'react-bootstrap';
import SideBar from '../SideBar';
import s from './style.scss';
import Footer from './Footer';
import withAuthorization from '../../auth/session/withAuthorization';

const Layout = (props) => {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
      <Row noGutters>
        <SideBar {...props} />
        <Col sm={9} lg={10} xl={10}>
          <div className={s.content}>{props.children}</div>
        </Col>
        <Col
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 9, offset: 3 }}
          lg={{ span: 10, offset: 2 }}
          className={`bg-rgba(0,0,0,.1) d-flex justify-content-end ${s.footer}`}
        >
          <Footer />
        </Col>
      </Row>
    </Tab.Container>
  );
};

const condition = (user) => !!user;
export default withAuthorization(condition)(Layout);
