import React, { Fragment, useEffect } from 'react';
import s from './style.scss';
import LogoBrand from '../../LogoBrand';
import { withFirebase } from '../../../auth/firebase';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { view } from 'react-easy-state';
import {appEasyState} from '../../../easyStateStore';
import { APP_URL } from '../../../utils/constants';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';
import { useHistory } from "react-router-dom";


function EmailVerification(props) {

  const history = useHistory();

  useEffect(() => {
    appEasyState.user = props.firebase.getCurrentUser()
  }, []);

  const signOut = async () => {
    await props.firebase.doSignOut()
      .then((signoutRes) => {
        history.push('login')
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const sendLink = async () => {
    await appEasyState.user
      .sendEmailVerification({
        url: APP_URL,
      }).then(async (emailSent) => {
        await signOut();
        store.addNotification({
          title: 'Email Sent!',
          message: 'Please validate your user information by clicking the link in the email we just sent you. If you do not receive an email within a minute or two, please check your spam folder.',
          type: 'default',
          ...NOTIFICATION_PROPS,
        });
    }).catch(err => {
      console.log("catch err: ", err);
      store.addNotification({
        title: 'Error',
        message: err.message,
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    })

  }

  return (
    <Container>
      <div className={s.root}>
        <div className={s.headerLogo}>
          <LogoBrand imgSize={48} className={s.logoStyle} {...props} />
        </div>
        <div className={s.content}>
          <Row>
            <Col
              xs={{ span: 10, offset: 1 }}
              sm={{ span: 8, offset: 2 }}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 4 }}
            >
              <Fragment>
                <div className={s.title}>
                  <p>Email Verification</p>
                </div>
                <p className={s.textSmall}>
                  Please verify your email to continue or click on the button below to generate another link
                </p>

                <Button
                  variant="primary"
                  block
                  onClick={sendLink}
                >
                  Send Link
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={signOut}
                  block
                >
                  Back to Login
                </Button>
              </Fragment>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  )
}

export default withFirebase(view(EmailVerification));