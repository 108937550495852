import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Col, Row } from 'react-bootstrap';
import s from './style.scss';
import DynamicModal from '../../../DynamicModal';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import CustomTooltip from '../../../AppComponents/CustomTooltip';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../../easyStateStore';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import { GOOGLE_API_KEY } from '../../../../utils/constants';
import { PLACE_AUTOCOMPLETE } from '../../../../utils/constants';

const DocumentInformationForm = ({
  handleModal,
  isFormSubmitted,
  show,
  props,
}) => {
  var getFirstName = props.user.firstName;
  var getLastName = props.user.lastName;
  var getFullName = getFirstName + ' ' + getLastName;
  var firmName = props.user.firmName;
  const website = props.user.website || '';

  // const [showCapturedAddress, setShowCapturedAddress] = useState(false)

  useEffect(() => {
    appEasyState.projectAddressMapsTextSearch = undefined;
  }, []);

  const validation = (e) => {
    const { projectName, Name, firmName, projectNumber, website } =
      e.target.elements;

    if (
      projectName.value &&
      Name.value &&
      appEasyState.projectAddressMapsTextSearch &&
      firmName.value &&
      projectNumber.value
    ) {
      const documentInfo = {
        projectName: projectName.value,
        name: Name.value,
        projectAddress: appEasyState.projectAddressMapsTextSearch,
        firmName: firmName.value,
        projectNumber: projectNumber.value,
        website: website.value,
      };
      isFormSubmitted(documentInfo);
      return true;
    }

    store.addNotification({
      title: 'Error',
      message: 'All fields are required on this page.',
      type: 'danger',
      ...NOTIFICATION_PROPS,
    });
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validation(e);
  };

  const [options, setOptions] = useState([]);
  const [singleSelection, setSingleSelection] = useState([]);

  const handleSingleSelection = (arr) => {
    console.log('rrrr arr: ', arr);
    setSingleSelection(arr);
    appEasyState.projectAddressMapsTextSearch = arr[0];
    console.log(
      'rrrr app projectAddress',
      appEasyState.projectAddressMapsTextSearch,
    );
  };

  // const useCapturedAddress = () => {
  //   setOptions([appEasyState.projectAddressReverseGeocode])
  //   setShowCapturedAddress(true)
  // }

  const filterBy = () => true;

  const textSearch = (string) => {
    // setShowCapturedAddress(false)

    if (string !== '') {
      console.log('rrrr string', string);
      appEasyState.projectAddressMapsTextSearch = string;

      axios
        .post(
          PLACE_AUTOCOMPLETE,
          {
            keyword: string,
            radius: 1500,
            google_api_key: GOOGLE_API_KEY,
          },
          {
            headers: {
              'Accept-Encoding': 'application/json',
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          console.log('rrrr response before: ', response);
          if (response.data) {
            console.log('rrrr response data: ', response.data);
            if (response.data.length > 0) {
              let predictions = response.data;
              let places = [];
              for (const prediction of predictions) {
                places.push(prediction.description);
              }
              console.log('rrrr places: ', places);
              setOptions(places);
            } else {
              store.addNotification({
                title: 'Error',
                message: 'Data retrieved unsuccessfully',
                type: 'warning',
                ...NOTIFICATION_PROPS,
              });
            }
            // if (response.data.data) {
            //   console.log('rrrr response: ', response.data.data)
            //   let predictions = response.data.data
            //   let places = []
            //   for (const prediction of predictions) {
            //     places.push(prediction.description)
            //   }
            //   console.log('rrrr places: ', places)
            //   setOptions(places)
            // }
            // else {
            //   store.addNotification({
            //     title: 'Error',
            //     message: "Data retrieved unsuccessfully",
            //     type: 'warning',
            //     ...NOTIFICATION_PROPS
            //   })
            // }
          } else {
            store.addNotification({
              title: 'Error',
              message: 'Data retrieved unsuccessfully',
              type: 'warning',
              ...NOTIFICATION_PROPS,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          return;
        });

      // let nearby_places_url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${latitude}%2C${longitude}&radius=${radius}&keyword=${query_text}&key=${GOOGLE_API_KEY}`
      //
      // let geocode_search_url = `https://maps.googleapis.com/maps/api/geocode/json?address=${query_text}&key=${GOOGLE_API_KEY}`
      //
      // let text_search_url = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${query_text}&radius=${radius}&key=${GOOGLE_API_KEY}`
      //
      // let config = {
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     // 'Content-Type': 'application/x-www-form-urlencoded'
      //   }
      // }

      // let place_autocomplete_url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${keyword}&types=geocode&radius=${radius}&key=${google_api_key}`
    }
  };

  return (
    <DynamicModal
      show={show}
      title="Document Information"
      hideModal={handleModal}
    >
      <Container>
        <Form onSubmit={onSubmit} className={s.formContent}>
          <Row>
            <Col
              xs={{ span: 10, offset: 1 }}
              sm={{ span: 8, offset: 2 }}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              xl={{ span: 4, offset: 4 }}
            >
              <div className={s.labels}>
                <CustomTooltip
                  content={
                    'We know, this may seem a little tedious, but it’s an important part of securing your document.'
                  }
                >
                  <Form.Group controlId="Name">
                    <Form.Label>
                      <p>Name</p>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={30}
                      defaultValue={getFullName}
                    />
                  </Form.Group>
                </CustomTooltip>
                <CustomTooltip
                  content={
                    'For this, put your current employer, who is contracted for this project.  If you are self-employed, with no firm, copy your own name here.'
                  }
                >
                  <Form.Group controlId="firmName">
                    <Form.Label>
                      <p>Firm Name</p>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={50}
                      defaultValue={firmName}
                    />
                  </Form.Group>
                </CustomTooltip>
                <Form.Group controlId="website">
                  <Form.Label>
                    <p>Company Website (Optional)</p>
                  </Form.Label>
                  <CustomTooltip
                    trigger={'hover'}
                    location={'auto'}
                    content={
                      'Adding your company website is completely optional, and a chance for you to market your own firm for users who verify this SealPact in the future.'
                    }
                  >
                    <i
                      style={{ marginLeft: '3px', marginTop: '3px' }}
                      className="fa fa-info-circle"
                      aria-hidden="true"
                    ></i>
                  </CustomTooltip>
                  <Form.Control
                    type="text"
                    maxLength={50}
                    defaultValue={website}
                  />
                </Form.Group>

                <CustomTooltip
                  content={
                    'Think of something “short and sweet” if you haven’t already.'
                  }
                >
                  <Form.Group controlId="projectName">
                    <Form.Label>
                      <p>Project Name</p>
                    </Form.Label>
                    <Form.Control type="text" maxLength={30} />
                  </Form.Group>
                </CustomTooltip>

                <CustomTooltip
                  content={
                    'Most firms have this as an important way to identify projects and deliverables.  If you don’t use project numbers, you should come up with your own numbering scheme to help track documents and projects in SealPact.'
                  }
                >
                  <Form.Group controlId="projectNumber">
                    <Form.Label>
                      <p>Project Number</p>
                    </Form.Label>
                    <Form.Control type="text" maxLength={25} />
                  </Form.Group>
                </CustomTooltip>

                <CustomTooltip
                  content={
                    'Use the format: Street Address, City, ST. Don’t worry about zip code if it doesn’t fit or you don’t know it. It is important to fit the address in the characters allotted.  Use abbreviations as needed to get as much of the address in as possible.'
                  }
                >
                  <Form.Group controlId="projectAddress">
                    <Form.Label>
                      <p>Project Address</p>
                    </Form.Label>
                    <AsyncTypeahead
                      id="projectAddress"
                      key="projectAddress"
                      filterdBy={filterBy}
                      minLength={1}
                      onSearch={textSearch}
                      onChange={handleSingleSelection}
                      options={options}
                      // placeholder="Choose a state..."
                      className={s.typeaheadInput}
                      // autoFocus={!showCapturedAddress}
                    />
                  </Form.Group>
                </CustomTooltip>
                {/*<div className={s.greyAddress}>*/}
                {/*  <p>System identified address:</p>*/}
                {/*  <span onClick={useCapturedAddress}>{appEasyState.projectAddressReverseGeocode}</span>*/}
                {/*</div>*/}
              </div>
            </Col>
          </Row>
          <div className={s.footerBtn}>
            <Button variant="outline-dark" onClick={handleModal}>
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Container>
    </DynamicModal>
  );
};

export default view(DocumentInformationForm);
