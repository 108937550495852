/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {view} from 'react-easy-state';
import s from './style.scss';
import { store } from 'react-notifications-component';
import { Button, Card, Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import GiftIcon from '../../../assets/GiftLarge.svg'
import CloseButtonIcon from '../../../assets/CloseButton.svg'
import CopyIcon from '../../../assets/Copy.svg';


const ConfirmRedeemModal = ({ showConfirmRedeemModal, setShowConfirmRedeemModal, setConfirmRedeem, sumAmountOff }) => {

  return (
    <Modal
      show={showConfirmRedeemModal}
      onHide={() => setShowConfirmRedeemModal(false)}
      animation
      centered
      size={'md'}
      backdrop
      dialogClassName="modal-90w"
      className={s.confirmRedeemModal}
    >
      <Modal.Header style={{ borderBottom: '1px solid #F1F1F4', position: 'relative' }}>
        <Modal.Title
          id='modal'
          className={s.title}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img src={GiftIcon} alt='GiftIcon' />
          <div>Redeem Your Coupons</div>
        </Modal.Title>
        <img
          src={CloseButtonIcon}
          alt={'CloseButton'}
          className={s.closeButton}
          onClick={() => setShowConfirmRedeemModal(false)}
        />
      </Modal.Header>
      <Modal.Body style={{ padding: '24px' }}>
        <h6>Are your sure, you want to redeem?</h6>
        <p>Coupon Discount</p>
        <p className={s.couponAmountText}>${sumAmountOff / 100}</p>
        <p style={{ fontSize: '14px' }}>
          Please note that referral coupons can be redeemed once annually, so please make sure that you are satisfied for this renewal period.
        </p>
      </Modal.Body>
      <Modal.Footer style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        background: '#FFF',
        border: 'none',
      }}>
        <Button
          variant='primary'
          className={s.confirmRedeemButton}
          onClick={() => {
            setConfirmRedeem(true);
            setShowConfirmRedeemModal(false);
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmRedeemModal;