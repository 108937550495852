import React, { useState, useEffect } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import {
  Row,
  ToggleButtonGroup,
  ToggleButton,
  Form,
  Col,
  Button,
} from 'react-bootstrap';
import s from './style.scss';
import './multiselect.css';
import style from '../style.scss';
import { FilePicker } from 'react-file-picker';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../../utils/constants';
import {
  SHOPDESIGNS,
  SHOP_DESIGNS_VALUES,
} from '../../../../../utils/constants';

const QualityControlTab = ({
  getQualityControlTabItems,
  uploadFormToFirebase,
  confirmationValues,
}) => {
  const [qualityNeeded, setqualityNeeded] = useState(
    confirmationValues ? Boolean(confirmationValues.qualityNeeded) : false,
  );
  const [qualityNeededWithDefault, setqualityNeededWithDefault] = useState(confirmationValues.qualityNeededWithDefault
    ? confirmationValues.qualityNeededWithDefault
    : 'N/A');
  const [independentReview, setIndependentReview] = useState(
    confirmationValues ? Boolean(confirmationValues.independentReview) : false,
  );
  const [independentReviewWithDefault, setIndependentReviewWithDefault] = useState(confirmationValues.independentReviewWithDefault ?
    confirmationValues.independentReviewWithDefault
    : 'N/A');
  const [shopDrawings, setShopDrawings] = useState(
    confirmationValues ? confirmationValues.shopDrawings : false,
  );
  const [shopDrawingsWithDefault, setShopDrawingsWithDefault] = useState(confirmationValues.shopDrawingsWithDefault
    ? confirmationValues.shopDrawingsWithDefault
    :'N/A');
  const [otherShopDrawings, setOtherShopDrawings] = useState(
    confirmationValues ? Boolean(confirmationValues.otherShopDrawings) : false,
  );
  const [constructionAdministration, setConstructionAdministration] = useState(
    confirmationValues
      ? Boolean(confirmationValues.constructionAdministration)
      : false,
  );
  const [constructionAdministrationWithDefault, setConstructionAdministrationWithDefault] = useState(confirmationValues.constructionAdministrationWithDefault
    ? confirmationValues.constructionAdministrationWithDefault
    : 'N/A');
  const [specialInspection, setSpecialInspection] = useState(
    confirmationValues ? Boolean(confirmationValues.specialInspection) : false,
  );
  const [specialInspectionWithDefault, setSpecialInspectionWithDefault] = useState(confirmationValues.specialInspectionWithDefault
    ? confirmationValues.specialInspectionWithDefault
    : 'N/A');
  const [SIForm, setSIForm] = useState();
  const [SIFormType, setSIFormType] = useState();
  const [selectionLimit, setSelectionLimit] = useState(-1);

  const [shopDrawingsOtherValue, setShopDrawingsOtherValue] = useState(confirmationValues.shopDrawingsOtherValue
    ? confirmationValues.shopDrawingsOtherValue
    : '')

  const [qualityControlTabsData, setQualityControlTabsData] = useState({
    qualityNeeded: confirmationValues
      ? Boolean(confirmationValues.qualityNeeded)
      : false,
    qualityNeededWithDefault: confirmationValues.qualityNeededWithDefault
      ? confirmationValues.qualityNeededWithDefault
      : 'N/A',
    independentReview: confirmationValues
      ? Boolean(confirmationValues.independentReview)
      : false,
    independentReviewWithDefault: confirmationValues.independentReviewWithDefault
      ? confirmationValues.independentReviewWithDefault
      : 'N/A',
    shopDrawings: confirmationValues
      ? Boolean(confirmationValues.shopDrawings)
      : false,
    shopDrawingsWithDefault: confirmationValues.shopDrawingsWithDefault
      ? confirmationValues.shopDrawingsWithDefault
      : 'N/A',
    otherShopDrawings: confirmationValues
      ? Boolean(confirmationValues.otherShopDrawings)
      : false,
    constructionAdministration: confirmationValues
      ? Boolean(confirmationValues.constructionAdministration)
      : false,
    constructionAdministrationWithDefault: confirmationValues.constructionAdministrationWithDefault
      ? confirmationValues.constructionAdministrationWithDefault
      : 'N/A',
    specialInspection: confirmationValues
      ? Boolean(confirmationValues.specialInspection)
      : false,
    specialInspectionWithDefault: confirmationValues.specialInspectionWithDefault
      ? confirmationValues.specialInspectionWithDefault
      : 'N/A',
    shopDrawingsValue: confirmationValues
      ? Boolean(confirmationValues.shopDrawingsValue)
      : false,
    shopDrawingsValueWithDefault: confirmationValues.shopDrawingsValueWithDefault
      ? confirmationValues.shopDrawingsValueWithDefault
      : 'N/A',
    shopDrawingsOtherValue: shopDrawingsOtherValue
  });

  let selectedValues = confirmationValues.shopDrawingsValue
    ? typeof confirmationValues.shopDrawingsValue === 'string' && confirmationValues.shopDrawingsValue.length !== 0
      ? confirmationValues.shopDrawingsValue.split(',')
      : []
    : [];

  // const handleOtheShopDrawings = (value) => {
  //   if (value === 'Other') {
  //     setOtherShopDrawings(true);
  //     qualityControlTabsData.otherShopDrawings = true;
  //     setQualityControlTabsData(qualityControlTabsData);
  //   } else {
  //     setOtherShopDrawings(false);
  //     qualityControlTabsData.otherShopDrawings = false;
  //     qualityControlTabsData.shopDrawingsValue = value;
  //     setQualityControlTabsData(qualityControlTabsData);
  //   }
  //   getQualityControlTabItems(qualityControlTabsData);
  // };

  const handleOtherShopDrawings = (value) => {
    qualityControlTabsData.shopDrawingsOtherValue = value;
    setShopDrawingsOtherValue(value)
    setQualityControlTabsData(qualityControlTabsData);
    getQualityControlTabItems(qualityControlTabsData);
  };

  // const handleConstructionAdministration = (e) => {
  //   qualityControlTabsData.constructionAdministrationValue = e.target.value;
  //   getQualityControlTabItems(qualityControlTabsData);
  // };


  const onSelect = (selectedList, selectedItem) => {
    console.log('selectedList ===> ', selectedList);
    if (selectedItem === 'Other (add your own)') {
      setOtherShopDrawings(true);
      qualityControlTabsData.otherShopDrawings = true;
      // setQualityControlTabsData(qualityControlTabsData);
      // getQualityControlTabItems(qualityControlTabsData);
      setSelectionLimit(selectedList.length);
    }
    // else {
    //   qualityControlTabsData.shopDrawingsValue = selectedList.toString();
    //   setQualityControlTabsData(qualityControlTabsData);
    //   getQualityControlTabItems(qualityControlTabsData);
    // }

    qualityControlTabsData.shopDrawingsValue = selectedList.toString();
    setQualityControlTabsData(qualityControlTabsData);
    getQualityControlTabItems(qualityControlTabsData);

    selectedValues = selectedList;
  };


  const onRemove = (selectedList, removedItem) => {
    if (removedItem === 'Other (add your own)') {
      setSelectionLimit(-1);
      qualityControlTabsData.shopDrawingsOtherValue = '';
      qualityControlTabsData.otherShopDrawings = false;
      setOtherShopDrawings(false);
    } else if (selectedList.indexOf('Other (add your own)') > -1) {
      setSelectionLimit(selectedList.length);
    }
    // setSelectionLimit(removedItem !== 'Other' ? selectedList.length : -1);
    selectedValues = selectedList;
    qualityControlTabsData.shopDrawingsValue = selectedList.toString();
    setQualityControlTabsData(qualityControlTabsData);
    getQualityControlTabItems(qualityControlTabsData);
  };

  return (
    <div className={s.contentPadding}>
      <Row>
        <Col xl={4} lg={12}>
          <Form.Label>
            Did the client and/or owner include quality control with this
            project?
          </Form.Label>
          <ToggleButtonGroup
            type="checkbox"
            value="value"
            onChange={(e) => {
              // setqualityNeeded(!!e[1]);
              // qualityControlTabsData.qualityNeeded = Boolean(!!e[1]);
              if (e[1] === 'Yes') {
                setqualityNeeded(true);
                qualityControlTabsData.qualityNeeded = true;
              }
              else {
                setqualityNeeded(false);
                qualityControlTabsData.qualityNeeded = false;

                setIndependentReview(false);
                qualityControlTabsData.independentReview = false;
                setIndependentReviewWithDefault('N/A');
                qualityControlTabsData.independentReviewWithDefault = 'N/A';

                setShopDrawings(false);
                qualityControlTabsData.shopDrawings = false;
                setShopDrawingsWithDefault('N/A');
                qualityControlTabsData.shopDrawingsWithDefault = 'N/A';
                handleOtherShopDrawings(null);

                setConstructionAdministration(false);
                qualityControlTabsData.constructionAdministration = false;
                setConstructionAdministrationWithDefault('N/A');
                qualityControlTabsData.constructionAdministrationWithDefault = 'N/A';

                setSpecialInspection(false);
                qualityControlTabsData.specialInspection = false;
                setSpecialInspectionWithDefault('N/A');
                qualityControlTabsData.specialInspectionWithDefault = 'N/A';

                setQualityControlTabsData(qualityControlTabsData);
                getQualityControlTabItems(qualityControlTabsData);
              }
              setqualityNeededWithDefault(e[1]);
              qualityControlTabsData.qualityNeededWithDefault = e[1];
              setQualityControlTabsData(qualityControlTabsData);
              getQualityControlTabItems(qualityControlTabsData);
            }}
            className={style.inlineBtn}
          >
            <ToggleButton
              value={'Yes'}
              className={qualityNeededWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={'No'}
              className={qualityNeededWithDefault === 'No' ? s.bgBlue : s.bgWhite}
            >
              No
            </ToggleButton>
            <ToggleButton
              value={'N/A'}
              className={qualityNeededWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
            >
              N/A
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>

        <Col xl={4} lg={12}>
          <div className={qualityNeeded ? s.displayBlock : s.displayNone}>
            <Form.Label>Independent Review Required?</Form.Label>
            <ToggleButtonGroup
              type="checkbox"
              value="value"
              onChange={(e) => {
                // setIndependentReview(!!e[1]);
                // qualityControlTabsData.independentReview = Boolean(!!e[1]);
                if (e[1] === 'Yes') {
                  setIndependentReview(true);
                  qualityControlTabsData.independentReview = true;
                }
                else {
                  setIndependentReview(false);
                  qualityControlTabsData.independentReview = false;
                }
                setIndependentReviewWithDefault(e[1]);
                qualityControlTabsData.independentReviewWithDefault = e[1];
                setQualityControlTabsData(qualityControlTabsData);
                getQualityControlTabItems(qualityControlTabsData);

              }}
              className={style.inlineBtn}
            >
              <ToggleButton
                value={'Yes'}
                className={independentReviewWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
              >
                Yes
              </ToggleButton>
              <ToggleButton
                value={'No'}
                className={independentReviewWithDefault === 'No' ? s.bgBlue : s.bgWhite}
              >
                No
              </ToggleButton>
              <ToggleButton
                value={'N/A'}
                className={independentReviewWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
              >
                N/A
              </ToggleButton>
            </ToggleButtonGroup>
            <Form.Label>
              Are Shop Drawings Required? (stamped delegated/component designs?)
            </Form.Label>
            <ToggleButtonGroup
              type="checkbox"
              value="value"
              onChange={(e) => {
                // setShopDrawings(!!e[1]);
                // qualityControlTabsData.shopDrawings = Boolean(!!e[1]);
                if (e[1] === 'Yes') {
                  setShopDrawings(true);
                  qualityControlTabsData.shopDrawings = true;
                }
                else {
                  setShopDrawings(false);
                  qualityControlTabsData.shopDrawings = false;
                }
                setShopDrawingsWithDefault(e[1]);
                qualityControlTabsData.shopDrawingsWithDefault = e[1];
                setQualityControlTabsData(qualityControlTabsData);
                getQualityControlTabItems(qualityControlTabsData);
              }}
              className={style.inlineBtn}
            >
              <ToggleButton
                value={'Yes'}
                className={shopDrawingsWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
              >
                Yes
              </ToggleButton>
              <ToggleButton
                value={'No'}
                className={shopDrawingsWithDefault === 'No' ? s.bgBlue : s.bgWhite}
              >
                No
              </ToggleButton>
              <ToggleButton
                value={'N/A'}
                className={shopDrawingsWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
              >
                N/A
              </ToggleButton>
            </ToggleButtonGroup>
            {shopDrawings && (
              <Form.Group controlId="shopDrawingsValue">
                <Form.Label>If so, which?</Form.Label>
                <Multiselect
                  options={SHOP_DESIGNS_VALUES} // Options to display in the dropdown
                  selectedValues={selectedValues} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="label" // Property name to display in the dropdown options
                  closeOnSelect={false}
                  isObject={false}
                  selectionLimit={selectionLimit}
                />
              </Form.Group>
            )}
            {otherShopDrawings && shopDrawings && (
              <Form.Group controlId="otherShopDrawing">
                <Form.Label>Enter Other</Form.Label>
                <Form.Control type="text" defaultValue={shopDrawingsOtherValue} onChange={(e) => handleOtherShopDrawings(e.target.value)} />
              </Form.Group>
            )}
            <Form.Label>Construction Administration (CA)</Form.Label>
            <ToggleButtonGroup
              type="checkbox"
              value="value"
              onChange={(e) => {
                // setConstructionAdministration(!!e[1]);
                // qualityControlTabsData.constructionAdministration = Boolean(
                //   !!e[1],
                // );
                if (e[1] === 'Yes') {
                  setConstructionAdministration(true);
                  qualityControlTabsData.constructionAdministration = true;
                }
                else {
                  setConstructionAdministration(false);
                  qualityControlTabsData.constructionAdministration = false;
                }
                setConstructionAdministrationWithDefault(e[1]);
                qualityControlTabsData.constructionAdministrationWithDefault = e[1];
                setQualityControlTabsData(qualityControlTabsData);
                getQualityControlTabItems(qualityControlTabsData);
              }}
              className={style.inlineBtn}
            >
              <ToggleButton
                value={'Yes'}
                className={constructionAdministrationWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
              >
                Yes
              </ToggleButton>
              <ToggleButton
                value={'No'}
                className={constructionAdministrationWithDefault === 'No' ? s.bgBlue : s.bgWhite}
              >
                No
              </ToggleButton>
              <ToggleButton
                value={'N/A'}
                className={constructionAdministrationWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
              >
                N/A
              </ToggleButton>
            </ToggleButtonGroup>
            {constructionAdministration && (
              <Form.Group controlId="siteVisits">
                <Form.Label>Site Visits</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input site visits"
                  onChange={(e) => {
                    qualityControlTabsData.constructionAdministrationValue =
                      e.target.value;
                    // handleConstructionAdministration(e.target.value);
                    qualityControlTabsData.siteVisits = e.target.value;
                    setQualityControlTabsData(qualityControlTabsData);
                    getQualityControlTabItems(qualityControlTabsData);
                  }}
                />
              </Form.Group>
            )}
          </div>
        </Col>
        <Col xl={4} lg={12}>
          <div className={qualityNeeded ? s.displayBlock : s.displayNone}>
            <Form.Label>Special Inspections (SI)</Form.Label>
            <ToggleButtonGroup
              type="checkbox"
              value="value"
              onChange={(e) => {
                // setSpecialInspection(!!e[1]);
                // qualityControlTabsData.specialInspection = Boolean(!!e[1]);
                if (e[1] === 'Yes') {
                  setSpecialInspection(true);
                  qualityControlTabsData.specialInspection = true;
                }
                else {
                  setSpecialInspection(false);
                  qualityControlTabsData.specialInspection = false;
                }
                setSpecialInspectionWithDefault(e[1]);
                qualityControlTabsData.specialInspectionWithDefault = e[1];
                setQualityControlTabsData(qualityControlTabsData);
                getQualityControlTabItems(qualityControlTabsData);
              }}
              className={style.inlineBtn}
            >
              <ToggleButton
                value={'Yes'}
                className={specialInspectionWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
              >
                Yes
              </ToggleButton>
              <ToggleButton
                value={'No'}
                className={specialInspectionWithDefault === 'No' ? s.bgBlue : s.bgWhite}
              >
                No
              </ToggleButton>
              <ToggleButton
                value={'N/A'}
                className={specialInspectionWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
              >
                N/A
              </ToggleButton>
            </ToggleButtonGroup>
            {specialInspection && (
              <>
                <Form.Label>Attach SI Form</Form.Label>
                {SIForm ? (<div>{SIForm.name}</div>) : (<div>{confirmationValues.SIFormName}</div>)}
                <FilePicker
                  extensions={['pdf']}
                  dims={{
                    minWidth: 100,
                    maxWidth: 50000,
                    minHeight: 100,
                    maxHeight: 50000,
                  }}
                  maxSize={5}
                  onChange={async (FileObject) => {
                    const link = await uploadFormToFirebase(FileObject, 'siform');
                    setSIForm(FileObject);
                    setSIFormType(FileObject.type);
                    qualityControlTabsData['SIFormName'] = FileObject.name
                    console.log('FileObject ===> ', FileObject);
                    qualityControlTabsData['specialInspectionForm'] = link
                    console.log('si link', link)
                    setQualityControlTabsData(qualityControlTabsData);
                    getQualityControlTabItems(qualityControlTabsData);
                  }}
                  onError={(errMsg) =>
                    store.addNotification({
                      title: 'Error',
                      message: errMsg,
                      type: 'danger',
                      ...NOTIFICATION_PROPS,
                    })
                  }
                >
                  <Button variant="secondary" block>
                    Upload
                  </Button>
                </FilePicker>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default QualityControlTab;
