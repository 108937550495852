import React from 'react';
import s from './style.scss';
import { ProgressBar } from 'react-bootstrap';

const SealProgressBar = (props) => {
  return (
    <div className={s.spinnerOuter}>
      <div className={s.spinnerInner}>
        <ProgressBar animated now={100} />
        <p className={s.progressText}>
          {props.message}
        </p>
        {/* <p className={s.note}>
          Note that this process can vary based on file size. If your document
          is not SealPact within 5 minutes, please notify support@sealpact.com
        </p> */}
      </div>
    </div>
  );
};

export default SealProgressBar;
