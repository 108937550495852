import React from 'react';
import { Row, Col, Form, FormCheck } from 'react-bootstrap';
import s from '../style.scss';

const RowField = ({ item, getFieldData }) => {
  console.log('item ===>', item);
  return (
    <Row noGutters>
      <Col
        xs={12}
        sm={6}
        md={12}
        lg={{ span: 4, offset: 2 }}
        xl={{ span: 4, offset: 2 }}
      >
        <Form.Group controlId={`emailNo${item.id}`}>
          <Form.Control
            type="text"
            value={item.email}
            onChange={e => getFieldData('email', e.target.value, item.id)}
          />
        </Form.Group>
      </Col>
      <Col xs={12} sm={6} md={12} lg={6} xl={6}>
        <div className={s.radioBtns}>
          <Form.Group
            onChange={e =>
              getFieldData('sealPacterType', e.target.value, item.id)
            }
          >
            <FormCheck>
              <FormCheck.Input
                name={item.id}
                type="radio"
                value="primary"
                checked={item.sealPacterType === 'primary'}
              />
              <FormCheck.Label>Primary SealPacter</FormCheck.Label>
            </FormCheck>
            <FormCheck>
              <FormCheck.Input
                name={item.id}
                type="radio"
                value="secondary"
                checked={item.sealPacterType === 'secondary'}
              />
              <FormCheck.Label>Secondary SealPacter</FormCheck.Label>
            </FormCheck>
            <FormCheck>
              <FormCheck.Input
                name={item.id}
                type="radio"
                value="viewer"
                checked={item.sealPacterType === 'viewer'}
              />
              <FormCheck.Label>Viewer</FormCheck.Label>
            </FormCheck>
          </Form.Group>
        </div>
      </Col>
    </Row>
  );
};

export default RowField;
