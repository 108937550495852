/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { view } from 'react-easy-state';
import s from './style.scss';
import { store } from 'react-notifications-component';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import GiftIcon from '../../../assets/GiftLarge.svg';
import CloseButtonIcon from '../../../assets/CloseButton.svg';
import CopyIcon from '../../../assets/Copy.svg';

const UnhideModal = ({
  showUnhideModal,
  setShowUnhideModal,
  hiddenInvitations,
  UnhideClick,
}) => {
  return (
    <Modal
      show={showUnhideModal}
      onHide={() => setShowUnhideModal(false)}
      animation
      centered
      size={'xl'}
      backdrop
      dialogClassName="modal-90w"
      className={s.unhideModal}
    >
      <Modal.Header
        style={{ borderBottom: '1px solid #F1F1F4', position: 'relative' }}
      >
        <Modal.Title id="modal" className={s.title}>
          <div>Unhide Invitations</div>
        </Modal.Title>
        <img
          src={CloseButtonIcon}
          alt={'CloseButton'}
          className={s.closeButton}
          onClick={() => setShowUnhideModal(false)}
        />
      </Modal.Header>
      <Modal.Body style={{ padding: '24px' }}>
        {hiddenInvitations.map((invitation) => (
          <div className={s.inviteRow}>
            <p>{invitation.email}</p>
            <Button
              variant="primary"
              className={s.unhideButton}
              onClick={() => UnhideClick(invitation.email)}
            >
              <span>Unhide</span>
            </Button>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default UnhideModal;
