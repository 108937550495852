import React from 'react';
import s from '../style.scss';
import { Row, Col } from 'react-bootstrap';
import { USERCONTRACTUALINFO } from '../../../../utils/constants';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../../easyStateStore';

const ContractualTabInfo = ({ confirmationValues }) => {
  // const renderValue = (arg) => (arg === true ? 'Yes' : 'No');
  const renderValue = (arg) => {
    if (arg !== undefined) {
      if (typeof  arg === 'string')
        return arg;
      return arg === true ? 'Yes' : 'No';
    }
    return 'N/A'
  };
  // const {
  //   fees,
  //   retainedSubConsultants,
  //   subConsultantProfession,
  //   contractType,
  //   uploadContractFile,
  //   contractOwner,
  //   contract,
  //   liabilityAmountLimit,
  //   waiver,
  // } = USERCONTRACTUALINFO;
  return (
    <Row>
      <Col>
        <div className={s.wrapper}>
          <p>Fees</p>
          <span>
            {appEasyState.getCurrencyValue(confirmationValues.fees)}
          </span>
          {/* <p>Have you retained sub-consultants for this project?</p>
          <span>
            {renderValue(confirmationValues.constructionAdministration)}
          </span> */}
          {/* <p>Sub-consultant Discipline</p>
          <span>{confirmationValues.consultantDiscipline || 'N/A'}</span> */}
          <p>Contract</p>
          <span>{confirmationValues.ifContract ? 'Written' : 'Verbal'}</span>
          <p>Upload Contract</p>
          <span>{confirmationValues.contract
            ? confirmationValues.contract.includes('https') ? (
              <a
                href={confirmationValues.contract}
                target="_blank"
              >
                Contract
              </a>
            ) : confirmationValues.contract : 'N/A'}</span>
          <p>Whose Contract?</p>
          <span>
            {confirmationValues.contractOwner || 'N/A'}
          </span>
          {/* <p>Contract</p>
          <span>{renderValue(confirmationValues.contract)}</span> */}
        </div>
      </Col>
      <Col>
        <div className={s.wrapper}>
          <p>Limitation of Liability</p>
          <span>
            {confirmationValues.liabilityLimitWithDefault || 'N/A'}
          </span>
          <p>Limit of Liability Amount</p>
          <span>
            {appEasyState.getCurrencyValue(confirmationValues.liabilityAmount)}
          </span>
          <p>Waiver of Consequential Damages</p>
          <span>
            {confirmationValues.waiverWithDefault || renderValue(confirmationValues.waiver)}
          </span>
          <p>Have you retained sub-consultants for this project?</p>
          <span>{confirmationValues.retainConsultantsWithDefault ||
            renderValue(confirmationValues.retainConsultants)}
          </span>
          <p>Sub-consultant Discipline</p>
          <span>
            {confirmationValues.retainConsultantsWithDefault
              ? confirmationValues.retainConsultantsWithDefault === 'Yes'
                ? renderValue(confirmationValues.subConsultantDiscipline) : 'N/A'
              : renderValue(confirmationValues.subConsultantDiscipline)
            }
          </span>
          <p>Sub-consultant Concentration</p>
          <span>
            {confirmationValues.retainConsultantsWithDefault
              ? confirmationValues.retainConsultantsWithDefault === 'Yes'
                ? confirmationValues.subConsultantDiscipline
                  ? confirmationValues.subConsultantDiscipline !== 'Land Surveyor'
                    ? confirmationValues.subConsultantConcentration === 'Other'
                      ? confirmationValues.otherConsultantConcentration
                      : confirmationValues.subConsultantConcentration
                    : 'N/A'
                  : 'N/A'
                : 'N/A'
              : 'N/A'
            }
          </span>
          <p>Upload Sub-consultant Contract</p>
          <span>{confirmationValues.subConsultantContract ?
            confirmationValues.subConsultantContract.includes('https')
            ? (
              <a
                href={confirmationValues.subConsultantContract}
                target="_blank"
              >
                SC Contract
              </a>
            )
            : confirmationValues.subConsultantContract : 'N/A'}
          </span>
        </div>
      </Col>
      <Col />
    </Row>
  );
};

export default view(ContractualTabInfo);
