/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card, Table } from 'react-bootstrap';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import s from './style.scss';
import TrailEndIcon from '../../../assets/trial-end-icon.svg';
import CreditCard from '../../../assets/CreditCard.svg';
import CurrencyDollarBlack from '../../../assets/CurrencyCircleDollarBlack.svg';
import UpdateIcon from '../../../assets/Pencil.svg';
import PaymentCardVisa from '../../../assets/visa.svg';
import PaymentCardMC from '../../../assets/mastercard.svg';
import PaymentCardAmex from '../../../assets/amex.svg';
import PaymentCardJCB from '../../../assets/jcb.svg';
import PaymentCardDiscover from '../../../assets/discover.svg';
import PaymentCardUnionPay from '../../../assets/unionpay.svg';
import PaymentCardDiners from '../../../assets/diners.svg';
import PaymentCardElo from '../../../assets/elo.svg';
import PaymentCardGeneric from '../../../assets/generic.svg';
import ListIcon from '../../../assets/NewspaperClipping.svg';
import ArrowSquareOut from '../../../assets/ArrowSquareOut.svg';
import {
  GET_SUBSCRIPTION,
  GET_CUSTOMER_INVOICES,
  NOTIFICATION_PROPS,
  LIST_PAYMENT_METHODS,
  DETACH_PAYMENT_METHOD,
  CHANGE_DEFAULT_PAYMENT_METHOD,
  GET_UPCOMING_INVOICE,
  CREATE_STRIPE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  RETRY_STRIPE_PAYMENT,
} from '../../../utils/constants';
import axios from 'axios';
import moment from 'moment';
import { store } from 'react-notifications-component';
import PaymentMethodsModal from './PaymentMethodsModal';

const PlanDetails = (props) => {
  const {
    user,
    firebase,
    getProductName,
    setShowPlanDetails,
    setShowUpdatePlan,
    setShowPaymentMethod,
    setShowBillingInfo,
    customer,
  } = props;
  const [display, setDisplay] = useState(false),
    [subscription, setSubscription] = useState(),
    [subscriptionStatus, setSubscriptionStatus] = useState(''),
    [subscriptionEndDate, setSubscriptionEndDate] = useState(''),
    [subscriptionEndDateIsAfter, setSubscriptionEndDateIsAfter] =
      useState(false),
    [invoices, setInvoices] = useState([]),
    [anyOpenInvoice, setAnyOpenInvoice] = useState(false),
    [paymentMethods, setPaymentMethods] = useState([]),
    [removingPaymentMethod, setRemovingPaymentMethod] = useState(false),
    [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState(null),
    [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null),
    [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false),
    [upcomingInvoice, setUpcomingInvoice] = useState({}),
    [disableRetryButton, setDisableRetryButton] = useState(false);

  const listPaymentMethods = (customerId) => {
    axios
      .post(LIST_PAYMENT_METHODS, { customerId })
      .then((response) => {
        console.log('methods response.data.data', response.data.data.data);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.data
        ) {
          setPaymentMethods(response.data.data.data);
          if (removingPaymentMethod) setRemovingPaymentMethod(false);
        }
      })
      .catch((err) => {
        console.log('payment methods err', err);
      });
  };

  useEffect(() => {
    if (
      customer &&
      customer.invoice_settings &&
      customer.invoice_settings.default_payment_method
    )
      setDefaultPaymentMethodId(
        customer.invoice_settings.default_payment_method,
      );
  }, [customer]);

  useEffect(() => {
    if (user.subscriptionDetails) {
      let customerId;

      if (
        user.subscriptionDetails.customer &&
        typeof user.subscriptionDetails.customer !== 'object'
      ) {
        customerId = user.subscriptionDetails.customer;
      } else if (
        user.subscriptionDetails.customer &&
        typeof user.subscriptionDetails.customer === 'object'
      ) {
        customerId = user.subscriptionDetails.customer.id;
      } else if (user.subscriptionDetails.id) {
        customerId = user.subscriptionDetails.id;
      } else {
        customerId = user.subscriptionDetails.customer.id;
      }

      appEasyState.customerId = customerId;
      appEasyState.subscriptionId = user.subscriptionDetails.id;

      axios
        .post(GET_SUBSCRIPTION, {
          subscriptionId: user.subscriptionDetails.id,
        })
        .then((result) => {
          console.log('axios result', result);
          if (result && result.data && result.data.subscription) {
            console.log('axios subscription', result.data.subscription);

            setSubscriptionStatus(result.data.subscription.status);
            setSubscriptionEndDate(
              moment
                .unix(result.data.subscription.current_period_end)
                .format('D MMMM YYYY'),
            );
            setSubscriptionEndDateIsAfter(
              moment
                .unix(result.data.subscription.current_period_end)
                .isAfter(moment(new Date())),
            );

            setSubscription(result.data.subscription);
          }
        })
        .catch((err) => {
          console.log('error get subscription =======> ', err);
        });

      console.log('appEasyState.customerId', appEasyState.customerId);

      axios
        .post(GET_UPCOMING_INVOICE, {
          customerId,
        })
        .then((upcomingInvoiceResult) => {
          if (
            upcomingInvoiceResult &&
            upcomingInvoiceResult.data &&
            upcomingInvoiceResult.data.data
          ) {
            console.log(
              'upcomingInvoiceResult.data.data',
              upcomingInvoiceResult.data.data,
            );
            setUpcomingInvoice(upcomingInvoiceResult.data.data);
          }
        })
        .catch((err) => {
          console.log('GET_UPCOMING_INVOICE err', err);
        });

      listPaymentMethods(appEasyState.customerId);

      axios
        .post(GET_CUSTOMER_INVOICES, {
          customerId: appEasyState.customerId,
        })
        .then((invoices_result) => {
          console.log('invoices_result', invoices_result);
          if (
            invoices_result &&
            invoices_result.data &&
            invoices_result.data.invoices
          ) {
            setInvoices(invoices_result.data.invoices);
            console.log('invoices', invoices_result.data.invoices);
          }
        })
        .catch((err) => {
          console.log('error get customer invoices =======> ', err);
        });
    }
  }, []);

  useEffect(() => {
    setDisplay(false);
    if (subscription && customer) {
      console.log('subscription.plan', subscription.plan);
    }
  }, [subscription]);

  useEffect(() => {
    setDisplay(false);
    if (invoices && subscription && upcomingInvoice) {
      setDisplay(true);
    }
  }, [invoices, subscription, upcomingInvoice]);

  useEffect(() => {
    if (defaultPaymentMethodId)
      setDefaultPaymentMethod(
        paymentMethods.find((method) => method.id === defaultPaymentMethodId),
      );

    if (paymentMethods.length === 0) setShowPaymentMethodsModal(false);
  }, [defaultPaymentMethodId, paymentMethods]);

  useEffect(() => {
    const openInvoice = invoices.find((invoice) => invoice.status === 'open');
    if (openInvoice) setAnyOpenInvoice(true);
  }, [invoices]);

  function stripeStatus(status) {
    switch (status) {
      case 'trialing':
        return 'Trialing';
      case 'active':
        return 'Active';
      case 'incomplete':
        return 'Incomplete';
      case 'incomplete_expired':
        return 'Incomplete Expired';
      case 'past_due':
        return 'Past Due';
      case 'unpaid':
        return 'Unpaid';
      case 'canceled':
        return 'Canceled';
      default:
        return 'Trialing';
    }
  }

  function stripeStatusBackground(status) {
    switch (status) {
      case 'trialing':
        return '#4285f4';
      case 'active':
        return '#1db25a';
      case 'incomplete':
        return 'orange';
      case 'incomplete_expired':
        return 'grey';
      case 'past_due':
        return '#e59c00';
      case 'unpaid':
        return '#f3d06b';
      case 'canceled':
        return '#e53333';
      default:
        return '#4285f4';
    }
  }

  function invoiceStatusBackground(status) {
    switch (status) {
      case 'draft':
        return '#c0c0c0';
      case 'open':
        return '#4285f4';
      case 'paid':
        return '#1db25a';
      case 'failed':
        return '#b3093c';
      case 'unpaid':
        return '#f3d06b';
      case 'uncollectible':
        return '#e59c00';
      default:
        return '#c0c0c0';
    }
  }

  const removePaymentMethod = async (id) => {
    appEasyState.removingPaymentMethodId = id;
    setRemovingPaymentMethod(true);
    await axios
      .post(DETACH_PAYMENT_METHOD, { paymentMethodId: id })
      .then((response) => {
        if (response && response.data) {
          if (response.data.success) {
            store.addNotification({
              title: 'Removed Successfully!',
              message: 'Payment method removed successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            listPaymentMethods(appEasyState.customerId);
          } else {
            setRemovingPaymentMethod(false);
            store.addNotification({
              title: 'Error!',
              message: 'Payment method removed unsuccessfully.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
          }
        }
      })
      .catch((err) => {
        setRemovingPaymentMethod(false);
        console.log('removed payment method err', err);
        store.addNotification({
          title: 'Error!',
          message: err.message,
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
      });
  };

  const makeDefaultPaymentMethod = async (id) => {
    await axios
      .post(CHANGE_DEFAULT_PAYMENT_METHOD, {
        paymentMethodId: id,
        subscriptionId: subscription.id,
        customerId: customer.id,
      })
      .then((response) => {
        if (response && response.data) {
          if (response.data.success) {
            store.addNotification({
              title: 'Removed Successfully!',
              message: 'Default payment method changed successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            setDefaultPaymentMethodId(id);
          } else {
            store.addNotification({
              title: 'Error!',
              message: 'Default payment method changed unsuccessfully.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
          }
        }
      })
      .catch((err) => {
        console.log('Default payment method changed err', err);
        store.addNotification({
          title: 'Error!',
          message: err.message,
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
      });
  };

  const retryPayment = async () => {
    console.log('user=====>', user);
    console.log('subscription=====>', subscription);
    // setRemovingPaymentMethod(true);
    setDisableRetryButton(true);
    await axios
      .post(RETRY_STRIPE_PAYMENT, {
        subscriptionId: subscription.id,
        // newPlanId: subscription.plan.id,
        // priceId: subscription.plan.id,
        // trial: true,
        // customer: subscription
        //   ? subscription.customer
        //     ? subscription.customer
        //     : subscription.id
        //     ? subscription.id
        //     : undefined
        //   : undefined,
      })
      .then((response) => {
        if (response && response.data) {
          if (response.data.success) {
            store.addNotification({
              title: 'Subscription renewed',
              message: 'Subscription renewed successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            listPaymentMethods(appEasyState.customerId);
            setDisableRetryButton(false);
          } else {
            setDisableRetryButton(false);
            setRemovingPaymentMethod(false);
            store.addNotification({
              title: 'Error!',
              message: 'Subscription renewal unsuccessful.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
          }
        }
      })
      .catch((err) => {
        setRemovingPaymentMethod(false);
        setDisableRetryButton(false);
        console.log('Subscription renewal err', err);
        store.addNotification({
          title: 'Error!',
          message: err.message,
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
      });
  };

  return display ? (
    <div>
      <Row>
        <Col>
          <Card className={s.subscriptionDetails}>
            <Card.Title
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: '#000A23',
              }}
            >
              <div className={s.trialEnds}>
                <img
                  src={TrailEndIcon}
                  alt="Clock Icon"
                  style={{ marginRight: '4px' }}
                />
                {subscriptionStatus === 'trialing' ? 'Trial' : 'Subscription'}{' '}
                {subscriptionEndDateIsAfter ? 'Ends' : 'Ended'} on{' '}
                {subscriptionEndDate}
              </div>
              <div
                className={s.trialEnds}
                style={{
                  marginLeft: '5px',
                  color: 'white',
                  background: stripeStatusBackground(subscription.status),
                }}
              >
                {stripeStatus(subscription.status)}
              </div>
              {upcomingInvoice && upcomingInvoice.discount && (
                <div
                  className={s.trialEnds}
                  style={{
                    marginLeft: '5px',
                    color: '#3457D5',
                    border: '1px solid #3457D5',
                    background: 'white',
                  }}
                >
                  {upcomingInvoice.discount.coupon.amount_off
                    ? `$${upcomingInvoice.discount.coupon.amount_off / 100}`
                    : `${upcomingInvoice.discount.coupon.percent_off}%`}{' '}
                  OFF
                </div>
              )}
            </Card.Title>
            <Card.Subtitle className={s.subTitle}>
              {getProductName(subscription.plan.product)}
            </Card.Subtitle>
            <Card.Body style={{ padding: '0 0 0 0' }}>
              <Row>
                <Col>
                  <div className={s.planBillingInterval}>Total Plan Price:</div>
                  <div className={s.planPriceDiv}>
                    <div className={s.planPrice}>
                      {subscription.plan.amount / 100}
                    </div>
                    <div className={s.planInterval}>
                      &nbsp; / {subscription.plan.interval}{' '}
                    </div>
                  </div>
                  <div className={s.planBillingInterval}>
                    {subscriptionStatus === 'trialing'
                      ? `After your free trial ends on ${subscriptionEndDate}, this plan will continue automatically.`
                      : subscriptionStatus === 'active'
                      ? `Your plan renews on ${subscriptionEndDate}.`
                      : null}
                  </div>
                  {/*{upcomingInvoice.discount && (*/}
                  {/*  <div className={s.planBillingInterval}*/}
                  {/*       style={{*/}
                  {/*         background: 'var(--Blue-Gradient, linear-gradient(135deg, #2946C1 0%, #0779EB 100%))',*/}
                  {/*         backgroundClip: 'text',*/}
                  {/*       }}*/}
                  {/*  >*/}
                  {/*    The discount of {upcomingInvoice.discount.coupon.amount_off*/}
                  {/*    ? `$${upcomingInvoice.discount.coupon.amount_off / 100}`*/}
                  {/*    : `${upcomingInvoice.discount.coupon.percent_off}%`} will also be included.*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  <div
                    className={s.planBillingInterval}
                    style={{ color: 'firebrick' }}
                  >
                    {subscriptionStatus === 'canceled'
                      ? `Your plan ended on ${subscriptionEndDate}. Please contact support@sealpact.com to renew your plan.`
                      : subscriptionStatus !== 'trialing' &&
                        subscriptionStatus !== 'active'
                      ? 'Your latest payment has failed. Pay the failed amount to continue this plan.'
                      : null}
                  </div>
                  {upcomingInvoice.total &&
                    upcomingInvoice.total !== subscription.plan.amount && (
                      <div style={{ marginTop: '16px' }}>
                        <div className={s.planBillingInterval}>
                          Current Total Price:
                        </div>
                        <div className={s.planPriceDiv}>
                          <div className={s.planPrice}>
                            {upcomingInvoice.total / 100}
                          </div>
                          <div className={s.planInterval}>
                            &nbsp; / {subscription.plan.interval}{' '}
                          </div>
                        </div>
                        <div className={s.planBillingInterval}>
                          This will be the amount of your subscription on
                          renewal, with your currently Redeemed coupons applied
                          (note - standard coupons can be redeemed on the
                          subscription page when updating plan, and referral
                          coupons can be redeemed on the Referrals & Rewards tab
                          within 30 days of subscription renewal).
                        </div>
                      </div>
                    )}
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (
                        subscriptionStatus !== 'trialing' &&
                        subscriptionStatus !== 'active'
                      ) {
                        retryPayment();
                      } else {
                        setShowUpdatePlan(true);
                        setShowPlanDetails(false);
                      }
                    }}
                    disabled={disableRetryButton}
                    // disabled={
                    //   subscriptionStatus !== 'trialing' &&
                    //   subscriptionStatus !== 'active'
                    // }
                  >
                    {subscriptionStatus !== 'trialing' &&
                    subscriptionStatus !== 'active'
                      ? 'Retry'
                      : 'Update Plan'}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Card className={s.paymentMethodCard} style={{ height: '190px' }}>
            <Card.Title className={s.title}>
              <img
                src={CurrencyDollarBlack}
                alt="CurrencyIcon"
                style={{ marginRight: '8px', marginTop: '-3px' }}
              />
              Payment Method
            </Card.Title>
            <Card.Body style={{ padding: '0 0 0 0', width: '100%' }}>
              <div>
                {paymentMethods.length === 0 ? (
                  !anyOpenInvoice ? (
                    <div className={s.itemDiv}>No payment method added</div>
                  ) : (
                    <div className={s.itemDiv}>
                      No payment method added. Please Pay Open Invoice(s) below
                      to update payment methods.
                    </div>
                  )
                ) : defaultPaymentMethod ? (
                  <div className={s.itemDiv}>
                    <div>
                      {defaultPaymentMethod.card && (
                        <span>
                          {defaultPaymentMethod.card.brand === 'visa' ? (
                            <img
                              src={PaymentCardVisa}
                              alt="Visa"
                              className={s.cardLogo}
                            />
                          ) : defaultPaymentMethod.card.brand ===
                            'mastercard' ? (
                            <img
                              src={PaymentCardMC}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : defaultPaymentMethod.card.brand === 'amex' ? (
                            <img
                              src={PaymentCardAmex}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : defaultPaymentMethod.card.brand === 'discover' ? (
                            <img
                              src={PaymentCardDiscover}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : defaultPaymentMethod.card.brand === 'diners' ? (
                            <img
                              src={PaymentCardDiners}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : defaultPaymentMethod.card.brand === 'jcb' ? (
                            <img
                              src={PaymentCardJCB}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : defaultPaymentMethod.card.brand === 'unionpay' ? (
                            <img
                              src={PaymentCardUnionPay}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : defaultPaymentMethod.card.brand === 'elo' ? (
                            <img
                              src={PaymentCardElo}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : (
                            <img
                              src={PaymentCardGeneric}
                              alt="card"
                              className={s.cardLogo}
                            />
                          )}
                        </span>
                      )}
                      &nbsp;....&nbsp;
                      <span>
                        {defaultPaymentMethod.card.last4} (expires&nbsp;
                        <span>
                          {defaultPaymentMethod.card.exp_month}/
                          {defaultPaymentMethod.card.exp_year}
                        </span>
                        )
                        {defaultPaymentMethod.id === defaultPaymentMethodId && (
                          <small className={s.defaultCardText}>Default</small>
                        )}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={s.itemDiv}>
                    <div>
                      {paymentMethods[0].card && (
                        <span>
                          {paymentMethods[0].card.brand === 'visa' ? (
                            <img
                              src={PaymentCardVisa}
                              alt="Visa"
                              className={s.cardLogo}
                            />
                          ) : paymentMethods[0].card.brand === 'mastercard' ? (
                            <img
                              src={PaymentCardMC}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : paymentMethods[0].card.brand === 'amex' ? (
                            <img
                              src={PaymentCardAmex}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : paymentMethods[0].card.brand === 'discover' ? (
                            <img
                              src={PaymentCardDiscover}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : paymentMethods[0].card.brand === 'diners' ? (
                            <img
                              src={PaymentCardDiners}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : paymentMethods[0].card.brand === 'jcb' ? (
                            <img
                              src={PaymentCardJCB}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : paymentMethods[0].card.brand === 'unionpay' ? (
                            <img
                              src={PaymentCardUnionPay}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : paymentMethods[0].card.brand === 'elo' ? (
                            <img
                              src={PaymentCardElo}
                              alt="card"
                              className={s.cardLogo}
                            />
                          ) : (
                            <img
                              src={PaymentCardGeneric}
                              alt="card"
                              className={s.cardLogo}
                            />
                          )}
                        </span>
                      )}
                      &nbsp;....&nbsp;
                      <span>
                        {paymentMethods[0].card.last4} (expires&nbsp;
                        <span>
                          {paymentMethods[0].card.exp_month}/
                          {paymentMethods[0].card.exp_year}
                        </span>
                        )
                        {paymentMethods[0].id === defaultPaymentMethodId && (
                          <small className={s.defaultCardText}>Default</small>
                        )}
                      </span>
                    </div>
                  </div>
                )}

                {paymentMethods.length > 0 && (
                  <div
                    className={s.updateButton}
                    onClick={() => setShowPaymentMethodsModal(true)}
                  >
                    <img
                      src={UpdateIcon}
                      alt="UpdateIcon"
                      className={s.updateIcon}
                    />
                    Edit Payment Methods
                  </div>
                )}
                <PaymentMethodsModal
                  showPaymentMethodsModal={showPaymentMethodsModal}
                  setShowPaymentMethodsModal={setShowPaymentMethodsModal}
                  paymentMethods={paymentMethods}
                  defaultPaymentMethodId={defaultPaymentMethodId}
                  removePaymentMethod={removePaymentMethod}
                  removingPaymentMethod={removingPaymentMethod}
                  makeDefaultPaymentMethod={makeDefaultPaymentMethod}
                />

                {paymentMethods.length === 0 && (
                  <div
                    className={s.updateButton}
                    onClick={() => {
                      setShowPaymentMethod(true);
                      setShowPlanDetails(false);
                    }}
                  >
                    <img
                      src={UpdateIcon}
                      alt="UpdateIcon"
                      className={s.updateIcon}
                    />
                    Add Payment Method
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Card className={s.paymentMethodCard}>
            <Card.Title className={s.title}>
              <img
                src={CreditCard}
                alt="CardIcon"
                style={{ marginRight: '8px', marginTop: '-3px' }}
              />
              Billing Information
            </Card.Title>
            <Card.Body style={{ padding: '0 0 0 0', width: '100%' }}>
              <div className={s.itemDiv}>
                <div>{customer.email}</div>
              </div>
              <div
                className={s.updateButton}
                onClick={() => {
                  setShowBillingInfo(true);
                  setShowPlanDetails(false);
                }}
              >
                <img
                  src={UpdateIcon}
                  alt="UpdateIcon"
                  className={s.updateIcon}
                />
                Update Billing Information
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={s.invoiceHistoryCard}>
            <Card.Title className={s.title}>
              <img
                src={ListIcon}
                alt="ListIcon"
                style={{ marginRight: '8px', marginTop: '-3px' }}
              />
              Invoice History
            </Card.Title>
            <Card.Body className={s.tableDiv}>
              <Table responsive borderless hover>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice) => (
                    <tr>
                      <td>
                        {moment.unix(invoice.created).format('MMMM DD, YYYY')}
                        &nbsp;
                        {invoice.status !== 'uncollectible' && (
                          <img
                            src={ArrowSquareOut}
                            alt={'ArrowSquareOut'}
                            className={s.arrowSquareOut}
                            onClick={() =>
                              window.open(invoice.hosted_invoice_url, '_blank')
                            }
                          />
                        )}
                      </td>
                      <td>${(invoice.amount_due / 100).toFixed(2)}</td>
                      <td
                        className={s.invoiceStatus}
                        style={{
                          color: invoiceStatusBackground(invoice.status),
                        }}
                      >
                        {invoice.status}
                      </td>
                      <td>{invoice.lines.data[0].description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default view(PlanDetails);
