/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Col, Form, InputGroup } from 'react-bootstrap';
import s from './style.scss'
import PaymentCardVisa from '../../../assets/PaymentCardVisa.svg'
import PaymentCardMC from '../../../assets/PaymentCardMC.svg'
import PaymentCardAE from '../../../assets/PaymentCardAE.svg'
import PaymentCardJCB from '../../../assets/PaymentCardJCB.svg'
import LinkIcon from '../../../assets/Link.svg'
import DotIcon from '../../../assets/Dot.svg'
import Switch from 'react-switch';
import axios from 'axios';
import { CARD_SETUP, NOTIFICATION_PROPS } from '../../../utils/constants';
import { view } from 'react-easy-state';
import  { appEasyState } from '../../../easyStateStore';
import { store } from 'react-notifications-component';
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm';


const PaymentMethodAdd = (props) => {
  const { stripePromise } = props
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    axios.post(CARD_SETUP, { customerId: appEasyState.customerId })
      .then(response => {
      if (response && response.data && response.data.data) {
        console.log('clientSecret response.data.data', response.data.data)
        setClientSecret(response.data.data)
      }
    }).catch((err) => {
        console.log('error card setup =======> ', err);
      })
  }, [])


  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm {...props} />
          </Elements>
        )}
      </Col>
    </Row>
  )
}

export default view(PaymentMethodAdd)