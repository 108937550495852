import React from 'react';
import s from './style.scss';
import { Row } from 'react-bootstrap';

const ColorLabels = ({ tab }) => {
  return (
    <Row className={`align-items-center p-2 mt-1`}>
      <div>
        <span className={`${s.circle} ${s.sealpacted}`} />
        <span className={s.circleLabel} onClick={() => tab('SealPacted')}>
          SealPacted
        </span>
      </div>
      <div>
        <span className={`${s.circle} ${s.pending}`} />
        <span className={s.circleLabel} onClick={() => tab('Pending')}>
          Pending
        </span>
      </div>
      <div>
        <span className={`${s.circle} ${s.invalid}`} />
        <span className={s.circleLabel} onClick={() => tab('Invalidated')}>
          Invalidated SealPacts
        </span>
      </div>
    </Row>
  );
};

export default ColorLabels;
