/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Form,
  Button,
  Modal,
  Tab,
  Row,
  Col,
  Nav,
  Image,
} from 'react-bootstrap';
import BasicTab from './BasicTab';
import AdvancedTab from './AdvancedTab';
import ButtonClose from '../../../../assets/button-close1.png';
import s from './style.scss';
import QualityControl from './QualityControlTab';
import ContractualInfo from './ContractualInfoTab';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import { store } from 'react-notifications-component';
import CustomTooltip from '../../../AppComponents/CustomTooltip';

const SealConfirmationFlow = ({
  show,
  hideModal,
  hidePopup,
  getSealValidatonData,
  documentInformation,
  confirmationValues,
  getConfimationValues,
  firebase,
  user,
  selectedSealInfo,
  sealPages,
}) => {
  let values = {};
  const [selectedTab, setselectedTab] = useState(1);
  const [qualityControlTabInfo, setQualityControlTabInfo] = useState({});
  const [contractualTabInfo, setContractualTabInfo] = useState({});
  // const [dateOfIssuance, setDateOfIssuance] = useState();
  // const [retainConsultants, setRetainConsultants] = useState();
  const [basicTabValues, setBasicTabValues] = useState({
    pageFrom: 1,
    pageTo: 1,
    name: documentInformation.name || null,
    licenseNo: selectedSealInfo.newLicenseNo || null,
    firmName: documentInformation.firmName || null,
    website: documentInformation.website || null,
    projectName: documentInformation.projectName || null,
    projectNumber: documentInformation.projectNumber || null,
    projectAddress: documentInformation.projectAddress || null,
  });

  const [advanceTabValues, setAdvanceTabValues] = useState({
    clientName: null,
    clientEmail: null,
    clientType: null,
    ownerType: null,
    workNature: null,
    scopeInclusions: null,
    scopeExclusions: null,
    discipline: null,
    concentration: null,
    projectRole: null,
    dateOfIssuance: null,
  });
  const [uploadingForm, setUploadingForm] = useState(false);

  useEffect(() => {
    if (selectedSealInfo.type !== 'signature') {
      getBasicTabValue('licenseNo', selectedSealInfo.newLicenseNo);
    }
  }, [selectedSealInfo]);

  const handleFlow = (direction) => {
    if (direction === 'Next') {
      if (selectedTab < 4) setselectedTab(selectedTab + 1);
    } else if (direction === 'Back') {
      if (selectedTab > 1) setselectedTab(selectedTab - 1);
    }
  };
  const handleCancel = () => {
    hideModal();
    hidePopup();
  };

  const getBasicTabValue = (key, value) => {
    setBasicTabValues({ ...basicTabValues, [key]: value });
  };

  const setBasicTabPages = (pageFrom, pageTo) => {
    setBasicTabValues({ ...basicTabValues, pageFrom, pageTo });
  };

  const getAdvanceTabValue = (key, value) => {
    setAdvanceTabValues({ ...advanceTabValues, [key]: value });
  };

  const getQualityControlTabItems = (qualityItems) => {
    setQualityControlTabInfo(qualityItems);
  };

  // const handleDateOfIssuance = (date) => {
  //   setDateOfIssuance(date);
  // };

  // const handleRetainConsultants = (value) => {
  //   setRetainConsultants(value);
  // };

  const getContractualInfoTabItems = (contractualInfoItems) => {
    setContractualTabInfo(contractualInfoItems);
  };

  async function uploadFormToFirebase(fileObject, formType) {
    setUploadingForm(true);
    let response
    await firebase
      .uploadForm(documentInformation, fileObject, formType, user.uid)
      .then((res) => {
        setUploadingForm(false);
        console.log('res ===> ', res);
        console.log('Uploaded');
        response = res
      })
      .catch((error) => {
        setUploadingForm(false);
        console.log('error ===> ', error);
      });

    return response
  };

  const handleSelect = (key) => setselectedTab(+key);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validation = (e) => {
    e.preventDefault();
    const {
      Name,
      licenseNo,
      firmName,
      projectName,
      projectNumber,
      projectAddress,
      clientEmail,
    } = e.target.elements;

    console.log(`clientEmail =====test=====>`, clientEmail);

    if (
      Name.value &&
      firmName.value &&
      projectName.value &&
      projectNumber &&
      projectAddress.value &&
      basicTabValues.dateOfIssuance
    ) {
      if (
        sealPages.pagesTo > documentInformation.totalPages &&
        documentInformation.fileType === 'application/pdf'
      ) {
        store.addNotification({
          title: 'Error',
          message:
            'Maximum pages to sealpact can be ' +
            documentInformation.totalPages,
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return false;
      }
      if (
        sealPages.pagesFrom < 1 &&
        documentInformation.fileType === 'application/pdf'
      ) {
        store.addNotification({
          title: 'Error',
          message: 'Minimum pages from can be 1',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return false;
      }
      if (
        documentInformation.selectedSealInfo.type === 'stamp' &&
        !licenseNo.value
      ) {
        return false;
      }
      if (clientEmail.value !== '' && !validateEmail(clientEmail.value)) {
        store.addNotification({
          title: 'Error',
          message: 'Please enter a valid client email.',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return false;
      }
      return true;
    } else {
      store.addNotification({
        title: 'Error',
        message: "All the required items in SealPact Level 1 shouldn't be empty.",
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { projectNumber, email } = e.target.elements;

    if (validation(e)) {
      getSealValidatonData(projectNumber.value);

      if (selectedSealInfo.type === 'signature') {
        delete basicTabValues.licenseNo;
      }
      console.log('advanceTabValues: ', advanceTabValues)

      values = {
        ...basicTabValues,
        ...advanceTabValues,
        ...qualityControlTabInfo,
        ...contractualTabInfo,
      };
      values.isInvalidated = false;
      getConfimationValues(values);
      hideModal();
    }
  };

  const getText = (heading, tooltipText) => {
    return (
      <div>
        <p>{heading}</p>
        <CustomTooltip
          trigger={'hover'}
          location={'auto'}
          content={tooltipText}
        >
          <i
            style={{
              marginLeft: '3px',
              marginBottom: '3px',
            }}
            className="fa fa-info-circle"
            aria-hidden="true"
          ></i>
        </CustomTooltip>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleCancel}
      centered
      size="xl"
      backdrop
      dialogClassName="modal-90w"
    >
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Container>
            <div className={s.wrapper}>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={1}
                activeKey={selectedTab}
                onSelect={(key) => handleSelect(key)}
              >
                <div>
                  <Row>
                    <Col sm={12}>
                      <Row className={s.tabRow}>
                        <Col sm={12}>
                          <p>SealPact</p>

                          {documentInformation.sealPacterType === 'seal' ? (
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link eventKey={1}>
                                  {getText(
                                    '1-Basic',
                                    'Items on this page are required. This is public information and will be viewable to anyone who has the document.',
                                  )}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={2}>
                                  {getText(
                                    '2-Advanced',
                                    'Items on this page are voluntary.  Don’t worry about filling this out every time if you prefer not to. The intent of this page is to allow you to provide specifics about your task and scope (i.e. inclusions and exclusions, etc.) that may help provide clarity and protection for your responsibilities.',
                                  )}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={3}>
                                  {getText(
                                    '3-Quality Control',
                                    'Items on this page are voluntary.  Don’t worry about filling this out every time if you prefer not to. The intent of this page is to allow you to provide specifics about quality control items (i.e. independent reviews, construction period services, etc.) that are agreed on by you and your client.',
                                  )}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={4}>
                                  {getText(
                                    '4-Contractual Info',
                                    'Items on this page are voluntary.  Don’t worry about filling this out every time if you prefer not to. This is for your internal purposes only, and stored separately from the other, public information already entered.',
                                  )}
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          ) : documentInformation.sealPacterType ===
                            'signature' ? (
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link eventKey={1}>
                                  {getText(
                                    '1-Basic',
                                    'Items on this page are required. This is public information and will be viewable to anyone who has the document.',
                                  )}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={2}>
                                  {getText(
                                    '2-Advanced',
                                    'Items on this page are voluntary.  Don’t worry about filling this out every time if you prefer not to. The intent of this page is to allow you to provide specifics about your task and scope (i.e. inclusions and exclusions, etc.) that may help provide clarity and protection for your responsibilities.',
                                  )}
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          ) : (
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link eventKey={1}>
                                  {getText(
                                    '1-Basic',
                                    'Items on this page are required. This is public information and will be viewable to anyone who has the document.',
                                  )}
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          )}

                          <Button variant="text" onClick={hideModal}>
                            <Image height={40} src={ButtonClose} alt="close" />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey={1}>
                        <BasicTab
                          documentInformation={documentInformation}
                          selectedSealInfo={selectedSealInfo}
                          getBasicTabValue={getBasicTabValue}
                          setBasicTabPages={setBasicTabPages}
                          basicTabValues={basicTabValues}
                          confirmationValues={confirmationValues}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey={2}>
                        <AdvancedTab
                          confirmationValues={confirmationValues}
                          getAdvanceTabValue={getAdvanceTabValue}
                          uploadFormToFirebase={uploadFormToFirebase}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey={3}>
                        <QualityControl
                          confirmationValues={confirmationValues}
                          getQualityControlTabItems={getQualityControlTabItems}
                          uploadFormToFirebase={uploadFormToFirebase}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey={4}>
                        <ContractualInfo
                          confirmationValues={confirmationValues}
                          getContractualInfoTabItems={
                            getContractualInfoTabItems
                          }
                          uploadFormToFirebase={uploadFormToFirebase}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
            <div className={s.flexBtw}>
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={
                    selectedTab === 1 ? handleCancel : () => handleFlow('Back')
                  }
                  className={s.btnBorder}
                >
                  {selectedTab > 1 ? 'Back' : 'Cancel'}
                </Button>
              </div>
              <div className={s.footerBtn}>
                {selectedTab !== 4 &&
                  documentInformation.sealPacterType === 'seal' && (
                    <Button
                      variant="outline-secondary"
                      onClick={() => handleFlow('Next')}
                      className={s.nextBtn}
                      // eslint-disable-next-line indent
                    >
                      Next
                    </Button>
                  )}
                {!uploadingForm && (
                  <CustomTooltip
                    trigger={'hover'}
                    location={'auto'}
                    content={
                      'Make sure that you have inputted everything you want to on all 4 levels (tabs) above before “Submitting”'
                    }
                  >
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </CustomTooltip>
                )}
                {uploadingForm && (
                  <button className="btn btn-primary">
                    <span className="spinner-border spinner-border-sm"></span>
                    Uploading..
                  </button>
                )}
              </div>
            </div>
          </Container>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SealConfirmationFlow;
