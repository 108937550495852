import React from 'react';
import s from '../style.scss';
import { Row, Col } from 'react-bootstrap';
import { USERCONTRACTUALINFO } from '../../../../utils/constants';

const ContractualTabInfo = ({ confirmationValues }) => {
  // const renderPermission = (arg) => (arg === true ? 'Yes' : 'No');
  const renderPermission = (arg) => {
    if (typeof  arg === 'string')
      return arg;
    return arg === true ? 'Yes' : 'No';
  };
  // const {
  //   fees,
  //   retainedSubConsultants,
  //   subConsultantProfession,
  //   contractType,
  //   uploadContractFile,
  //   contractOwner,
  //   contract,
  //   liabilityAmountLimit,
  //   waiver,
  // } = USERCONTRACTUALINFO;
  return (
    <Row>
      <Col>
        <div className={s.wrapper}>
          <p>Fees</p>
          <span>
            {confirmationValues.fees ? confirmationValues.fees : 'N/A'}
          </span>
          {/* <p>Have you retained sub-consultants for this project?</p>
          <span>
            {renderPermission(confirmationValues.constructionAdministration)}
          </span> */}
          {/* <p>Sub-consultant Discipline</p>
          <span>{confirmationValues.consultantDiscipline || 'N/A'}</span> */}
          <p>Contract</p>
          <span>{confirmationValues.ifContract ? 'Written' : 'Verbal'}</span>
          <p>Upload Contract</p>
          <span>{confirmationValues.contract || 'N/A'}</span>
          <p>Whose Contract?</p>
          <span>
            {confirmationValues.contractOwner
              ? confirmationValues.contractOwner
              : 'N/A'}
          </span>
          {/* <p>Contract</p>
          <span>{renderPermission(confirmationValues.contract)}</span> */}
        </div>
      </Col>
      <Col>
        <div className={s.wrapper}>
          <p>Limit of Liability Amount</p>
          <span>
            {confirmationValues.liabilityAmount
              ? confirmationValues.liabilityAmount
              : 'N/A'}
          </span>
          <p>Waiver of Consequential Damages</p>
          <span>
            {renderPermission(
              confirmationValues.waiverWithDefault
                ? confirmationValues.waiverWithDefault
                : confirmationValues.waiver ? confirmationValues.waiver : 'N/A',
              // confirmationValues.waiver ? confirmationValues.waiver : 'N/A',
            )}
          </span>
        </div>
      </Col>
      <Col />
    </Row>
  );
};

export default ContractualTabInfo;
