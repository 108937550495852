/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import s from './style.scss'
import './dropdown.css';
import PaymentCardVisa from '../../../assets/visa.svg';
import PaymentCardMC from '../../../assets/mastercard.svg';
import PaymentCardAmex from '../../../assets/amex.svg';
import PaymentCardDiscover from '../../../assets/discover.svg';
import PaymentCardDiners from '../../../assets/diners.svg';
import PaymentCardJCB from '../../../assets/jcb.svg';
import PaymentCardUnionPay from '../../../assets/unionpay.svg';
import PaymentCardElo from '../../../assets/elo.svg';
import PaymentCardGeneric from '../../../assets/generic.svg';
import { appEasyState } from '../../../easyStateStore';
import ThreeDotsIcon from '../../../assets/dot.png';
import DynamicModal from '../../DynamicModal';

const PaymentMethodsModal = ({
                               showPaymentMethodsModal,
                               setShowPaymentMethodsModal,
                               paymentMethods,
                               defaultPaymentMethodId,
                               removePaymentMethod,
                               removingPaymentMethod,
                               makeDefaultPaymentMethod,
                             }) => {

  return (
    <DynamicModal
      show={showPaymentMethodsModal}
      title={'Edit Payment Methods'}
      hideModal={() => setShowPaymentMethodsModal(false)}
      size={'md'}
    >
      <div className={s.paymentMethodCard} style={{ padding: '0 10px' }}>
        {paymentMethods.length === 0 && (
          <div className={s.itemDiv}>
            No payment method added
          </div>
        )}
        {paymentMethods.map(method => (
          <div className={s.itemDiv}>
            <div>
              {method.card && (
                <span>
                              {method.card.brand === 'visa'
                                ? (
                                  <img
                                    src={PaymentCardVisa}
                                    alt='Visa'
                                    className={s.cardLogo}
                                  />
                                )
                                : method.card.brand === 'mastercard'
                                  ? (
                                    <img
                                      src={PaymentCardMC}
                                      alt='card'
                                      className={s.cardLogo}
                                    />
                                  )
                                  : method.card.brand === 'amex'
                                    ? (
                                      <img
                                        src={PaymentCardAmex}
                                        alt='card'
                                        className={s.cardLogo}
                                      />
                                    )
                                    : method.card.brand === 'discover'
                                      ? (
                                        <img
                                          src={PaymentCardDiscover}
                                          alt='card'
                                          className={s.cardLogo}
                                        />
                                      )
                                      : method.card.brand === 'diners'
                                        ? (
                                          <img
                                            src={PaymentCardDiners}
                                            alt='card'
                                            className={s.cardLogo}
                                          />
                                        )
                                        : method.card.brand === 'jcb'
                                          ? (
                                            <img
                                              src={PaymentCardJCB}
                                              alt='card'
                                              className={s.cardLogo}
                                            />
                                          )
                                          : method.card.brand === 'unionpay'
                                            ? (
                                              <img
                                                src={PaymentCardUnionPay}
                                                alt='card'
                                                className={s.cardLogo}
                                              />
                                            )
                                            : method.card.brand === 'elo'
                                              ? (
                                                <img
                                                  src={PaymentCardElo}
                                                  alt='card'
                                                  className={s.cardLogo}
                                                />
                                              )
                                              : (
                                                <img
                                                  src={PaymentCardGeneric}
                                                  alt='card'
                                                  className={s.cardLogo}
                                                />
                                              )
                              }
                            </span>
              )}

              &nbsp;....&nbsp;
              <span>
                            {method.card.last4} (expires&nbsp;
                <span>{method.card.exp_month}/{method.card.exp_year}</span>)
                {method.id === defaultPaymentMethodId && (
                  <small className={s.defaultCardText}>Default</small>
                )}
                          </span>
            </div>
            {removingPaymentMethod && method.id === appEasyState.removingPaymentMethodId ? (
              <div className={s.removeButton}>
                            <span
                              className={s.removeText}
                              style={{
                                fontStyle: 'italic',
                                color: 'darkgrey'
                              }}
                            >
                              Removing...
                            </span>
              </div>
            ) : paymentMethods.length <= 1 ? (
              <div className={s.removeButton} style={{ padding: '10px 0'}}>
                <Dropdown className={'myClass'}>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <img
                      src={ThreeDotsIcon}
                      alt='ThreeDotsIcon'
                      style={{ width: '20px', height: 'auto' }}/>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => removePaymentMethod(method.id)}>Remove</Dropdown.Item>
                    {method.id !== defaultPaymentMethodId && (
                      <Dropdown.Item onClick={() => makeDefaultPaymentMethod(method.id)}>Make default</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : method.id !== defaultPaymentMethodId ? (
              <Dropdown className={'myClass'}>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <img
                    src={ThreeDotsIcon}
                    alt='ThreeDotsIcon'
                    style={{ width: '20px', height: 'auto' }}/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => removePaymentMethod(method.id)}>Remove</Dropdown.Item>
                  {method.id !== defaultPaymentMethodId && (
                    <Dropdown.Item onClick={() => makeDefaultPaymentMethod(method.id)}>Make default</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </div>
        ))}
      </div>
    </DynamicModal>
  )
}

export default PaymentMethodsModal;