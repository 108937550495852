/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {view} from 'react-easy-state';
import { appEasyState } from '../../../../easyStateStore';
import { withFirebase } from '../../../../auth/firebase';
import s from '../style.scss';
import s1 from '../../MobileAuth/new_style.scss'
import { Button, Card } from 'react-bootstrap';
import CareLeft from '../../../../assets/CaretLeft.svg';
import OTPVerification from '../verification';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import { sendOnboardingRequest } from '../../../../services/Auth';

function VerificationCard(props) {

  const [loading, setLoading] = useState(false),
    [captchaVerified, setCaptchaVerified] = useState(false),
    [verificationCode, setVerificationCode] = useState('');

  let recaptchaNode;
  useEffect(() => {
    if (props && !captchaVerified) {
      window.recaptchaVerifier = new props.firebase.RecaptchaVerifier(
        recaptchaNode,
        {
          size: 'normal',
          callback: () => setCaptchaVerified(true),
        },
      );

      window.recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
      });

      const phoneNumber = appEasyState.onboarding.phoneNumber.length > 11
        ? '' + appEasyState.onboarding.phoneNumber
        : appEasyState.onboarding.phoneNumber.length === 11
          ? '+' + appEasyState.onboarding.phoneNumber
          : '+1' + appEasyState.onboarding.phoneNumber

      sendVerificationCode(phoneNumber)
    }
  }, [captchaVerified]);

  const sendVerificationCode = (phoneNumber) => {
    console.log(`phoneNumber ===> `, phoneNumber);
    const appVerifier = window.recaptchaVerifier;
    console.log('appVerifier', appVerifier)
    props.firebase.auth
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmFunction) => {
        window.confirmFunction = confirmFunction;
        console.log('window.confirmFunction', window.confirmFunction)
      })
      .catch((error) => {
        console.log('error', error);
        if (!error.message.includes('client element has been removed')) {
          store.addNotification({
            title: 'Error',
            message: error.message,
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
        }
      });
  };

  const verifySMScode = async (code) => {
    if (!code) {
      store.addNotification({
        title: 'Error',
        message: 'Please enter the verification code.',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
      return;
    }
    setLoading(true)
    await window.confirmFunction
      .confirm(code)
      .then(async (res) => {
        console.log('verifySmsCode response', res);

        let userParams = {
          email: appEasyState.onboarding.email.toLowerCase(),
          phoneNumber: appEasyState.onboarding.phoneNumber,
          createdAt: new Date().getTime(),
          twoFactorAuthentication: true,
          emailNotifications: true,
          isOnboardingCompleted: false,
          adminEmail: null,
          firstName: null,
          middleName: null,
          lastName: null,
          website: null,
          firmName: null,
          discipline: null,
          concentration: null,
          otherConcentration: null,
          liability: null,
        };

        if (appEasyState.referrerCustomerId) {
          userParams= {
            ...userParams,
            referrerUid: appEasyState.referrerUid,
            referrerCustomerId: appEasyState.referrerCustomerId,
            isReferrerSignupCouponCreated: false,
            isReferrerSubscriptionCouponCreated: false,
          }
        }

        console.log('userParams === > ', userParams);
        debugger

        setTimeout(async () => {
          await sendOnboardingRequest(
            userParams,
            appEasyState.onboarding.password,
            props,
          );
          props.setVerificationCardCompleted(true)
        }, 7000)
      })
      .catch((err) => {
        setLoading(false)
        console.log('code verification err', err)
        store.addNotification({
          title: 'Error',
          message: 'The provided SMS verification code is invalid.',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
      });
  }

  const onFormSubmit = async (event) => {
    event.preventDefault();
    await verifySMScode(verificationCode)
  };


  return (
    <Card className={s1.onboardingCard}>
      <div className={s1.wrapper}>
        <Card.Title>
          <div className={'d-flex flex-direction-row'}>
            <img
              src={CareLeft}
              className={s1.arrowImage}
              alt='Arrow'
              onClick={async () => {
                await props.firebase.doSignOut()
                  .then((signoutRes) => {
                    localStorage.setItem('encryptedString', 'null')
                    localStorage.setItem('userId', 'null')
                    setCaptchaVerified(false)
                    props.setLoading(false)
                    props.setSignupCardCompleted(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            />
            <span className={s1.cardTitle}>OTP Verification</span>
          </div>
        </Card.Title>
        <Card.Subtitle className={s1.cardSubtitle}>
          {!captchaVerified ? (
            'Please verify the CAPTCHA for a code to be sent to your mobile number'
          ) : (
            'Please enter the 6 digit verification code that we have sent to your mobile number'
          )}
        </Card.Subtitle>
        <Card.Body style={{ padding: ' 0' }}>
          <div>
            {!captchaVerified ? (
              <center
                style={{ marginTop: '32px', minHeight: '80px' }}
                ref={(ref) => (recaptchaNode = ref)}>
              </center>
            ): (
              <>
                <OTPVerification
                  setVerificationCode={setVerificationCode}
                  verifySMScode={verifySMScode}
                />
                <center>
                  If you didn't receive the code!&nbsp;
                  <b
                    style={{ cursor: 'pointer', color: '#04B5FF' }}
                    onClick={async () => {
                      setCaptchaVerified(false)
                    }}
                  >
                    Resend
                  </b>
                </center>
              </>
            )}
          </div>
          <Button
            variant="primary"
            className={s.marginDbl}
            block
            disabled={verificationCode.length !== 6 || !captchaVerified}
            // onClick={(e) => onFormSubmit(e)}
          >
            {loading ? (
              <div>
                <span className="spinner-border spinner-border-sm mr-2" />
                Verifying
              </div>
            ) : (
              <div>
                Verify
              </div>
            )}
          </Button>
        </Card.Body>
      </div>
    </Card>
  )
}

export default withFirebase(view(VerificationCard))