/* eslint-disable indent */
import React, { useState, Fragment, useEffect } from 'react';
import Header from '../../Header';
import UploadDocument from '../UploadDocument';
import SearchInput from '../../SearchInput';
import s from './style.scss';
import { Tabs, Tab, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { All_DOCUMENTS } from '../../../utils/constants';
import ColorLabels from './ColorLabels';
import COLUMNS from './COLUMNS';
import Spinner from '../../Layout/Spinner';
import withAuthorization from '../../../auth/session/withAuthorization';
import { set } from 'local-storage';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';

const UserDocs = (props) => {
  console.log(`props in user docs ===>`, props);
  const { user, firebase } = props;
  const [key, setKey] = useState('all');
  const [allDocuments, setAllDocuments] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    firebase
      .getAllDocuments(props.location.state.user)
      .then((userDocs) => {
        setLoading(false);
        setAllDocuments(userDocs);
        setOriginalData(userDocs);
        console.log('userDocs ===> ', userDocs);
        return;
      })
      .catch((error) => {
        setLoading(false);
        console.log(`error ===> `, error);
      });
  }, []);

  const showdocumentTab = (arg) => setKey(arg);
  const getFilteredData = (data) => {
    setAllDocuments(data);
  };

  const getTrProps = (table, rowInfo) => {
    return {
      onClick: (e) => {
        console.log('rowInfo ===> ', rowInfo);

        if (rowInfo.original.subscribers) {
          props.history.push('/scannedDocumentDetails', {
            scanDetailsList: rowInfo.original.subscribers,
          });
        } else {
          store.addNotification({
            title: 'No Subscribers',
            message: 'This document does not have any subscriber.',
            type: 'warning',
            ...NOTIFICATION_PROPS,
          });
        }

        // if (rowInfo.original.status !== 'pacted') {
        //   props.history.push('/documentDetails', {
        //     documentSubmitted: true,
        //     documentDetails: rowInfo.original.data,
        //     backToDoc: false,
        //     numPages: rowInfo.original.totalPages,
        //     fileType: rowInfo.original.fileType,
        //     documentInformation: rowInfo.original,
        //     isMyDocuments: true,
        //   });
        // } else {
        //   props.history.push('/documentDetails', {
        //     documentSubmitted: true,
        //     documentDetails: rowInfo.original.sealPactDownloadUrl,
        //     backToDoc: false,
        //     numPages: rowInfo.original.totalPages,
        //     fileType: rowInfo.original.fileType,
        //     documentInformation: rowInfo.original,
        //     isMyDocuments: true,
        //   });
        //   // props.history.push('/sealpactDetails', {
        //   //   confirmationValues: rowInfo.original,
        //   //   documentInformation: rowInfo.original,
        //   // });
        // }
      },
      style: {
        borderRight: 0,
        borderBottom: rowInfo
          ? rowInfo.original
            ? 'Solid black 2px'
            : 'lightgray'
          : null,
        borderLeft: rowInfo
          ? rowInfo.original.status === 'pending' ||
            rowInfo.original.status === 'shared'
            ? 'Solid #fcd325 5px'
            : rowInfo.original.status === 'pacted'
            ? 'Solid #3c9 5px'
            : 'Solid #e64767 5px'
          : null,
      },
    };
  };

  const getTitle = () => {
    let title = '';
    console.log(
      `props.location.state.user.lastName.charAt('s') ===>`,
      props.location.state.user.lastName.charAt(1),
    );
    if (
      props.location.state.user.lastName
        .toLowerCase()
        .charAt(props.location.state.user.lastName.length - 1) === 's'
    ) {
      title = props.location.state.user.lastName + "' Documents";
      return (
        <dic>
          <div>{title}</div>
          <p style={{ fontSize: '20px' }}>
            Unique scan count: {getUniqueDocsCount()}
          </p>
        </dic>
      );
    } else {
      title = props.location.state.user.lastName + "'s Documents";
      return (
        <div>
          <div>{title}</div>
          <p style={{ fontSize: '20px' }}>
            Unique scan count: {getUniqueDocsCount()}
          </p>
        </div>
      );
    }
  };

  const getUniqueDocsCount = () => {
    let count = 0;
    const subsArray = [];
    allDocuments.forEach((document) => {
      if (document.subscribers) {
        subsArray.push(...document.subscribers);
      }
    });
    if (subsArray.length > 0) {
      const unique = [...new Set(subsArray.map((item) => item.ip4))];
      count = unique.length;
      return count;
    } else {
      return count;
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Header title={getTitle()} />

      <div className={`row no-gutters  ${s.features} `}>
        <div className="col text-right">{}</div>
        <div className="col-12 col-sm-8 col-md-5 col-lg-3 mt-1">
          <SearchInput
            array={originalData}
            getFilteredData={getFilteredData}
            searchTarget="firstName"
          />
        </div>
        <Col xs={12}>
          <Tabs
            id="docs-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className={`docstable ${s.tabs}`}
          >
            <Tab eventKey="all" title="All" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                />
              </div>
            </Tab>

            <Tab eventKey="SealPacted" title="SealPacted" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments.filter((a) => a.status === 'pacted')}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                />
              </div>
            </Tab>
            <Tab eventKey="Pending" title="Pending" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments.filter((a) => a.status === 'pending')}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                />
              </div>
            </Tab>

            <Tab eventKey="Invalidated" title="Invalidated" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments.filter((a) => a.status === 'invalidated')}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                />
              </div>
            </Tab>
          </Tabs>
          <ColorLabels tab={showdocumentTab} />
        </Col>
      </div>
    </Fragment>
  );
};
const condition = (user) => !!user && user.admin === true;
export default withAuthorization(condition)(UserDocs);
