import React from 'react';
import s from './style.scss';
import { ProgressBar } from 'react-bootstrap';

const UploadingProgressBar = (props) => {
  const now = 100;
  const label = 'Uploading';
  return (
    <div className={s.spinnerOuter}>
      <div className={s.spinnerInner}>
        <ProgressBar animated now={now} label={label} />
        <p className={s.progressText}>Your document is being created.</p>
        {/* <p className={s.note}>
          Note that this process can vary based on file size. If your document
          is not Ready for sealpacting within 2 minutes, please notify
          support@sealpact.com
        </p> */}
      </div>
    </div>
  );
};

export default UploadingProgressBar;
