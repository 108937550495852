import React, { useState, Fragment, useEffect, ReactFragment } from 'react';
import s from './style.scss';
import { Formik, Form } from 'formik';
import { Form as BForm, Button, Container, Row, Col } from 'react-bootstrap';
import LogoBrand from '../../LogoBrand';
import MaskedInput from '../../AppComponents/MaskedInput';
import { LOGINVALIDATION } from '../../../config/FormSchemas';
import DynamicModal from '../../DynamicModal';
import TermsAndConditions from '../../TermsAndPrivcay/TermsAndConditions';
import PrivacyPolicy from '../../TermsAndPrivcay/PrivacyPolicy';
import { withFirebase } from '../../../auth/firebase/context';
import { store } from 'react-notifications-component';
import Spinner from '../../Layout/Spinner';
import Header from '../../Header';
import crypto, { privateEncrypt } from 'crypto';
import {
  SERVICE_BOT_SECRECT_KEY,
  CREATE_CUSTOMER_PORTAL_LINK,
  GET_STRIPE_PRODUCTS,
  CREATE_STRIPE_SUBSCRIPTION, NOTIFICATION_PROPS, NOTIFICATION_PROPS_LONGER,
} from '../../../utils/constants';
import axios from 'axios';
import './subscription.css';
// https://sealpact-d669e.web.app/subscription
import withAuthorization from '../../../auth/session/withAuthorization';
import { appEasyState } from '../../../easyStateStore';

// import firebase from '../../../auth/firebase'

// const app = firebase.app

let email_hash;
class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prices: [],
      intervalView: 'year',
      selectedPrice: {},
    };
  }
  async componentDidMount() {
    console.log('this.props.user.subscriptionDetails', this.props.user.subscriptionDetails);
    if (this.props.user.subscriptionDetails && Object.keys(this.props.user.subscriptionDetails).length > 1) {
      const result = await axios
        .post(
          CREATE_CUSTOMER_PORTAL_LINK,
          {
            customer_id: this.props.user.subscriptionDetails.customer.id
              ? this.props.user.subscriptionDetails.customer.id
              : this.props.user.subscriptionDetails.customer,
          },
        )
        .catch((err) => {
          console.log(err);
        });
      console.log(this.props.user.subscriptionDetails.customer, result);
      if (result != undefined) window.location.replace(result.data.url);
    }
    email_hash = crypto
      .createHmac(
        'sha256',
        SERVICE_BOT_SECRECT_KEY, // SECRET KEY (KEEP SAFE!)
      )
      .update(this.props.user.email)
      .digest('hex');

    // window.billflowSettings = {
    //   billing_page_id: 'XtF8G4NEZf9EHPLRb7PJ', //V3 billing page id
    //   // billing_page_id: '8VuLxUAjHfwJXEdjTXk7', //V4 billing page id
    //   email: this.props.user.email,
    //   hash: email_hash,
    //   handleResponse: async (payload) => {
    //     debugger;

    //     console.log('Event ', payload.event);
    //     console.log('Response Object ', payload.response);
    //     // Redirect on Signup example
    //     // payload.event == 'change_plan' ||
    //     // payload.event == 'resubscribe' ||
    //     // payload.event == 'update_card')

    //     if (payload.event == 'create_subscription') {
    //       console.log('this.props => ', this.props);
    //       console.log('payload.response ===> ', payload.response);
    //       this.props.firebase
    //         .addUserSubscriptionDetails(this.props.user.uid, payload.response)
    //         .then((res) => {
    //           window.location.href = '/';
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     } else if (payload.event == 'cancel_subscription') {
    //       console.log('this.props => ', this.props);
    //       debugger;
    //       this.props.firebase
    //         .changeSubscriptionStatus(this.props.user.uid, 'cancel')
    //         .then((res) => {
    //           window.location.href = '/';
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     } else if (payload.event == 'resubscribe') {
    //       console.log(`payload.response ===> `, payload.response);
    //       this.props.firebase
    //         .addUserSubscriptionDetails(this.props.user.uid, payload.response)
    //         .then((res) => {
    //           window.location.href = '/';
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });

    //       // this.props.firebase
    //       //   .changeSubscriptionStatus(
    //       //     this.props.user.uid,
    //       //     payload.response.status,
    //       //   )
    //       //   .then((res) => {
    //       //     window.location.href = '/';
    //       //   })
    //       //   .catch((err) => {
    //       //     console.log(err);
    //       //   });
    //       debugger;
    //     } else if (payload.event == 'add_coupon') {
    //       console.log('payload.response ====> ', payload.response.status);
    //       //do nothing on adding a coupon event.
    //     }
    //   },
    // };

    if (!this.props.user.subscriptionDetails) {
      // (function () {
      //   var s = document.createElement('script');
      //   s.src = 'https://js.billflow.io/billflow-embed.js';
      //   s.async = true;
      //   s.type = 'text/javascript';
      //   var x = document.getElementsByTagName('script')[0];
      //   x.parentNode.insertBefore(s, x);
      // })();
      const result = (
        await axios
          .post(
            GET_STRIPE_PRODUCTS
          )
          .catch((err) => {
            console.log('error stripe =======> ', err);
          })
      ).data;
      console.log('result ****: ', result);
      let prices = result.prices.data.filter((price) => {
        return price.recurring.trial_period_days != null;
        // return price.product == 'prod_Hz6CwaTjIUxevB';
        // return price.recurring.trial_period_days != null;
      });
      this.setState({ prices });
    }
    else if ((this.props.user.subscriptionDetails.customer || this.props.user.subscriptionDetails.id)
      && Object.keys(this.props.user.subscriptionDetails).length === 1) {
      const result = (
        await axios
          .post(
            GET_STRIPE_PRODUCTS
          )
          .catch((err) => {
            console.log('error stripe =======> ', err);
          })
      ).data;
      let prices = result.prices.data.filter((price) => {
        return price.recurring.trial_period_days != null;
        // return price.product == 'prod_Hz6CwaTjIUxevB';
        // return price.recurring.trial_period_days != null;
      });
      this.setState({ prices });
    }
  }

  addUserPaymentDetatils = (userPaymentDetails) => {
    this.props.firebase.addUserSubscriptionDetails(
      this.props.user.uid,
      userPaymentDetails,
    );
  };

  createSubscription = async () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    let result = (
      await axios
        .post(
          CREATE_STRIPE_SUBSCRIPTION,
          {
            email: this.props.user.email,
            phoneNumber: this.props.user.phoneNumber,
            priceId: this.state.selectedPrice.id,
            customer: this.props.user.subscriptionDetails
              ? this.props.user.subscriptionDetails.customer
                ? this.props.user.subscriptionDetails.customer
                : this.props.user.subscriptionDetails.id
                  ? this.props.user.subscriptionDetails.id
                  : undefined
              : undefined,
          },
        )
        .catch((err) => {
          this.setState({ loading: false });
          store.addNotification({
            title: 'Error!',
            message: err.message || 'Subscription created unsuccessfully.',
            type: 'danger',
            ...NOTIFICATION_PROPS_LONGER,
          });
        })
    );
    console.log({ result });
    if (result && result.data && result.data.success) {
      result = result.data.data

      await this.addUserPaymentDetatils(result);
      setTimeout(() => {
        localStorage.setItem('planSubscribed', 'true')
        this.setState({ loading: false });

        if (this.props.user.isOnboardingCompleted !== undefined) {
          localStorage.setItem('onboardingCompleted', this.props.user.isOnboardingCompleted)
          if (this.props.user.isOnboardingCompleted === false) {
            localStorage.setItem('userId', this.props.user.uid)
            appEasyState.onboardingCompleted = 'false'
            window.location.href = 'account'
          }
          else {
            window.location.reload()
          }

        }
        else {
          window.location.reload()
        }

        // window.location.reload();
      }, 3000);
    }
    else {
      this.setState({ loading: false });
      store.addNotification({
        title: 'Error!',
        message: result.data.message || 'Subscription created unsuccessfully.',
        type: 'danger',
        ...NOTIFICATION_PROPS_LONGER,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Spinner />}
        <Header title="Subscription" />
        <Container className={s.appContainer}>
          <div className={s.mainContainer}>
            <div className={s.subscriptions}>
              <div className="__header" className={s.subscriptionHeader}>
                <div className="__product-image" className={s.imageContainer}>
                  <img
                    className={s.productImage}
                    src="https://files.stripe.com/links/MDB8YWNjdF8xR3p2YWZLcXREOWU1bmZrfGZsX2xpdmVfUVdac1BNWG5rUU5qelJ0SW12anBVZFFv00AR5XeZaU"
                    alt="stripe product image"
                  />
                </div>
                <h3
                  className="__tier-name __tier-name-SealPact"
                  className={s.tierName}
                >
                  SealPact
                </h3>
              </div>

              <div
                className="pricing-interval-selector __center"
                className={s.pricingInterValSelector}
              >
                <div className="servicebot-radio-group servicebot-radio-group-outline pricing-toggle-group">
                  {this.state.prices &&
                    this.state.prices.map((price) => {
                      if (
                        price.recurring.interval == 'year' &&
                        Object.keys(this.state.selectedPrice).length == 0
                      )
                        this.setState({ selectedPrice: price });
                      return (
                        <label
                          key={price.id}
                          onClick={() =>
                            this.setState({
                              intervalView: price.recurring.interval,
                              selectedPrice: price,
                            })
                          }
                          className={`capitalize __${
                            price.recurring.interval
                          } i18n-key-IntervalToggles_${
                            price.recurring.interval
                          } servicebot-radio-button-wrapper ${
                            this.state.intervalView == price.recurring.interval
                              ? 'servicebot-radio-button-wrapper-checked'
                              : ''
                          }`}
                        >
                          <span className="servicebot-radio-button servicebot-radio-button-checked">
                            <input
                              type="radio"
                              name="plan"
                              // className="servicebot-radio-button-input"
                              className={s.btnradio}
                              data-interval={price.recurring}
                              value={price.recurring.interval}
                              checked={price.recurring.interval === this.state.intervalView}
                            />
                            <span className="servicebot-radio-button-inner"></span>
                          </span>
                          <span>
                            {price.recurring.interval.charAt(0).toUpperCase() +
                              price.recurring.interval.slice(1)}
                            ly
                          </span>
                        </label>
                      );
                    })}
                </div>
              </div>
              {this.state.selectedPrice.currency && (
                <div className="servicebot-pricing-table __column-count-1">
                  <div className="__tier __tier-1 __tier-name-sealpact __align-left">
                    <div className="__body" className={s.bodyStyles}>
                      <div
                        data-component="PlanItem"
                        data-tiered="false"
                        data-metered="false"
                        data-one-time="false"
                        data-plan-type="flat"
                        data-tiered-flat_amount="false"
                        data-tiered-unit_amoount="true"
                        data-quantity="0"
                        data-unit=""
                        className="servicebot-row __tier-plan __plan-item __plan-1"
                      >
                        <div className="servicebot-col __price-label servicebot-col-sm-24 servicebot-col-md-10"></div>
                        <div className="servicebot-col __price servicebot-col-sm-24 servicebot-col-md-24">
                          <div className="__price-label" className={s.priceLabel}>
                            <span className="__amount ">
                              <span
                                className="servicebot-currency"
                                data-currency={
                                  this.state.selectedPrice.currency
                                }
                                data-amount={parseFloat(
                                  this.state.selectedPrice.unit_amount_decimal,
                                )}
                              >
                                $
                                {`${this.state.selectedPrice.unit_amount_decimal.slice(
                                  0,
                                  -2,
                                )}.${this.state.selectedPrice.unit_amount_decimal.slice(
                                  -2,
                                )}`}
                              </span>
                            </span>
                            <span
                              className="__label __price-suffix __interval"
                              data-i18n="PlanItemLabel_"
                            >
                              <span> / </span>
                              <span>
                                {this.state.selectedPrice.recurring.interval}
                              </span>
                            </span>
                          </div>

                          <div
                            className="__discounted m-t-md"
                            className={s.discounted}
                          >
                            {this.state.intervalView == 'year' && (
                              <span className="servicebot-tag servicebot-tag-green">
                                Discounted
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="__feature-list"
                        style={{
                          alignSelf: 'flex-start',
                          marginLeft: '1.25%',
                          color: '#4F566B',
                        }}
                      >
                        No credit card or coupon code required until after free
                        trial expires
                      </div>
                    </div>
                    <div className="__footer" className={s.footerStyles}>
                      <div className={s.divbtnTrail}>
                        <Button
                          className={s.btnTrail}
                          className="sc-dIvrsQ biCrYn bf-button __full   _3NM6Cu2g7QjO6j4WSEYBGp _1RTkeWIbayZ7rSk_0XZ-hN"
                          onClick={this.createSubscription}
                        >
                          {'Start Free Trial'}
                        </Button>
                      </div>
                      <div className="__trials">
                        <span className="__line-1">
                          <span className="__trial-notice">
                            Free{' '}
                            {
                              this.state.selectedPrice.recurring
                                .trial_period_days
                            }{' '}
                            Day Trial
                          </span>
                        </span>
                        <br />
                        <span className="__line-2" className={s.footerNote}>
                          No credit card required
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
        {/* <div id="billflow-embed" style={{ display: 'none' }}></div> */}
      </React.Fragment>
    );
  }
}
const condition = (user) => !!user;
export default withAuthorization(condition)(withFirebase(Subscription));
