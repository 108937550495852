import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import s from './style.scss';

const CustomTooltip = (props) => {
  console.log(`props in customtooltip`, props);
  const showOnTop = () => {
    var w = window.screen.width;
    if (w < 768) {
      return true;
    } else return false;
  };

  // const entering = (e) => {
  //   console.log('e ===> ', e);
  //   e.children[0].style.borderColor = 'blue';
  //   e.children[0].style.backgroundColor = 'white';
  //   e.children[1].style.backgroundColor = 'white';
  //   e.children[1].style.color = 'black';
  // };

  return (
    <OverlayTrigger
      trigger={props.trigger ? props.trigger : 'focus'}
      // onEntering={entering}
      target={props.target}
      show={true}
      key={'right'}
      placement={
        props.location ? props.location : showOnTop() ? 'top' : 'auto'
      }
      overlay={
        <Tooltip className id={`tooltip`}>
          {props.content}
        </Tooltip>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
