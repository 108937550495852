/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Container, Row, Col, Image } from 'react-bootstrap';
import s from './style.scss';
import DynamicModal from '../../DynamicModal';
// import Upload from '../../../assets/upload.png';
import UploadIcon from '../../../assets/UploadSimple.svg';
import UploadFile from '../../../assets/uploadFile.png';
import LocalComputer from '../../../assets/localComputer.png';
import SealProgressBar from '../../Layout/sealProgressBar';
import DropxBox from '../../../assets/dropbox.svg';
import GoogleDriveLogo from '../../../assets/google-drive.svg';
import OneDrive from '../../../assets/one-drive.svg';
import MicrosoftLoginPrompt from 'react-microsoft-login';
import axios from 'axios';
import isomorphicFetch from 'isomorphic-fetch';
import CustomTooltip from '../../AppComponents/CustomTooltip';
import {
  AUTH_DOMAIN,
  APP_URL,
  GOOGLE_PICKER_KEY,
  GOOGLE_PICKER_CLIENT_ID,
  GOOGLE_PICKER_SCOPE,
  CONVERT,
} from '../../../utils/constants';
import { NOTIFICATION_PROPS } from '../../../utils/constants';
import Switch from 'react-switch';

// import oneDriveAPI from 'onedrive-api';

import { withRouter } from 'react-router-dom';
import { FilePicker, ImagePicker } from 'react-file-picker';
import DropboxChooser from 'react-dropbox-chooser';
import GooglePicker from 'react-google-picker';
import { GraphFileBrowser } from '@microsoft/file-browser';
import { store } from 'react-notifications-component';
import GoogleDrive from 'google-drive';
import Uppy from '@uppy/core';
import oneDrive from '@uppy/onedrive';
import Dashboard from '@uppy/dashboard';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { PDFDocument, StandardFonts } from 'pdf-lib';
const { DashboardModal } = require('@uppy/react');

// const Uppy = require('@uppy/core');
// const GoogleDrive = require('@uppy/google-drive');

const UploadDocument = ({ history: { push }, uploading }) => {
  const fileSizeLimit = 100;
  const [showUploadModal, setUploadModalToggler] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOnedriveModel, setOnedriveModel] = useState(false);
  const [userAuthToken, setUserAuthToken] = useState();
  const [showUppy, setShowUppy] = useState(false);
  const [flattenFileSwitch, setFlattenFileSwitch] = useState(false);

  let uppy;

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const handleModal = () => setUploadModalToggler(!showUploadModal);
  const handleOneDriveModel = () => {
    // uppy = new Uppy({
    //   debug: true,
    //   autoProceed: false,
    //   restrictions: {
    //     maxFileSize: 1000000,
    //     maxNumberOfFiles: 3,
    //     minNumberOfFiles: 2,
    //     allowedFileTypes: ['image/*', 'video/*'],
    //   },
    // })
    //   .use(Dashboard, {
    //     trigger: '.UppyModalOpenerBtn',
    //     inline: true,
    //     target: '.DashboardContainer',
    //     replaceTargetContent: true,
    //     showProgressDetails: true,
    //     note: 'Images and video only, 2–3 files, up to 1 MB',
    //     height: 470,
    //     metaFields: [
    //       { id: 'name', name: 'Name', placeholder: 'file name' },
    //       {
    //         id: 'caption',
    //         name: 'Caption',
    //         placeholder: 'describe what the image is about',
    //       },
    //     ],
    //     browserBackButtonClose: true,
    //   })
    //   .use(oneDrive, {
    //     target: Dashboard,
    //     companionUrl: 'https://companion.uppy.io',
    //   });
    // uppy.on('complete', result => {
    //   console.log('successful files:', result.successful);
    //   console.log('failed files:', result.failed);
    // });
  };

  // let uppy;
  // useEffect(() => {
  //   initializeUppy();
  // }, []);

  // const initializeUppy = () => {
  //   uppy = new Uppy({
  //     id: 'uppysealpact',
  //     autoProceed: false,
  //     trigger: '#select-files',
  //     debug: true,
  //     restrictions: {
  //       maxFileSize: 10000000,
  //     },
  //     onBeforeUpload: files => {
  //       console.log('before upload', JSON.stringify(files));
  //       const value = Object.values(files).find(prop => prop);
  //       console.log(`my values ${JSON.stringify(value.preview)}`);
  //     },
  //   }).use(GoogleDrive, {});

  //   uppy.on('complete', result => {
  //     console.log('successful files:', result.successful);
  //     console.log('failed files:', result.failed);
  //   });

  //   // this.uppy.on('upload-success', (file, body) => {
  //   //   // do something with extracted response data
  //   //   // (`body` is equivalent to `file.response.body` or `uppy.getFile(fileID).response.body`)
  //   //   console.log('file:', file);
  //   //   console.log('fbody:', body);
  //   // });
  // };
  const authHandler = (err, data) => {
    if (!err) {
      setUserAuthToken(data.authResponseWithAccessToken.accessToken);
      setOnedriveModel(!showOnedriveModel);
    }
  };

  const getAuthenticationToken = () => {
    return new Promise((resolve) => {
      resolve(userAuthToken);
    });
  };

  const apiKey =
    'aman.mohsin@crewlogix.com_eEI8EOqCy3xeIi4R9n94T1omGafG41k973VC22but6kOAGva8J8q1zbwAiJ3F3c1'; // Replace with your PDF.co API key

  const handleFileChange = async (fileObject) => {
    try {
      // Check if the selected file is a PDF
      if (fileObject.type === 'application/pdf') {
        // Convert PDF to images using the PDF.co API
        const images = await convertPdfToImages(fileObject);

        // Convert the images back to PDF
        const newPdfFile = await convertImagesToPdf(images);

        // Update the state or navigate as needed with the new PDF file
        setAcceptedFiles([newPdfFile]);
        push({
          pathname: '/documentPreview',
          state: {
            acceptedFiles: [newPdfFile],
            fileType: newPdfFile.type,
          },
        });
      } else {
        // If the file is not a PDF, handle the file normally
        setAcceptedFiles([fileObject]);
        push({
          pathname: '/documentPreview',
          state: {
            acceptedFiles: [fileObject],
            fileType: fileObject.type,
          },
        });
      }
    } catch (error) {
      console.error('Error during file conversion:', error);
      store.addNotification({
        title: 'Error',
        message: 'An error occurred during file conversion. Please try again.',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    }
  };

  // Function to convert PDF to images using the PDF.co API
  const convertPdfToImages = async (fileObject) => {
    const formData = new FormData();
    formData.append('file', fileObject);

    // Define the PDF.co API endpoint for PDF to images conversion
    const apiUrl = 'https://api.pdf.co/v1/pdf/convert/to/jpg';

    const payload = {
      password: '',
      pages: '',
      url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      async: true,
    };

    const response = await axios.post(apiUrl, formData, {
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
      },
    });

    if (response.data.error) {
      throw new Error(`Conversion failed: ${response.data.message}`);
    }

    // Return the array of image file URLs
    const imageUrls = response.data.urls;

    // Fetch each image and return as an array of File objects
    const imageFiles = await Promise.all(
      imageUrls.map(async (url, index) => {
        const imageResponse = await axios.get(url, { responseType: 'blob' });
        return new File([imageResponse.data], `page${index + 1}.jpg`, {
          type: 'image/jpeg',
        });
      }),
    );

    return imageFiles;
  };

  // Function to convert images to PDF using the PDF.co API
  const convertImagesToPdf = async (imageFiles) => {
    const formData = new FormData();

    // Append each image file to the formData
    imageFiles.forEach((imageFile, index) => {
      formData.append(`file${index}`, imageFile);
    });

    // Define the PDF.co API endpoint for images to PDF conversion
    const apiUrl = 'https://api.pdf.co/v1/image/convert/to/pdf';

    const response = await axios.post(apiUrl, formData, {
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.error) {
      throw new Error(`Conversion failed: ${response.data.message}`);
    }

    // Return the link to the converted PDF file
    const pdfFileUrl = response.data.link;
    const pdfFileResponse = await axios.get(pdfFileUrl, {
      responseType: 'blob',
    });

    return new File([pdfFileResponse.data], 'converted.pdf', {
      type: 'application/pdf',
    });
  };

  // Function to read a file as an array buffer
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  // Function to manipulate the PDF file and navigate to the new page
  const manipulatePDF = async (FileObject) => {
    // Read file as array buffer
    const fileBuffer = await readFile(FileObject);

    // Load the PDF document
    const pdfDoc = await PDFDocument.load(fileBuffer);

    // Create a new page in the existing document
    const newPage = pdfDoc.addPage();
    // Add any necessary content or manipulation here

    // Save the updated PDF document and get the array buffer
    const updatedPdfBytes = await pdfDoc.save();

    // Convert the array buffer to a Blob
    const pdfBlob = new Blob([updatedPdfBytes], { type: 'application/pdf' });

    // Navigate to the new page and pass the modified PDF blob in the state
    setAcceptedFiles(new Array(pdfBlob));
    push({
      pathname: '/documentPreview',
      state: {
        acceptedFiles: [pdfBlob],
        fileType: 'application/pdf',
      },
    });
  };

  function base64ToFile(base64String, fileName, contentType = '') {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    return new File([blob], fileName, { type: contentType });
  }

  // if (loading) {
  //   return (
  //     <Fragment>
  //       <SealProgressBar message={'Your document is being fetched.'} />
  //     </Fragment>
  //   );
  // }

  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1]; // Remove data URL prefix
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  return (
    <Fragment>
      {loading && (
        <Fragment>
          <SealProgressBar message={'Your document is being fetched.'} />
        </Fragment>
      )}
      <CustomTooltip
        trigger={'hover'}
        location={'auto'}
        content={`Upload any PDF or Image (less than ${fileSizeLimit} MB). If larger documents are needed, please contact support@sealpact.com`}
      >
        <Button variant="text" className={s.uploadButton} onClick={handleModal}>
          <div style={{ cursor: 'pointer' }}>
            <Image
              src={UploadIcon}
              height={24}
              width={24}
              alt="upload"
              className={s.btn}
            />
            Upload Document
          </div>
        </Button>
      </CustomTooltip>

      <DynamicModal
        title="Upload New Document"
        show={showUploadModal}
        hideModal={handleModal}
        size="lg"
      >
        <Container>
          {/* <Row> */}
          {/* <Col
              xs={{ span: 10, offset: 1 }}
              sm={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              xl={{ span: 4, offset: 4 }}
            > */}
          {/* <div className={s.content}> */}
          {/* <p>Upload from cloud</p> */}
          {/* <div className={s.divBox}>
                  <div className={s.flexRow}> */}
          {/* <GooglePicker
                      clientId={GOOGLE_PICKER_CLIENT_ID}
                      developerKey={GOOGLE_PICKER_KEY}
                      scope={GOOGLE_PICKER_SCOPE}
                      onAuthenticate={(token) => {
                        setUserAuthToken(token);
                      }}
                      onChange={(data) => {
                        if (data.action === 'loaded') {
                          handleModal();
                        }
                        if (data.action === 'picked') {
                          let url =
                            'https://www.googleapis.com/drive/v2/files/' +
                            data.docs[0].id +
                            '?alt=media&source=downloadUrl';
                          axios
                            .get(url, {
                              responseType: 'arraybuffer',
                              headers: {
                                Authorization: `Bearer ${userAuthToken}`,
                                'Access-Control-Allow-Origin': '*',
                              },
                            })
                            .then(function (response) {
                              console.log('response ====> ', response);
                              const blob = new Blob([response.data], {
                                type: response.headers['content-type'],
                              });
                              console.log('blob => ', blob);

                              setAcceptedFiles(new Array(response));
                              push({
                                pathname: '/documentPreview',
                                state: {
                                  acceptedFiles: new Array(blob),
                                  fileType: response.headers['content-type'],
                                },
                              });
                              return;
                            })
                            .catch(function (error) {
                              console.log(error);
                            });
                          // push({
                          //   pathname: '/documentPreview',
                          //   state: {
                          //     acceptedFile: data.docs[0].embedUrl,
                          //     fileType: data.docs[0].mimeType,
                          //   },
                          // });

                          // fetch(data.docs[0].url, {})
                          //   .then(res => {
                          //     console.log('restinggg ====>', res);
                          //     return res.blob();
                          //   })
                          //   .then(blob => {
                          //     console.log('blob =>', blob);
                          //     var file = new File([blob], 'name');
                          //     push({
                          //       pathname: '/documentPreview',
                          //       state: {
                          //         acceptedFile: file,
                          //         fileType: data.docs[0].mimeType,
                          //       },
                          //     });

                          //     // const reader = new FileReader();

                          //     // reader.onabort = () =>
                          //     //   console.log('file reading was aborted');
                          //     // reader.onerror = () =>
                          //     //   console.log('file reading has failed');
                          //     // reader.onload = () => {
                          //     //   // Do whatever you want with the file contents
                          //     //   const binaryStr = reader.result;
                          //     //   console.log('binaryStr ========> ', binaryStr);
                          //     //     push({
                          //     //   pathname: '/documentPreview',
                          //     //   state: {
                          //     //     acceptedFile: blob,
                          //     //     fileType: data.docs[0].mimeType,
                          //     //   },
                          //     // });

                          //     // };
                          //     // console.log(
                          //     //   'array base 64 ',
                          //     //   reader.readAsDataURL(blob),
                          //     // );
                          //   });

                          // GoogleDrive(userAuthToken)
                          //   .files(data.docs[0].id)
                          //   .get(function callback(err, response, body) {
                          //     if (err) return console.log('err', err);
                          //     console.log('response', response);
                          //     console.log('body', JSON.parse(body));

                          // let blob = null;
                          // let xhr = new XMLHttpRequest();
                          // xhr.open('GET', body.webContentLink);
                          // xhr.responseType = 'blob';
                          // xhr.onload = function() {
                          //   blob = xhr.response;
                          //   console.log('blob ==========> ', blob);
                          // };
                          // xhr.send();

                          // fetch(body.webContentLink, {})
                          //   .then(res => {
                          //     console.log('restinggg ====>', res);

                          //     return res.blob();
                          //   })
                          //   .then(blob => {
                          //     console.log('blob =>', blob);
                          //     setAcceptedFiles(blob);
                          //     push({
                          //       pathname: '/documentPreview',
                          //       state: {
                          //         acceptedFile: blob,
                          //         fileType: data.docs[0].mimeType,
                          //       },
                          //     });
                          //   });
                          // });
                        }
                      }}
                      onAuthFailed={(data) =>
                        console.log('on auth failed:', data)
                      }
                      multiselect={true}
                      navHidden={true}
                      authImmediate={false}
                      mimeTypes={[
                        'image/png',
                        'image/jpeg',
                        'image/jpg',
                        'application/pdf',
                      ]}
                      viewId={'DOCS'}
                    >
                      <div className={s.boxContainer} onClick={() => ''}>
                        <img
                          className={s.box}
                          alt={'onedrive-pic'}
                          src={GoogleDriveLogo}
                        ></img>{' '}
                        <p>G-Drive</p>
                      </div>
                    </GooglePicker> */}
          {/* <MicrosoftLoginPrompt
                      clientId={'907904f5-1d08-4339-bf1c-044b9235987e'}
                      authCallback={authHandler}
                      redirectUri={APP_URL}
                      prompt={'select_account'}
                      graphScopes={[
                        'user.read',
                        'Files.Read',
                        'Files.Read.All',
                        'Files.Read.Selected',
                      ]}
                    >
                      <div
                        className={s.boxContainer}
                        onClick={handleOneDriveModel}
                      >
                        <img
                          className={s.box}
                          alt={'onedrive-pic'}
                          src={OneDrive}
                        ></img>
                        <p>One Drive</p>

                        <DashboardModal
                         uppy={uppy}
                         open={showUppy}
                         target={document.body}
                         plugins={['OneDrive']}
                         onRequestClose={() => {}}
                       />
                      </div>
                    </MicrosoftLoginPrompt> */}

          {/* <DropboxChooser
                      appKey={'d06ht7xg6hgrspi'}
                      success={(files) => {
                        console.log('files', files);
                        isomorphicFetch(files[0].link)
                          .then(function (response) {
                            return response.blob();
                          })
                          .then((blob) => {
                            console.log('blob ==> ', blob);
                            setAcceptedFiles(new Array(blob));
                            push({
                              pathname: '/documentPreview',
                              state: {
                                acceptedFiles: new Array(blob),
                                fileType: blob.type,
                              },
                            });
                          });

                        // axios
                        //   .get(files[0].link, {
                        //     responseType: 'arraybuffer',
                        //     headers: {
                        //       Authorization: `Bearer ${userAuthToken}`,
                        //       'Access-Control-Allow-Origin':
                        //         'https://dl.dropboxusercontent.com/',
                        //       'Api-User-Agent': 'SealPact',
                        //     },
                        //   })
                        //   .then(function(response) {
                        //     console.log('response ====> ', response);
                        //     const blob = new Blob([response.data], {
                        //       type: response.headers['content-type'],
                        //     });
                        //     console.log('blob => ', blob);

                        //     setAcceptedFiles(new Array(response));
                        //     push({
                        //       pathname: '/documentPreview',
                        //       state: {
                        //         acceptedFiles: new Array(blob),
                        //         fileType: response.headers['content-type'],
                        //       },
                        //     });
                        //     return;
                        //   })
                        //   .catch(function(error) {
                        //     console.log(error);
                        //   });

                        // fetch(files[0].link, {
                        //   mode: 'cors',
                        //   headers: new Headers({
                        //     'Access-Control-Allow-Origin': '*',
                        //   }),
                        // })
                        //   .then(res => {
                        //     console.log('restinggg ====>', res);
                        //     return res.blob();
                        //   })
                        //   .then(blob => {
                        //     setAcceptedFiles(blob);
                        //     push({
                        //       pathname: '/documentPreview',
                        //       state: {
                        //         acceptedFiles: new Array(blob),
                        //         fileType: 'application/pdf',
                        //       },
                        //     });
                        //   });
                      }}
                      cancel={() => ''}
                      multiselect={false}
                      linkType={'direct'}
                      extensions={['.pdf', '.jpg', '.jpeg']}
                    >
                      <div className={s.boxContainer}>
                        <img
                          className={s.box}
                          alt={'onedrive-pic'}
                          src={DropxBox}
                        ></img>{' '}
                        <p>Dropbox</p>
                      </div>
                    </DropboxChooser>
                  </div>
                </div> */}
          {/* <div className={s.flattenFileSwitchDiv}>
                  <span style={{ marginRight: '10px' }}>Flatten File</span>
                  <Switch
                    checked={flattenFileSwitch}
                    onChange={() => setFlattenFileSwitch(!flattenFileSwitch)}
                  />
                </div> */}
          {/* <p className={s.btnText}>Upload from local computer</p> */}

          {/* <input
                   type="file"
                   id="docpicker"
                   name="myfile"
                   text="somefile"
                   onClick={}
                   style="display: none;"
                   variant="primary"
                 />
                   <Button
                     variant="primary"
                     name="docpicker"

                     type="file"
                     onClick={() => push('/documentPreview')}
                     block
                   >
                     Upload
                   </Button> */}
          {/* <FilePicker
                  extensions={['jpg', 'jpeg', 'png', 'pdf']}
                  dims={{
                    minWidth: 100,
                    maxWidth: 50000,
                    minHeight: 100,
                    maxHeight: 50000,
                  }}
                  maxSize={fileSizeLimit}
                  onChange={async (FileObject) => {
                    if (flattenFileSwitch) {
                      uploading(true);
                      const base64String = await fileToBase64(FileObject);
                      if (FileObject.type == 'application/pdf') {
                        const formData = new FormData();
                        formData.append('file', FileObject);
                        try {
                          const response = await axios.post(CONVERT, {
                            base64Data: base64String,
                          });

                          if (response.status === 200) {
                            const fileName = 'example.pdf'; // Name of the file
                            const contentType = 'application/pdf'; // Content type of the file
                            const newfileObject = base64ToFile(
                              response.data.base64String,
                              fileName,
                              contentType,
                            );

                            // Navigate to the new page and pass the modified PDF blob in the state
                            setAcceptedFiles(new Array(newfileObject));
                            push({
                              pathname: '/documentPreview',
                              state: {
                                acceptedFiles: new Array(newfileObject),
                                fileType: FileObject.type,
                              },
                            });
                          } else {
                            console.error('File upload failed');
                          }
                        } catch (error) {
                          console.error('Error uploading file:', error);
                        }
                      } else {
                        setAcceptedFiles(new Array(FileObject));

                        push({
                          pathname: '/documentPreview',
                          state: {
                            acceptedFiles: new Array(FileObject),
                            fileType: FileObject.type,
                          },
                        });
                      }
                    } else {
                      setAcceptedFiles(new Array(FileObject));

                      push({
                        pathname: '/documentPreview',
                        state: {
                          acceptedFiles: new Array(FileObject),
                          fileType: FileObject.type,
                        },
                      });
                    }
                  }}
                  // onChange={async (FileObject) => {
                  //   if (FileObject.type === 'application/pdf') {
                  //     await manipulatePDF(FileObject);
                  //   } else {
                  //     setAcceptedFiles(new Array(FileObject));
                  //     push({
                  //       pathname: '/documentPreview',
                  //       state: {
                  //         acceptedFiles: [FileObject],
                  //         fileType: FileObject.type,
                  //       },
                  //     });
                  //   }
                  // }}
                  // onChange={handleFileChange}
                  onError={(errMsg) =>
                    store.addNotification({
                      title: 'Error',
                      message: errMsg,
                      type: 'danger',
                      ...NOTIFICATION_PROPS,
                    })
                  }
                >
                  <Button
                    variant="primary"
                    // onClick={() => push('/documentPreview')}
                    block
                  >
                    Upload
                  </Button>
                </FilePicker> */}
          {/* </div> */}
          {/* </Col> */}
          {/* </Row> */}
          <Row>
            <Col>
              <Dropzone
                onDrop={async (FileObject) => {
                  const newFile = FileObject[0];
                  console.log('newFile', newFile);
                  if (newFile.size <= fileSizeLimit * 1000000) {
                    if (flattenFileSwitch) {
                      uploading(true);
                      const base64String = await fileToBase64(FileObject[0]);
                      if (FileObject[0].type == 'application/pdf') {
                        const formData = new FormData();
                        formData.append('file', FileObject[0]);
                        try {
                          const response = await axios.post(CONVERT, {
                            base64Data: base64String,
                          });

                          if (response.status === 200) {
                            const fileName = 'example.pdf'; // Name of the file
                            const contentType = 'application/pdf'; // Content type of the file
                            const newfileObject = base64ToFile(
                              response.data.base64String,
                              fileName,
                              contentType,
                            );

                            // Navigate to the new page and pass the modified PDF blob in the state
                            setAcceptedFiles(new Array(newfileObject));
                            push({
                              pathname: '/documentPreview',
                              state: {
                                acceptedFiles: new Array(newfileObject),
                                fileType: FileObject[0].type,
                              },
                            });
                          } else {
                            console.error('File upload failed');
                          }
                        } catch (error) {
                          console.error('Error uploading file:', error);
                        }
                      } else {
                        setAcceptedFiles(new Array(FileObject[0]));

                        push({
                          pathname: '/documentPreview',
                          state: {
                            acceptedFiles: new Array(FileObject[0]),
                            fileType: FileObject[0].type,
                          },
                        });
                      }
                    } else {
                      setAcceptedFiles(new Array(FileObject[0]));

                      push({
                        pathname: '/documentPreview',
                        state: {
                          acceptedFiles: new Array(FileObject[0]),
                          fileType: FileObject[0].type,
                        },
                      });
                    }
                  } else {
                    store.addNotification({
                      title: 'Error',
                      message: `File size must be less than ${fileSizeLimit} MB.`,
                      type: 'danger',
                      ...NOTIFICATION_PROPS,
                    });
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({ className: 'dropzone' })}
                      className={s.dragDiv}
                    >
                      <input
                        {...getInputProps()}
                        accept={'application/pdf, image/*'}
                      />
                      <div className={'d-flex flex-column align-items-center'}>
                        <p className={s.text}>
                          Drag file here or click to upload
                        </p>
                        <p className={s.textSmall}>PDF up to 100 MB</p>
                        <img src={UploadFile} />
                        <p className={s.textSmallBold}>Upload File</p>
                      </div>
                    </div>
                    {/* <aside>
                       <ul>
                         {acceptedFiles.map(file => (
                           <li key={file.path}>
                             {file.path} - {file.size} bytes
                           </li>
                         ))}
                       </ul>
                     </aside> */}
                  </section>
                )}
              </Dropzone>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={s.dropBoxDiv}>
                <p className={s.text}>Upload from cloud</p>
                <p className={s.textSmall}>
                  Browse your Dropbox and choose the files you want to upload.
                </p>
                <DropboxChooser
                  appKey={'d06ht7xg6hgrspi'}
                  success={(files) => {
                    console.log('files', files);
                    isomorphicFetch(files[0].link)
                      .then(function (response) {
                        return response.blob();
                      })
                      .then((blob) => {
                        console.log('blob ==> ', blob);
                        setAcceptedFiles(new Array(blob));
                        push({
                          pathname: '/documentPreview',
                          state: {
                            acceptedFiles: new Array(blob),
                            fileType: blob.type,
                          },
                        });
                      });

                    // axios
                    //   .get(files[0].link, {
                    //     responseType: 'arraybuffer',
                    //     headers: {
                    //       Authorization: `Bearer ${userAuthToken}`,
                    //       'Access-Control-Allow-Origin':
                    //         'https://dl.dropboxusercontent.com/',
                    //       'Api-User-Agent': 'SealPact',
                    //     },
                    //   })
                    //   .then(function(response) {
                    //     console.log('response ====> ', response);
                    //     const blob = new Blob([response.data], {
                    //       type: response.headers['content-type'],
                    //     });
                    //     console.log('blob => ', blob);

                    //     setAcceptedFiles(new Array(response));
                    //     push({
                    //       pathname: '/documentPreview',
                    //       state: {
                    //         acceptedFiles: new Array(blob),
                    //         fileType: response.headers['content-type'],
                    //       },
                    //     });
                    //     return;
                    //   })
                    //   .catch(function(error) {
                    //     console.log(error);
                    //   });

                    // fetch(files[0].link, {
                    //   mode: 'cors',
                    //   headers: new Headers({
                    //     'Access-Control-Allow-Origin': '*',
                    //   }),
                    // })
                    //   .then(res => {
                    //     console.log('restinggg ====>', res);
                    //     return res.blob();
                    //   })
                    //   .then(blob => {
                    //     setAcceptedFiles(blob);
                    //     push({
                    //       pathname: '/documentPreview',
                    //       state: {
                    //         acceptedFiles: new Array(blob),
                    //         fileType: 'application/pdf',
                    //       },
                    //     });
                    //   });
                  }}
                  cancel={() => ''}
                  multiselect={false}
                  linkType={'direct'}
                  extensions={['.pdf', '.jpg', '.jpeg']}
                >
                  <div className={s.boxContainer}>
                    <img
                      className={s.box}
                      alt={'onedrive-pic'}
                      src={DropxBox}
                    ></img>{' '}
                    <p>Dropbox</p>
                  </div>
                </DropboxChooser>
              </div>
            </Col>
            <Col>
              <div className={s.localUploadDivDiv}>
                <p className={s.text}>Upload from local computer</p>
                <p className={s.textSmall}>Upload file from local computer</p>
                <FilePicker
                  extensions={['jpg', 'jpeg', 'png', 'pdf']}
                  dims={{
                    minWidth: 100,
                    maxWidth: 50000,
                    minHeight: 100,
                    maxHeight: 50000,
                  }}
                  maxSize={fileSizeLimit}
                  onChange={async (FileObject) => {
                    if (flattenFileSwitch) {
                      uploading(true);
                      const base64String = await fileToBase64(FileObject);
                      if (FileObject.type == 'application/pdf') {
                        const formData = new FormData();
                        formData.append('file', FileObject);
                        try {
                          const response = await axios.post(CONVERT, {
                            base64Data: base64String,
                          });

                          if (response.status === 200) {
                            const fileName = 'example.pdf'; // Name of the file
                            const contentType = 'application/pdf'; // Content type of the file
                            const newfileObject = base64ToFile(
                              response.data.base64String,
                              fileName,
                              contentType,
                            );

                            // Navigate to the new page and pass the modified PDF blob in the state
                            setAcceptedFiles(new Array(newfileObject));
                            push({
                              pathname: '/documentPreview',
                              state: {
                                acceptedFiles: new Array(newfileObject),
                                fileType: FileObject.type,
                              },
                            });
                          } else {
                            console.error('File upload failed');
                          }
                        } catch (error) {
                          console.error('Error uploading file:', error);
                        }
                      } else {
                        setAcceptedFiles(new Array(FileObject));

                        push({
                          pathname: '/documentPreview',
                          state: {
                            acceptedFiles: new Array(FileObject),
                            fileType: FileObject.type,
                          },
                        });
                      }
                    } else {
                      setAcceptedFiles(new Array(FileObject));

                      push({
                        pathname: '/documentPreview',
                        state: {
                          acceptedFiles: new Array(FileObject),
                          fileType: FileObject.type,
                        },
                      });
                    }
                  }}
                  // onChange={async (FileObject) => {
                  //   if (FileObject.type === 'application/pdf') {
                  //     await manipulatePDF(FileObject);
                  //   } else {
                  //     setAcceptedFiles(new Array(FileObject));
                  //     push({
                  //       pathname: '/documentPreview',
                  //       state: {
                  //         acceptedFiles: [FileObject],
                  //         fileType: FileObject.type,
                  //       },
                  //     });
                  //   }
                  // }}
                  // onChange={handleFileChange}
                  onError={(errMsg) =>
                    store.addNotification({
                      title: 'Error',
                      message: errMsg,
                      type: 'danger',
                      ...NOTIFICATION_PROPS,
                    })
                  }
                >
                  <div className={s.boxContainer}>
                    <img src={LocalComputer} />
                    <p className={s.textSmallBold}>Local Computer</p>
                  </div>
                </FilePicker>
              </div>
              {/* <div className={s.localUploadDivDiv}>
                <p className={s.text}>Upload from local computer</p>
                <p className={s.textSmall}>Upload file from local computer</p>
                <img src={LocalComputer} />
                <p className={s.textSmallBold}>Local Computer</p>
              </div> */}
            </Col>
          </Row>
        </Container>
        <DynamicModal
          title="Select the file"
          show={showOnedriveModel}
          hideModal={handleOneDriveModel}
        >
          <GraphFileBrowser
            getAuthenticationToken={getAuthenticationToken}
            onSuccess={(selectedKeys) => {
              console.log('userAuthToken ', userAuthToken);
              console.log('selectedKeys =>', selectedKeys);
              console.log(selectedKeys[0].driveItem_203[1]);
              console.log(selectedKeys[0].driveItem_203[2]);

              let url =
                'https://graph.microsoft.com/v1.0/me/drive/items/' +
                selectedKeys[0].driveItem_203[2] +
                '/content';
              axios
                .get(url, {
                  responseType: 'arraybuffer',
                  headers: {
                    Authorization: `Bearer ${userAuthToken}`,
                  },
                })
                .then(function (response) {
                  console.log(response);
                  const blob = new Blob([response.data], {
                    type: response.headers['content-type'],
                  });
                  console.log('blob => ', blob);

                  setAcceptedFiles(new Array(response));
                  push({
                    pathname: '/documentPreview',
                    state: {
                      acceptedFiles: new Array(blob),
                      fileType: response.headers['content-type'],
                    },
                  });
                  return;
                })
                .catch(function (error) {
                  console.log(error);
                });
            }}
            onCancel={handleOneDriveModel}
            selectionMode={'pick'}
            itemMode={'files'}
          />
        </DynamicModal>
      </DynamicModal>
    </Fragment>
  );
};
export default withRouter(UploadDocument);
