import React from 'react';
import DynamicModal from '../../../DynamicModal';
import { Button } from 'react-bootstrap';
import s from '../style.scss';

const DeactivationModal = ({
  hideModal,
  confirmDeactivation,
  showModal,
  deactivationType,
  activateSignature,
}) => {
  const action = activateSignature ? 'Activate' : 'Deactivate';
  return (
    <DynamicModal
      show={showModal}
      title="SealPact - Confirmation"
      hideModal={hideModal}
      size="lg"
    >
      <div className={s.modalContent}>
        <p>
          Are you sure you want to manually {action} this{' '}
          {deactivationType === 'SubmittalReviewStamp'
            ? 'Submittal review stamp'
            : deactivationType}
          ?
        </p>
      </div>
      <div className={s.btnStyle}>
        <Button variant="secondary" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="primary-outline" onClick={confirmDeactivation}>
          Confirm
        </Button>
      </div>
    </DynamicModal>
  );
};

export default DeactivationModal;
