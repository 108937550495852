import Dashboard from '../assets/my-dashboard.png';
import ADashboard from '../assets/my-dashboard-active.png';
import Seals from '../assets/my-seal.png';
import ASeal from '../assets/my-seal-active.png';
import Account from '../assets/my-account.png';
import AAccount from '../assets/my-account-active.png';
import Document from '../assets/my-documents.png';
import ADocument from '../assets/my-document-active.png';
import DashboardIcon from '../assets/SquaresFour.svg';
import DashboardIconClicked from '../assets/Dashboard-clicked.png';
import SealsIcon from '../assets/Stamp.svg';
import SealsIconClicked from '../assets/Seal-clicked.png';
import DocumentsIcon from '../assets/Newspaper.svg';
import DocumentsIconClicked from '../assets/document-clicked.png';
import AccountIcon from '../assets/User_.svg';
import AccountIconClicked from '../assets/account-clicked.png';
import SubscriptionIcon from '../assets/CurrencyCircleDollar.svg';
import SubscriptionIconClicked from '../assets/subscription-clicked.png';
import GiftIconBlack from '../assets/GiftIconBlack.svg';
import GiftIconClicked from '../assets/GiftActive.svg';

export const ALLASSETS = [
  {
    id: 1,
    type: 'stamp',
    status: true,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 2,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 3,
    type: 'stamp',
    status: false,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 4,
    type: 'stamp',
    status: false,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 5,
    type: 'stamp',
    status: true,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 6,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 7,
    type: 'signature',
    status: false,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 8,
    type: 'stamp',
    status: false,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 9,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 10,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 11,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 12,
    type: 'signature',
    status: false,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
];
export const SEALS = [
  {
    id: 1,
    type: 'stamp',
    status: true,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 2,
    type: 'stamp',
    status: true,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 3,
    type: 'stamp',
    status: false,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 4,
    type: 'stamp',
    status: true,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 4,
    type: 'stamp',
    status: true,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 5,
    type: 'stamp',
    status: false,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 6,
    type: 'stamp',
    status: true,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 7,
    type: 'stamp',
    status: false,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 8,
    type: 'stamp',
    status: true,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 9,
    type: 'stamp',
    status: false,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 10,
    type: 'stamp',
    status: true,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 11,
    type: 'stamp',
    status: false,
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
];

export const SIGNATURES = [
  {
    id: 1,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 2,
    type: 'signature',
    status: false,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 3,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 4,
    type: 'signature',
    status: false,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 5,
    type: 'signature',
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 6,
    type: 'signature',
    status: false,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 7,
    type: 'signature',
    status: false,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 8,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 9,
    type: 'signature',
    status: false,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 10,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
  {
    id: 11,
    type: 'signature',
    status: true,
    imageSrc: `https://filedb.experts-exchange.com/incoming/2013/11_w45/686479/John-Doe-sig-not-transparent.jpg`,
  },
];

export const PENDING_PRIMARY = [
  {
    id: 1,
    dateUploaded: 'Date',
    type: 'primary',
    pacted: false,
    name: 'First Document Name',
    projectName: 'Project Name',
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 2,
    date: 'Date',
    type: 'primary',
    pacted: false,
    documentName: 'Document Name',
    projectName: 'Pending Project',
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 3,
    date: 'Date',
    pacted: false,
    type: 'primary',
    documentName: 'First Document Name',
    projectName: 'Project Name',
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
];

export const PENDING_SECONDARY = [];

export const PENDING_VIEWER = [
  {
    id: 1,
    date: 'Date',
    type: 'viewer',
    documentName: 'Viewer Document Name',
    projectName: 'Project Name',
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 2,
    date: 'Date',
    type: 'viewer',
    documentName: 'Document Name',
    projectName: 'Project Name',
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 3,
    date: 'Date',
    type: 'viewer',
    documentName: 'Document Name',
    projectName: 'Project Name',
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 4,
    date: 'Date',
    type: 'viewer',
    documentName: 'Viewer Document Name',
    projectName: 'Project Name',
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
  {
    id: 5,
    date: 'Date',
    type: 'viewer',
    documentName: 'Document Name',
    projectName: 'Testing',
    imageSrc: `https://www.cab.ca.gov/images/arch/seal_renewal_no_date.png`,
  },
];

export const SIDEBAR_ITEMS = [
  {
    id: 1,
    title: 'My Dashboard',
    url: '/dashboard',
    imgSrc: DashboardIcon,
    activeImgSrc: DashboardIconClicked,
  },
  {
    id: 2,
    title: 'My Seals',
    url: '/seals',
    imgSrc: SealsIcon,
    activeImgSrc: SealsIconClicked,
  },
  {
    id: 3,
    title: 'My Documents',
    url: '/documents',
    imgSrc: DocumentsIcon,
    activeImgSrc: DocumentsIconClicked,
  },
  {
    id: 4,
    title: 'My Account',
    url: '/account',
    imgSrc: AccountIcon,
    activeImgSrc: AccountIconClicked,
  },
  {
    id: 5,
    title: 'Subscription',
    url: '/subscription',
    imgSrc: SubscriptionIcon,
    activeImgSrc: SubscriptionIconClicked,
  },
  {
    id: 6,
    title: 'Referrals & Rewards',
    url: '/referrals',
    imgSrc: GiftIconBlack,
    activeImgSrc: GiftIconClicked,
  },
  {
    id: 7,
    title: 'Portal',
    url: '/allUsers',
    imgSrc: AccountIcon,
    activeImgSrc: AccountIconClicked,
  },
];

export const All_DOCUMENTS = [
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pacted',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pacted',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pacted',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pacted',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Tanner Telsi',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pacted',
    type: 'shared',
  },
  {
    primarySigner: 'Tanner Telsi',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },

  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'invalidated',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Tanner Telsi',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Tanner Telsi',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'invalidated',
    type: 'shared',
  },
  {
    primarySigner: 'Tanner Telsi',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Primary Name',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Tanner Telsi',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
  {
    primarySigner: 'Tanner Telsi',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'invalidated',
    type: 'shared',
  },

  {
    primarySigner: 'Tanner Telsi',
    secondarySigner: 'Second Owner',
    sealOriginator: 'Originator',
    projectNo: '230123',
    projectAddress: 'Project Address',
    projectName: 'Project Name',
    dateUploaded: 'Uploaded date',
    dateSealpacted: 'Date Sealpacted',
    status: 'pending',
    type: 'shared',
  },
];
export const user = {
  image: require('../assets/avatar.png'),
  name: 'John A. Smith',
  profession: 'Design Professional',
  company: 'Company Name',
  discipline: 'JS',
  primaryEmail: 'sealpacter@sealpact.com',
  secondaryEmail: 'sealpacter2@sealpact.com',
  phone: '123-4567890',
  address: '123 Main Street',
  firmName: 'Firm Name',
  admin: 'Administrator',
  proLiability: 'Professional liability insurer',
  emailNotif: false,
};
export const USERBASICINFO = {
  name: 'john A,Smith',
  licenseNo: '12345678',
  firmName: 'SealPact inc.',
  projectName: 'SealPact',
  projectNo: 'No. 12345',
  projectAddress: '123 Main Street, Anytown, CT 12345',
  deliverable: 'File name.filetype',
};
export const USERADVANCEDINFO = {
  clientName: 'First name Last name',
  clientEmail: '12345678',
  clientType: 'SealPact inc.',
  ownerType: 'SealPact',
  natureOfWork: 'Design',
  generalScope: 'General scope',
  projectType: 'Project type',
  discipline: 'Discipline',
  concentration: 'concentration ',
  projectRole: 'Speciality ',
  dateOfIssuance: 'Jun 24, 2019',
  scopeInclusions: 'Lorem Ipsum',
  scopeExclusions: 'scopeExclusions',
};

export const USERQUALITYINFO = {
  qualityNeeded: true,
  IndependentReviewRequired: false,
  drawingRequired: false,
  ifDrawingRequired: 'N/A',
  constructionAdministration: true,
  siteVisits: 'Input Site visits',
  specialInspections: true,
  attachedSIFileName: 'filename',
};

export const USERCONTRACTUALINFO = {
  fees: 'N/A',
  retainedSubConsultants: false,
  subConsultantProfession: 'N/A',
  contractType: 'written',
  uploadContractFile: 'contract.filename',
  contractOwner: 'John A.Smith',
  contract: 'Written',
  liabilityAmountLimit: 'N/A',
  waiver: 'N/A',
};
export const STATES = [
  {
    label: 'Alabama',
    value: 1,
  },
  {
    label: 'Alaska',
    value: 2,
  },
  {
    label: 'Arizona',
    value: 2,
  },
  {
    label: 'Arkansas',
    value: 2,
  },
  {
    label: 'California',
    value: 2,
  },
  {
    label: 'Colorado',
    value: 2,
  },
  {
    label: 'Connecticut',
    value: 2,
  },
  {
    label: 'Delaware',
    value: 2,
  },
  {
    label: 'District of Columbia',
    value: 2,
  },
  {
    label: 'Florida',
    value: 2,
  },
  {
    label: 'Georgia',
    value: 2,
  },
  {
    label: 'Hawaii',
    value: 2,
  },
  {
    label: 'Idaho',
    value: 2,
  },
  {
    label: 'Illinois',
    value: 2,
  },
  {
    label: 'Indiana',
    value: 2,
  },
  {
    label: 'Iowa',
    value: 2,
  },
  {
    label: 'Kansas',
    value: 2,
  },
  {
    label: 'Kentucky',
    value: 2,
  },
  {
    label: 'Louisiana',
    value: 2,
  },
  {
    label: 'Maine',
    value: 2,
  },
  {
    label: 'Maryland',
    value: 2,
  },
  {
    label: 'Massachusetts',
    value: 2,
  },
  {
    label: 'Michigan',
    value: 2,
  },
  {
    label: 'Minnesota',
    value: 2,
  },
  {
    label: 'Mississippi',
    value: 2,
  },
  {
    label: 'Missouri',
    value: 2,
  },
  {
    label: 'Montana',
    value: 2,
  },
  {
    label: 'Nebraska',
    value: 2,
  },
  {
    label: 'Nevada',
    value: 2,
  },
  {
    label: 'New Hampshire',
    value: 2,
  },
  {
    label: 'New Jersey',
    value: 2,
  },
  {
    label: 'New Mexico',
    value: 2,
  },
  {
    label: 'New York',
    value: 2,
  },
  {
    label: 'North Carolina',
    value: 2,
  },
  {
    label: 'North Dakota',
    value: 2,
  },
  {
    label: 'Ohio',
    value: 2,
  },
  {
    label: 'Oklahoma',
    value: 2,
  },
  {
    label: 'Oregon',
    value: 2,
  },
  {
    label: 'Pennsylvania',
    value: 2,
  },
  {
    label: 'Rhode Island',
    value: 2,
  },
  {
    label: 'South Carolina',
    value: 2,
  },
  {
    label: 'South Dakota',
    value: 2,
  },
  {
    label: 'Tennessee',
    value: 2,
  },
  {
    label: 'Texas',
    value: 2,
  },
  {
    label: 'Utah',
    value: 2,
  },
  {
    label: 'Vermont',
    value: 2,
  },
  {
    label: 'Virginia',
    value: 2,
  },
  {
    label: 'Washington',
    value: 2,
  },
  {
    label: 'West Virginia',
    value: 2,
  },
  {
    label: 'Wisconsin',
    value: 2,
  },
  {
    label: 'Wyoming',
    value: 2,
  },
  {
    label: 'Other',
    // value: 'Other',
  },
];
export const CLIENTTYPE = [
  {
    label: 'Commercial (Private)',
    value: 'commercial',
  },
  {
    label: 'Residential (Private)',
    value: 'residential',
  },
  {
    label: 'Government Agency',
    value: 'agency',
  },
  {
    label: 'Condominium/Management Company',
    value: 'management',
  },
  {
    label: 'Design Professional',
    value: 'designProfessional',
  },
  {
    label: 'Contractor/Subcontractor',
    value: 'contractor',
  },
  {
    label: 'Insurance Company',
    value: 'insuranceCompany',
  },
  {
    label: 'Law Firm',
    value: 'lawFilm',
  },
  {
    label: 'School/University',
    value: 'school',
  },
  {
    label: 'Realtor',
    value: 'realtor',
  },
  {
    label: 'Testing Agency',
    value: 'testingAgency',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const INVALIDATION_REASON = [
  {
    key: 1,
    value: 'Updates/Revisions to Deliverable',
  },
  {
    key: 2,
    value: 'Updates/Revisions to SealPact Information',
  },
  {
    key: 3,
    value: 'Design Professional and Client have Terminated Relationship',
  },
  {
    key: 4,
    value: 'Other',
  },
];

export const OWNERTYPE = [
  {
    label: 'Commercial (Private)',
    value: 'commercial',
  },
  {
    label: 'Residential (Private)',
    value: 'residential',
  },
  {
    label: 'Government Agency',
    value: 'agency',
  },
  {
    label: 'Condominium/Management Company',
    value: 'management',
  },
  {
    label: 'Contractor/Subcontractor',
    value: 'contractor',
  },
  {
    label: 'Insurance Company',
    value: 'insuranceCompany',
  },
  {
    label: 'Law Firm',
    value: 'lawFilm',
  },
  {
    label: 'School/University',
    value: 'school',
  },
  {
    label: 'Realtor',
    value: 'realtor',
  },
  {
    label: 'Testing Agency',
    value: 'testingAgency',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
export const PROJECTTYPE = [
  {
    label: 'Externel',
    value: 'one',
  },
  {
    label: 'Internel',
    value: 'two',
  },
];

export const PROJECTROLE = [
  {
    label: 'Architect of Record',
    value: 'architectRecord',
  },
  {
    label: 'Engineer of Record',
    value: 'engineerRecord',
  },
  {
    label: 'Component Design',
    value: 'componentDesign',
  },
  {
    label: 'Forensic Investigator',
    value: 'forensic',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
export const DISCIPLINE = [
  {
    label: 'Architect',
    value: 'architect',
  },
  {
    label: 'Civil Engineer',
    value: 'civilEngineer',
  },
  {
    label: 'Mechanical Engineer',
    value: 'mechanicalEngineer',
  },
  {
    label: 'Electrical Engineer',
    value: 'electricalEngineer',
  },
  {
    label: 'Chemical Engineer',
    value: 'chemicalEngineer',
  },
  {
    label: 'Inspector',
    value: 'inspector',
  },
  {
    label: 'Land Surveyor',
    value: 'landSurveyor',
  },
];
export const CONCENTRATION = [
  {
    id: 'architect',
    label: 'Architect',
    items: [
      'General',
      'Commercial',
      'Residential',
      'Interior Design',
      'Green Design',
      'Landscape Architect',
      'Urban Designer',
      'Industrial Architect',
      'Other',
    ],
  },
  {
    id: 'civilEngineer',
    label: 'Civil Engineer',
    items: [
      'General',
      'Structural',
      'Geotechnical',
      'Architectural',
      'Environmental',
      'Fire Protection',
      'Transportation',
      'Water Resource',
      'Hydraulic',
      'Mining',
      'Other',
    ],
  },
  {
    id: 'mechanicalEngineer',
    label: 'Mechanical Engineer',
    items: [
      'General',
      'HVAC',
      'Plumbing',
      'Aerospace',
      'Automotive',
      'Acoustic',
      'Thermal',
      'Nuclear',
      'Other',
    ],
  },
  {
    id: 'electricalEngineer',
    label: 'Electrical Engineer',
    items: [
      'General',
      'Computer & Software',
      'Electronics',
      'Telecommunications',
      'Power',
      'Other',
    ],
  },
  {
    id: 'chemicalEngineer',
    label: 'Chemical Engineer',
    items: [
      'General',
      'Packaging',
      'Ceramic',
      'Drilling',
      'Materials',
      'Petroleum',
      'Plastics',
      'Reservoir',
      'Other',
    ],
  },
  {
    id: 'inspector',
    label: 'Inspector',
    items: ['Testing laboratory', 'Home inspector', 'Other'],
  },
  {
    id: 'landSurveyor',
    label: 'Land Surveyor',
    items: [],
  },
];
export const LIABILITY = [
  {
    label: 'AXA XL',
    value: 'AXA XL',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
export const WORKNATURE = [
  {
    label: 'Design',
    value: 1,
  },
  {
    label: 'Independent Review',
    value: 2,
  },
  {
    label: 'Special Inspection',
    value: 3,
  },
  {
    label: 'Forensic Opinion',
    value: 4,
  },
  {
    label: 'Other',
    value: 5,
  },
];
export const CONSULTANTPROFESSION = [
  {
    label: 'Owner',
    value: 'one',
  },
  {
    label: 'Employee',
    value: 'two',
  },
];
export const PAGESFROM = [
  {
    label: 1,
    value: 'one',
  },
  {
    label: 2,
    value: 'two',
  },
];

export const PAGESTO = [
  {
    label: 1,
    value: 'one',
  },
  {
    label: 2,
    value: 'two',
  },
];

export const CONTRACTOWNER = [
  {
    label: "Design Professional's",
    value: 'designProfessional',
  },
  {
    label: "Client's",
    value: 'client',
  },
  {
    label: 'Combination of Design Professional & Client',
    value: 'combination',
  },
  {
    label: 'AIA',
    value: 'AIA',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const SHOP_DESIGNS_VALUES = [
  'Concrete: Mix Design – with Air',
  'Concrete: Mix Design – without Air',
  'Concrete: Cast-in-Place Reinforcing',
  'Concrete: Pre-Cast Concrete Reinforcing',
  'Cold-Formed Steel Framing: Metal Stud Framing/Details',
  'Cold-Formed Steel Framing: Design Calculations',
  'Wood Framing: Roof Truss Framing/Details',
  'Wood Framing: Floor Truss Framing/Details',
  'Wood Framing: Sealed Piece Drawings',
  'Building Envelope: Roofing',
  'Building Envelope: Sheet Metal Flashing',
  'Building Envelope: Flexible Flashing',
  'Building Envelope: Below Grade Waterproofing',
  'Building Envelope: Sealant',
  'Building Envelope: Siding',
  'Architectural: Millwork',
  'Architectural: Windows',
  'Architectural: Doors',
  'Architectural: Flooring',
  'Architectural: Finishes',
  'Architectural: Appliances',
  'Mechanical: Air Handling Units',
  'Mechanical: Furnace/Boiler',
  'Mechanical: Metal Duct Work',
  'Mechanical: Flexible Duct Work',
  'Mechanical: Elevator',
  'Mechanical: General HVAC',
  'Electrical: Electrical Layouts',
  'Electrical: Electrical Load Calculations',
  'Plumbing: Piping',
  'Plumbing: Fire Protection',
  'Plumbing: Plumbing Layouts',
  'Other (add your own)',
];

export const SHOPDESIGNS = [
  {
    label: 'Structural Steel',
    value: 'Structural Steel',
  },
  {
    label: 'Pre-Engineered Structure Design',
    value: 'Pre-Engineered Structure Design',
  },
  {
    label: 'Truss Design',
    value: 'Truss Design',
  },
  {
    label: 'Foundation Design',
    value: 'Foundation Design',
  },
  {
    label: 'Cold Form Metal',
    value: 'Cold Form Metal',
  },
  {
    label: 'Elevator',
    value: 'Elevator',
  },
  {
    label: 'Air Handling Units',
    value: 'Air Handling Units',
  },
  {
    label: 'Millwork',
    value: 'Millwork',
  },
  {
    label: 'Windows',
    value: 'Windows',
  },
  {
    label: 'Appliances',
    value: 'Appliances',
  },
  {
    label: 'Cabinets',
    value: 'Cabinets',
  },
  {
    label: 'Metal Ductwork',
    value: 'Metal Ductwork',
  },
  {
    label: 'Piping',
    value: 'Piping',
  },
  {
    label: 'Plumbing',
    value: 'Plumbing',
  },
  {
    label: 'Fire Protection',
    value: 'Fire Protection',
  },
  {
    label: 'Electrical',
    value: 'Electrical',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const NOTIFICATION_PROPS = {
  insert: 'top',
  container: 'top-center',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 4000,
    onScreen: true,
  },
};
export const NOTIFICATION_PROPS_LONGER = {
  insert: 'top',
  container: 'top-center',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 10000,
    onScreen: true,
  },
};
export const GOOGLE_PICKER_CLIENT_ID =
  '164796006658-aeh9l6ufprtnsn6pakcuffkpjeunv6nd.apps.googleusercontent.com';
export const GOOGLE_PICKER_SCOPE = [
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/drive.metadata.readonly',
  'https://www.googleapis.com/auth/drive.readonly',
  'https://www.googleapis.com/auth/drive',
];
export const GOOGLE_API_KEY = 'AIzaSyDr4FA-FGx80nAzVmPUCxqBoDujQG8-8Zg';
export const PLACE_AUTOCOMPLETE = 'https://dev.sealpact.com/place_autocomplete';

// Stripe Test Mode
// export const STRIPE_API_KEY =
//   'rk_test_51GzvafKqtD9e5nfkLmnnSRxJnllQaV6m9Kuo9llETskajuiA7sVK5wFW1hLMv4KYXZpUD26vZizuz4r7f3xAvVaW00QOq9SueX';
// export const STRIPE_PUBLISHED_KEY =
//   'pk_test_51GzvafKqtD9e5nfk1jY5G67rTn0M5QinpXJb6kNFZ6WLygpgE3Fb3bWI70hsMLffHa0ME0Qjn0HNOUGp1PZfxqCd00kdf9K8ir';

const CLOUD_STAGING_LOCAL = 'http://localhost:5001/sealpact-d669e/us-central1';
// const CLOUD_STAGING = 'http://localhost:5001/sealpact-d669e/us-central1'
const CLOUD_STAGING = 'https://us-central1-sealpact-d669e.cloudfunctions.net';
const CLOUD_PRODUCTION =
  'https://us-central1-sealpact-production.cloudfunctions.net';
const CLOUD_PRODUCTION_LOCAL =
  'http://localhost:5000/sealpact-production/us-central1';

// Stripe Live Mode
export const STRIPE_API_KEY =
  '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';
export const STRIPE_PUBLISHED_KEY =
  'pk_live_51GzvafKqtD9e5nfkCvKXLqIbLnGA1cvfTqzGd2pmY30pcaSC9YBO1NzGR7emr3aU8Y9hHcH80blMMXEIZs0hqfZN00XQfF14vL';

//staging instance constants
// export const AUTH_DOMAIN = 'sealpact-d669e.firebaseapp.com';
// export const GOOGLE_PICKER_KEY = 'AIzaSyAHhBlbXX0BcAPLPXcn0DilDp5UgLyiPaM';
// export const APP_URL = 'https://sealpact-d669e.firebaseapp.com/';
// export const SERVICE_BOT_SECRECT_KEY = 'wLds9XRB6g6Gag2dwLYHWc8ZunOFi0';
// export const SEND_EMAIL_API_URL =
//   'https://us-central1-sealpact-d669e.cloudfunctions.net/sendEmail';
// export const SEND_EMAIL_API_URL_NON_SUBSCRIBERS =
//   'https://us-central1-sealpact-d669e.cloudfunctions.net/sendEmailNonSubscribers';
// export const UNSUBSCRIBE_EMAIL_API_URL =
//   'https://us-central1-sealpact-d669e.cloudfunctions.net/unsubscribe';
// export const SEND_FLORIDA_SEAL_INFO_EMAIL =
//   // 'https://dev.sealpact.com/sendFloridaSealInfoEmail';
//   'https://us-central1-sealpact-d669e.cloudfunctions.net/sendFloridaSealInfoEmail';
// export const SEND_LAST_SUBSCRIPTION_MONTH_REMINDER_EMAIL =
//   'https://us-central1-sealpact-d669e.cloudfunctions.net/sendLastSubscriptionMonthReminderEmail';
// export const SEND_LINK_INVITATIONS =
//   'https://us-central1-sealpact-d669e.cloudfunctions.net/sendLinkInvitations';
// export const CREATE_CUSTOMER_PORTAL_LINK = `${CLOUD_STAGING}/create_create_customer_portal_link`;
// // export const DRAW_CLICKABLE = 'https://dev.sealpact.com/draw_clickable';
// export const CONVERT = 'https://dev.sealpact.com/convert';
// export const DRAW_CLICKABLE = 'https://dev.sealpact.com/draw_clickable';
// export const GET_STRIPE_PRODUCTS = `${CLOUD_STAGING}/get_products`;
// export const CREATE_STRIPE_SUBSCRIPTION = `${CLOUD_STAGING}/createSubscription`;
// export const RETRY_STRIPE_PAYMENT = `${CLOUD_STAGING}/retryPayment`;
// export const CHANGE_PHONE_NUMBER = `${CLOUD_STAGING}/changePhoneNumber`;
// export const GET_SUBSCRIPTION = `${CLOUD_STAGING}/get_subscription`;
// export const GET_CUSTOMER_INVOICES = `${CLOUD_STAGING}/get_customer_invoices`;
// export const ATTACH_PAYMENT_METHOD = `${CLOUD_STAGING}/attach_payment_method`;
// export const CARD_SETUP = `${CLOUD_STAGING}/card_setup`;
// export const UPDATE_BILLING_INFORMATION = `${CLOUD_STAGING}/update_billing_information`;
// export const GET_UPCOMING_INVOICE = `${CLOUD_STAGING}/upcoming_invoice`;
// export const GET_CUSTOMER = `${CLOUD_STAGING}/get_customer`;
// export const GET_INVOICE = `${CLOUD_STAGING}/get_invoice`;
// export const PREVIEW_PRORATION = `${CLOUD_STAGING}/previewProration`;
// export const UPDATE_SUBSCRIPTION = `${CLOUD_STAGING}/updateSubscription`;
// export const LIST_PAYMENT_METHODS = `${CLOUD_STAGING}/list_payment_methods`;
// export const DETACH_PAYMENT_METHOD = `${CLOUD_STAGING}/detach_payment_method`;
// export const CHANGE_DEFAULT_PAYMENT_METHOD = `${CLOUD_STAGING}/change_default_payment_method`;
// export const APPLY_COUPON_CODE = `${CLOUD_STAGING}/applyCouponCode`;
// export const GET_CUSTOMER_CODES = `${CLOUD_STAGING}/get_customer_codes`;
// // export const CLOUD_LINK = 'http://localhost:3000'
// export const CLOUD_LINK = 'https://sealpact-d669e.web.app';
// export const CREATE_CUSTOMER_CODE = `${CLOUD_STAGING}/create_customer_code`;
// export const UPDATE_INVITATION = `${CLOUD_STAGING}/updateInvitation`;
// export const REDEEM_PROMOTION_CODE = `${CLOUD_STAGING}/redeemPromotionCode`;
// export const REDEEM_COMBINED_CODES = `${CLOUD_STAGING}/redeemCombinedCodes`;

//live instance constants
export const AUTH_DOMAIN = 'app.sealpact.com';
export const GOOGLE_PICKER_KEY = 'AIzaSyBXZ1r1PCh_9R5t-p9fyPZUuX8VccsnM-s';
export const APP_URL = 'https://app.sealpact.com/';
export const SERVICE_BOT_SECRECT_KEY = 'wLds9XRB6g6Gag2dwLYHWc8ZunOFi0';
export const SEND_EMAIL_API_URL =
  'https://us-central1-sealpact-production.cloudfunctions.net/sendEmail';
export const SEND_EMAIL_API_URL_NON_SUBSCRIBERS =
  'https://us-central1-sealpact-production.cloudfunctions.net/sendEmailNonSubscribers';
export const UNSUBSCRIBE_EMAIL_API_URL =
  'https://us-central1-sealpact-production.cloudfunctions.net/unsubscribe';
export const SEND_FLORIDA_SEAL_INFO_EMAIL =
  'https://us-central1-sealpact-production.cloudfunctions.net/sendFloridaSealInfoEmail';
export const SEND_LAST_SUBSCRIPTION_MONTH_REMINDER_EMAIL =
  'https://us-central1-sealpact-production.cloudfunctions.net/sendLastSubscriptionMonthReminderEmail';
export const SEND_LINK_INVITATIONS =
  'https://us-central1-sealpact-production.cloudfunctions.net/sendLinkInvitations';
export const CREATE_CUSTOMER_PORTAL_LINK =
  'https://us-central1-sealpact-production.cloudfunctions.net/create_create_customer_portal_link';
export const CONVERT = 'https://dev.sealpact.com/convert';
export const DRAW_CLICKABLE = 'https://dev.sealpact.com/draw_clickable';
//  'https://us-central1-sealpact-production.cloudfunctions.net/draw_clickable';
// https://api.sealpact.com/
// https://us-central1-sealpact-production.cloudfunctions.net/draw_clickable
export const GET_STRIPE_PRODUCTS =
  'https://us-central1-sealpact-production.cloudfunctions.net/get_products';
export const CREATE_STRIPE_SUBSCRIPTION =
  'https://us-central1-sealpact-production.cloudfunctions.net/createSubscription';
export const RETRY_STRIPE_PAYMENT = `https://us-central1-sealpact-production.cloudfunctions.net/retryPayment`;
export const CHANGE_PHONE_NUMBER =
  'https://us-central1-sealpact-production.cloudfunctions.net/changePhoneNumber';
export const GET_SUBSCRIPTION =
  'https://us-central1-sealpact-production.cloudfunctions.net/get_subscription';
export const GET_CUSTOMER_INVOICES =
  'https://us-central1-sealpact-production.cloudfunctions.net/get_customer_invoices';
export const ATTACH_PAYMENT_METHOD =
  'https://us-central1-sealpact-production.cloudfunctions.net/attach_payement_method';
export const CARD_SETUP =
  'https://us-central1-sealpact-production.cloudfunctions.net/card_setup';
export const UPDATE_BILLING_INFORMATION =
  'https://us-central1-sealpact-production.cloudfunctions.net/update_billing_information';
export const GET_UPCOMING_INVOICE =
  'https://us-central1-sealpact-production.cloudfunctions.net/upcoming_invoice';
export const GET_CUSTOMER =
  'https://us-central1-sealpact-production.cloudfunctions.net/get_customer';
export const GET_INVOICE =
  'https://us-central1-sealpact-production.cloudfunctions.net/get_invoice';
export const PREVIEW_PRORATION =
  'https://us-central1-sealpact-production.cloudfunctions.net/previewProration';
export const UPDATE_SUBSCRIPTION =
  'https://us-central1-sealpact-production.cloudfunctions.net/updateSubscription';
export const LIST_PAYMENT_METHODS =
  'https://us-central1-sealpact-production.cloudfunctions.net/list_payment_methods';
export const DETACH_PAYMENT_METHOD =
  'https://us-central1-sealpact-production.cloudfunctions.net/detach_payment_method';
export const CHANGE_DEFAULT_PAYMENT_METHOD =
  'https://us-central1-sealpact-production.cloudfunctions.net/change_default_payment_method';
export const APPLY_COUPON_CODE =
  'https://us-central1-sealpact-production.cloudfunctions.net/applyCouponCode';
export const GET_CUSTOMER_CODES =
  'https://us-central1-sealpact-production.cloudfunctions.net/get_customer_codes';
export const CLOUD_LINK =
  'https://us-central1-sealpact-production.cloudfunctions.net';
export const CREATE_CUSTOMER_CODE =
  'https://us-central1-sealpact-production.cloudfunctions.net/create_customer_code';
export const UPDATE_INVITATION =
  'https://us-central1-sealpact-production.cloudfunctions.net/updateInvitation';
export const REDEEM_PROMOTION_CODE =
  'https://us-central1-sealpact-production.cloudfunctions.net/redeemPromotionCode';
export const REDEEM_COMBINED_CODES = `https://us-central1-sealpact-production.cloudfunctions.net/redeemCombinedCodes`;
