import React, { Fragment } from 'react';
import DynamicModal from '../../DynamicModal';

const FaqModel = ({ showFaqModal, toggleFaqModal }) => {
  console.log('showFaqModal ====> ', showFaqModal);
  return (
    <DynamicModal
      size="lg"
      title="FAQs"
      show={showFaqModal}
      hideModal={toggleFaqModal}
    >
      <Fragment>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <strong>Q: How does SealPact work?</strong>
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          A: PKI Technology, utilizing state of the art blockchain technology
          creates an immutable artifact after &ldquo;SealPacting&rdquo; (signing
          or sealing) a document.
        </p>
        <br />
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <strong>Q: How can documents be invalidated?</strong>
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          A: Documents can be invalided in one of two ways
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <ol>
            <li>
              Manually invalidated - This is when a design professional chooses to invalidate every copy of a signed/sealed document for any reason of their choosing (i.e. revisions to the deliverable, lack of payment from client, etc.).
            </li>
            <br/>
            <li>
              Automatic invalidation - This is when a specific copy of a signed/sealed document has been modified or tampered with. That file will show as Verification Failed, while other copies will remain valid. Note that even the most insignificant alteration to a file (i.e. rotating pages after SealPacting) can cause this type of invalidation.
            </li>
          </ol>
        </p>
        <br />
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <strong>
            Q: What makes SealPact different from other digital signature
            platforms?
          </strong>
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          A: SealPact was created by design professionals for design
          professionals. We are actively trying to make it Simple Secure and
          Transparent for you.
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <u>Simple:</u> tailored to architect&rsquo;s and engineers&rsquo;
          needs
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <u>Secure:</u> by utilizing QR codes, the signature is verifiable no
          matter where the document ends up &ndash; even if it is downloaded
          outside of the SealPact software universe &ndash; or better yet, even
          if it is printed out of the digital universe altogether. As we all
          know, as cool as digital signatures are, many people (for good reason)
          still like hard copies of drawings and other construction
          deliverables. Our QR Codes helps our users provide signature security
          throughout the lifecycle of the document.
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <u>Transparent:</u> When sealing a document, we give design
          professionals an opportunity to voluntarily add scope specific
          information (i.e. exclusions) to help provide clarity to parties
          scanning the digital signature.
        </p>
        <br />
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <strong>Q: Can I operate SealPact in any browser?</strong>
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          A: Currently, you can use any browser for most SealPact functions,
          including uploading and reviewing documents. However, creating
          seals/signatures/stamps and SealPacting work best in Chrome and Edge.
        </p>
        <br />
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <strong>
            Q: How do I share documents with my colleagues working on my team as
            part of the same project?
          </strong>
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          A: Glad you asked! We are currently developing an
          &ldquo;Organization&rdquo; feature which will allow this type of
          collaboration, among other features. Stay tuned!
        </p>
        <br />
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          <strong>
            Q: After SealPacting, my seal is not visible on the document and/or there appear to be other visible glitches with my file. Is it something I did?
          </strong>
        </p>
        <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
          A: Maybe. Currently, PDF’s need to be flattened before uploading to SealPact. If you use Revit, this may require “Print to Adobe PDF” when you PDF your file. If you use AutoCAD and see this issue, then try flattening another way (free PDF flatteners exists online as well as on desktop applications). Reach out to us at <a href="mailto: support@sealpact.com">support@sealpact.com</a> if the issue persists so that we can help you resolve it ASAP.
        </p>
        <br />
      </Fragment>
    </DynamicModal>
  );
};

export default FaqModel;
