import React from 'react';
import { Modal } from 'react-bootstrap';

const DynamicModal = ({ show, title, children, hideModal, size }) => {
  return (
    <Modal
      show={show}
      onHide={hideModal}
      animation
      centered
      size={size || 'xl'}
      backdrop
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal" className="font-weight-bold">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default DynamicModal;
