import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import s from '../Signup/style.scss';

const OTPVerification = ({ setVerificationCode, verifySMScode }) => {

  const [fields, setFields] = useState({
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
  });

  // Create refs for each input field
  const field1Ref = useRef();
  const field2Ref = useRef();
  const field3Ref = useRef();
  const field4Ref = useRef();
  const field5Ref = useRef();
  const field6Ref = useRef();

  const fieldRefs = [field1Ref, field2Ref, field3Ref, field4Ref, field5Ref, field6Ref];

  const handleInputChange = (event, fieldName) => {
    const { value } = event.target;
    if (/^\d{1}$/.test(value) || value === '') {
      setFields((prevFields) => ({ ...prevFields, [fieldName]: value }));

      // Determine which field is currently focused and move to the next field
      if (fieldName === 'field1' && value.length === 1) {
        field2Ref.current.focus();
      } else if (fieldName === 'field2' && value.length === 1) {
        field3Ref.current.focus();
      } else if (fieldName === 'field3' && value.length === 1) {
        field4Ref.current.focus();
      } else if (fieldName === 'field4' && value.length === 1) {
        field5Ref.current.focus();
      } else if (fieldName === 'field5' && value.length === 1) {
        field6Ref.current.focus();
      }

      if (fieldName === 'field6' && value.length === 1) {
        verifySMScode(fields.field1 + fields.field2 + fields.field3 + fields.field4 + fields.field5 + value)
        setVerificationCode(fields.field1 + fields.field2 + fields.field3 + fields.field4 + fields.field5 + value)
      }

    }

  };

  useEffect(() => {
    // Add an event listener to capture the backspace key press
    const handleBackspace = (event) => {
      if (event.key === 'Backspace') {
        if (event.target.value === '') {
          const currentFieldIndex = fieldRefs.findIndex((ref) => ref.current === event.target);
          if (currentFieldIndex > 0) {
            fieldRefs[currentFieldIndex - 1].current.focus();
          }

        }
        else {
          setVerificationCode('')
        }
      }
    };

    fieldRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.addEventListener('keydown', handleBackspace);
      }
    });

    // Cleanup event listeners on component unmount
    return () => {
      fieldRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.removeEventListener('keydown', handleBackspace);
        }
      });
    };
  }, []);


  return (
    <div className={s.wrapper}>
      <div className={s.otpDiv}>
        <Row className={'d-flex justify-content-center align-items-center mt-3 '}>
          <Col>
            <div className={'d-flex flex-direction-row justify-content-center align-items-center ' + s.optFields}>
              <Form.Group>
                <Form.Control
                  type="text"
                  maxLength={1}
                  value={fields.field1}
                  onChange={(e) => handleInputChange(e, 'field1')}
                  ref={field1Ref}
                  autoFocus={true}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  maxLength={1}
                  value={fields.field2}
                  onChange={(e) => handleInputChange(e, 'field2')}
                  ref={field2Ref}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  maxLength={1}
                  value={fields.field3}
                  onChange={(e) => handleInputChange(e, 'field3')}
                  ref={field3Ref}
                />
              </Form.Group>
              <Form.Group controlId="otherConcentration">
                <Form.Control
                  type="text"
                  maxLength={1}
                  value={fields.field4}
                  onChange={(e) => handleInputChange(e, 'field4')}
                  ref={field4Ref}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  maxLength={1}
                  value={fields.field5}
                  onChange={(e) => handleInputChange(e, 'field5')}
                  ref={field5Ref}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  maxLength={1}
                  value={fields.field6}
                  onChange={(e) => handleInputChange(e, 'field6')}
                  ref={field6Ref}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OTPVerification;
