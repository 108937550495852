/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react';

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <p className="margin-bottom: 0in;line-height: 100%;text-align: left;">
        <strong>Privacy Policy</strong>
      </p>
      <p className="margin-bottom: 0in;line-height: 100%;text-align: left;">
        <br />
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Privacy Policy</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        This privacy policy (&quot;Policy&quot;) describes how SealPact, LLC
        (&quot;SealPact, LLC&quot;, &quot;we&quot;, &quot;us&quot; or
        &quot;our&quot;) collects, protects and uses the personally identifiable
        information (&quot;Personal Information&quot;) you (&quot;User&quot;,
        &quot;you&quot; or &quot;your&quot;) may provide on the{' '}
        <a href="http://www.sealpact.com/">
          <u>sealpact.com</u>
        </a>{' '}
        website and any of its products or services (collectively,
        &quot;Website&quot; or &quot;Services&quot;). It also describes the
        choices available to you regarding our use of your Personal Information
        and how you can access and update this information. This Policy does not
        apply to the practices of companies that we do not own or control, or to
        individuals that we do not employ or manage.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        The Term &ldquo;<em>Personal Information</em>&rdquo; does not include
        any information that can be reasonably found in a public forum, nor does
        it include information provided by the User about tasks or projects when
        using software provided by SealPact, LLC. The user understands that
        SealPact, LLC can use this information per the Terms and Conditions of
        this website/software.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Automatic Collection of Information</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        When you visit the Website our servers automatically record information
        that your browser sends. This data may include information such as your
        device&apos;s IP address, browser type and version, operating system
        type and version, language preferences or the webpage you were visiting
        before you came to our Website, pages of our Website that you visit, the
        time spent on those pages, information you search for on our Website,
        access times and dates, and other statistics.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Collection of Personal Information</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You can visit the Website without telling us who you are or revealing
        any information by which someone could identify you as a specific,
        identifiable individual. If, however, you wish to use some of the
        Website&apos;s features, you will be asked to provide certain Personal
        Information (for example, your name and e-mail address). We receive and
        store any information you knowingly provide to us when you create an
        account, make a purchase, or fill any online forms on the Website. When
        required, this information may include your email address, name, phone
        number, address, credit card information, or other Personal Information.
        You can choose not to provide us with your Personal Information, but
        then you may not be able to take advantage of some of the Website&apos;s
        features. Users who are uncertain about what information is mandatory
        are welcome to contact us.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Managing Personal Information</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You are able to access, add to, update and delete certain Personal
        Information about you. The information you can view, update, and delete
        may change as the Website or Services change. When you update
        information, however, we may maintain a copy of the unrevised
        information in our records. Some information may remain in our private
        records after your deletion of such information from your account. We
        will retain and use your Personal Information for the period necessary
        to comply with our legal obligations, resolve disputes, and enforce our
        agreements unless a longer retention period is required or permitted by
        law. We may use any aggregated data derived from or incorporating your
        Personal Information after you update or delete it, but not in a manner
        that would identify you personally. Once the retention period expires,
        Personal Information shall be deleted. Therefore, the right to access,
        the right to erasure, the right to rectification and the right to data
        portability cannot be enforced after the expiration of the retention
        period.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Use and Processing of Collected Information</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Any of the information we collect from you may be used to personalize
        your experience; improve our Website; improve customer service and
        respond to queries and emails of our customers; process transactions;
        send newsletters; send notification emails such as password reminders,
        updates, etc; run and operate our Website and Services. Information
        collected automatically is used only to identify potential cases of
        abuse and establish statistical information regarding Website usage.
        This statistical information is not otherwise aggregated in such a way
        that would identify any particular user of the system.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We may process Personal Information related to you if one of the
        following applies: (i) You have given your consent for one or more
        specific purposes. Note that under some legislations we may be allowed
        to process information until you object to such processing (by opting
        out), without having to rely on consent or any other of the following
        legal bases below. This, however, does not apply, whenever the
        processing of Personal Information is subject to European data
        protection law; (ii) Provision of information is necessary for the
        performance of an agreement with you and/or for any pre-contractual
        obligations thereof; (iii) Processing is necessary for compliance with a
        legal obligation to which you are subject; (iv) Processing is related to
        a task that is carried out in the public interest or in the exercise of
        official authority vested in us; (v) Processing is necessary for the
        purposes of the legitimate interests pursued by us or by a third party.
        In any case, we will be happy to clarify the specific legal basis that
        applies to the processing, and in particular whether the provision of
        Personal Information is a statutory or contractual requirement, or a
        requirement necessary to enter into a contract.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Information Transfer and Storage</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Depending on your location, data transfers may involve transferring and
        storing your information in a country other than your own. You are
        entitled to learn about the legal basis of information transfers to a
        country outside the European Union or to any international organization
        governed by public international law or set up by two or more countries,
        such as the UN, and about the security measures taken by us to safeguard
        your information. If any such transfer takes place, you can find out
        more by checking the relevant sections of this document or inquire with
        us using the information provided in the contact section.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>The Rights of Users</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You may exercise certain rights regarding your information processed by
        us. In particular, you have the right to do the following: (i) you have
        the right to withdraw consent where you have previously given your
        consent to the processing of your information; (ii) you have the right
        to object to the processing of your information if the processing is
        carried out on a legal basis other than consent; (iii) you have the
        right to learn if information is being processed by us, obtain
        disclosure regarding certain aspects of the processing and obtain a copy
        of the information undergoing processing; (iv) you have the right to
        verify the accuracy of your information and ask for it to be updated or
        corrected; (v) you have the right, under certain circumstances, to
        restrict the processing of your information, in which case, we will not
        process your information for any purpose other than storing it; (vi) you
        have the right, under certain circumstances, to obtain the erasure of
        your Personal Information from us; (vii) you have the right to receive
        your information in a structured, commonly used and machine readable
        format and, if technically feasible, to have it transmitted to another
        controller without any hindrance. This provision is applicable provided
        that your information is processed by automated means and that the
        processing is based on your consent, on a contract which you are part of
        or on pre-contractual obligations thereof.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>The Right to Object to Processing</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Where Personal Information is processed for the public interest, in the
        exercise of an official authority vested in us or for the purposes of
        the legitimate interests pursued by us, you may object to such
        processing by providing a ground related to your particular situation to
        justify the objection. You must know that, however, should your Personal
        Information be processed for direct marketing purposes, you can object
        to that processing at any time without providing any justification. To
        learn, whether we are processing Personal Information for direct
        marketing purposes, you may refer to the relevant sections of this
        document.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>How to Exercise These Rights</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Any requests to exercise User rights can be directed to the Owner
        through the contact details provided in this document. These requests
        can be exercised free of charge and will be addressed by the Owner as
        early as possible.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>California Privacy Rights</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        In addition to the rights as explained in this Privacy Policy,
        California residents who provide Personal Information (as defined in the
        statute) to obtain products or services for personal, family, or
        household use are entitled to request and obtain from us, once a
        calendar year, information about the Personal Information we shared, if
        any, with other businesses for marketing uses. If applicable, this
        information would include the categories of Personal Information and the
        names and addresses of those businesses with which we shared such
        personal information for the immediately prior calendar year (e.g.,
        requests made in the current year will receive information about the
        prior year). To obtain this information please contact us.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Billing and Payments</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We use third-party payment processors to assist us in processing your
        payment information securely. Such third-party processors&apos; use of
        your Personal Information is governed by their respective privacy
        policies which may or may not contain privacy protections as protective
        as this Privacy Policy. We suggest that you review their respective
        privacy policies.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Privacy of Children</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We do not knowingly collect any Personal Information from children under
        the age of 13. If you are under the age of 13, please do not submit any
        Personal Information through our Website or Service. We encourage
        parents and legal guardians to monitor their children&apos;s Internet
        usage and to help enforce this Policy by instructing their children
        never to provide Personal Information through our Website or Service
        without their permission. If you have reason to believe that a child
        under the age of 13 has provided Personal Information to us through our
        Website or Service, please contact us. You must also be at least 16
        years of age to consent to the processing of your Personal Information
        in your country (in some countries we may allow your parent or guardian
        to do so on your behalf).
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Newsletters</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We offer electronic newsletters to which you may voluntarily subscribe
        at any time. You may choose to stop receiving our newsletter or
        marketing emails by following the unsubscribe instructions included in
        these emails or by contacting us. However, you will continue to receive
        essential transactional emails.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Cookies</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        The Website uses &quot;cookies&quot; to help personalize your online
        experience. A cookie is a text file that is placed on your hard disk by
        a web page server. Cookies cannot be used to run programs or deliver
        viruses to your computer. Cookies are uniquely assigned to you, and can
        only be read by a web server in the domain that issued the cookie to
        you. We may use cookies to collect, store, and track information for
        statistical purposes to operate our Website and Services. You have the
        ability to accept or decline cookies. Most web browsers automatically
        accept cookies, but you can usually modify your browser setting to
        decline cookies if you prefer. If you choose to decline cookies, you may
        not be able to fully experience the features of the Website and
        Services. To learn more about cookies and how to manage them, visit{' '}
        <a href="https://www.internetcookies.org/">
          <u>internetcookies.org</u>
        </a>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        In addition to using cookies and related technologies as described
        above, we also may permit certain third-party companies to help us
        tailor advertising that we think may be of interest to users and to
        collect and use other data about user activities on the Website. These
        companies may deliver ads that might also place cookies and otherwise
        track user behavior.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Do Not Track Signals</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Some browsers incorporate a Do Not Track feature that signals to
        websites you visit that you do not want to have your online activity
        tracked. Tracking is not the same as using or collecting information in
        connection with a website. For these purposes, tracking refers to
        collecting personally identifiable information from consumers who use or
        visit a website or online service as they move across different websites
        over time. Our Website does not track its visitors over time and across
        third party websites. However, some third party sites may keep track of
        your browsing activities when they serve you content, which enables them
        to tailor what they present to you.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Affiliates</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We may disclose information about you to our affiliates for the purpose
        of being able to offer you related or additional products and services.
        Any information relating to you that we provide to our affiliates will
        be treated by those affiliates in accordance with the terms of this
        Privacy Policy.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        This Privacy Policy only addresses the use and disclosure of information
        we collect from you. If you disclose your information to other parties
        using the Website, or visit other websites linked through the Website,
        different rules may apply to their use or disclosure of the information
        you disclose to them. Since SealPact, LLC does not control the privacy
        policies of third parties, or other individual&rsquo;s actions, you are
        subject to the privacy policies of that third party or those
        individuals. We encourage you to be sure the recipients are
        authenticated to your satisfaction before you send them any documents or
        sensitive information.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        In order to process your credit card orders, we use a third party
        provider to validate and verify your account information. All billing
        and account information is transmitted via https, which is a secure
        encrypted protocol system. You acknowledge that the secure affiliate,
        providing the https server is the conduit through which your payments
        are made, and agree to hold SealPact, LLC harmless of any payment
        malfunctions that may occur though this third party provider.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Links to other websites</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Our Website contains links to other websites that are not owned or
        controlled by us. Please be aware that we are not responsible for the
        privacy practices of such other websites or third-parties. We encourage
        you to be aware when you leave our Website and to read the privacy
        statements of each and every website that may collect Personal
        Information.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Information security</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We secure information you provide on computer servers in a controlled,
        secure environment, protected from unauthorized access, use, or
        disclosure. We maintain reasonable administrative, technical, and
        physical safeguards in an effort to protect against unauthorized access,
        use, modification, and disclosure of Personal Information in its control
        and custody. However, no data transmission over the Internet or wireless
        network can be guaranteed. Therefore, while we strive to protect your
        Personal Information, you acknowledge that (i) there are security and
        privacy limitations of the Internet which are beyond our control; (ii)
        the security, integrity, and privacy of any and all information and data
        exchanged between you and our Website cannot be guaranteed; and (iii)
        any such information and data may be viewed or tampered with in transit
        by a third-party, despite best efforts.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Data Breach</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        In the event we become aware that the security of the Website has been
        compromised or users Personal Information has been disclosed to
        unrelated third parties as a result of external activity, including, but
        not limited to, security attacks or fraud, we reserve the right to take
        reasonably appropriate measures, including, but not limited to,
        investigation and reporting, as well as notification to and cooperation
        with law enforcement authorities. In the event of a data breach, we will
        make reasonable efforts to notify affected individuals if we believe
        that there is a reasonable risk of harm to the user as a result of the
        breach or if notice is otherwise required by law. When we do, we will
        send you an email.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Legal Disclosure</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We will disclose any information we collect, use or receive if required
        or permitted by law, such as to comply with a subpoena, or similar legal
        process, and when we believe in good faith that disclosure is necessary
        to protect our rights, protect your safety or the safety of others,
        investigate fraud, or respond to a government request. In the event we
        go through a business transition, such as a merger or acquisition by
        another company, or sale of all or a portion of its assets, your user
        account, and Personal Information will likely be among the assets
        transferred.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Email Communications</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Emails from Us: SealPact, LLC may occasionally send notification emails
        about changes to documents you&apos;ve created or others have shared
        with you. Depending on your email settings we may also send important
        policy changes, offer customer support or send marketing emails.
        There&apos;s an unsubscribe link located at the bottom of each of the
        emails we send you.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Emails from Other Users: We allow our users to send limited outbound
        emails for purposes such as sharing and signing documents. When a user
        sends an outbound email, it may delivered with the sender&apos;s email
        address and other Personal Information such as their name or SealPact,
        LLC user name. Any accounts that abuse this service may be suspended
        immediately and indefinitely. If you receive an email from a user of
        SealPact, LLC&rsquo;s products or services which violates our terms of
        use, please notify our support team.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Changes and Amendments</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We may update this Privacy Policy from time to time in our discretion
        and will notify you of any material changes to the way in which we treat
        Personal Information. When changes are made, we will revise the updated
        date at the bottom of this page. We may also provide notice to you in
        other ways in our discretion, such as through contact information you
        have provided. Any updated version of this Privacy Policy will be
        effective immediately upon the posting of the revised Privacy Policy
        unless otherwise specified. Your continued use of the Website or
        Services after the effective date of the revised Privacy Policy (or such
        other act specified at that time) will constitute your consent to those
        changes. However, we will not, without your consent, use your Personal
        Data in a manner materially different than what was stated at the time
        your Personal Data was collected.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Acceptance of This Policy</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You acknowledge that you have read this Policy and agree to all its
        terms and conditions. By using the Website or its Services you agree to
        be bound by this Policy. If you do not agree to abide by the terms of
        this Policy, you are not authorized to use or access the Website and its
        Services.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Contacting Us</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        If you would like to contact us to understand more about this Policy or
        wish to contact us concerning any matter relating to individual rights
        and your Personal Information, you may send an email to
        support@sealpact.com
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        This document was last updated on February 9, 2021
      </p>{' '}
    </Fragment>
  );
};

export default PrivacyPolicy;
