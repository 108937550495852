import React, { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import s from './style.scss';
import SignatureForm from './SignatureForm';
import DynamicModal from '../../../DynamicModal';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import { view } from 'react-easy-state';

const CreateNewSignature = ({ user, firebase, getSealsAndSigns, setRefreshSeals, }) => {
  const [showSignatureModal, setShowModal] = useState(false);
  const handleModal = () => {
    const modalShown = showSignatureModal;
    setShowModal(!showSignatureModal);
    // if (modalShown)
    //   window.location.href = 'seals';
  };
  const getSignatureData = async (dataUrl, fontType, signatureName) => {
    console.log('object', dataUrl, fontType, signatureName);
    const sign = {
      data: dataUrl,
      fontType: fontType,
      signatureName: signatureName,
      status: true,
      createdAt: new Date().getTime(),
      type: 'signature',
    };
    console.log('dataUrl ====> ', dataUrl);
    console.log('fontType ====> ', fontType);
    await firebase
      .createNewSignature(user.uid, sign)
      .then((uploadedRes) => {
        // getSealsAndSigns();
        setRefreshSeals(true)
        store.addNotification({
          title: 'Created Successfully!',
          message: 'Signature created successfully.',
          type: 'default',
          ...NOTIFICATION_PROPS,
        });
        store.addNotification({
          title: '',
          message: 'Please wait while the page is being updated.',
          type: 'info',
          ...NOTIFICATION_PROPS,
        });
        return;
      })
      .catch((error) => {
        store.addNotification({
          title: 'Error',
          message: 'Error in creating Signature',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        window.location.href = 'seals'
        return;
      });
  };
  return (
    <Fragment>
      <Button variant="text" onClick={handleModal} className={s.btnText}>
        <i className="fa fa-plus" aria-hidden="true"></i>
        Create New Signature
      </Button>
      <DynamicModal
        show={showSignatureModal}
        title="Create New Signature"
        hideModal={handleModal}
      >
        <SignatureForm
          handleModal={handleModal}
          getSignatureData={getSignatureData}
        />
      </DynamicModal>
    </Fragment>
  );
};

export default view(CreateNewSignature);
