import React from 'react';
import ColHeading from './ColHeading';
import s from './style.scss';
import {appEasyState} from '../../../easyStateStore';

const headerStyle = { justifyContent: 'space-between', border: 0 };
const cellStyle = { border: 0, justify_content: 'center', display: 'flex' };
const tempColStyle =  { width: '100%' };

const formatDate = (date) => {
  const dateObj = new Date(date);
  var year = dateObj.getFullYear();
  var month = (1 + dateObj.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = dateObj.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
};

function handleSortValues(collectionName, columnId, index) {
  const oldValue = appEasyState[collectionName].sortConfig.sortBy[index];
  appEasyState[collectionName].sortConfig.sortBy = Array(appEasyState.allDocs.sortConfig.sortBy.length).fill(true);
  appEasyState[collectionName].sortConfig.sortBy[index] = !oldValue;
  appEasyState[collectionName].sortConfig.columnId = columnId;
  appEasyState[collectionName].sortConfig.colIndex = index;
}

async function handleHeaderClick(columnId, index){
  // alert('col')
  console.log('appEasyState.tabKey  =======> ', appEasyState.tabKey)

  if (appEasyState.tabKey === 'all') {
    appEasyState.allDocs.sortConfig.sort = true
    if (columnId === 'primarySealPactSigner' || columnId === 'dateSealPacted')
      columnId = 'dateUploaded'
    handleSortValues('allDocs', columnId, index);
  }
  else if (appEasyState.tabKey === 'SealPacted') {
    handleSortValues('pactedDocs', columnId, index);
  }
  else if (appEasyState.tabKey === 'Pending') {
    if (columnId === 'primarySealPactSigner' || columnId === 'dateSealPacted')
      columnId = 'dateUploaded'
    handleSortValues('pendingDocs', columnId, index);
  }
  else if (appEasyState.tabKey === 'Invalidated') {
    handleSortValues('invalidatedDocs', columnId, index);
  }
}

const COLUMNS = [
  {
    Header: () => (
      <div onClick={() => handleHeaderClick('sealPacterType', 0)} style={tempColStyle}>
        <ColHeading heading="Seal Name" />
      </div>
    ),
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'primarySealPactSigner' || 'dateUploaded',
    style: cellStyle,
    id: 'primarySealPactSigner',
  },
  {
    Header: () => (
      <div onClick={() => handleHeaderClick('sealPacterType', 1)} style={tempColStyle}>
        <ColHeading heading="Type" />
      </div>
      ),
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'sealPacterType',
    style: cellStyle,
    id: 'sealPacterType',
  },
  // {
  //   Header: () => <ColHeading heading="Secondary SealPact Signer" />,
  //   Cell: props => <div className={s.tblCell}> {props.value}</div>,
  //   headerStyle,
  //   accessor: 'secondarySealPactSigner',
  //   style: cellStyle,
  //   id: 'secondarySealPactSigner',
  // },
  // {
  //   Header: () => <ColHeading heading="SealPact Originator" />,
  //   Cell: props => <div className={s.tblCell}> {props.value}</div>,
  //   headerStyle,
  //   accessor: 'sealPactOriginator',
  //   style: cellStyle,
  //   id: 'sealPactOriginator',
  // },
  {
    Header: () => (
        <div onClick={() => handleHeaderClick('projectNumber', 2)} style={tempColStyle}>
          <ColHeading heading="Project Number" />
        </div>
      ),
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    headerStyle,
    accessor: 'projectNumber',
    style: cellStyle,
    id: 'projectNo',
  },
  {
    Header: () => (
        <div onClick={() => handleHeaderClick('projectAddress', 3)} style={tempColStyle}>
          <ColHeading heading="Project Address" />
        </div>
      ),
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    headerStyle,
    accessor: 'projectAddress',
    style: cellStyle,
    id: 'projectAddress',
  },
  {
    Header: () => (
        <div onClick={() => handleHeaderClick('projectName', 4)} style={tempColStyle}>
          <ColHeading heading="Project Name" />
        </div>
      ),
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    headerStyle,
    accessor: 'projectName',
    style: cellStyle,
    id: 'projectName',
  },
  {
    Header: () => (
        <div onClick={() => handleHeaderClick('dateUploaded', 5)} style={tempColStyle}>
          <ColHeading heading="Date Uploaded" showDivider={false} />
        </div>
      ),
    Cell: (props) => (
      <div className={s.tblCell}>
        {props.value ? formatDate(props.value) : null}
      </div>
    ),
    headerStyle,
    accessor: 'dateUploaded',
    style: cellStyle,
    id: 'dateUploaded',
  },
  {
    Header: () => (
        <div onClick={() => handleHeaderClick('dateSealPacted', 6)} style={tempColStyle}>
          <ColHeading heading="Date SealPacted" showDivider={false} />
        </div>
      ),
    Cell: (props) => (
      <div className={s.tblCell}>
        {props.value ? formatDate(props.value) : null}
      </div>
    ),
    headerStyle,
    accessor: 'dateSealPacted',
    style: cellStyle,
    id: 'dateSealPacted',
  },
];
export default COLUMNS;
