import { store } from 'react-easy-state'
import CryptoJS from 'crypto-js';

export let appEasyState = store({
  firebase: undefined,
  user: null,
  getEncryptedString(text, secretKey) {
    return CryptoJS.AES.encrypt(text, secretKey).toString();
  },
  customerId: null,
  customer: null,
  referrerUid: null,
  referrerCustomerId: null,
  paymentMethodId: null,
  removingPaymentMethodId: null,
  subscriptionId: null,
  codes: [],
  sumAmountOff: 0,
  userObject: undefined,
  documentInformation: null,
  confirmationValues: null,
  projectAddressReverseGeocode: null,
  projectAddressMapsTextSearch: undefined,
  capturedAddressSealScan: null,
  circularCropShape: false,
  cropperWidthTextValue: '',
  cropperHeightTextValue: '',
  customPages: [],
  profileUpdatedSuccessfully: JSON.parse(JSON.stringify(localStorage.getItem('profileUpdatedSuccessfully'))) || 'false',
  anyProfileError: false,
  showOnboardingCompletionMessage: JSON.parse(JSON.stringify(localStorage.getItem('showOnboardingCompletionMessage'))) || 'false',
  onboardingCompleted: JSON.parse(JSON.stringify(localStorage.getItem('onboardingCompleted'))) || 'true',
  userId: JSON.parse(JSON.stringify(localStorage.getItem('userId'))) || null,
  enableVerificationWatermark: false,
  onboarding: {
    email: '',
    phoneNumber: '',
    password: '',
    verifyPassword: '',
  },
  login: {
    email: '',
    phoneNumber: '',
    password: ''
  },
  toCurrencyFormat(str) {
    const numberValue = parseFloat(str);

    const currencyString = numberValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    });

    return currencyString;
  },
  getCurrencyValue(value) {
    if (value) {
      return this.toCurrencyFormat(value);
    }
    return 'N/A';
  },
  encryptValue(value) {
    return CryptoJS.AES.encrypt(value, 'SealPact-Encryption').toString();
  },
  decryptValue(value) {
    return CryptoJS.AES.decrypt(value, 'SealPact-Encryption').toString(CryptoJS.enc.Utf8);
  },
  invalidateEmails: [{ email: '' }],
  tabKey: 'all',
  searchTerm: '',
  originalData: [],
  filteredData: [],
  showOriginal: false,
  docsCount: {
    pending: 0,
    pacted: 0,
    invalidated: 0,
    shared: 0
  },
  allDocsCount: 0,
  allDocs: {
    loading: true,
    pageIndex: 0,
    pageSize: 20,
    paginatedData: [],
    sortConfig: {
      columnId: 'dateUploaded',
      sortBy: [],
      colIndex: 5,
      sort: false,
    },
  },
  pendingDocs: {
    loading: true,
    pageIndex: 0,
    pageSize: 20,
    paginatedData: [],
    sortConfig: {
      columnId: 'dateUploaded',
      sortBy: [],
      colIndex: 5,
    },
  },
  pactedDocs: {
    loading: true,
    pageIndex: 0,
    pageSize: 20,
    paginatedData: [],
    sortConfig: {
      columnId: 'dateUploaded',
      sortBy: [],
      colIndex: 5
    },
  },
  invalidatedDocs: {
    loading: true,
    pageIndex: 0,
    pageSize: 20,
    paginatedData: [],
    sortConfig: {
      columnId: 'dateUploaded',
      sortBy: [],
      colIndex: 5
    },
  },
  sharedDocs: {
    loading: true,
    pageIndex: 0,
    pageSize: 20,
    paginatedData: [],
    sortConfig: {
      columnId: 'dateUploaded',
      sortBy: [],
      colIndex: 5
    },
  },
  tableLoading: true,
  fetchUserData(firebase, user) {
    firebase.getAllDocuments(user).then((documentsArr) => {
      console.log('documentsArr =======> ', documentsArr);
      appEasyState.originalData = documentsArr;
      appEasyState.showOriginal = true;
    }).catch(error => {
      console.log('error', error);
    });
  },
  // handleDataFetching() {
  //   const data = appEasyState.filteredData
  //   appEasyState.filteredData = []
  //   appEasyState.tableLoading = true;
  //   setTimeout(() => {
  //     appEasyState.filteredData = data
  //     appEasyState.tableLoading = false;
  //   }, 650)
  // }
})
