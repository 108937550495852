import React from 'react';
import { withFirebase } from '../firebase';
// import * as ROUTES from "../../constants/routes";
import AuthUserContext from './context';
import { Redirect } from 'react-router-dom';
const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => {
            return condition(authUser.user) ? (
              <Component
                {...this.props}
                user={authUser.user}
                reloadUser={authUser.reloadUser}
              />
            ) : (
              <>
                {!window.location.href.includes('/dashboard') && <Redirect to="login" />}
              </>
            );
          }}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithAuthorization);
};

export default withAuthorization;
