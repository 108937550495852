import React, { Fragment, useEffect } from 'react';
import Routes from '../../routes';
import withAuthentication from '../../auth/session/withAuthentication';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useIdleTimer } from 'react-idle-timer';

const App = (props) => {
  // console.log('props ==> ', props);

  const handleOnIdle = (event) => {
    props.firebase
      .doSignOut()
      .then((signoutRes) => {
        localStorage.setItem('encryptedString', 'null')
        localStorage.setItem('userId', 'null')
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleOnActive = (event) => {
    // console.log('user is active', event);
    // console.log('time remaining', getRemainingTime());
  };

  const handleOnAction = (event) => {
    // console.log('user did something', event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 4 * 60 * 60 * 1000,
    // timeout: 3 * 60 * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <Fragment>
      <ReactNotification />
      <Routes firebase={props.firebase} />
    </Fragment>
  );
};
export default withAuthentication(App);
