import React, {useEffect, useState} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHED_KEY } from '../../../utils/constants';

function Completion() {
  const [ messageBody, setMessageBody ] = useState(''),
    [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    loadStripe(STRIPE_PUBLISHED_KEY).then((stripe) => {
      console.log('loadStripe', stripe)
      setStripePromise(stripe);
    })
  }, []);

  useEffect( () => {
    if (!stripePromise) return;

    const url = new URL(window.location);
    const clientSecret = url.searchParams.get('payment_intent_client_secret');
    stripePromise.retrievePaymentIntent(clientSecret).then(paymentIntent => {
      console.log('paymentIntent', paymentIntent)
      if (paymentIntent && paymentIntent.paymentIntent) {
        setMessageBody(
          <>Payment {paymentIntent.paymentIntent.status}: <a href={`https://dashboard.stripe.com/test/payments/${paymentIntent.paymentIntent.id}`} target="_blank" rel="noreferrer">{paymentIntent.paymentIntent.id}</a></>
        );
      }
    }).catch(error => {
      setMessageBody(`> ${error.message}`);
    });
  }, [stripePromise]);

  return (
    <>
      <h1>Thank you!</h1>
      {messageBody && (
        <div id="messages" role="alert" style={messageBody ? {display: 'block'} : {}}>{messageBody}</div>
      )}
    </>
  );
}

export default Completion;