/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import s from './style.scss';
import { store } from 'react-notifications-component';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Table,
} from 'react-bootstrap';
import GiftIcon from '../../../assets/GiftSmall.svg';
import CopyIcon from '../../../assets/Copy.svg';
import FunnelIcon from '../../../assets/Funnel.svg';
import moment from 'moment';
import InstructionsModal from './InstructionsModal';
import {
  CLOUD_LINK,
  GET_CUSTOMER_CODES,
  GET_UPCOMING_INVOICE,
  NOTIFICATION_PROPS,
  NOTIFICATION_PROPS_LONGER,
  REDEEM_COMBINED_CODES,
  REDEEM_PROMOTION_CODE,
  SEND_LINK_INVITATIONS,
} from '../../../utils/constants';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import './react-multi-email.css';
import DynamicModal from '../../DynamicModal';
import withAuthorization from '../../../auth/session/withAuthorization';
import { withFirebase } from '../../../auth/firebase';
import ConfirmRedeemModal from './ConfirmRedeemModal';
import UnhideModal from './UnhideModal';

const Referrals = (props) => {
  const { user, firebase, reloadUser } = props;
  const [showInstructionsModal, setShowInstructionsModal] = useState(false),
    [link, setLink] = useState(
      `${CLOUD_LINK}/signup?uid=${appEasyState.encryptValue(
        user.uid,
      )}&customer=${appEasyState.encryptValue(appEasyState.customerId)}`,
    ),
    [invitations, setInvitations] = useState([]),
    [display, setDisplay] = useState(false),
    [showBlank, setShowBlank] = useState(true),
    [emails, setEmails] = useState([]),
    [focused, setFocused] = useState(false),
    [sendingInvitation, setSendingInvitation] = useState(false),
    [redeeming, setRedeeming] = useState(false),
    [redeemingCodeId, setRedeemingCodeId] = useState(''),
    [hidingIndex, setHidingIndex] = useState(null),
    [hidingEmail, setHidingEmail] = useState(null),
    [hiding, setHiding] = useState(false),
    [showHideConfirmation, setShowHideConfirmation] = useState(false),
    [upcomingInvoice, setUpcomingInvoice] = useState({}),
    [redeemCodes, setRedeemCodes] = useState([]),
    [planAmount, setPlanAmount] = useState(0),
    [showRedeemButton, setShowRedeemButton] = useState(false),
    [selectAllCoupons, setSelectAllCoupons] = useState(false),
    [showConfirmRedeemModal, setShowConfirmRedeemModal] = useState(false),
    [confirmRedeem, setConfirmRedeem] = useState(false),
    [showUnhideModal, setShowUnhideModal] = useState(false),
    [hiddenInvitations, setHiddenInvitations] = useState([]);

  const getUpcomingInvoice = () => {
    axios
      .post(GET_UPCOMING_INVOICE, {
        customerId: appEasyState.customerId,
      })
      .then((upcomingInvoiceResult) => {
        if (
          upcomingInvoiceResult &&
          upcomingInvoiceResult.data &&
          upcomingInvoiceResult.data.data
        ) {
          console.log(
            'upcomingInvoiceResult.data.data',
            upcomingInvoiceResult.data.data,
          );
          setUpcomingInvoice(upcomingInvoiceResult.data.data);
        }
      })
      .catch((err) => {
        console.log('GET_UPCOMING_INVOICE err', err);
        setUpcomingInvoice({});
      });
  };

  useEffect(() => {
    if (
      upcomingInvoice.discount &&
      upcomingInvoice.discount.coupon &&
      upcomingInvoice.discount.coupon.max_redemptions == 1
    ) {
      if (upcomingInvoice.discount.coupon.amount_off)
        appEasyState.sumAmountOff = upcomingInvoice.discount.coupon.amount_off;
      else if (upcomingInvoice.discount.coupon.percent_off) {
        appEasyState.sumAmountOff = parseInt(
          (
            (upcomingInvoice.discount.coupon.percent_off / 100) *
            planAmount
          ).toString(),
        );
      }
    } else appEasyState.sumAmountOff = 0;
  }, [upcomingInvoice]);

  const getCustomerCodesAndInvitations = () => {
    setDisplay(false);
    setRedeemCodes([]);
    axios
      .post(GET_CUSTOMER_CODES, { customerId: appEasyState.customerId })
      .then((result) => {
        console.log('result', result);
        let _codes;
        if (result && result.data && result.data.codes) {
          _codes = result.data.codes;
          _codes.forEach((item) => (item['isAdded'] = false));
          console.log(_codes);
          appEasyState.codes = _codes;
        }

        firebase
          .getInvitations(user.uid)
          .then((result) => {
            console.log('getInvitations result', result);
            let _invitations = result;
            console.log('_codes', _codes);
            for (let elm of _invitations) {
              if (elm.signupPromotionCode && _codes) {
                // console.log('elm.signupPromotionCode', elm.signupPromotionCode)
                elm['signupPromotionCodeStripe'] =
                  _codes.find(
                    (item) => item.code === elm.signupPromotionCode.code,
                  ) || null;
              }

              if (elm.subscriptionPromotionCode && _codes) {
                // console.log('elm.subscriptionPromotionCode', elm.subscriptionPromotionCode)
                elm['subscriptionPromotionCodeStripe'] =
                  _codes.find(
                    (item) => item.code === elm.subscriptionPromotionCode.code,
                  ) || null;
              }
            }
            setInvitations(_invitations);
            setDisplay(true);
          })
          .catch((err) => {
            console.log('getInvitations err', err);
            setDisplay(true);
          });
      })
      .catch((err) => {
        console.log('GET_CUSTOMER_CODES err', err);
        setDisplay(true);
      });

    firebase
      .getHiddenInvitations(user.uid)
      .then((result) => {
        console.log('hidden invitations =====>', result);
        setHiddenInvitations(result);
      })
      .catch((err) => {
        console.log('getHiddenInvitations err', err);
        setDisplay(true);
      });
    getUpcomingInvoice();
  };

  function daysBetweenDates(date1, date2) {
    // Convert both dates to milliseconds
    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    const difference_ms = Math.abs(date2_ms - date1_ms);

    // Convert the difference from milliseconds to days
    return Math.ceil(difference_ms / (1000 * 60 * 60 * 24));
  }

  useEffect(() => {
    const docRef = firebase.userRef(user.uid);
    const updatedSubscription = docRef.onSnapshot(async (doc) => {
      if (doc.exists) {
        const subscriptionUpdated = doc.data().subscriptionUpdated;
        if (user.subscriptionUpdated !== subscriptionUpdated) {
          await reloadUser();
          window.location.reload(false);
        }
      }
    });

    return () => updatedSubscription();
  }, []);

  useEffect(() => {
    console.log('user.uid', user.uid);
    if (
      user &&
      user.subscriptionDetails &&
      user.subscriptionDetails.plan &&
      user.subscriptionDetails.plan.interval === 'year'
    ) {
      setShowBlank(false);
      // store.addNotification({
      //   title: 'Annual Subscription Required!',
      //   message:
      //     'Please change your subscription plan to annual to use this feature.',
      //   type: 'warning',
      //   ...NOTIFICATION_PROPS,
      // });
      // setTimeout(() => {
      //   window.location.href = '/subscription'
      // }, 4000)
    }

    getCustomerCodesAndInvitations();

    if (user && Object.keys(user.subscriptionDetails).length !== 0) {
      const currentDate = new Date(),
        currentYear = currentDate.getFullYear(),
        subscriptionEndDate = new Date(
          user.subscriptionDetails.current_period_end * 1000,
        );

      if (user.subscriptionDetails.plan && user.subscriptionDetails.plan.amount)
        setPlanAmount(user.subscriptionDetails.plan.amount);

      if (daysBetweenDates(currentDate, subscriptionEndDate) < 30) {
        if (!user.subscriptionDetails.discount) setShowRedeemButton(true);
        else {
          const discountYear = new Date(
            user.subscriptionDetails.discount.created * 1000,
          ).getFullYear();

          if (discountYear < currentYear) setShowRedeemButton(true);
        }
      }
    }
  }, []);

  const sendInvitation = async () => {
    if (!showBlank && emails.length > 0) {
      setSendingInvitation(true);
      console.log('user.uid', user.uid);
      await firebase
        .createInvitations(user.uid, {
          emails,
        })
        .then((result) => {
          console.log('sendInvitation result', result);
          if (result && result.success) {
            axios
              .post(
                SEND_LINK_INVITATIONS,
                {
                  emails,
                  link,
                  name: [user.firstName, user.middleName, user.lastName]
                    .filter(Boolean)
                    .join(' '),
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                },
              )
              .then((resp) => {
                setSendingInvitation(false);
                console.log('resp', resp);
                if (resp && resp.data && resp.data.success) {
                  setEmails([]);
                  getCustomerCodesAndInvitations();
                  store.addNotification({
                    title: 'Sent Successfully!',
                    message: 'Invitation(s) sent successfully.',
                    type: 'success',
                    ...NOTIFICATION_PROPS,
                  });
                } else {
                  store.addNotification({
                    title: 'Error!',
                    message: 'Invitation(s) sent unsuccessfully.',
                    type: 'danger',
                    ...NOTIFICATION_PROPS,
                  });
                }
              })
              .catch((err) => {
                console.log('err', err);
                setSendingInvitation(false);
                store.addNotification({
                  title: 'Error!',
                  message: err.message || 'Invitation(s) sent unsuccessfully.',
                  type: 'danger',
                  ...NOTIFICATION_PROPS,
                });
              });
          } else {
            setSendingInvitation(false);
            store.addNotification({
              title: 'Error!',
              message: 'Invitation(s) already exist.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
          }
        })
        .catch((err) => {
          setSendingInvitation(false);
          console.log('sendInvitation err', err);
          store.addNotification({
            title: 'Error!',
            message: err.message || 'Invitation(s) sent unsuccessfully',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
        });
    }
  };

  const redeem = async (promotionCode) => {
    setRedeemingCodeId(promotionCode.id);
    setRedeeming(true);
    await axios
      .post(REDEEM_PROMOTION_CODE, {
        subscriptionId: user.subscriptionDetails.id,
        promotionCode,
      })
      .then((result) => {
        setRedeemingCodeId('');
        setRedeeming(false);
        if (result && result.data && result.data.success) {
          console.log('redeem result.data.data', result.data.data);
          store.addNotification({
            title: 'Redeemed Successfully!',
            message:
              result.data.message ||
              'Coupon redeemed successfully. Your upcoming invoice will reflect the discount.',
            type: 'success',
            ...NOTIFICATION_PROPS_LONGER,
          });
          getCustomerCodesAndInvitations();
        } else {
          store.addNotification({
            title: 'Error!',
            message: result.data.message || 'Coupon redeemed unsuccessfully.',
            type: 'danger',
            ...NOTIFICATION_PROPS_LONGER,
          });
        }
      })
      .catch((err) => {
        setRedeemingCodeId('');
        setRedeeming(false);
        store.addNotification({
          title: 'Error!',
          message: err.message,
          type: 'danger',
          ...NOTIFICATION_PROPS_LONGER,
        });
      });
  };

  function invitationStatusBackground(status) {
    switch (status) {
      case 'Pending':
        return '#F8FAFF';
      case 'Trialing':
        return 'rgba(4, 181, 255, 0.16)';
      case 'Subscribed':
        return 'rgba(141, 241, 125, 0.16)';
      default:
        return '#F8FAFF';
    }
  }

  function couponAvailabilityBackground(status) {
    switch (status) {
      case 'Available':
        return 'rgba(54, 147, 39, 0.16)';
      case 'Used':
        return 'rgba(255, 199, 0, 0.15)';
      case 'Expired':
        return 'rgba(189, 37, 37, 0.15)';
      default:
        return 'rgba(54, 147, 39, 0.16)';
    }
  }

  function couponAvailabilityText(coupon) {
    if (coupon.metadata && coupon.metadata.valid !== undefined) {
      if (coupon.metadata.valid && coupon.metadata.times_redeemed === 0)
        return 'Available';

      // if (coupon.metadata.times_redeemed === coupon.max_redemptions)
      //   return 'Used'

      return 'Used';
    } else {
      if (coupon.valid && coupon.times_redeemed === 0) return 'Available';

      // if (coupon.times_redeemed === coupon.max_redemptions)
      //   return 'Used'

      return 'Used';
    }
  }

  function invitationCouponsAvailability(
    subscriptionPromotionCodeStripe,
    signupPromotionCodeStripe,
  ) {
    if (subscriptionPromotionCodeStripe) {
      return couponAvailabilityText(subscriptionPromotionCodeStripe.coupon);
    } else if (signupPromotionCodeStripe) {
      return couponAvailabilityText(signupPromotionCodeStripe.coupon);
    }
  }

  const hideConfirmationModal = () => {
    setHidingEmail(null);
    setHidingIndex(null);
    setShowHideConfirmation(false);
  };

  const hideInvitation = async () => {
    setHiding(true);
    setShowHideConfirmation(false);
    await firebase
      .hideInvitation(user.uid, hidingEmail)
      .then((result) => {
        console.log('hideInvitation result', result);
        getCustomerCodesAndInvitations();
      })
      .catch((err) => {
        console.log('hideInvitation err', err);
      });

    setHidingIndex(null);
    setHidingEmail(null);
    setHiding(false);
  };

  const UnhideInvitation = async (email) => {
    console.log('email =====>', email);

    setShowUnhideModal(false);
    await firebase
      .unhideInvitation(user.uid, email)
      .then((result) => {
        console.log('unhideInvitation result', result);
        getCustomerCodesAndInvitations();
      })
      .catch((err) => {
        console.log('unhideInvitation err', err);
      });
  };

  const combineCodes = (code, index) => {
    if (upcomingInvoice.total !== undefined) {
      if (appEasyState.sumAmountOff < 10000) {
        let sumOff = 0;
        if (code.coupon.amount_off) {
          sumOff = code.coupon.amount_off;
        } else if (code.percent_off) {
          sumOff = parseInt(((code.percent_off / 100) * planAmount).toString());
        }

        console.log('appEasyState.sumAmountOff ', appEasyState.sumAmountOff);
        console.log(
          'appEasyState.sumAmountOff + sumOff',
          appEasyState.sumAmountOff + sumOff,
        );

        if (appEasyState.sumAmountOff + sumOff <= 10000) {
          appEasyState.sumAmountOff = appEasyState.sumAmountOff + sumOff;
          redeemCodes.push(code);
          appEasyState.codes[index].isAdded = true;
        } else {
          // coupons amount exceeded
          store.addNotification({
            title: 'Error!',
            message:
              'Coupon(s) amount summation must not be greater than $100 per year.',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
        }
      }
    } else {
      // invoice not retrieved
      store.addNotification({
        title: 'Error!',
        message: 'Upcoming invoice retrieved unsuccessfully.',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    }
  };

  const removeCode = (code, index) => {
    const filtered = redeemCodes.filter((item) => item.id !== code.id);
    setRedeemCodes(filtered);
    appEasyState.codes[index].isAdded = false;
    let sumOff = 0;
    if (code.coupon.amount_off) {
      sumOff += code.coupon.amount_off;
    } else if (code.percent_off) {
      sumOff += parseInt(((code.percent_off / 100) * planAmount).toString());
    }
    if (appEasyState.sumAmountOff - sumOff >= 0)
      appEasyState.sumAmountOff = appEasyState.sumAmountOff - sumOff;
  };

  const redeemCombinedCodes = () => {
    axios
      .post(REDEEM_COMBINED_CODES, {
        subscriptionId: user.subscriptionDetails.id,
        sumAmountOff: appEasyState.sumAmountOff,
        codes: redeemCodes,
      })
      .then((result) => {
        if (result && result.data && result.data.success) {
          store.addNotification({
            title: 'Redeemed Successfully!',
            message:
              result.data.message ||
              'Coupon(s) redeemed successfully. Your upcoming invoice will reflect the discount.',
            type: 'success',
            ...NOTIFICATION_PROPS_LONGER,
          });
          getCustomerCodesAndInvitations();
        } else {
        }
      })
      .catch((err) => {
        console.log(' redeemCombinedCodes err', err);
      });
  };

  useEffect(() => {
    console.log('start appEasyState.sumAmountOff', appEasyState.sumAmountOff);
  }, []);

  useEffect(() => {
    if (confirmRedeem) {
      redeemCombinedCodes();
      setConfirmRedeem(false);
    }
  }, [confirmRedeem]);

  const handleCodeRowClick = (code, index) => {
    if (couponAvailabilityText(code.coupon) === 'Available') {
      if (!appEasyState.codes[index].isAdded) combineCodes(code, index);
      else removeCode(code, index);
    }
  };

  useEffect(() => {
    if (selectAllCoupons) {
      appEasyState.codes.map((code, index) => {
        if (
          couponAvailabilityText(code.coupon) === 'Available' &&
          !appEasyState.codes[index].isAdded
        )
          combineCodes(code, index);
      });
    } else {
      setRedeemCodes([]);
      appEasyState.codes.forEach((item) => (item['isAdded'] = false));
      if (
        upcomingInvoice.discount &&
        upcomingInvoice.discount.coupon &&
        upcomingInvoice.discount.coupon.max_redemptions == 1
      ) {
        if (upcomingInvoice.discount.coupon.amount_off)
          appEasyState.sumAmountOff =
            upcomingInvoice.discount.coupon.amount_off;
        else if (upcomingInvoice.discount.coupon.percent_off) {
          appEasyState.sumAmountOff = parseInt(
            (
              (upcomingInvoice.discount.coupon.percent_off / 100) *
              planAmount
            ).toString(),
          );
        }
      } else appEasyState.sumAmountOff = 0;
    }
  }, [selectAllCoupons]);

  return (
    <div className={s.appContainer}>
      <Row onClick={() => setShowInstructionsModal(true)}>
        <Col className={s.learnMoreDiv}>
          <span>
            <img
              src={GiftIcon}
              alt="Gift"
              style={{ marginRight: '12px', marginTop: '-5px' }}
            />
            Refer Friends and Earn Rewards
          </span>
          <span className={s.text}>Learn More</span>
        </Col>
      </Row>

      <Row>
        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
          <Card className={s.linkCard}>
            <Card.Title className={s.title}>Send Referral Link</Card.Title>
            <Card.Body style={{ padding: '0 0 0 0', width: '100%' }}>
              <Form.Group controlId="">
                <div className={s.inputLabel}>Your Referral Link</div>
                <InputGroup
                  className={
                    'd-flex justify-content-between flex-direction-row align-items-end'
                  }
                >
                  <Form.Control
                    className={s.inputInfo}
                    type="text"
                    value={link}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-primary"
                      className={s.promotionButton}
                      onClick={async () => {
                        await navigator.clipboard.writeText(link);
                        console.log('Text copied to clipboard!');
                        store.addNotification({
                          title: 'Copied!',
                          message: 'Referral Link copied to clipboard.',
                          type: 'success',
                          ...NOTIFICATION_PROPS,
                        });
                      }}
                    >
                      <img
                        src={CopyIcon}
                        alt="Copy"
                        style={{ marginRight: '5px' }}
                      />
                      Copy
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
          <Card className={s.linkCard}>
            <Card.Title className={s.title}>Invite Friends</Card.Title>
            <Card.Body style={{ padding: '0 0 0 0', width: '100%' }}>
              <Form.Group controlId="">
                <div className={s.inputLabel}>Enter emails to invite</div>
                <div
                  className={
                    'd-flex justify-content-between flex-direction-row align-items-end'
                  }
                >
                  <ReactMultiEmail
                    // placeholder='Input your email'
                    emails={emails}
                    onChange={(_emails) => {
                      setEmails(_emails);
                    }}
                    autoFocus={false}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                  {!sendingInvitation ? (
                    <Button
                      variant="primary"
                      className={s.inviteButton}
                      onClick={sendInvitation}
                    >
                      Send Invite
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className={s.inviteButton}
                      onClick={() => {}}
                    >
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                      Sending
                    </Button>
                  )}
                </div>
                <span className={s.inviteDescription}>
                  Enter multiple email address and separate them with comma,
                  space or line break
                </span>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showBlank ? (
        <Row>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <Card className={s.linkCard}>
              <Card.Title className={s.title}>
                <span>
                  <img
                    src={GiftIcon}
                    alt="Gift"
                    width={46}
                    height={46}
                    style={{ marginRight: '12px', marginTop: '-5px' }}
                  />
                  Earned Rewards
                </span>
              </Card.Title>
              <Card.Body style={{ padding: '0 0 0 0', width: '100%' }}>
                <div className={s.inviteDescription}>
                  <div>
                    Referral coupons can only be applied once per year towards
                    annual plans, with a $100 annual limit.
                  </div>
                  <br />
                  <div>
                    You can redeem your rewards 30 days before your next
                    subscription renewal. Once you redeem your referral coupon,
                    this amount will be deducted from your next subscription
                    plan.
                  </div>
                </div>
              </Card.Body>
              <Card.Footer
                className={'text-right'}
                style={{
                  padding: '16px 0 0 0',
                  width: '100%',
                  background: '#FFF',
                  border: 'none',
                }}
              >
                <Button
                  variant="primary"
                  className={s.couponDiscountButton}
                  onClick={() => (window.location.href = '/subscription')}
                >
                  <span>Update Plan</span>
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      ) : !showRedeemButton ? (
        <Row>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <Card className={s.linkCard}>
              <Card.Title className={s.title}>
                <span>
                  <img
                    src={GiftIcon}
                    alt="Gift"
                    width={46}
                    height={46}
                    style={{ marginRight: '12px', marginTop: '-5px' }}
                  />
                  Earned Rewards
                </span>
              </Card.Title>
              <Card.Body style={{ padding: '0 0 0 0', width: '100%' }}>
                <div className={s.inviteDescription}>
                  <Row>
                    <Col>
                      <div>
                        Referral coupons can only be applied once per year
                        towards annual plans, with a $100 annual limit.
                      </div>
                      <br />
                      <div>
                        You can redeem your rewards 30 days before your next
                        subscription renewal. Once you redeem your referral
                        coupon, this amount will be deducted from your next
                        subscription plan.
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={3} sm={3} xs={3}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {upcomingInvoice && upcomingInvoice.total && (
                          <div className={s.couponAmountText}>
                            ${appEasyState.sumAmountOff / 100}
                          </div>
                        )}
                        <div>Coupon Discount</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
              <Card.Footer
                className={'text-right'}
                style={{
                  padding: '16px 0 0 0',
                  width: '100%',
                  background: '#FFF',
                  border: 'none',
                }}
              >
                <Button
                  variant="primary"
                  className={s.couponDiscountButton}
                  onClick={redeemCombinedCodes}
                  disabled={!showRedeemButton}
                >
                  <span>Redeem</span>
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Card className={s.listEarnedRewards}>
              <Card.Title className={s.title}>
                <div>Earned Rewards</div>
                <div className={s.filter}>
                  <Button
                    variant="primary"
                    className={s.redeemButton}
                    disabled={!showRedeemButton}
                    onClick={() => {
                      if (appEasyState.sumAmountOff > 0)
                        setShowConfirmRedeemModal(true);
                    }}
                  >
                    <span>Redeem</span>
                  </Button>
                </div>
              </Card.Title>
              <Card.Body className={s.tableDiv}>
                <Table responsive borderless hover>
                  <thead>
                    <tr>
                      <th
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Form.Check
                          // className={s.checkboxLabel}
                          type="checkbox"
                          onChange={() =>
                            setSelectAllCoupons(!selectAllCoupons)
                          }
                          checked={selectAllCoupons}
                        />
                        <span className={'ml-1'}>Coupon Earned</span>
                      </th>
                      <th>Coupon Value</th>
                      <th>Coupon Expiry Date</th>
                      {/* <th>Coupon Availability</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {display &&
                      appEasyState.codes.map((code, index) => (
                        <tr>
                          <td
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <Form.Check
                              // className={s.checkboxLabel}
                              type="checkbox"
                              onChange={() => handleCodeRowClick(code, index)}
                              checked={code.isAdded}
                            />
                            <span className={'ml-1'}>{code.code}</span>
                          </td>
                          <td>
                            {console.log(
                              'amount off =====>',
                              code.coupon.amount_off,
                            )}
                            {code.coupon.amount_off
                              ? `$${code.coupon.amount_off / 100}`
                              : `${code.coupon.percent_off}%`}
                          </td>
                          <td>
                            {code.expires_at
                              ? `${moment
                                  .unix(code.expires_at)
                                  .format('MMMM DD, YYYY')}`
                              : '-'}
                          </td>
                          {/* <td>
                            <span
                              className={s.couponAvailability}
                              style={{
                                backgroundColor: couponAvailabilityBackground(
                                  couponAvailabilityText(code.coupon),
                                ),
                              }}
                            >
                              {couponAvailabilityText(code.coupon)}
                            </span>
                          </td> */}
                          {/*<td>*/}
                          {/*  {!redeeming ? (*/}
                          {/*    <Button*/}
                          {/*      variant='primary'*/}
                          {/*      className={s.redeemButton}*/}
                          {/*      onClick={() => redeem(code)}*/}
                          {/*      disabled={couponAvailabilityText(code.coupon) !== 'Available'}*/}
                          {/*    >*/}
                          {/*      <span>Redeem</span>*/}
                          {/*    </Button>*/}
                          {/*  ) : redeemingCodeId === code.id ? (*/}
                          {/*    <Button*/}
                          {/*      variant='primary'*/}
                          {/*      className={s.redeemButton}*/}
                          {/*      onClick={() => {*/}
                          {/*      }}*/}
                          {/*    >*/}
                          {/*      <span className='spinner-border spinner-border-sm mr-1'></span>*/}
                          {/*    </Button>*/}
                          {/*  ) : (*/}
                          {/*    <Button*/}
                          {/*      variant='primary'*/}
                          {/*      className={s.redeemButton}*/}
                          {/*      onClick={() => redeem(code)}*/}
                          {/*      disabled={couponAvailabilityText(code.coupon) === 'Used' ||*/}
                          {/*        couponAvailabilityText(code.coupon) === 'Expired'}*/}
                          {/*    >*/}
                          {/*      <span>Redeem</span>*/}
                          {/*    </Button>*/}
                          {/*  )}*/}
                          {/*</td>*/}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Card className={s.referralHistory}>
            <Card.Title className={s.title}>
              <div>Referral History</div>
              {hiddenInvitations.length ? (
                <Button
                  variant="primary"
                  className={s.unhideButton}
                  onClick={() => {
                    setShowUnhideModal(true);
                  }}
                >
                  <span>Unhide Invitations</span>
                </Button>
              ) : null}

              {/*<div className={s.filter}>*/}
              {/*  <img src={FunnelIcon} alt={'Filter'} className={s.icon} />*/}
              {/*  <span className={s.text}>Filters</span>*/}
              {/*</div>*/}
            </Card.Title>
            <Card.Body className={s.tableDiv}>
              <Table responsive borderless hover>
                <thead>
                  <tr>
                    <th>Email Address</th>
                    <th>Subscription Status</th>
                    <th>Trial Date</th>
                    <th>Subscription Date</th>
                    <th>Coupon Earned</th>
                    <th>Coupon Availability</th>
                    <th>Referral Action</th>
                  </tr>
                </thead>
                <tbody>
                  {display &&
                    invitations.map((invitation, index) => (
                      <tr>
                        <td>{invitation.email}</td>
                        <td>
                          <span
                            className={s.invitationStatus}
                            style={{
                              backgroundColor: invitationStatusBackground(
                                invitation.status,
                              ),
                            }}
                          >
                            {invitation.status}
                          </span>
                        </td>
                        {/*<td>*/}
                        {/*  {invitation.subscriptionPromotionCodeStripe*/}
                        {/*    ? invitation.subscriptionPromotionCodeStripe.coupon.amount_off / 100*/}
                        {/*      ? `$${invitation.subscriptionPromotionCodeStripe.coupon.amount_off / 100}`*/}
                        {/*      : `${invitation.subscriptionPromotionCodeStripe.coupon.percent_off}%`*/}
                        {/*    : invitation.signupPromotionCodeStripe*/}
                        {/*      ? invitation.signupPromotionCodeStripe.coupon.amount_off*/}
                        {/*        ? `$${invitation.signupPromotionCodeStripe.coupon.amount_off / 100}`*/}
                        {/*        : `${invitation.signupPromotionCodeStripe.coupon.percent_off}%`*/}
                        {/*      : '-'*/}
                        {/*  }*/}
                        {/*</td>*/}
                        <td>
                          {invitation.trialingDate
                            ? `${moment
                                .unix(invitation.trialingDate)
                                .format('MMMM DD, YYYY')}`
                            : '-'}
                        </td>
                        <td>
                          {invitation.firstSubscriptionDate
                            ? `${moment
                                .unix(invitation.firstSubscriptionDate)
                                .format('MMMM DD, YYYY')}`
                            : '-'}
                        </td>
                        {/*<td>*/}
                        {/*  {invitation.subscriptionPromotionCodeStripe*/}
                        {/*    ? invitation.subscriptionPromotionCodeStripe.expires_at*/}
                        {/*      ? `${moment.unix(invitation.subscriptionPromotionCodeStripe.expires_at).format('MMMM DD, YYYY')}` : '-'*/}
                        {/*    : invitation.signupPromotionCodeStripe*/}
                        {/*      ? invitation.signupPromotionCodeStripe.expires_at*/}
                        {/*        ? `${moment.unix(invitation.signupPromotionCodeStripe.expires_at).format('MMMM DD, YYYY')}` : '-'*/}
                        {/*      : '-'}*/}
                        {/*</td>*/}
                        <td>
                          {invitation.subscriptionPromotionCodeStripe
                            ? invitation.subscriptionPromotionCodeStripe.code
                            : invitation.signupPromotionCodeStripe
                            ? invitation.signupPromotionCodeStripe.code
                            : '-'}
                        </td>
                        <td>
                          {!invitation.subscriptionPromotionCodeStripe &&
                          !invitation.signupPromotionCodeStripe ? (
                            <span></span>
                          ) : invitation.status == 'Trialing' ? (
                            <span></span>
                          ) : (
                            <span
                              className={s.couponAvailability}
                              style={{
                                backgroundColor: couponAvailabilityBackground(
                                  invitationCouponsAvailability(
                                    invitation.subscriptionPromotionCodeStripe,
                                    invitation.signupPromotionCodeStripe,
                                  ),
                                ),
                              }}
                            >
                              {invitationCouponsAvailability(
                                invitation.subscriptionPromotionCodeStripe,
                                invitation.signupPromotionCodeStripe,
                              )}
                            </span>
                          )}
                        </td>
                        <td>
                          {invitation.status === 'Pending' && (
                            <span>
                              {!hiding ? (
                                <Button
                                  variant="primary"
                                  className={s.redeemButton}
                                  // onClick={() => hideInvitation(invitation.email, index)}
                                  onClick={() => {
                                    setHidingEmail(invitation.email);
                                    setHidingIndex(index);
                                    setShowHideConfirmation(true);
                                  }}
                                >
                                  <span>Hide</span>
                                </Button>
                              ) : hidingIndex === index ? (
                                <Button
                                  variant="primary"
                                  className={s.redeemButton}
                                  onClick={() => {}}
                                >
                                  <span className="spinner-border spinner-border-sm mr-1"></span>
                                </Button>
                              ) : (
                                <Button
                                  variant="primary"
                                  className={s.redeemButton}
                                  // onClick={() => hideInvitation(invitation.email, index)}
                                  onClick={() => {
                                    setHidingEmail(invitation.email);
                                    setHidingIndex(index);
                                    setShowHideConfirmation(true);
                                  }}
                                >
                                  <span>Hide</span>
                                </Button>
                              )}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <InstructionsModal
        showInstructionsModal={showInstructionsModal}
        setShowInstructionsModal={setShowInstructionsModal}
      />
      <UnhideModal
        showUnhideModal={showUnhideModal}
        setShowUnhideModal={setShowUnhideModal}
        hiddenInvitations={hiddenInvitations}
        UnhideClick={UnhideInvitation}
      />
      <ConfirmRedeemModal
        showConfirmRedeemModal={showConfirmRedeemModal}
        setShowConfirmRedeemModal={setShowConfirmRedeemModal}
        setConfirmRedeem={setConfirmRedeem}
        sumAmountOff={appEasyState.sumAmountOff}
      />
      <DynamicModal
        title="Hide Referral Confirmation"
        size="md"
        show={showHideConfirmation}
        hideModal={hideConfirmationModal}
      >
        <div className={s.modalContent}>
          <p>Are you sure you want to hide this Referral?</p>
        </div>
        <div className={s.footer}>
          <Button variant="secondary" onClick={hideConfirmationModal}>
            Cancel
          </Button>
          <Button variant="text" onClick={hideInvitation}>
            Confirm
          </Button>
        </div>
      </DynamicModal>
    </div>
  );
};

const condition = (user) => !!user;
export default withAuthorization(condition)(withFirebase(view(Referrals)));
