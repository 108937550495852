/* eslint-disable indent */
import React, { useState, Fragment, useEffect } from 'react';
import Header from '../../Header';
import UploadDocument from '../UploadDocument';
import SearchInput from '../../SearchInput';
import s from './style.scss';
import { Tabs, Tab, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { All_DOCUMENTS } from '../../../utils/constants';
import ColorLabels from './ColorLabels';
import COLUMNS from './COLUMNS';
import Spinner from '../../Layout/Spinner';
import withAuthorization from '../../../auth/session/withAuthorization';
import { set } from 'local-storage';

const AllUsers = (props) => {
  const { user, firebase } = props;
  const [key, setKey] = useState('all');
  const [allUsers, setAllUsers] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    firebase
      .getAllUsers()
      .then((usersList) => {
        setLoading(false);
        setAllUsers(usersList);
        setOriginalData(usersList);
        console.log('usersList ===> ', usersList);
        return;
      })
      .catch((error) => {
        setLoading(false);
        console.log(`error ===> `, error);
      });
  }, []);

  const showdocumentTab = (arg) => setKey(arg);
  const getFilteredData = (data) => {
    setAllUsers(data);
  };

  const getTitle = () => {
    return (
      <div>
        <div>All Users</div>
        {/* <p style={{ fontSize: '20px' }}>
          Unique scan count: {getUniqueDocsCount()}
        </p> */}
      </div>
    );
  };

  const getTrProps = (table, rowInfo) => {
    return {
      onClick: (e) => {
        props.history.push('/userDocs', {
          user: rowInfo.original,
        });

        // if (rowInfo.original.status !== 'pacted') {
        //   props.history.push('/documentDetails', {
        //     documentSubmitted: true,
        //     documentDetails: rowInfo.original.data,
        //     backToDoc: false,
        //     numPages: rowInfo.original.totalPages,
        //     fileType: rowInfo.original.fileType,
        //     documentInformation: rowInfo.original,
        //     isMyDocuments: true,
        //   });
        // } else {
        //   props.history.push('/documentDetails', {
        //     documentSubmitted: true,
        //     documentDetails: rowInfo.original.sealPactDownloadUrl,
        //     backToDoc: false,
        //     numPages: rowInfo.original.totalPages,
        //     fileType: rowInfo.original.fileType,
        //     documentInformation: rowInfo.original,
        //     isMyDocuments: true,
        //   });
        //   // props.history.push('/sealpactDetails', {
        //   //   confirmationValues: rowInfo.original,
        //   //   documentInformation: rowInfo.original,
        //   // });
        // }
      },
      style: {
        borderRight: 0,
        borderBottom: rowInfo
          ? rowInfo.original
            ? 'Solid black 2px'
            : 'lightgray'
          : null,
        borderLeft: 'solid #0b71ff 5px',
      },
    };
  };
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Header title={getTitle()} />
      <div className={`row no-gutters  ${s.features} `}>
        <div className="col text-right"></div>
        <div className="col-12 col-sm-8 col-md-5 col-lg-3 mt-1">
          <SearchInput
            array={originalData}
            getFilteredData={getFilteredData}
            searchTarget="firstName"
          />
        </div>
        <Col xs={12}>
          <Tabs
            id="docs-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className={`docstable ${s.tabs}`}
          >
            <Tab eventKey="all" title="All" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allUsers}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                />
              </div>
            </Tab>
            {/*
            <Tab eventKey="SealPacted" title="SealPacted" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments.filter((a) => a.status === 'pacted')}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                />
              </div>
            </Tab>
            <Tab eventKey="Pending" title="Pending" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments.filter((a) => a.status === 'pending')}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                />
              </div>
            </Tab>

            <Tab eventKey="Invalidated" title="Invalidated" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments.filter((a) => a.status === 'invalidated')}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                />
              </div>
            </Tab> */}
          </Tabs>
          {/* <ColorLabels tab={showdocumentTab} /> */}
        </Col>
      </div>
    </Fragment>
  );
};
const condition = (user) => !!user && user.admin === true;
export default withAuthorization(condition)(AllUsers);
