import React, { useState, useEffect } from 'react';
import DynamicModal from '../../../../DynamicModal';
import { Container, Button, Form } from 'react-bootstrap';
import s from '../style.scss';
import RowField from './RowField';

const ShareModal = ({
  handleModal,
  show,
  getEmails,
  emails,
  shareDocumentWithUsers,
}) => {
  console.log('emails ==> ', emails);
  const [peopleCount, setPeopleCount] = useState(1);
  const SHAREDPEOPLE = [
    {
      id: emails.length + 1,
      email: '',
      sealPacterType: '',
    },
  ];
  const [sharedPeople, setsharedPeople] = useState(SHAREDPEOPLE);

  useEffect(() => {
    setsharedPeople([...emails, ...sharedPeople]);
  }, []);
  const handleNewEntry = () => {
    setPeopleCount(peopleCount + 1);
    setsharedPeople([
      ...sharedPeople,
      { id: peopleCount, email: '', sealPacterType: '' },
    ]);
  };

  const getFieldData = (inputType, data, id) => {
    let newArr = sharedPeople.map(item => {
      if (item.id === id)
        return {
          ...item,
          [inputType]: data,
        };
      return item;
    });
    setsharedPeople(newArr);
  };
  const onSubmit = e => {
    e.preventDefault();
    // const { primary, secondary, viewer } = e.target.elements;
    handleModal();
    getEmails(sharedPeople);
    shareDocumentWithUsers();
  };

  return (
    <DynamicModal show={show} title="Share Document" hideModal={handleModal}>
      <Container>
        <Form onSubmit={onSubmit}>
          <div className={s.rowContent}>
            <Form.Label>Enter Email address</Form.Label>
            {sharedPeople.map((item, key) => (
              <RowField key={key} item={item} getFieldData={getFieldData} />
            ))}
            <Button variant="text" onClick={handleNewEntry}>
              + Add more
            </Button>
          </div>
          <div className={s.footerBtn}>
            <Button variant="outline-secondary" onClick={handleModal}>
              Cancel
            </Button>
            <Button type="submit">Share</Button>
          </div>
        </Form>
      </Container>
    </DynamicModal>
  );
};

export default ShareModal;
