/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import s from './style.scss'
import axios from 'axios';
import { NOTIFICATION_PROPS, UPDATE_BILLING_INFORMATION } from '../../../utils/constants';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import { store } from 'react-notifications-component';


const BillingInformationAdd = ({ setShowPlanDetails, setShowBillingInfo, user, firebase, customer, setUpdateCustomer }) => {
  const [countries, setCountries] = useState([]),
    [states, setStates] = useState([]),
    [email, setEmail] = useState(''),
    [country, setCountry] = useState('United States'),
    [state, setState] = useState('New York'),
    [city, setCity] = useState(''),
    [zipCode, setZipCode] = useState(''),
    [addressLine1, setAddressLine1] = useState(''),
    [addressLine2, setAddressLine2] = useState(''),
    [name, setName] = useState([user.firstName, user.middleName, user.lastName].filter(Boolean).join(' ')),
    [loading, setLoading] = useState(false),
    [displayStateInput, setDisplayStateInput] = useState(false)


  useEffect(() => {
    console.log('billing info customer', customer)
    if (customer) {
      setEmail(customer.email)
      if (customer.address) {
        setCountry(customer.address.country || '')
        setState(customer.address.state || '')
        setAddressLine1(customer.address.line1 || '')
        setAddressLine2(customer.address.line2 || '')
        setCity(customer.address.city || '')
        setZipCode(customer.address.postal_code || '')
      }
    }

    axios.get('https://restcountries.com/v3.1/all')
      .then(countries_response => {
        if (countries_response && countries_response.data) {
          let countries = countries_response.data
          countries.sort((a, b) => a.name.common.localeCompare(b.name.common));
          // console.log('countries', countries)
          setCountries(countries);
        }
      })
      .catch(err => {
        console.log('get countries err', err)
      })

  }, []);

  useEffect(() => {

    if (country === 'United States') {
      firebase.getStates().then((usStates) => {
        setStates(usStates);
      });
    }
    else if (country === 'Canada') {
      firebase.getCanadianStates().then((canadianStates) => {
        setStates(canadianStates);
      });
    }

  }, [country]);


  const handleCountryChange = (value) => {
    setState('')
    setCountry(value)
    if (value === 'United States' || value === 'Canada')
      setDisplayStateInput(false)
    else
      setDisplayStateInput(true)
  }

  const submitBillingInfo = async (e) => {
   e.preventDefault();

    if (!/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      store.addNotification({
        title: 'Error!',
        message: 'Email is invalid.',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    }
   else if (!/^[a-zA-Z\s]*$|^$/.test(city)) {
     store.addNotification({
       title: 'Error!',
       message: 'City should be either an alphabetic name or empty.',
       type: 'danger',
       ...NOTIFICATION_PROPS,
     });
   }
   else if (!/^[a-zA-Z\s]*$|^$/.test(state)) {
     store.addNotification({
       title: 'Error!',
       message: 'State should be either an alphabetic name or empty.',
       type: 'danger',
       ...NOTIFICATION_PROPS,
     });
   }
   else {

     setLoading(true)
     await axios.post(UPDATE_BILLING_INFORMATION, {
       customerId: appEasyState.customerId,
       email,
       name,
       phoneNumber: user.phoneNumber,
       addressLine1: addressLine1 !== '' ? addressLine1 : null,
       addressLine2: addressLine2 !== '' ? addressLine2 : null,
       country,
       state: state !== '' ? state : null,
       city: city !== '' ? city : null,
       zipCode: zipCode !== '' ? zipCode : null,
     }).then(response => {
       if (response && response.data && response.data.success) {
         setLoading(false)
         store.addNotification({
           title: 'Updated Successfully!',
           message: 'Billing information updated successfully.',
           type: 'default',
           ...NOTIFICATION_PROPS,
         });
         setUpdateCustomer(true)
         setShowPlanDetails(true)
         setShowBillingInfo(false)
       }
       else {
         setLoading(false)
         store.addNotification({
           title: 'Error',
           message: response.data.message,
           type: 'danger',
           ...NOTIFICATION_PROPS,
         });
       }
     }).catch(err => {
       console.log('err', err)
       setLoading(false)
       store.addNotification({
         title: 'Error',
         message: err.message,
         type: 'danger',
         ...NOTIFICATION_PROPS,
       });
     })

   }

  }


  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <form onSubmit={submitBillingInfo}>
          <Card className={s.confirmPlanCard}>
            <Card.Title className={s.title}>
              Add Billing Information
            </Card.Title>
            <Card.Body style={{ padding: '16px 0 0 0', width: '100%' }}>
              {/*<Row>*/}
              {/*  <Col>*/}
              {/*    <Form.Group controlId="">*/}
              {/*      <div className={s.paymentCardInputLabel}>Email</div>*/}
              {/*      <Form.Control*/}
              {/*        type="email"*/}
              {/*        placeholder={'example@email.com'}*/}
              {/*        defaultValue={email}*/}
              {/*        onChange={(e) => setEmail(e.target.value)}*/}
              {/*        isInvalid={!/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)}*/}
              {/*        maxLength={100}*/}
              {/*      />*/}
              {/*    </Form.Group>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <Row>
                <Col>
                  <Form.Group controlId="">
                    <div className={s.paymentCardInputLabel}>Address</div>
                    <Form.Group controlId="country">
                      <Form.Control
                        as="select"
                        value={country}
                        onChange={(e) => handleCountryChange(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Country
                        </option>
                        {countries.map((item, index) => (
                          <option key={index} value={item.name.common}>{item.name.common}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Control
                      className={s.inputInfo}
                      type="text"
                      placeholder={'Address line 1'}
                      value={addressLine1}
                      onChange={(e) => setAddressLine1(e.target.value)}
                      maxLength={250}
                    />
                    <Form.Control
                      className={s.inputInfo}
                      type="text"
                      placeholder={'Address line 2'}
                      defaultValue={addressLine2}
                      onChange={(e) => setAddressLine2(e.target.value)}
                      maxLength={250}
                    />
                    <Form.Control
                      className={s.inputInfo}
                      type="text"
                      placeholder={'City'}
                      defaultValue={city}
                      onChange={(e) => setCity(e.target.value)}
                      maxLength={50}
                      isInvalid={!/^[a-zA-Z\s]*$|^$/.test(city)}
                    />
                    {!displayStateInput && (country === 'United States' || country === 'Canada') ? (
                      <Form.Group controlId="state" className={s.inputInfo}>
                        <Form.Control
                          as="select"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        >
                          <option value="" selected disabled>
                            State
                          </option>s
                          {states.map((item, index) => (
                            <option key={index} value={item.label}>{item.label}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    ) : (
                      <Form.Control
                        className={s.inputInfo}
                        type="text"
                        placeholder={'Enter state'}
                        defaultValue={state}
                        onChange={(e) => setState(e.target.value)}
                        maxLength={50}
                        isInvalid={!/^[a-zA-Z\s]*$|^$/.test(state)}
                      />
                    )}
                    <Form.Control
                      className={s.inputInfo}
                      type="number"
                      placeholder={'ZIP'}
                      value={zipCode}
                      onChange={(e) => {
                        if (e.target.value.length <= 7)
                          setZipCode(e.target.value)
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer style={{ padding: '0 0 0 0', background: 'white', width: '100%', border: 'none' }}>
              <div className={'d-flex justify-content-between align-items-center'}>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowPlanDetails(true)
                    setShowBillingInfo(false)
                  }}
                  className={s.goBack}
                >
                  Cancel
                </Button>
                {!loading ? (
                  <Button
                    variant="primary"
                    className={s.confirmButton}
                    type='submit'
                  >
                    Save
                  </Button>
                ): (
                  <Button
                    variant="primary"
                    className={s.confirmButton}
                  >
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                    Saving
                  </Button>
                )}
              </div>
            </Card.Footer>
          </Card>
        </form>
      </Col>
    </Row>
  )
}

export default view(BillingInformationAdd)