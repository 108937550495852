import React, { useState, useEffect } from 'react';
import DynamicModal from '../../DynamicModal';
import { Button, Col, Form, Row } from 'react-bootstrap';
import s from './style.scss';
import withAuthorization from '../../../auth/session/withAuthorization';
import MaskedInput from '../../AppComponents/MaskedInput';
import { INVALIDATION_REASON, NOTIFICATION_PROPS, SEND_EMAIL_API_URL_NON_SUBSCRIBERS } from '../../../utils/constants';
import axios from 'axios';
import { SEND_EMAIL_API_URL } from '../../../utils/constants';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import { store } from 'react-notifications-component';

const InvalidateSealModal = ({
  handleInvalidate,
  show,
  history: { push },
  backToDoc,
  documentInformation,
  documentDetails,
  confirmationValues,
  fileType,
  numPages,
  firebase,
  invalidationReasonKey,
  setConfirmationValues,
  setLoading,
  setDocumentStatus,
  user,
}) => {
  const [invalidationReason, setInvalidationReason] = useState(null),
    [confirmInvalidate, setConfirmInvalidate] = useState(false),
    [showEmailFields, setShowEmailFields] = useState(false)
  console.log('invalidationReasonKey ======> ', invalidationReasonKey);
  console.log(`documentInformation ===> `, documentInformation);
  console.log(`confirmationValues ===> `, confirmationValues);

  const handleInvalidationReasonChange = (e) => {
    setInvalidationReason(e.target.value);
  };

  const hideModal = () => {
    setConfirmInvalidate(false)
    setShowEmailFields(false)
    appEasyState.invalidateEmails = [{ email: '' }]
    handleInvalidate()
  }

  const performInvalidation = (emails = []) => {
    handleInvalidate();
    setLoading(true);
    firebase
      .getQrCodeScanDetails(
        documentInformation.id,
        user.uid,
        documentInformation.status,
      )
      .then((subs) => {
        console.log(`subs in invalidated ===> `, subs);
        documentInformation.invalidationReason = invalidationReason
          ? invalidationReason
          : INVALIDATION_REASON[invalidationReasonKey].value;
        documentInformation.status = 'invalidated';
        setDocumentStatus('invalidated');

        documentInformation.isInvalidated = true;
        confirmationValues.subscribers = subs;

        firebase
          .uploadInvalidatedDocument(
            user.uid,
            documentInformation,
            confirmationValues,
          )
          .then((invalidateRes) => {
            console.log('invalidateRes ===>', invalidateRes);

            setConfirmationValues(invalidateRes);

            subs.forEach((subUser) => {
              if (subUser.email && subUser.status === true) {
                axios
                  .post(SEND_EMAIL_API_URL, {
                    projectName: documentInformation.projectName,
                    projectAddress: documentInformation.projectAddress,
                    projectNumber: documentInformation.projectNumber,
                    designProfessionalFirm:
                    documentInformation.firmName,
                    designProfessionalWebsite: documentInformation.website && documentInformation.website !== '' ? documentInformation.website : 'N/A',
                    designProfessional: documentInformation.name,
                    status: documentInformation.status,
                    invlidationReason:
                    documentInformation.invalidationReason,
                    userEmail: subUser.email,
                    link:
                      window.location.origin +
                      '/unSubscribe?' +
                      user.uid +
                      '&' +
                      parseInt(documentInformation.id) +
                      '&' +
                      subUser.email,
                  })
                  .then(function(response) {
                    return;
                  })
                  .catch((error) => {
                    console.log(`error ==> `, error);
                  });
              }
            });
            if (emails.length > 0) {
              emails.forEach((emailUser) => {
                if (emailUser.email) {
                  axios
                    .post(SEND_EMAIL_API_URL_NON_SUBSCRIBERS, {
                      projectName: documentInformation.projectName,
                      projectAddress: documentInformation.projectAddress,
                      projectNumber: documentInformation.projectNumber,
                      designProfessionalFirm:
                      documentInformation.firmName,
                      designProfessionalWebsite: documentInformation.website && documentInformation.website !== '' ? documentInformation.website : 'N/A',
                      designProfessional: documentInformation.name,
                      status: documentInformation.status,
                      invlidationReason:
                      documentInformation.invalidationReason,
                      userEmail: emailUser.email,
                      link:
                        window.location.origin +
                        '/unSubscribe?' +
                        user.uid +
                        '&' +
                        parseInt(documentInformation.id) +
                        '&' +
                        emailUser.email,
                      fullName: [user.firstName, user.middleName, user.lastName].filter(Boolean).join(' '),
                    })
                    .then(function(response) {
                      return;
                    })
                    .catch((error) => {
                      console.log(`error ==> `, error);
                    });
                }
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log('error ==> ', error);
            return;
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const handleConfirmAfterEmails = () => {
    const filtered = appEasyState.invalidateEmails.filter(elm => elm.email !== '')
    if (filtered === 0) {
      appEasyState.invalidateEmails = [{  email: '' }]
      store.addNotification({
        title: 'Error',
        message: 'Invalid Email(s)',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    }
    else {
      appEasyState.invalidateEmails = filtered
      let invalidEMails = false
      for (let i = 0; i < appEasyState.invalidateEmails.length && !invalidEMails; i++)
        if (!/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/.test(appEasyState.invalidateEmails[i].email)) {
          invalidEMails = true
          store.addNotification({
            title: 'Error',
            message: 'Invalid Email(s)',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
        }

      if (!invalidEMails) {
        performInvalidation(appEasyState.invalidateEmails)
        appEasyState.invalidateEmails = [{ email: '' }]
      }
    }
  }

  return (
    <DynamicModal
      title="Invalidate SealPact"
      size="lg"
      show={show}
      hideModal={hideModal}
    >
      {!confirmInvalidate ? (
        <>
          <div className={s.modalContent}>
            <p>Are you sure you want to manually invalidate this SealPact?</p>
          </div>
          {parseInt(invalidationReasonKey) === 3 && (
            <Form.Group controlId="otherInvalidationReason">
              {/* <Form.Label>Invalidation reason</Form.Label> */}
              <Form.Control
                onChange={handleInvalidationReasonChange}
                type="text"
                placeholder="Invalidation reason"
              />
            </Form.Group>
          )}
          <div className={s.footer}>
            <Button
              variant="secondary"
              onClick={() => {
                handleInvalidate();
              }}
              className={s.buttonBack}
            >
              Cancel
            </Button>
            <Button
              className={s.buttonSubmit}
              variant="text"
              onClick={() => setConfirmInvalidate(true)}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : !showEmailFields
        ? (
          <>
            <div className={s.modalContent}>
              <p>Do you want to notify anyone else about this invalidation?</p>
            </div>
            <div className={s.footer}>
              <Button
                variant="secondary"
                onClick={() => performInvalidation()}
                className={s.buttonBack}
              >
                No
              </Button>
              <Button
                className={s.buttonSubmit}
                variant="text"
                onClick={() => setShowEmailFields(true)}
              >
                Yes
              </Button>
            </div>
          </>
        )
        : (
          <>
            <div className={s.modalContent}>
              <p>Add Upto Four Emails</p>
              {appEasyState.invalidateEmails.map((item, index) => (
                <Row className={'mb-3'}>
                  <Col>
                    <Form.Group controlId="email">
                      <Form.Control
                        key={index}
                        type={'email'}
                        placeholder={'example@email.com'}
                        defaultValue={item.email}
                        onChange={e => item.email = e.target.value}
                      />
                    </Form.Group>
                  </Col>
                  {appEasyState.invalidateEmails.length > 1 && (
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Button
                        variant='text'
                        style={{
                          border: '1px solid black',
                          fontWeight: 'bold',
                          borderRadius: '8px',
                        }}
                        onClick={() => {
                          appEasyState.invalidateEmails.splice(index, 1)
                        }}
                      >
                        <i className='fa fa-trash' aria-hidden='true' />
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
              <Button
                variant='text'
                className={'mt-3'}
                style={{
                  border: '1px solid black',
                  fontWeight: 'bold',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  appEasyState.invalidateEmails.push({ email: '' })
                }}
                disabled={appEasyState.invalidateEmails.length === 4}
              >
                Add Another Email
              </Button>
            </div>
            <div className={s.footer}>
              <Button
                variant="secondary"
                onClick={() => performInvalidation()}
                className={s.buttonBack}
              >
                Cancel
              </Button>
              <Button
                className={s.buttonSubmit}
                variant="text"
                onClick={() => handleConfirmAfterEmails()}
              >
                Confirm
              </Button>
            </div>
          </>
        ) }
    </DynamicModal>
  );
};
const condition = (user) => !!user;
export default withAuthorization(condition)(view(InvalidateSealModal));
