/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {view} from 'react-easy-state';
import PlanCard from './PlanCard';

const PlansForUpdate = ({ prices, handleUpdatePlan, getProductName, currentPlanId, updatePlanLoading, clickedPlanId, applyPromotionCode }) => {

  return (
    <Row className={'g-0'}>
      {prices.map(price => (
        <Col xl={4} lg={6} md={6} sm={12} xs={12}
             style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center'
             }}>
          <PlanCard
            plan={price}
            name={getProductName(price.product)}
            createSubscription={handleUpdatePlan}
            currentPlanId={currentPlanId}
            loading={updatePlanLoading}
            clickedPlanId={clickedPlanId}
            applyPromotionCode={applyPromotionCode}
          />
        </Col>
      ))}
    </Row>
  )
}

export default view(PlansForUpdate)