import React, { Fragment, useState, useEffect } from 'react';
import s from '../style.scss';
import DynamicModal from '../../../DynamicModal';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import CustomTooltip from '../../../AppComponents/CustomTooltip';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import {view} from 'react-easy-state';
import { appEasyState } from '../../../../easyStateStore';
import Switch from 'react-switch';

const uncheckedIcon = (
  <div className={[s.label, s.offLabel].join(' ')}>OFF</div>
);
const checkedIcon = <div className={[s.label, s.onLabel].join(' ')}>ON</div>;


const SelectSealPage = ({
                          documentInformation,
                          confirmSealPages,
                          getBasicTabValue,
                          setBasicTabPages,
                          showModal,
                          hideModal,
                          sealPages,
                          // selectedPagesAndSealPos,
                          setSelectedPagesAndSealPos,
                          setPageFromTo,
                        }) => {
  const [disableControl, setDisableControl] = useState(false);
  const [pagesFrom, setPagesFrom] = useState(1);
  const [pagesTo, setPagesTo] = useState(1);
  const [name, setName] = useState();
  const [licenseNo, setLicenseNo] = useState();
  const [firmName, setFirmName] = useState();
  const [projectName, setProjectName] = useState();
  const [projectNumber, setProjectNumber] = useState();
  const [projectAddress, setProjectAddress] = useState();
  const [date, setdate] = useState(Date.now());
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isAllPages, setIsAllPages] = useState(false);
  const [isOtherPages, setOtherPages] = useState(false);
  const [isSealOnSamePos, setIsSealOnSamePos] = useState(null);
  const [pagesFromToCheck, setPagesFromToCheck] = useState(false);
  const [pagesRangeCheck, setPagesRangeCheck] = useState(false);
  const [pagesRange, setPagesRange] = useState(null);
  const [enableVerificationWatermark, setEnableVerificationWatermark] = useState(false);

  useEffect(() => {
    console.log('documentInformation', documentInformation)
    setPagesFrom(sealPages.pageFrom);
    setPagesTo(sealPages.pageTo);
    // if (sealPages.pageTo === documentInformation.totalPages) {
    //   setIsFirstPage(false);
    //   setIsAllPages(true);
    // }

    if (
      sealPages.pageFrom === 1 &&
      sealPages.pageTo === documentInformation.totalPages &&
      sealPages.pageTo !== 1
    ) {
      setDisableControl(true);
      setIsAllPages(true);
    }

    if (
      (sealPages.pageFrom === 1 &&
        sealPages.pageTo === documentInformation.totalPages) ||
      (sealPages.pageFrom === 1 && sealPages.pageTo === 1)
    ) {
      setDisableControl(true);
      setIsFirstPage(true);
    }
  }, []);

  const handleClick = (e) => {
    console.log(`e.target.checked ==> `, e.target.checked);
    setIsFirstPage(e.target.checked);
    setDisableControl(e.target.checked);
    setIsSealOnSamePos(null);
    setPagesFromToCheck(false);
    setPagesRangeCheck(false);
    setPagesRange(null);
    if (e.target.checked) {
      // getBasicTabValue('pageFrom', 1);
      setIsAllPages(false);
      setOtherPages(false);
      setPagesFrom(1);
      setPagesTo(1);
      setBasicTabPages(1, 1);
      setSelectedPagesAndSealPos({
        isFirstPage: true,
        isOtherPages: false,
        isAllPages: false,
        isSealOnSamePos: null,
      });

      // getBasicTabValue('pageTo', 1);
    }
  };

  const handleAllDocumentClick = (e) => {
    setDisableControl(e.target.checked);
    setIsAllPages(e.target.checked);
    setPagesFromToCheck(false);
    setPagesRangeCheck(false);
    setPagesRange(null);
    console.log(`e.target.checked ===> `, e.target.checked);
    if (e.target.checked) {
      // getBasicTabValue('pageFrom', 1);
      setIsFirstPage(false);
      setOtherPages(false);
      setPagesFrom(1);
      setPagesTo(documentInformation.totalPages);
      setBasicTabPages(1, documentInformation.totalPages);
      setSelectedPagesAndSealPos({
        isFirstPage: false,
        isOtherPages: false,
        isAllPages: true,
        isSealOnSamePos,
      });

      // getBasicTabValue('pageTo', documentInformation.totalPages);
    }
  };
  const handleCustomPages = (e) => {
    setOtherPages(e.target.checked);
    setDisableControl(false);
    setPagesFromToCheck(true);
    setIsFirstPage(false);
    setIsAllPages(false);
    if (e.target.checked) {
      // getBasicTabValue('pageFrom', 1);
      setPagesFrom(1);
      setPagesTo(documentInformation.totalPages);
      setBasicTabPages(1, documentInformation.totalPages);
      setSelectedPagesAndSealPos({
        isFirstPage: false,
        isAllPages: false,
        isOtherPages: true,
        isSealOnSamePos,
      });

      // getBasicTabValue('pageTo', documentInformation.totalPages);
    }
  };

  const handlePageFromChange = (e) => {
    if (!pagesFromToCheck) {
      setPagesFromToCheck(true);
      setPagesRangeCheck(false);
    }
    let number = parseInt(e.target.value);

    if (number > 0 && number >= pagesTo && number <= documentInformation.totalPages) {
      setPagesFrom(number);
      setPagesTo(number)
      // getBasicTabValue('pageTo', number);
      // getBasicTabValue('pageFrom', number);
      setPageFromTo(number, number)
    }
    else  {
      if (number < 1) number = 1;

      if (number > documentInformation.totalPages)
        number = documentInformation.totalPages;
      if (number > pagesTo) number = pagesTo;

      setPagesFrom(number);
      getBasicTabValue('pageFrom', number);
    }

  };

  const handlePageToChange = (e) => {
    if (!pagesFromToCheck) {
      setPagesFromToCheck(true);
      setPagesRangeCheck(false);
    }
    let number = parseInt(e.target.value);

    if (number <= pagesFrom && number > 0) {
      setPagesFrom(number);
      setPagesTo(number)
      // getBasicTabValue('pageFrom', number);
      // getBasicTabValue('pageTo', number);
      setPageFromTo(number, number)
    }
    else {
      if (number > documentInformation.totalPages)
        number = documentInformation.totalPages;
      if (number < pagesFrom) number = pagesFrom;

      setPagesTo(number);
      getBasicTabValue('pageTo', number);
    }

  };

  const findInCustomPages = (pageNum) => {
    return !!appEasyState.customPages.find(elm => elm === pageNum);
  }

  const processPageRange = (value) => {
    appEasyState.customPages = [];
    const range = value.trim();
    console.log('range', range)
    const tokenized_range = range.split(',');
    console.log('tokenized_range', tokenized_range)

    if (tokenized_range.length > 0) {
      for (let token of tokenized_range) {
        if (token.includes('-')) {
          const dashedTokenizedRange = token.split('-');
          if (dashedTokenizedRange.length === 2) {
            if (parseInt(dashedTokenizedRange[0]) && parseInt(dashedTokenizedRange[1])) {
              const minRange = parseInt(dashedTokenizedRange[0]);
              const maxRange = parseInt(dashedTokenizedRange[1]);

              if (minRange <= maxRange) {
                for (let i = minRange; i <= maxRange; i++) {
                  if (!findInCustomPages(i) && i >= 1 && i <= documentInformation.totalPages)
                    appEasyState.customPages.push(i);
                }
              }
            }
          }
        }
        else if (parseInt(token)) {
          const pageNum = parseInt(token);
          if (!findInCustomPages(pageNum) && pageNum >= 1 && pageNum <= documentInformation.totalPages)
            appEasyState.customPages.push(pageNum)
        }
      }

      if (appEasyState.customPages.length > 0) {
        appEasyState.customPages.sort((a, b) => a - b);
        setPagesFrom(appEasyState.customPages[0]);
        setPagesTo(appEasyState.customPages[appEasyState.customPages.length - 1]);
        setBasicTabPages(appEasyState.customPages[0], appEasyState.customPages[appEasyState.customPages.length - 1], appEasyState.customPages)
      }

      console.log('customPages', appEasyState.customPages)

    }
  }

  const handleRange = (e) => {
    if (!pagesRangeCheck) {
      setPagesFromToCheck(false);
      setPagesRangeCheck(true);
    }
    setPagesRange(e.target.value);
    processPageRange(e.target.value);
  }

  const handleConfirm = () => {
    if (
      (isAllPages && isSealOnSamePos === null) ||
      (isOtherPages && isSealOnSamePos === null)
    ) {
      store.addNotification({
        title: 'Error',
        message: 'Please Choose a Location Option',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    } else if (isNaN(pagesFrom)) {
      store.addNotification({
        title: 'Error',
        message: 'Please Choose a Number From',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    } else if (isNaN(pagesTo)) {
      store.addNotification({
        title: 'Error',
        message: 'Please Choose a Number To',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    } else if (pagesRangeCheck && appEasyState.customPages.length === 0) {
      store.addNotification({
        title: 'Error',
        message: 'Pages cannot be Empty or Invalid',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    }
    else {
      appEasyState.enableVerificationWatermark = enableVerificationWatermark
      confirmSealPages();
    }
  };
  return (
    <DynamicModal
      show={showModal}
      title="Select Pages to be Sealed"
      hideModal={hideModal}
      size="lg"
    >
      <Container>
        <p className={`${s.pages} ${s.marginBottom}`}>
          SealPact Pages <span>(Required)</span>
        </p>
        <Row>
          <Col xl={1} lg={1} md={1} sm={1} xs={1} className={s.pageCheckBox}>
            <Form.Check
              className={s.checkboxLabel}
              type="radio"
              onChange={() => {
                setPagesFromToCheck(true);
                setPagesRangeCheck(false);
                setBasicTabPages(pagesFrom, pagesTo);
              }}
              checked={pagesFromToCheck}
              disabled={
                documentInformation.fileType === 'application/pdf'
                  ? disableControl
                  : true
              }
            />
          </Col>
          <Col xl={5} lg={5} md={5} sm={5} xs={5}>
            <Form.Label className={s.formInlineLabel}>From</Form.Label>
            <div>
              <Form.Group controlId="pagesFrom">
                <Form.Control
                  type="number"
                  value={pagesFrom}
                  onChange={handlePageFromChange}
                  // style={{ width: '70%' }}
                  disabled={
                    documentInformation.fileType === 'application/pdf'
                      ? disableControl
                      : true
                  }
                />
              </Form.Group>
            </div>
          </Col>
          <Col xl={5} lg={5} md={5} sm={5} xs={5}>
            <Form.Label className={s.formInlineLabel}>To</Form.Label>
            <div>
              <Form.Group controlId="pagesTo">
                <Form.Control
                  type="number"
                  onChange={handlePageToChange}
                  value={pagesTo}
                  // style={{ width: '100%' }}
                  disabled={
                    documentInformation.fileType === 'application/pdf'
                      ? disableControl
                      : true
                  }
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '5px' }}>
          <Col xl={1} lg={1} md={1} sm={1} xs={1} className={s.pageCheckBox}>
            <Form.Check
              className={s.checkboxLabel}
              type="radio"
              onChange={() => {
                setPagesFromToCheck(false);
                setPagesRangeCheck(true);
                if (appEasyState.customPages.length !== 0) {
                  setBasicTabPages(appEasyState.customPages[0], appEasyState.customPages[appEasyState.customPages.length - 1], appEasyState.customPages);
                }
                else {
                  setBasicTabPages(pagesFrom, pagesTo);
                }
              }}
              checked={pagesRangeCheck}
              disabled={
                documentInformation.fileType === 'application/pdf'
                  ? disableControl
                  : true
              }
            />
          </Col>
          <Col xl={10} lg={10} md={10} sm={10} xs={10}>
            <Form.Group controlId="pagesRange">
              <Form.Label className={s.formInlineLabel}>Pages</Form.Label>
              <CustomTooltip content={'Page numbers or ranges must be comma separated'}>
                <Form.Control
                  type="text"
                  onChange={handleRange}
                  value={pagesRange}
                  disabled={
                    documentInformation.fileType === 'application/pdf'
                      ? disableControl
                      : true
                  }
                />
              </CustomTooltip>
            </Form.Group>
            {pagesRangeCheck && (
              <>
                <div style={{ color: 'darkgrey'}}>
                  <small>Sample: 1, 2, 4-7, 9, 11-13, 15</small>
                  {/*<small>{appEasyState.customPages.join(', ')}</small>*/}
                  <br/>
                  <br/>
                </div>
              </>
            )}
          </Col>
        </Row>
        <Container>
          <Row>
            <Col>
              <h4 className={s.subHeading}><u>Pages</u></h4>
              <Form.Group controlId="forFirstPageOnly">
                <Form.Check
                  className={s.checkboxLabel}
                  type="radio"
                  label="SealPact 1st page only"
                  onChange={handleClick}
                  checked={isFirstPage}
                />

                {/* <Form.Check
            className={s.checkboxLabel}
            type="checkbox"
            label="SealPact each page of document"
            onChange={handleClick}
            checked={
              documentInformation.fileType === 'application/pdf' ? null : true
            }
          /> */}
              </Form.Group>

              <div className={'d-flex'}>
                <Form.Group controlId="forEachPage">
                  <Form.Check
                    className={s.checkboxLabel}
                    type="radio"
                    label="SealPact each page of document"
                    onChange={handleAllDocumentClick}
                    checked={isAllPages}
                  />
                </Form.Group>
                <CustomTooltip
                  trigger={'hover'}
                  location={'auto'}
                  content={
                    'This feature is usually used for drawings where you want to stamp every sheet.  If you only want to sign/seal certain pages of your document, select those pages above.'
                  }
                >
                  <i
                    style={{
                      marginLeft: '4px',
                      marginTop: '4px',
                    }}
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                </CustomTooltip>
              </div>
              <Form.Group controlId="forOtherPage">
                <Form.Check
                  className={s.checkboxLabel}
                  type="radio"
                  label="Use Other Page Configuration"
                  onChange={handleCustomPages}
                  checked={isOtherPages}
                />
              </Form.Group>
            </Col>
            <Col>
              <h4 className={s.subHeading}><u>Location</u></h4>
              <Form.Group controlId="forSamePos">
                <Form.Check
                  className={s.checkboxLabel}
                  type="radio"
                  label="Seal in the same place on every sheet"
                  onChange={(e) => {
                    setSelectedPagesAndSealPos({
                      isAllPages,
                      isFirstPage,
                      isOtherPages,
                      isSealOnSamePos: true,
                    });
                    setIsSealOnSamePos(true);
                  }}
                  disabled={isFirstPage}
                  checked={isSealOnSamePos === true}
                />
              </Form.Group>{' '}
              <Form.Group controlId="forDiffPos">
                <Form.Check
                  className={s.checkboxLabel}
                  type="radio"
                  label="Seal in different places on different sheets"
                  onChange={(e) => {
                    setSelectedPagesAndSealPos({
                      isAllPages,
                      isFirstPage,
                      isOtherPages,
                      isSealOnSamePos: false,
                    });
                    setIsSealOnSamePos(false);
                  }}
                  disabled={isFirstPage}
                  checked={isSealOnSamePos === false}
                />
              </Form.Group>
            </Col>
          </Row>
          {documentInformation.sealPacterType === 'seal' && (
            <Row>
              <Col xl={6} lg={6} md={12} sm={12} xs={12} className={'mt-2 mb-2'}>
                <h4 className={s.subHeading}><u>Watermark</u>
                  <span style={{ fontSize: '18px' }}>
                    <CustomTooltip
                      trigger={'hover'}
                      location={'auto'}
                      content={`This will add a watermark across your seal for validity/security purposes. It is optional.`}
                    >
                      <i
                        style={{
                          marginLeft: '4px',
                          marginTop: '4px',
                        }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      ></i>
                    </CustomTooltip>
                  </span>
                </h4>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <h6
                    style={{ fontWeight: 700, fontSize: '18px', marginTop: '8px', color: '#031937' }}
                  >Enable Verification Watermark</h6>
                  <Switch
                    checked={enableVerificationWatermark}
                    height={22}
                    onChange={() => setEnableVerificationWatermark(!enableVerificationWatermark)}
                    uncheckedIcon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    className="react-switch"
                    id="icon-switch"
                    offColor="#e64767"
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row className={s.contentEnd}>
            <Button onClick={handleConfirm}>Confirm</Button>
          </Row>
        </Container>
      </Container>
    </DynamicModal>
  );
};

export default view(SelectSealPage);
