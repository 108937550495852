/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import s from './style.scss';
// import DatePicker from 'react-datepicker';
import style from '../style.scss';
import {
  CLIENTTYPE,
  OWNERTYPE,
  PROJECTROLE,
  DISCIPLINE,
  WORKNATURE,
  CONCENTRATION,
} from '../../../../../utils/constants';

const AdvancedTab = ({ confirmationValues }) => {
  console.log('confirmationValues ===>', confirmationValues);

  const getValue = (value) => {
    if (value) {
      return value;
    }
    return 'N/A';
  };

  const formatDate = (date) => {
    //console.log('date == = = = =  >', date ? date.toDate() : date);
    const dateObj = new Date(date);
    //console.log('dateObj =============>', dateObj);
    var year = dateObj.getFullYear();
    var month = (1 + dateObj.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = dateObj.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  };

  const getDiscipline = (dis) => {
    if (dis) {
      const disciplineObj = DISCIPLINE.filter(
        (disciplineObj) => disciplineObj.value === dis,
      );
      return disciplineObj[0].label;
    }
    return dis;
  };

  const getClientType = (value) => {
    if (value) {
      const resultObject = CLIENTTYPE.filter((obj) => (obj.value === value || obj.label === value));
      return resultObject[0].label;
    }
    return value;
  };

  const getOwnerType = (value) => {
    if (value) {
      const resultObject = OWNERTYPE.filter((obj) => (obj.value === value || obj.label === value));
      return resultObject[0].label;
    }
    return value;
  };

  const getProjectRole = (value) => {
    if (value) {
      const resultObject = PROJECTROLE.filter((obj) => (obj.value === value || obj.label === value));
      return resultObject[0].label;
    }
    return value;
  };
  return (
    <div className={s.contentPadding}>
      <Row>
        <Col md={6} lg={4}>
          <div className={s.leftContent}>
            <Form.Label className={s.label}>Client Name:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.clientName)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>Client Email:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.clientEmail)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>Client Type:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(getClientType(confirmationValues.clientType))}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>Owner Type:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(getOwnerType(confirmationValues.ownerType))}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>Nature of Work:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(
                confirmationValues.workNature === 'Other'
                  ? confirmationValues.otherWorkNature
                  : confirmationValues.workNature,
              )}
            </Form.Label>
          </div>
        </Col>

        <Col md={6} lg={4}>
          <div className={s.leftContent}>
            <Form.Label className={s.label}>Discipline:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(getDiscipline(confirmationValues.discipline))}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>Concentration:</Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.discipline
                ? confirmationValues.discipline !== 'landSurveyor'
                  ? confirmationValues.concentration === 'Other'
                    ? confirmationValues.otherConcentration
                    : confirmationValues.concentration
                  : 'N/A'
                : 'N/A'
              }
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>Project Role:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(getProjectRole(confirmationValues.projectRole))}
            </Form.Label>
          </div>

          {/* <div className={s.leftContent}>
            <Form.Label className={s.label}>Design Type:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.designType)}
            </Form.Label>
          </div> */}
        </Col>

        <Col md={6} lg={4}>
          <div className={s.leftContent}>
            <Form.Label className={s.label}>Scope Inclusions:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.scopeInclusions)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>Scope Exclusions:</Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.scopeExclusions)}
            </Form.Label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdvancedTab;
