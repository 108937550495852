/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import CreateNewSealForm from '../CreateNewSealForm';
import { Col, Button, Form, Container, Row } from 'react-bootstrap';
import style from '../style.scss';
import s from './style.scss';
import {
  CREATENEWSEALVALIDATION,
  CREATENEWSEALVALIDATIONOTHERSTATE,
  UPLOADNEWSEALVALIDATION,
  UPLOADNEWSTAMPVALIDATION,
} from '../../../../config/FormSchemas';
import { Formik } from 'formik';
import CircleType from 'circletype';
import { ImagePicker } from 'react-file-picker';
import { store } from 'react-notifications-component';
import Spinner from '../../../Layout/Spinner';
import {
  NOTIFICATION_PROPS,
  NOTIFICATION_PROPS_LONGER,
} from '../../../../utils/constants';
import Cropper from '../../../Cropper/Cropper';
import { getCroppedImg } from '../../../Cropper/cropImage';
import DynamicModal from '../../../DynamicModal';
import { appEasyState } from '../../../../easyStateStore';

const SealScreen = (props) => {
  var ref = React.createRef(null);
  var sealImgRef = useRef();

  const [sealName, setsealName] = useState(null);
  const [sealState, setsealState] = useState(null);
  const [sealImage, setsealImage] = useState(null);
  const [createNewSeal, setCreateNewSeal] = useState(true);
  const [license, setlicense] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [isOtherState, setisOtherState] = useState(false);
  const [expirationDate, setExpirationDate] = useState(Date.now());
  const [sealImg, setSealImg] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showImageUploadError, setShowImageUploadError] = useState(false);
  const [imageCropped, setImageCropped] = useState(false);

  // useEffect(() => {
  //   if (createNewSeal) {
  //     const circleType = new CircleType(document.querySelector('#state'));
  //     circleType.radius(90).dir(-1);
  //     ref = circleType;
  //   }
  // }, [sealState]);

  const handleOtherState = (boolean) => setisOtherState(boolean);
  const getName = ({ target: { value } }) => {
    setsealName(value);
  };
  const getState = (val) => {
    console.log('getState e.target.value: ', val);
    setsealState(val);
  };

  const getExpirationDate = (expDate) => {
    setExpirationDate(expDate.toUTCString());
  };

  const getLicense = (license) => {
    setlicense(license);
  };

  const getApprovalStatus = (approalStatusText) => {
    setApprovalStatus(approalStatusText);
  };

  // const makeBase64Img = () => {
  // return domtoimage.toPng(sealImgRef.current);
  // .then(dataUrl => {
  //    setsealImg(dataUrl);
  //   console.log('dataUrl =================> ', dataUrl);
  //   // props.handleModal();
  // })
  // .catch(error => console.error('oops, something went wrong!', error));
  // };
  const [showCropper, setShowCropper] = useState(false);
  const onCropComplete = (croppedImg) => {
    setsealImage(croppedImg);
    setImageCropped(true);
    setShowCropper(false);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      if (
        appEasyState.cropperWidthTextValue === '' ||
        appEasyState.cropperHeightTextValue === ''
      ) {
        store.addNotification({
          title: 'Error',
          message: 'Size fields cannot be empty.',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return;
      }

      appEasyState.cropperWidthTextValue = '';
      appEasyState.cropperHeightTextValue = '';
      const croppedImage = await getCroppedImg(
        sealImage,
        croppedAreaPixels,
        rotation,
        appEasyState.selectFullImage,
      );
      console.log('done', { croppedImage });
      console.log('selectFullImage', appEasyState.selectFullImage);
      onCropComplete(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <Formik
      initialValues={{
        sealName: '',
        state: '',
        newLicenseNo: '',
      }}
      validationSchema={
        props.isStamp
          ? UPLOADNEWSTAMPVALIDATION
          : isOtherState
          ? CREATENEWSEALVALIDATIONOTHERSTATE
          : createNewSeal
          ? CREATENEWSEALVALIDATION
          : UPLOADNEWSEALVALIDATION
      }
      onSubmit={async (_values, { setSubmitting }) => {
        console.log('_values  ====> ', _values);
        if (!sealImage) {
          store.addNotification({
            title: 'Error',
            message: props.isStamp
              ? 'Please upload the stamp.'
              : 'Please upload the seal.',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
          return;
        }
        setLoading(true);
        setDisableSubmit(true);
        // setTimeout(() => {
        setSubmitting(false);
        if (!props.isStamp) {
          _values.expirationDate = expirationDate;
        }
        _values.data = sealImage;
        await props.handleSubmit(_values);
        props.handleModal();
        // makeBase64Img()
        //   .then(data => {
        //     setSubmitting(false);
        //     _values.expirationDate = expirationDate;
        //     setSealImg(data);
        //     _values.data = data;
        //     props.handleSubmit(_values);
        //     props.handleModal();
        //     return;
        //   })
        //   .catch(error => {
        //     console.log('error', error);
        //   });
        // }, 0);
      }}
    >
      {(formikProps) => {
        const hasErrors = Object.keys(formikProps.errors).length > 0;
        return (
          <div>
            <Form onSubmit={formikProps.handleSubmit}>
              <Container>
                <div className={s.content}>
                  <Row>
                    <Col
                      sm={6}
                      md={6}
                      lg={{ span: 5, offset: 1 }}
                      xl={4}
                      className={s.alignCenter}
                    >
                      {!showCropper ? (
                        <div>
                          <div className={s.columnStyle}>
                            <div className={s.uploadImageButton}>
                              {createNewSeal && (
                                <div
                                  ref={sealImgRef}
                                  className={
                                    props.isStamp
                                      ? s.outerBoundaryStamp
                                      : s.outerBoundary
                                  }
                                >
                                  <div className={s.innerBoundary}>
                                    {/* <p id="name">{sealName}</p> */}
                                    {/* <p id="state">{sealState}</p> */}
                                  </div>
                                </div>
                              )}
                              {!createNewSeal && (
                                <img
                                  className={s.uploadSealImg}
                                  ref={sealImgRef}
                                  src={sealImage}
                                  alt={'seal-pic'}
                                  style={
                                    appEasyState.circularCropShape
                                      ? { borderRadius: '50%' }
                                      : { borderRadius: '0' }
                                  }
                                ></img>
                              )}

                              <div className={s.imagePickerDiv}>
                                <ImagePicker
                                  extensions={[
                                    'jpg',
                                    'jpeg',
                                    'png',
                                    'tif',
                                    'gif',
                                    'bmp',
                                    'svg',
                                    'tiff',
                                    'dib',
                                    'svg',
                                  ]}
                                  dims={
                                    {
                                      // minWidth: 500,
                                      // minHeight: 500,
                                      // maxWidth: 50000,
                                      // maxHeight: 50000,
                                    }
                                  }
                                  maxSize={5}
                                  onChange={(base64) => {
                                    setShowImageUploadError(false);
                                    console.log(
                                      'createSeal image picker file: ',
                                      base64,
                                    );
                                    setCreateNewSeal(false);
                                    setsealImage(base64);
                                    console.log('base64 =>', base64);
                                    setShowCropper(true);
                                  }}
                                  onError={(errMsg) => {
                                    setShowImageUploadError(true);
                                    console.log(
                                      'createSeal image picker error: ',
                                      errMsg,
                                    );
                                    if (
                                      errMsg.includes(
                                        'an error uploading the image',
                                      )
                                    ) {
                                      store.addNotification({
                                        title: 'Error',
                                        message:
                                          errMsg +
                                          '.' +
                                          ' Use any image editor (free) to upload a "Save As" or duplicated file of the image. Thank you!',
                                        type: 'danger',
                                        ...NOTIFICATION_PROPS_LONGER,
                                      });
                                    } else {
                                      store.addNotification({
                                        title: 'Error',
                                        message:
                                          errMsg +
                                          ' Use any image editor (free) to reduce the file size, as needed. Thank you!',
                                        type: 'danger',
                                        ...NOTIFICATION_PROPS_LONGER,
                                      });
                                    }
                                  }}
                                >
                                  <Button className={s.uploadSealButton}>
                                    Upload Image
                                  </Button>
                                </ImagePicker>
                              </div>
                            </div>
                            {props.isStamp && (
                              <span className={s.spanText}>
                                (Check appropriate approval status)
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Cropper
                          image={sealImage}
                          handleComplete={onCropComplete}
                          isMandatory={true}
                          isSignature={false}
                          isStamp={props.isStamp}
                          setShowCropper={setShowCropper}
                          setCroppedAreaPixels={setCroppedAreaPixels}
                          rotation={rotation}
                          setRotation={setRotation}
                          welcomeScreen={false}
                        />
                      )}
                    </Col>

                    <Col sm={6} md={6} lg={{ span: 5, offset: 1 }} xl={4}>
                      <CreateNewSealForm
                        handleOtherState={handleOtherState}
                        isOtherState={isOtherState}
                        formikProps={formikProps}
                        getName={getName}
                        getLicense={getLicense}
                        getApprovalStatus={getApprovalStatus}
                        getState={getState}
                        getExpirationDate={getExpirationDate}
                        setsealImage={setsealImage}
                        createNewSeal={createNewSeal}
                        isStamp={props.isStamp}
                      />
                    </Col>
                  </Row>
                </div>
                <div className={style.footerBtn}>
                  <Button variant="outline-dark" onClick={props.handleModal}>
                    Cancel
                  </Button>
                  {!showCropper ? (
                    <>
                      {loading ? (
                        <Button className="btn btn-primary">
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                          Creating
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          disabled={
                            hasErrors || disableSubmit
                              ? disableSubmit
                              : !imageCropped
                          }
                        >
                          Create
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button onClick={showCroppedImage}>Crop</Button>
                  )}
                </div>
              </Container>
            </Form>

            {/* <div>
              <DynamicModal
                show={showCropper}
                title={'Crop Image'}
                hideModal={() => setShowCropper(false)}
              ></DynamicModal>
            </div> */}
          </div>
        );
      }}
    </Formik>
  );
};

export default SealScreen;
