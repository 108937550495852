import React, { useState } from 'react';
import { Row, Button } from 'react-bootstrap';
import s from './style.scss';
import DynamicModal from '../DynamicModal';
import TermsAndConditions from '../TermsAndPrivcay/TermsAndConditions';
import PrivacyPolicy from '../TermsAndPrivcay/PrivacyPolicy';

const Footer = () => {
  const [showTermsModal, termsToggler] = useState(false);
  const [showPrivacyModal, privacyToggler] = useState(false);
  const toggleTermsModal = () => termsToggler(!showTermsModal);
  const togglePrivacyModal = () => privacyToggler(!showPrivacyModal);
  return (
    <Row className="align-items-center" noGutters>
      <Button variant="transparent" onClick={toggleTermsModal}>
        <span className={s.footerBtnLabel}>Terms of Service</span>
      </Button>
      <span className={s.divider} />
      <Button variant="transparent" onClick={togglePrivacyModal}>
        <span className={s.footerBtnLabel}>Privacy Policy</span>
      </Button>
      <DynamicModal
        title="Terms of Service"
        show={showTermsModal}
        size="lg"
        hideModal={toggleTermsModal}
      >
        <TermsAndConditions />
      </DynamicModal>
      <DynamicModal
        title="Privacy Policy"
        show={showPrivacyModal}
        size="lg"
        hideModal={togglePrivacyModal}
      >
        <PrivacyPolicy />
      </DynamicModal>
    </Row>
  );
};

export default Footer;
