import React, { useState } from 'react';
import DynamicModal from '../../DynamicModal';
import { Button, Form } from 'react-bootstrap';
import s from './style.scss';
import withAuthorization from '../../../auth/session/withAuthorization';
import axios from 'axios';
import { SEND_EMAIL_API_URL } from '../../../utils/constants';

const ReSealPactModal = ({
  toggleReSealPactModel,
  show,
  history: { push },
  backToDoc,
  documentInformation,
  documentStatus,
  confirmationValues,
  firebase,
  setLoading,
  setDocumentStatus,
  user,
}) => {
  return (
    <DynamicModal
      title="Invalidate SealPact"
      size="lg"
      show={show}
      hideModal={toggleReSealPactModel}
    >
      <div className={s.modalContent}>
        <p>Are you sure you want to Re-SealPact this document?</p>
      </div>

      <div className={s.footer}>
        <Button
          variant="secondary"
          onClick={() => {
            toggleReSealPactModel();
          }}
          className={s.buttonBack}
        >
          Cancel
        </Button>
        <Button
          className={s.buttonSubmit}
          variant="text"
          onClick={() => {
            toggleReSealPactModel();
            setLoading(true);

            firebase
              .getQrCodeScanDetails(
                documentInformation.id,
                user.uid,
                documentStatus,
              )
              .then((subs) => {
                console.log(`subs in resealpact ===> `, subs);

                documentInformation.status = 'pacted';
                setDocumentStatus('pacted');
                documentInformation.isInvalidated = false;
                confirmationValues.subscribers = subs;
                firebase
                  .reSealPact(user.uid, documentInformation, confirmationValues)
                  .then((invalidateRes) => {
                    console.log('invalidateRes ===>', invalidateRes);
                    //send email here
                    if (subs.length > 0) {
                      subs.forEach((subUser) => {
                        debugger;
                        if (subUser.email && subUser.status === true) {
                          axios
                            .post(SEND_EMAIL_API_URL, {
                              projectName: documentInformation.projectName,
                              projectAddress:
                                documentInformation.projectAddress,
                              projectNumber: documentInformation.projectNumber,
                              designProfessionalFirm:
                                documentInformation.firmName,
                              designProfessionalWebsite: documentInformation.website && documentInformation.website !== '' ? documentInformation.website : 'N/A',
                              designProfessional: documentInformation.name,
                              status: 'pacted',
                              invlidationReason:
                                confirmationValues.invalidationReason,
                              userEmail: subUser.email,
                              link:
                                window.location.origin +
                                '/unSubscribe?' +
                                user.uid +
                                '&' +
                                parseInt(documentInformation.id) +
                                '&' +
                                subUser.email,
                            })
                            .then(function (response) {
                              return;
                            })
                            .catch(function (error) {
                              console.log(error);
                              return;
                            });
                        }
                      });
                      setLoading(false);
                    } else {
                      setLoading(false);
                    }
                  })
                  .catch((error) => {
                    console.log('error ==> ', error);
                    return;
                  });
              })
              .catch((error) => {});
          }}
        >
          Confirm
        </Button>
      </div>
    </DynamicModal>
  );
};
const condition = (user) => !!user;
export default withAuthorization(condition)(ReSealPactModal);
