/* eslint-disable indent */
import React, { Fragment, useState, useRef, useEffect } from 'react';
import Header from '../../Header';
import {
  Button,
  Row,
  Col,
  Tab,
  Nav,
  Image,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import s from './style.scss';
import style from '../DocumentDetails/OnConfirmation/style.scss';
import BasicTabInfo from './BasicTabInfo';
import AdvancedTabInfo from './AdvancedTabInfo';
import PrintBtn from '../../../assets/print-btn.png';
import QualityTabInfo from './QualityTabInfo';
import ContractualTabInfo from './ContractualTabInfo';
import InvalidateSealModal from './InvalidateSealModal';
import ReSealPactModal from './ReSealPactModal';
import PrintComponents from 'react-print-components';
import { INVALIDATION_REASON } from '../../../utils/constants';
import CustomTooltip from '../../AppComponents/CustomTooltip';
import { view } from 'react-easy-state'

const SealPactDetailsScreen = ({
  location: {
    state: { confirmationValues, documentInformation },
  },
  ...rest
}) => {
  console.log('confirmationValues ===> ', confirmationValues);
  console.log('documentInformation ===> ', documentInformation);


  const [selectedTab, setselectedTab] = useState(1);
  const [invalidateConfirmaton, setInvalidateConfirmaton] = useState(false);
  const [reSealPactConfirmaton, setReSealPactConfirmaton] = useState(false);

  const handleSelect = (key) => {
    setselectedTab(+key);
  };
  const componentRef = useRef();
  const [invalidationReason, setInvalidationReason] = useState();

  const toggleReSealPactModel = () => {
    setReSealPactConfirmaton(!reSealPactConfirmaton);
  };

  const handleInvalidate = (eventKey) => {
    setInvalidationReason(eventKey);
    setInvalidateConfirmaton(!invalidateConfirmaton);
  };

  const getText = (heading, tooltipText) => {
    return (
      <div>
        <p>{heading}</p>
        <CustomTooltip
          trigger={'hover'}
          location={'auto'}
          content={tooltipText}
        >
          <i
            style={{
              marginLeft: '3px',
              marginBottom: '3px',
            }}
            className="fa fa-info-circle"
            aria-hidden="true"
          ></i>
        </CustomTooltip>
      </div>
    );
  };

  return (
    <Fragment>
      {/* <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      /> */}
      <div className={s.goBack}>
        <Button
          variant="text"
          onClick={() =>
            rest.history.push({
              pathname: '/documentDetails',
              state: {
                backToDoc: true,
                documentInformation: documentInformation,
                documentDetails: documentInformation.data,
                fileType: documentInformation.fileType,
                numPages: documentInformation.totalPages,
              },
            })
          }
        >
          <i className={s.left} />
          Document Details
        </Button>
      </div>
      <Header title="SealPact Validation Status" />
      <div className={s.topHeader}>
        <div>
          <span>SealPact ID:</span>
          <div className={s.sealId}>{confirmationValues.sealpactId}</div>
          <PrintComponents
            trigger={
              <Button variant="text" className={s.printBtn}>
                <Image src={PrintBtn} alt="print-btn" />
              </Button>
            }
          >
            <h3>Basic</h3>
            <span>SealPact ID:</span>
            <div className={s.sealId}>{confirmationValues.sealpactId}</div>
            <br />
            <BasicTabInfo
              documentInformation={documentInformation}
              confirmationValues={confirmationValues}
            />
            <div className={s.pageBreak}>
              <h3>Advanced</h3>
              <AdvancedTabInfo confirmationValues={confirmationValues} />
            </div>
            <div className={s.pageBreak}>
              <h3>Quality Control</h3>
              <QualityTabInfo confirmationValues={confirmationValues} />
            </div>
            <div className={s.pageBreak}>
              <h3>Contractual Info</h3>
              <ContractualTabInfo confirmationValues={confirmationValues} />
            </div>
          </PrintComponents>
        </div>
        <div />
        <div className={s.btnStyle}>
          <Button
            variant="outline-secondary"
            onClick={() =>
              rest.history.push({
                pathname: '/documentDetails',
                state: {
                  backToDoc: true,
                  documentInformation: documentInformation,
                  documentDetails: documentInformation.data,
                  fileType: documentInformation.fileType,
                  numPages: documentInformation.totalPages,
                },
              })
            }
          >
            View Document
          </Button>
          {/* {documentInformation.status === 'invalidated' && (
            <Button
              onClick={toggleReSealPactModel}
              className={s.btnReSealPact}
              variant="outline-transparent"
            >
              Re-SealPact
            </Button>
          )} */}

          {/* {documentInformation.status !== 'invalidated' && (
            <DropdownButton
              id={'dropdown-menu-align-right'}
              title={'Invalidate'}
              variant={'danger'}
              alignRight={true}
              onSelect={(eventKey, event) => {
                handleInvalidate(eventKey);
              }}
            >
              {INVALIDATION_REASON.map((item, i) => (
                <Dropdown.Item eventKey={i}>{item.value}</Dropdown.Item>
              ))}
            </DropdownButton>
          )} */}
        </div>
      </div>
      <div className={s.separator} />
      <div className={s.tabsContent} ref={componentRef}>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={1}
          activeKey={selectedTab}
          onSelect={(key) => handleSelect(key)}
        >
          <Row>
            <Col sm={12}>
              <Row className={`${style.tabRow} ${s.tabRow}`}>
                <Col sm={12}>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey={1}>
                        {getText(
                          '1-Basic',
                          'Items on this page were required by the user. This is public information and will be shown to anyone who has the document.',
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <div className={s.spaceDiv} />
                    <Nav.Item>
                      <Nav.Link eventKey={2}>
                        {getText(
                          '2-Advanced',
                          'Items on this page were voluntary by the user.  Here’s where more detailed scope items are identified.',
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <div className={s.spaceDiv} />
                    <Nav.Item>
                      <Nav.Link eventKey={3}>
                        {getText(
                          '3-Quality Control',
                          'Items on this page were voluntary by the user.  Here’s where quality control items, such as site visits, CA/SI, shop drawing requirements, etc. are displayed.',
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <div className={s.spaceDiv} />
                    <Nav.Item>
                      <Nav.Link eventKey={4}>
                        {getText(
                          '4-Contractual Info',
                          'Items on this page were voluntary by the user.  These items are stored for the user’s internal use only, and should only be shared with their client.',
                        )}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey={1}>
                  <BasicTabInfo
                    documentInformation={documentInformation}
                    confirmationValues={confirmationValues}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={2}>
                  <AdvancedTabInfo confirmationValues={confirmationValues} />
                </Tab.Pane>
                <Tab.Pane eventKey={3}>
                  <QualityTabInfo confirmationValues={confirmationValues} />
                </Tab.Pane>
                <Tab.Pane eventKey={4}>
                  <ContractualTabInfo confirmationValues={confirmationValues} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      <InvalidateSealModal
        handleInvalidate={handleInvalidate}
        show={invalidateConfirmaton}
        backToDoc={true}
        documentInformation={documentInformation}
        documentDetails={documentInformation.data}
        confirmationValues={confirmationValues}
        fileType={documentInformation.fileType}
        numPages={documentInformation.totalPages}
        invalidationReasonKey={invalidationReason}
        {...rest}
      />

      <ReSealPactModal
        toggleReSealPactModel={toggleReSealPactModel}
        show={reSealPactConfirmaton}
        backToDoc={true}
        documentStatus={documentInformation.status}
        documentInformation={documentInformation}
        confirmationValues={confirmationValues}
        {...rest}
      />
    </Fragment>
  );
};

export default view(SealPactDetailsScreen);
