/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import s from './style.scss';
import TagIcon from '../../../assets/Tag.svg';
import { store } from 'react-notifications-component';
import {
  NOTIFICATION_PROPS,
  NOTIFICATION_PROPS_LONGER,
} from '../../../utils/constants';

const PlanCard = ({
  plan,
  name,
  createSubscription,
  currentPlanId,
  loading,
  clickedPlanId,
  applyPromotionCode,
}) => {
  const [addPromotionCode, setAddPromotionCode] = useState(false),
    [promotionCode, setPromotionCode] = useState(''),
    [applyingCode, setApplyingCode] = useState(false);

  return (
    <Card className={s.planCard}>
      <Card.Title className={s.planTitle}>
        {currentPlanId && plan.id === currentPlanId && (
          <div className={s.currentPlan}>Current Plan</div>
        )}
        {name}
      </Card.Title>
      <Card.Body style={{ padding: '0 0 0 0' }}>
        <div className={s.planPriceDiv}>
          <div className={s.planPrice}>${plan.unit_amount / 100}</div>
          <div className={s.planInterval}>
            &nbsp; / {plan.recurring.interval}
          </div>
        </div>
        <div className={s.planBillingInterval}>
          Billed {plan.recurring.interval === 'year' ? 'Yearly' : 'Monthly'}
        </div>
        {plan.recurring.trial_period_days && (
          <div className={s.planDescription}>
            Free {plan.recurring.trial_period_days}{' '}
            {plan.recurring.trial_period_days > 1 ? 'Days' : 'Day'} Trial
          </div>
        )}
        <div className={s.planDescriptionTextGrey}>
          No credit card or coupon code required until after free trial expires
        </div>
        {!currentPlanId && (
          <div className={s.promotionCode} style={{ marginTop: '5px' }}>
            {addPromotionCode ? (
              <Form.Group controlId="promotionCode" style={{ marginBottom: 0 }}>
                <div
                  className={s.label}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setAddPromotionCode(false);
                    setPromotionCode('');
                  }}
                >
                  Promotion Code
                </div>
                <Form.Control
                  className={''}
                  type="text"
                  placeholder={'Enter promotion code'}
                  defaultValue={promotionCode}
                  maxLength={20}
                  onChange={(e) => setPromotionCode(e.target.value)}
                />
              </Form.Group>
            ) : (
              <div
                className={s.promotionCode}
                onClick={() => setAddPromotionCode(true)}
                style={{
                  cursor: 'pointer',
                  display: 'inline-flex',
                  width: 'max-content',
                  marginTop: '20px',
                }}
              >
                <img
                  src={TagIcon}
                  alt="TagIcon"
                  style={{ marginRight: '8px', marginTop: '-7px' }}
                />
                <div className={s.label}>Add Promotion Code</div>
              </div>
            )}
          </div>
        )}
        {currentPlanId && currentPlanId === plan.id && (
          <div
            className={s.promotionCode}
            style={{ marginTop: '5px', marginBottom: '5px' }}
          >
            {addPromotionCode ? (
              <Form.Group controlId="promotionCode" style={{ marginBottom: 0 }}>
                <div
                  className={s.label}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setAddPromotionCode(false);
                    setPromotionCode('');
                  }}
                >
                  Promotion Code
                </div>
                <InputGroup>
                  <Form.Control
                    className={''}
                    type="text"
                    placeholder={'Enter promotion code'}
                    defaultValue={promotionCode}
                    maxLength={20}
                    onChange={(e) => setPromotionCode(e.target.value)}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="primary"
                      className={s.promotionButton}
                      style={{ height: '58px', width: '75px' }}
                      onClick={async () => {
                        if (promotionCode !== '') {
                          setApplyingCode(true);
                          if (applyPromotionCode) {
                            await applyPromotionCode(promotionCode);
                          }
                          setApplyingCode(false);
                        } else {
                          store.addNotification({
                            title: 'Error!',
                            message: 'Please provide promotion code.',
                            type: 'danger',
                            ...NOTIFICATION_PROPS,
                          });
                        }
                      }}
                    >
                      {!applyingCode ? (
                        'Apply'
                      ) : (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            ) : (
              <div
                className={s.promotionCode}
                onClick={() => setAddPromotionCode(true)}
                style={{
                  cursor: 'pointer',
                  display: 'inline-flex',
                  width: 'max-content',
                  marginTop: '20px',
                }}
              >
                <img
                  src={TagIcon}
                  alt="TagIcon"
                  style={{ marginRight: '8px', marginTop: '-7px' }}
                />
                <div className={s.label}>Add Promotion Code</div>
              </div>
            )}
          </div>
        )}
      </Card.Body>
      <Card.Footer
        style={{
          padding: '0 0 0 0',
          background: 'white',
          width: '100%',
          border: 'none',
        }}
      >
        {!loading ? (
          <Button
            variant="primary"
            block
            onClick={() => createSubscription(plan, name, promotionCode)}
            // disabled={currentPlanId ? plan.id === currentPlanId : false}
          >
            {!currentPlanId ? 'Start Free Trial' : 'Update'}
          </Button>
        ) : clickedPlanId === plan.id ? (
          <Button variant="primary" block>
            <span className="spinner-border spinner-border-sm mr-1"></span>
            Loading
          </Button>
        ) : (
          <Button
            variant="primary"
            block
            // onClick={() => createSubscription(plan, name)}
            disabled={currentPlanId ? plan.id === currentPlanId : false}
          >
            {!currentPlanId ? 'Start Free Trial' : 'Update'}
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

export default PlanCard;
