/* eslint-disable indent */
import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import s from './style.scss';
import { Link } from 'react-router-dom';
const Cards = ({ item }) => {
  console.log('item ----> ', item);
  const PendingPrimary = {
    pathname: '/documentDetails',
    state: {
      documentDetails: item.sealPactDownloadUrl
        ? item.sealPactDownloadUrl
        : item.downloadUrl,
      backToDoc: false,
      numPages: item.totalPages,
      fileType: item.fileType,
      documentInformation: item,
      shared: true,
      isMyDocuments: false,
    },
  };
  const PendingViewer = {
    pathname: '/documentDetails',
    state: {
      documentDetails: item.downloadUrl,
      backToDoc: false,
      numPages: item.totalPages,
      fileType: item.fileType,
      documentInformation: item,
      viewer: true,
      shared: true,
      isMyDocuments: false,
    },
  };

  const pendingSecondary = {
    pathname: '/documentDetails',
    state: {
      documentDetails: item.sealPactDownloadUrl
        ? item.sealPactDownloadUrl
        : item.downloadUrl,
      documentSubmitted: true,
      backToDoc: false,
      numPages: item.totalPages,
      fileType: item.fileType,
      documentInformation: item,
      shared: true,
      isMyDocuments: false,
    },
  };
  const formatDate = date => {
    //console.log('date == = = = =  >', date ? date.toDate() : date);
    const dateObj = new Date(date);
    //console.log('dateObj =============>', dateObj);
    var year = dateObj.getFullYear();
    var month = (1 + dateObj.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = dateObj.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  };

  const dateToShow = item.dateUploaded ? formatDate(item.dateUploaded) : null;
  console.log('item ===> ', item);
  return (
    <Link
      to={
        item.sealPacterType === 'primary'
          ? PendingPrimary
          : item.sealPacterType === 'secondary'
          ? pendingSecondary
          : PendingViewer
      }
      className={s.pointer}
    >
      <Card text="black" className={s.cardStyle}>
        <div className={s.cardBodyTop}>
          <Row>
            <Col>
              <p className={s.docName}>{item.name}</p>
              <p>{item.projectName}</p>
            </Col>
          </Row>
          <Row style={{ marginTop: '16px', fontWeight: 300 }}>
            <Col>
              <p className={s.blueHeading}>Document ID:</p>
              <p>{item.id}</p>
            </Col>
            <Col>
              <p className={s.blueHeading}>Date Uploaded:</p>
              <p>{dateToShow}</p>
            </Col>
          </Row>
        </div>
      </Card>
    </Link>
  );
};

export default Cards;
