/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Form,
  Button,
  Modal,
  Tab,
  Row,
  Col,
  Nav,
  Image,
} from 'react-bootstrap';
import BasicTab from './BasicTab';
import AdvancedTab from './AdvancedTab';
import ButtonClose from '../../../../assets/button-close1.png';
import s from './style.scss';
import QualityControl from './QualityControlTab';
import ContractualInfo from './ContractualInfoTab';
import { view } from 'react-easy-state';

const ReviewSealPact = ({
  show,
  hideModal,
  hidePopup,
  documentInformation,
  confirmationValues,
  selectedSealInfo,
  onSubmitCall,
  reviewCall,
}) => {
  let values = {};
  const [selectedTab, setselectedTab] = useState(1);

  const handleFlow = (direction) => {
    if (direction === 'Next') {
      if (selectedTab < 4) setselectedTab(selectedTab + 1);
    } else if (direction === 'Back') {
      if (selectedTab > 1) setselectedTab(selectedTab - 1);
    }
  };
  const handleCancel = () => {
    hideModal();
    // hidePopup();
  };

  const handleSelect = (key) => setselectedTab(+key);

  const validation = (e) => {
    e.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    hideModal();
    onSubmitCall();
  };

  return (
    <Modal
      show={show}
      onHide={handleCancel}
      centered
      size="lg"
      backdrop
      dialogClassName="modal-90w"
    >
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Container>
            <h1 className={s.modalHeading}>Document Details</h1>
            <div className={s.wrapper}>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={1}
                activeKey={selectedTab}
                onSelect={(key) => handleSelect(key)}
              >
                <div>
                  <Row>
                    <Col sm={12}>
                      <Row className={s.tabRow}>
                        <Col sm={12}>
                          <p>Review</p>

                          {documentInformation.sealPacterType === 'seal' ? (
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link eventKey={1}>1-Basic</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={2}>2-Advanced</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={3}>
                                  3-Quality Control
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={4}>
                                  4-Contractual Info
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          ) : documentInformation.sealPacterType ===
                            'signature' ? (
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link eventKey={1}>1-Basic</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={2}>2-Advanced</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          ) : (
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link eventKey={1}>1-Basic</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          )}

                          <Button variant="text" onClick={hideModal}>
                            <Image height={40} src={ButtonClose} alt="close" />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey={1}>
                        <BasicTab
                          selectedSealInfo={selectedSealInfo}
                          confirmationValues={confirmationValues}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey={2}>
                        <AdvancedTab confirmationValues={confirmationValues} />
                      </Tab.Pane>
                      <Tab.Pane eventKey={3}>
                        <QualityControl
                          confirmationValues={confirmationValues}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey={4}>
                        <ContractualInfo
                          confirmationValues={confirmationValues}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
            <div className={s.flexBtw}>
              <Button
                variant="outline-secondary"
                onClick={
                  selectedTab === 1 ? reviewCall : () => handleFlow('Back')
                }
                className={s.btnBorder}
              >
                {selectedTab > 1 ? 'Back' : 'Review'}
              </Button>
              <div className={s.footerBtn}>
                {selectedTab !== 4 &&
                  documentInformation.sealPacterType === 'seal' && (
                    <Button
                      variant="text"
                      onClick={() => handleFlow('Next')}
                      className={s.nextBtn}
                      // eslint-disable-next-line indent
                    >
                      Next
                    </Button>
                  )}

                <Button type="submit" variant="primary">
                  Confirm
                </Button>
              </div>
            </div>
          </Container>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default view(ReviewSealPact);
