import React, { Fragment, useState, useEffect } from 'react';
import { Image, Row, Button } from 'react-bootstrap';
import s from './style.scss';
import EditProfile from './EditProfile';
const avatar = require('../../../assets/avatar.png');
const UserCard = ({ user, show, reloadUser, handleModal }) => {
  const [imageUrl, setImageUrl] = useState([]);
  const [userFirstName, setUserFirstName] = useState([]);
  const [userMiddleName, setUserMiddleName] = useState([]);
  const [userLastName, setUserLastName] = useState([]);

  useEffect(() => {
    setImageUrl(user.image);
    setUserFirstName(user.firstName);
    setUserMiddleName(user.middleName);
    setUserLastName(user.lastName);
  }, [user]);

  const updateProfilePicture = (imageData, firstName, lastName, middleName) => {
    //reloadUser();
    setImageUrl(imageData);
    setUserFirstName(firstName);
    setUserLastName(lastName);
    setUserMiddleName(middleName);
    //window.location.reload();
  };

  return (
    <Fragment>
      <Row className={`justify-content-between ${s.avatarColumn}`}>
        <span className={s.grayLabel}>Profile</span>
        <Button variant="transparent" onClick={handleModal} className="p-0">
          Edit
        </Button>
      </Row>
      <EditProfile
        show={show}
        handleModal={handleModal}
        user={user}
        reloadUser={reloadUser}
        updateProfilePicture={updateProfilePicture}
      />
      <Row
        className={`d-flex flex-column align-items-center justify-content-center ${s.thumbContainer}`}
      >
        <Image src={imageUrl || avatar} rounded width="20%" />
        <h2 className={s.userName}>
          {userFirstName + ' ' + userMiddleName + ' ' + userLastName}
        </h2>
        <span className={s.profession}>{user.profession}</span>
      </Row>
    </Fragment>
  );
};

export default UserCard;
