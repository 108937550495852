import React, { useEffect, useState } from 'react';
import DynamicModal from '../../../DynamicModal';
import { Row, Container, Button, Col, Form, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import s from '../style.scss';
import '../../DocumentDetails/OnConfirmation/QualityControlTab/multiselect.css';
import { withFirebase } from '../../../../auth/firebase';
import style from '../../DocumentDetails/OnConfirmation/style.scss';
import { Multiselect } from 'multiselect-react-dropdown';
import { NOTIFICATION_PROPS, SHOP_DESIGNS_VALUES } from '../../../../utils/constants';
import { FilePicker } from 'react-file-picker';
import { store } from 'react-notifications-component';
import { view } from 'react-easy-state'
import { appEasyState } from '../../../../easyStateStore';

const EditDetails = ({ show, hideModal, confirmationValues, firebase }) => {

  const [uploadingForm, setUploadingForm] = useState(false);

  const [qualityNeeded, setQualityNeeded] = useState(
    confirmationValues ? Boolean(confirmationValues.qualityNeeded) : false,
  );
  const [qualityNeededWithDefault, setQualityNeededWithDefault] = useState(confirmationValues.qualityNeededWithDefault
    ? confirmationValues.qualityNeededWithDefault
    : 'N/A');
  const [independentReview, setIndependentReview] = useState(
    confirmationValues ? Boolean(confirmationValues.independentReview) : false,
  );
  const [independentReviewWithDefault, setIndependentReviewWithDefault] = useState(confirmationValues.independentReviewWithDefault ?
    confirmationValues.independentReviewWithDefault
    : 'N/A');
  const [shopDrawings, setShopDrawings] = useState(
    confirmationValues ? confirmationValues.shopDrawings : false,
  );
  const [shopDrawingsWithDefault, setShopDrawingsWithDefault] = useState(confirmationValues.shopDrawingsWithDefault
    ? confirmationValues.shopDrawingsWithDefault
    :'N/A');
  const [otherShopDrawings, setOtherShopDrawings] = useState(
    confirmationValues ? Boolean(confirmationValues.otherShopDrawings) : false,
  );
  const [constructionAdministration, setConstructionAdministration] = useState(
    confirmationValues
      ? Boolean(confirmationValues.constructionAdministration)
      : false,
  );
  const [constructionAdministrationWithDefault, setConstructionAdministrationWithDefault] = useState(confirmationValues.constructionAdministrationWithDefault
    ? confirmationValues.constructionAdministrationWithDefault
    : 'N/A');
  const [specialInspection, setSpecialInspection] = useState(
    confirmationValues ? Boolean(confirmationValues.specialInspection) : false,
  );
  const [specialInspectionWithDefault, setSpecialInspectionWithDefault] = useState(confirmationValues.specialInspectionWithDefault
    ? confirmationValues.specialInspectionWithDefault
    : 'N/A');
  const [SIForm, setSIForm] = useState();
  const [SIFormType, setSIFormType] = useState();
  const [selectionLimit, setSelectionLimit] = useState(-1);

  const [shopDrawingsOtherValue, setShopDrawingsOtherValue] = useState(confirmationValues.shopDrawingsOtherValue
    ? confirmationValues.shopDrawingsOtherValue
    : '')

  const [qualityControlTabsData, setQualityControlTabsData] = useState({
    qualityNeeded: confirmationValues
      ? Boolean(confirmationValues.qualityNeeded)
      : false,
    qualityNeededWithDefault: confirmationValues.qualityNeededWithDefault
      ? confirmationValues.qualityNeededWithDefault
      : 'N/A',
    independentReview: confirmationValues
      ? Boolean(confirmationValues.independentReview)
      : false,
    independentReviewWithDefault: confirmationValues.independentReviewWithDefault
      ? confirmationValues.independentReviewWithDefault
      : 'N/A',
    shopDrawings: confirmationValues
      ? Boolean(confirmationValues.shopDrawings)
      : false,
    shopDrawingsWithDefault: confirmationValues.shopDrawingsWithDefault
      ? confirmationValues.shopDrawingsWithDefault
      : 'N/A',
    otherShopDrawings: confirmationValues
      ? Boolean(confirmationValues.otherShopDrawings)
      : false,
    constructionAdministration: confirmationValues
      ? Boolean(confirmationValues.constructionAdministration)
      : false,
    constructionAdministrationWithDefault: confirmationValues.constructionAdministrationWithDefault
      ? confirmationValues.constructionAdministrationWithDefault
      : 'N/A',
    specialInspection: confirmationValues
      ? Boolean(confirmationValues.specialInspection)
      : false,
    specialInspectionWithDefault: confirmationValues.specialInspectionWithDefault
      ? confirmationValues.specialInspectionWithDefault
      : 'N/A',
    shopDrawingsValue: confirmationValues
      ? Boolean(confirmationValues.shopDrawingsValue)
      : false,
    shopDrawingsValueWithDefault: confirmationValues.shopDrawingsValueWithDefault
      ? confirmationValues.shopDrawingsValueWithDefault
      : 'N/A',
    shopDrawingsOtherValue: shopDrawingsOtherValue,
    specialInspectionForm: confirmationValues.specialInspectionForm
      ? confirmationValues.specialInspectionForm : null,
  });

  const [siteVisits, setSiteVisits] = useState(confirmationValues.siteVisits || null)

  let selectedValues = confirmationValues.shopDrawingsValue
    ? typeof confirmationValues.shopDrawingsValue === 'string' && confirmationValues.shopDrawingsValue.length !== 0
      ? confirmationValues.shopDrawingsValue.split(',')
      : []
    : [];

  useEffect(() => {
    appEasyState.confirmationValues = confirmationValues
    console.log('edit appEasyState.confirmationValues', appEasyState.confirmationValues)
  }, [])

  useEffect(() => {
    if (show) {
      console.log('appEasyState.user', appEasyState.user)
      appEasyState.confirmationValues = confirmationValues
      setQualityNeeded(
        confirmationValues ? Boolean(confirmationValues.qualityNeeded) : false,
      );
      setQualityNeededWithDefault(confirmationValues.qualityNeededWithDefault
        ? confirmationValues.qualityNeededWithDefault
        : 'N/A');
      setIndependentReview(
        confirmationValues ? Boolean(confirmationValues.independentReview) : false,
      );
      setIndependentReviewWithDefault(confirmationValues.independentReviewWithDefault ?
        confirmationValues.independentReviewWithDefault
        : 'N/A');
      setShopDrawings(
        confirmationValues ? confirmationValues.shopDrawings : false,
      );
      setShopDrawingsWithDefault(confirmationValues.shopDrawingsWithDefault
        ? confirmationValues.shopDrawingsWithDefault
        :'N/A');
      setOtherShopDrawings(
        confirmationValues ? Boolean(confirmationValues.otherShopDrawings) : false,
      );
      setConstructionAdministration(
        confirmationValues
          ? Boolean(confirmationValues.constructionAdministration)
          : false,
      );
      setConstructionAdministrationWithDefault(confirmationValues.constructionAdministrationWithDefault
        ? confirmationValues.constructionAdministrationWithDefault
        : 'N/A');
      setSpecialInspection(
        confirmationValues ? Boolean(confirmationValues.specialInspection) : false,
      );
      setSpecialInspectionWithDefault(confirmationValues.specialInspectionWithDefault
        ? confirmationValues.specialInspectionWithDefault
        : 'N/A');
      setShopDrawingsOtherValue(confirmationValues.shopDrawingsOtherValue
        ? confirmationValues.shopDrawingsOtherValue
        : '')

      setQualityControlTabsData({
        qualityNeeded: confirmationValues
          ? Boolean(confirmationValues.qualityNeeded)
          : false,
        qualityNeededWithDefault: confirmationValues.qualityNeededWithDefault
          ? confirmationValues.qualityNeededWithDefault
          : 'N/A',
        independentReview: confirmationValues
          ? Boolean(confirmationValues.independentReview)
          : false,
        independentReviewWithDefault: confirmationValues.independentReviewWithDefault
          ? confirmationValues.independentReviewWithDefault
          : 'N/A',
        shopDrawings: confirmationValues
          ? Boolean(confirmationValues.shopDrawings)
          : false,
        shopDrawingsWithDefault: confirmationValues.shopDrawingsWithDefault
          ? confirmationValues.shopDrawingsWithDefault
          : 'N/A',
        otherShopDrawings: confirmationValues
          ? Boolean(confirmationValues.otherShopDrawings)
          : false,
        constructionAdministration: confirmationValues
          ? Boolean(confirmationValues.constructionAdministration)
          : false,
        constructionAdministrationWithDefault: confirmationValues.constructionAdministrationWithDefault
          ? confirmationValues.constructionAdministrationWithDefault
          : 'N/A',
        specialInspection: confirmationValues
          ? Boolean(confirmationValues.specialInspection)
          : false,
        specialInspectionWithDefault: confirmationValues.specialInspectionWithDefault
          ? confirmationValues.specialInspectionWithDefault
          : 'N/A',
        shopDrawingsValue: confirmationValues
          ? Boolean(confirmationValues.shopDrawingsValue)
          : false,
        shopDrawingsValueWithDefault: confirmationValues.shopDrawingsValueWithDefault
          ? confirmationValues.shopDrawingsValueWithDefault
          : 'N/A',
        shopDrawingsOtherValue: shopDrawingsOtherValue,
        specialInspectionForm: confirmationValues.specialInspectionForm
          ? confirmationValues.specialInspectionForm : null,
      });

      setSiteVisits(confirmationValues.siteVisits || null)

      selectedValues = confirmationValues.shopDrawingsValue
        ? typeof confirmationValues.shopDrawingsValue === 'string' && confirmationValues.shopDrawingsValue.length !== 0
          ? confirmationValues.shopDrawingsValue.split(',')
          : []
        : [];
    }
  }, [show])

  // const handleOtheShopDrawings = (value) => {
  //   if (value === 'Other') {
  //     setOtherShopDrawings(true);
  //     qualityControlTabsData.otherShopDrawings = true;
  //     setQualityControlTabsData(qualityControlTabsData);
  //   } else {
  //     setOtherShopDrawings(false);
  //     qualityControlTabsData.otherShopDrawings = false;
  //     qualityControlTabsData.shopDrawingsValue = value;
  //     setQualityControlTabsData(qualityControlTabsData);
  //   }
  //   getQualityControlTabItems(qualityControlTabsData);
  // };

  const handleOtherShopDrawings = (value) => {
    qualityControlTabsData.shopDrawingsOtherValue = value;
    setShopDrawingsOtherValue(value)
    setQualityControlTabsData(qualityControlTabsData);
    // getQualityControlTabItems(qualityControlTabsData);
  };

  // const handleConstructionAdministration = (e) => {
  //   qualityControlTabsData.constructionAdministrationValue = e.target.value;
  //   getQualityControlTabItems(qualityControlTabsData);
  // };

  const onSelect = (selectedList, selectedItem) => {
    console.log('selectedList ===> ', selectedList);
    if (selectedItem === 'Other (add your own)') {
      setOtherShopDrawings(true);
      qualityControlTabsData.otherShopDrawings = true;
      // setQualityControlTabsData(qualityControlTabsData);
      // getQualityControlTabItems(qualityControlTabsData);
      setSelectionLimit(selectedList.length);
    }
    // else {
    //   qualityControlTabsData.shopDrawingsValue = selectedList.toString();
    //   setQualityControlTabsData(qualityControlTabsData);
    //   getQualityControlTabItems(qualityControlTabsData);
    // }

    qualityControlTabsData.shopDrawingsValue = selectedList.toString();
    setQualityControlTabsData(qualityControlTabsData);
    // getQualityControlTabItems(qualityControlTabsData);

    selectedValues = selectedList;
  };


  const onRemove = (selectedList, removedItem) => {
    if (removedItem === 'Other (add your own)') {
      setSelectionLimit(-1);
      qualityControlTabsData.shopDrawingsOtherValue = '';
      qualityControlTabsData.otherShopDrawings = false;
      setOtherShopDrawings(false);
    } else if (selectedList.indexOf('Other (add your own)') > -1) {
      setSelectionLimit(selectedList.length);
    }
    // setSelectionLimit(removedItem !== 'Other' ? selectedList.length : -1);
    selectedValues = selectedList;
    qualityControlTabsData.shopDrawingsValue = selectedList.toString();
    setQualityControlTabsData(qualityControlTabsData);
    // getQualityControlTabItems(qualityControlTabsData);
  };

  const uploadFormToFirebase = async (fileObject, formType) => {
    console.log('appEasyState.documentInformation', appEasyState.documentInformation)
    setUploadingForm(true);
    await firebase
      .uploadForm(appEasyState.documentInformation, fileObject, formType, appEasyState.user.uid)
      .then((res) => {
        setUploadingForm(false);
        console.log('res ===> ', res);
        console.log('Uploaded');
        qualityControlTabsData.specialInspectionForm = res;
      })
      .catch((error) => {
        setUploadingForm(false);
        console.log('error ===> ', error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('editDetails confirmationValues: doc', appEasyState.documentInformation)
    console.log('editDetails confirmationValues: user', appEasyState.user)
    console.log('editDetails confirmationValues: appState', appEasyState.confirmationValues)

    appEasyState.confirmationValues.qualityNeeded = qualityControlTabsData.qualityNeeded
    appEasyState.confirmationValues.qualityNeededWithDefault = qualityControlTabsData.qualityNeededWithDefault
    appEasyState.confirmationValues.independentReview = qualityControlTabsData.independentReview
    appEasyState.confirmationValues.independentReviewWithDefault = qualityControlTabsData.independentReviewWithDefault
    appEasyState.confirmationValues.shopDrawings = qualityControlTabsData.shopDrawings
    appEasyState.confirmationValues.shopDrawingsWithDefault = qualityControlTabsData.shopDrawingsWithDefault
    appEasyState.confirmationValues.otherShopDrawings = qualityControlTabsData.otherShopDrawings
    appEasyState.confirmationValues.constructionAdministration = qualityControlTabsData.constructionAdministration
    appEasyState.confirmationValues.constructionAdministrationWithDefault = qualityControlTabsData.constructionAdministrationWithDefault
    appEasyState.confirmationValues.specialInspection = qualityControlTabsData.specialInspection
    appEasyState.confirmationValues.specialInspectionWithDefault = qualityControlTabsData.specialInspectionWithDefault
    appEasyState.confirmationValues.shopDrawingsOtherValue = qualityControlTabsData.shopDrawingsOtherValue
    appEasyState.confirmationValues.shopDrawingsValue = qualityControlTabsData.shopDrawingsValue
    appEasyState.confirmationValues.shopDrawingsValueWithDefault = qualityControlTabsData.shopDrawingsValueWithDefault
    appEasyState.confirmationValues.specialInspectionForm = qualityControlTabsData.specialInspectionForm
    appEasyState.confirmationValues.documentDetailsLastModified = new Date().getTime()
    appEasyState.confirmationValues.siteVisits = siteVisits


    firebase.updateDocumentDetailsInSealPactedDocument(
      appEasyState.user.uid,
      appEasyState.documentInformation.id,
      appEasyState.confirmationValues
    ).then(res => {
      console.log('func res: ', res)
      store.addNotification({
        title: 'Edited Successfully!',
        message: 'Document details edited Successfully.',
        type: 'default',
        ...NOTIFICATION_PROPS,
      });
    }).catch((error) => {
      store.addNotification({
        title: 'Error',
        message: 'Error editing document details',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
      console.log('error', error);
    });

    hideModal();
  }

  return (
    <DynamicModal title="Edit Details" show={show} hideModal={hideModal}>
      <Form onSubmit={onSubmit}>
        <Container>
          <div className={s.editContainer}>
            <Row>
              <Col lg={4}>
                <Form.Label>
                  Did the client and/or owner include quality control with this
                  project?
                </Form.Label>
                <ToggleButtonGroup
                  type="checkbox"
                  value={qualityControlTabsData.qualityNeeded
                    ? qualityControlTabsData.qualityNeeded === true ? 'Yes' : 'No'
                    : 'N/A'}
                  onChange={(e) => {
                    // setQualityNeeded(!!e[1]);
                    // qualityControlTabsData.qualityNeeded = Boolean(!!e[1]);
                    if (e[1] === 'Yes') {
                      setQualityNeeded(true);
                      qualityControlTabsData.qualityNeeded = true;
                    }
                    else {
                      setQualityNeeded(false);
                      qualityControlTabsData.qualityNeeded = false;

                      setIndependentReview(false);
                      qualityControlTabsData.independentReview = false;
                      setIndependentReviewWithDefault('N/A');
                      qualityControlTabsData.independentReviewWithDefault = 'N/A';

                      setShopDrawings(false);
                      qualityControlTabsData.shopDrawings = false;
                      setShopDrawingsWithDefault('N/A');
                      qualityControlTabsData.shopDrawingsWithDefault = 'N/A';
                      handleOtherShopDrawings(null);

                      setConstructionAdministration(false);
                      qualityControlTabsData.constructionAdministration = false;
                      setConstructionAdministrationWithDefault('N/A');
                      qualityControlTabsData.constructionAdministrationWithDefault = 'N/A';

                      setSpecialInspection(false);
                      qualityControlTabsData.specialInspection = false;
                      setSpecialInspectionWithDefault('N/A');
                      qualityControlTabsData.specialInspectionWithDefault = 'N/A';

                      setQualityControlTabsData(qualityControlTabsData);
                      // getQualityControlTabItems(qualityControlTabsData);
                    }
                    setQualityNeededWithDefault(e[1]);
                    qualityControlTabsData.qualityNeededWithDefault = e[1];
                    setQualityControlTabsData(qualityControlTabsData);
                    // getQualityControlTabItems(qualityControlTabsData);
                  }}
                  className={style.inlineBtn}
                >
                  <ToggleButton
                    value={'Yes'}
                    className={qualityNeededWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    value={'No'}
                    className={qualityNeededWithDefault === 'No' ? s.bgBlue : s.bgWhite}
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    value={'N/A'}
                    className={qualityNeededWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
                  >
                    N/A
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>

              <Col lg={4}>
                <div className={qualityNeeded ? s.displayBlock : s.displayNone}>
                  <Form.Label>Independent Review Required?</Form.Label>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={qualityControlTabsData.independentReview
                      ? qualityControlTabsData.independentReview === true ? 'Yes' : 'No'
                      : 'N/A'}
                    onChange={(e) => {
                      // setIndependentReview(!!e[1]);
                      // qualityControlTabsData.independentReview = Boolean(!!e[1]);
                      if (e[1] === 'Yes') {
                        setIndependentReview(true);
                        qualityControlTabsData.independentReview = true;
                      }
                      else {
                        setIndependentReview(false);
                        qualityControlTabsData.independentReview = false;
                      }
                      setIndependentReviewWithDefault(e[1]);
                      qualityControlTabsData.independentReviewWithDefault = e[1];
                      setQualityControlTabsData(qualityControlTabsData);
                      // getQualityControlTabItems(qualityControlTabsData);

                    }}
                    className={style.inlineBtn}
                  >
                    <ToggleButton
                      value={'Yes'}
                      className={independentReviewWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      value={'No'}
                      className={independentReviewWithDefault === 'No' ? s.bgBlue : s.bgWhite}
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value={'N/A'}
                      className={independentReviewWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
                    >
                      N/A
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Form.Label>
                    Are Shop Drawings Required? (stamped delegated/component designs?)
                  </Form.Label>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={qualityControlTabsData.shopDrawings
                      ? qualityControlTabsData.shopDrawings === true ? 'Yes' : 'No'
                      : 'N/A'}
                    onChange={(e) => {
                      // setShopDrawings(!!e[1]);
                      // qualityControlTabsData.shopDrawings = Boolean(!!e[1]);
                      if (e[1] === 'Yes') {
                        setShopDrawings(true);
                        qualityControlTabsData.shopDrawings = true;
                      }
                      else {
                        setShopDrawings(false);
                        qualityControlTabsData.shopDrawings = false;
                      }
                      setShopDrawingsWithDefault(e[1]);
                      qualityControlTabsData.shopDrawingsWithDefault = e[1];
                      setQualityControlTabsData(qualityControlTabsData);
                      // getQualityControlTabItems(qualityControlTabsData);
                    }}
                    className={style.inlineBtn}
                  >
                    <ToggleButton
                      value={'Yes'}
                      className={shopDrawingsWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      value={'No'}
                      className={shopDrawingsWithDefault === 'No' ? s.bgBlue : s.bgWhite}
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value={'N/A'}
                      className={shopDrawingsWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
                    >
                      N/A
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {shopDrawings && (
                    <Form.Group controlId="shopDrawingsValue">
                      <Form.Label>If so, which?</Form.Label>
                      <Multiselect
                        options={SHOP_DESIGNS_VALUES} // Options to display in the dropdown
                        selectedValues={selectedValues} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="label" // Property name to display in the dropdown options
                        closeOnSelect={false}
                        isObject={false}
                        selectionLimit={selectionLimit}
                      />
                    </Form.Group>
                  )}
                  {otherShopDrawings && shopDrawings && (
                    <Form.Group controlId="otherShopDrawing">
                      <Form.Label>Enter Other</Form.Label>
                      <Form.Control type="text" value={shopDrawingsOtherValue} onChange={(e) => handleOtherShopDrawings(e.target.value)} />
                    </Form.Group>
                  )}
                  <Form.Label>Construction Administration (CA)</Form.Label>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={qualityControlTabsData.constructionAdministration
                      ? qualityControlTabsData.constructionAdministration === true ? 'Yes' : 'No'
                      : 'N/A'}
                    onChange={(e) => {
                      // setConstructionAdministration(!!e[1]);
                      // qualityControlTabsData.constructionAdministration = Boolean(
                      //   !!e[1],
                      // );
                      if (e[1] === 'Yes') {
                        setConstructionAdministration(true);
                        qualityControlTabsData.constructionAdministration = true;
                      }
                      else {
                        setConstructionAdministration(false);
                        qualityControlTabsData.constructionAdministration = false;
                      }
                      setConstructionAdministrationWithDefault(e[1]);
                      qualityControlTabsData.constructionAdministrationWithDefault = e[1];
                      setQualityControlTabsData(qualityControlTabsData);
                      // getQualityControlTabItems(qualityControlTabsData);
                    }}
                    className={style.inlineBtn}
                  >
                    <ToggleButton
                      value={'Yes'}
                      className={constructionAdministrationWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      value={'No'}
                      className={constructionAdministrationWithDefault === 'No' ? s.bgBlue : s.bgWhite}
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value={'N/A'}
                      className={constructionAdministrationWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
                    >
                      N/A
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {constructionAdministration && (
                    <Form.Group controlId="siteVisits">
                      <Form.Label>Site Visits</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Input site visits"
                        value={siteVisits}
                        onChange={(e) => {
                          // handleConstructionAdministration(e.target.value);
                          setSiteVisits(e.target.value)
                          // getQualityControlTabItems(qualityControlTabsData);
                        }}
                      />
                    </Form.Group>
                  )}
                </div>
              </Col>
              <Col lg={4}>
                <div className={qualityNeeded ? s.displayBlock : s.displayNone}>
                  <Form.Label>Special Inspections (SI)</Form.Label>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={qualityControlTabsData.specialInspection
                      ? qualityControlTabsData.specialInspection === true ? 'Yes' : 'No'
                      : 'N/A'}
                    onChange={(e) => {
                      // setSpecialInspection(!!e[1]);
                      // qualityControlTabsData.specialInspection = Boolean(!!e[1]);
                      if (e[1] === 'Yes') {
                        setSpecialInspection(true);
                        qualityControlTabsData.specialInspection = true;
                      }
                      else {
                        setSpecialInspection(false);
                        qualityControlTabsData.specialInspection = false;
                      }
                      setSpecialInspectionWithDefault(e[1]);
                      qualityControlTabsData.specialInspectionWithDefault = e[1];
                      setQualityControlTabsData(qualityControlTabsData);
                      // getQualityControlTabItems(qualityControlTabsData);
                    }}
                    className={style.inlineBtn}
                  >
                    <ToggleButton
                      value={'Yes'}
                      className={specialInspectionWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      value={'No'}
                      className={specialInspectionWithDefault === 'No' ? s.bgBlue : s.bgWhite}
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value={'N/A'}
                      className={specialInspectionWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
                    >
                      N/A
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {specialInspection && (
                    <>
                      <Form.Label>Attach SI Form</Form.Label>
                      {specialInspectionWithDefault === 'Yes' && (
                        <div>
                          {SIForm ? (<span>{SIForm.name}</span>) : (<span>{confirmationValues.SIFormName}</span>)}
                        </div>
                      )}
                      <FilePicker
                        extensions={['pdf']}
                        dims={{
                          minWidth: 100,
                          maxWidth: 50000,
                          minHeight: 100,
                          maxHeight: 50000,
                        }}
                        maxSize={5}
                        onChange={async (FileObject) => {
                          // getQualityControlTabItems(qualityControlTabsData);
                          await uploadFormToFirebase(FileObject, 'siform');

                          setSIForm(FileObject);
                          setSIFormType(FileObject.type);

                          setQualityControlTabsData(qualityControlTabsData);
                          console.log('FileObject ===> ', FileObject);
                        }}
                        onError={(errMsg) =>
                          store.addNotification({
                            title: 'Error',
                            message: errMsg,
                            type: 'danger',
                            ...NOTIFICATION_PROPS,
                          })
                        }
                      >
                        <Button variant="secondary" block>
                          Upload
                        </Button>
                      </FilePicker>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="pt-4 mt-4">
              <Col
                xs={12}
                className={`d-flex justify-content-between ${s.btnDecision}`}
              >
                <Button variant="outline-dark" onClick={hideModal}>
                  Cancel
                </Button>
                {uploadingForm ? (
                  <button className="btn btn-primary">
                    <span className="spinner-border spinner-border-sm"></span>
                    Uploading..
                  </button>
                ) : (
                  <Button variant="primary" type="submit">
                    Confirm
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </Form>
    </DynamicModal>
  )
}

export default withFirebase(view(EditDetails));