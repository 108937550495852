import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import s from '../style.scss';
import CustomTooltip from '../../../../AppComponents/CustomTooltip';

const BasicTab = ({
  documentInformation,
  selectedSealInfo,
  getBasicTabValue,
  setBasicTabPages,
  basicTabValues,
  confirmationValues,
}) => {
  console.log('basicTabValuestest ==> ', basicTabValues);
  const [disableControl, setDisableControl] = useState(false);
  const [pagesFrom, setPagesFrom] = useState(1);
  const [pagesTo, setPagesTo] = useState(1);
  const [name, setName] = useState();
  const [licenseNo, setLicenseNo] = useState();
  const [firmName, setFirmName] = useState();
  const [website, setWebsite] = useState();
  const [projectName, setProjectName] = useState();
  const [projectNumber, setProjectNumber] = useState();
  const [projectAddress, setProjectAddress] = useState();
  const [date, setdate] = useState(Date.now());
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isAllPages, setIsAllPages] = useState(false);
  const [isOtherPages, setOtherPages] = useState(false);

  useEffect(() => {
    console.log('tabvalue documentInformation', documentInformation)
    setPagesFrom(confirmationValues.pageFrom || basicTabValues.pageFrom);
    setPagesTo(confirmationValues.pageTo || basicTabValues.pageTo);
    // if (basicTabValues.pageTo === documentInformation.totalPages) {
    //   setIsFirstPage(false);
    //   setIsAllPages(true);
    // }

    if (
      basicTabValues.pageFrom === 1 &&
      basicTabValues.pageTo === documentInformation.totalPages &&
      basicTabValues.pageTo !== 1
    ) {
      setDisableControl(true);
      setIsAllPages(true);
    }

    if (
      (basicTabValues.pageFrom === 1 &&
        basicTabValues.pageTo === documentInformation.totalPages) ||
      (basicTabValues.pageFrom === 1 && basicTabValues.pageTo === 1)
    ) {
      setDisableControl(true);
      setIsFirstPage(true);
    }

    setName(selectedSealInfo.sealName || selectedSealInfo.signatureName);
    setLicenseNo(confirmationValues.licenseNo || documentInformation.selectedSealInfo.newLicenseNo);
    setFirmName(confirmationValues.firmName || documentInformation.firmName);
    setWebsite(confirmationValues.website || documentInformation.website || '');
    setProjectName(confirmationValues.projectName || documentInformation.projectName);
    setProjectNumber(confirmationValues.projectNumber || documentInformation.projectNumber);
    setProjectAddress(confirmationValues.projectAddress || documentInformation.projectAddress);
    getBasicTabValue('dateOfIssuance', formatDate(date));
  }, []);

  const formatDate = (date) => {
    const dateObj = new Date(date);
    var year = dateObj.getFullYear();
    var month = (1 + dateObj.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = dateObj.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return month + '/' + day + '/' + year;
  };

  const handleChange = (date) => {
    setdate(date);
    getBasicTabValue('dateOfIssuance', formatDate(date));
  };

  const handleClick = (e) => {
    console.log(`e.target.checked ==> `, e.target.checked);
    setIsFirstPage(e.target.checked);
    setDisableControl(e.target.checked);
    if (e.target.checked) {
      // getBasicTabValue('pageFrom', 1);
      setIsAllPages(false);
      setOtherPages(false);
      setPagesFrom(1);
      setPagesTo(1);
      setBasicTabPages(1, 1);

      // getBasicTabValue('pageTo', 1);
    }
  };

  const handleAllDocumentClick = (e) => {
    setDisableControl(e.target.checked);
    setIsAllPages(e.target.checked);
    console.log(`e.target.checked ===> `, e.target.checked);
    if (e.target.checked) {
      // getBasicTabValue('pageFrom', 1);
      setIsFirstPage(false);
      setOtherPages(false);
      setPagesFrom(1);
      setPagesTo(documentInformation.totalPages);
      setBasicTabPages(1, documentInformation.totalPages);

      // getBasicTabValue('pageTo', documentInformation.totalPages);
    }
  };
  const handleCustomPages = (e) => {
    setOtherPages(e.target.checked);
    setDisableControl(false);
    setIsFirstPage(false);
    setIsAllPages(false);
    if (e.target.checked) {
      // getBasicTabValue('pageFrom', 1);
      setPagesFrom(1);
      setPagesTo(documentInformation.totalPages);
      setBasicTabPages(1, documentInformation.totalPages);

      // getBasicTabValue('pageTo', documentInformation.totalPages);
    }
  };

  const handlePageFromChange = (e) => {
    const number = parseInt(e.target.value);
    setPagesFrom(number);
    getBasicTabValue('pageFrom', number);
  };
  const handlePageToChange = (e) => {
    const number = parseInt(e.target.value);
    setPagesTo(number);
    getBasicTabValue('pageTo', number);
  };
  const handleName = (e) => {
    setName(e.target.value);
    getBasicTabValue('name', e.target.value);
  };
  const handleLicenseNo = (e) => {
    setLicenseNo(e.target.value);
    if (selectedSealInfo.type !== 'signature')
      getBasicTabValue('licenseNo', e.target.value);
  };
  const handleFirmName = (e) => {
    setFirmName(e.target.value);
    getBasicTabValue('firmName', e.target.value);
  };
  const handleWebsite = (e) => {
    setWebsite(e.target.value);
    getBasicTabValue('website', e.target.value);
  };
  const handleProjectName = (e) => {
    setProjectName(e.target.value);
    getBasicTabValue('projectName', e.target.value);
  };
  const handleProjectNumber = (e) => {
    setProjectNumber(e.target.value);
    getBasicTabValue('projectNumber', e.target.value);
  };
  const handleProjectAddress = (e) => {
    setProjectAddress(e.target.value);
    getBasicTabValue('projectAddress', e.target.value);
  };

  return (
    <Row>
      <Col sm={6} md={6} lg={4} xl={{ span: 4, offset: 0 }}>
        <Form.Group controlId="Name">
          <Form.Label>
            <p className={s.pages}>
              Name <span>(Required)</span>
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            maxLength={30}
            disabled={
              documentInformation.selectedSealInfo.type !== 'stamp'
                ? true
                : true
            }
            defaultValue={name}
            onChange={handleName}
          />
        </Form.Group>
        <Form.Group controlId="licenseNo">
          <Form.Label>
            <p className={s.pages}>
              License Number <span>(Required)</span>
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            maxLength={25}
            disabled={
              documentInformation.selectedSealInfo.type !== 'stamp'
                ? true
                : true
            }
            defaultValue={licenseNo}
            onChange={handleLicenseNo}
          />
        </Form.Group>
        <Form.Group controlId="firmName">
          <Form.Label>
            <p className={s.pages}>
              Firm Name <span>(Required)</span>
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            maxLength={50}
            defaultValue={firmName}
            onChange={handleFirmName}
          />
        </Form.Group>
        <Form.Group controlId="website">
          <Form.Label>
            <p className={s.pages}>
              Company Website (Optional)
            </p>
          </Form.Label>
          <CustomTooltip trigger={'hover'} location={'auto'} content={'Adding your company website is completely optional, and a chance for you to market your own firm for users who verify this SealPact in the future.'}>
            <i
              style={{ marginLeft: '3px', marginTop: '3px' }}
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
          </CustomTooltip>
          <Form.Control
            type="text"
            maxLength={50}
            defaultValue={website}
            onChange={handleWebsite}
          />
        </Form.Group>
      </Col>
      <Col sm={6} md={6} lg={4} xl={{ span: 4, offset: 0 }}>
        <Form.Group controlId="projectName">
          <Form.Label>
            <p className={s.pages}>
              Project Name <span>(Required)</span>
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            maxLength={30}
            defaultValue={projectName}
            onChange={handleProjectName}
          />
        </Form.Group>
        <Form.Group controlId="projectNumber">
          <Form.Label>
            <p className={s.pages}>
              Project Number <span>(Required)</span>
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            maxLength={25}
            defaultValue={projectNumber}
            onChange={handleProjectNumber}
          />
        </Form.Group>
        <Form.Group controlId="projectAddress">
          <Form.Label>
            <p className={s.pages}>
              Project Address <span>(Required)</span>
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            maxLength={50}
            defaultValue={projectAddress}
            onChange={handleProjectAddress}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="dateOfIssuance">
          <Form.Label>
            <p className={s.pages}>Date of SealPact</p>
          </Form.Label>
          <DatePicker
            selected={date}
            onChange={handleChange}
            startDate={date}
            disabled={true}
            // minDate={Date.now()}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default BasicTab;
