/* eslint-disable indent */
import React, { Fragment, useEffect, useState } from 'react';
import s from './style.scss';
import { Button, Image, DropdownButton, Dropdown, Row, Col, Form, Container } from 'react-bootstrap';
import DownloadImg from '../../../assets/download.png';
import ButtonUp from '../../../assets/button-up.png';
import ButtonDown from '../../../assets/button-down.png';
import ButtonMove from '../../../assets/button-move.png';
import ButtonHand from '../../../assets/button-hand.png';
import ZoomIn from '../../../assets/zoominn.png';
import ZoomOut from '../../../assets/zoomoutt.png';
import View from '../../../assets/view.png';
import RotateRight from '../../../assets/rotate-right.png';
import RotateRightOne from '../../../assets/rotate-right-one.png';
import RotateLeftOne from '../../../assets/rotate-left-one.png';
import RotateLeft from '../../../assets/rotate-left.png';
import Manage from '../../../assets/button-manage.png';
import Delete from '../../../assets/send-to-trash.png';
import fileDownload from 'js-file-download';
import mimeTypes from 'mime-types';
import CustomTooltip from '../../AppComponents/CustomTooltip';
import { INVALIDATION_REASON } from '../../../utils/constants';
import Spinner from '../../Layout/Spinner';
import ProgressBarComponent from '../../Layout/downloadProgressBar';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';
import DynamicModal from '../../DynamicModal';

const ImageControls = ({
  zoomIn,
  zoomOut,
  isSealConfirmed,
  isSealInvalidated,
  isSealSelected,
  handleSealPactDrawer,
  enableZoom,
  enableMove,
  moveEnabled,
  onCancel,
  afterSealpacted,
  nextPage,
  previousPage,
  rotateRight,
  rotateOneRight,
  rotateLeft,
  rotateOneLeft,
  handleDeleteDocument,
  // deleteSealPactedDocument,
  // deleteInvalidatedDocument,
  documentDetails,
  numPages,
  pageNumber,
  fileType,
  documentInformation,
  confirmationValues,
  shared,
  viewer,
  resetTransform,
  disableControl,
  sealPactDownloadUrl,
  handleInvalidate,
  toggleReSealPactModel,
  documentStatus,
  props,
  ...rest
}) => {
  const [loading, setLoading] = useState(false),
    [showRotationModal, setShowRotationModal] = useState(false),
    [rotateLeftClicked, setRotateLeftClicked] = useState(false),
    [rotateRightClicked, setRotateRightClicked] = useState(false),
    [rotateAllPages, setRotateAllPages] = useState(true),
    [rotateAllPagesModalOption, setRotateAllPagesModalOption] = useState(true)

  const handleConfirmRotate = () => {
    if (rotateLeftClicked) {
      if (rotateAllPagesModalOption) {
        rotateLeft()
      }
      else {
        rotateOneLeft()
      }
    }
    else {
      if (rotateAllPagesModalOption) {
        rotateRight()
      }
      else {
        rotateOneRight()
      }
    }
    setShowRotationModal(false)
    setRotateAllPages(rotateAllPagesModalOption)
  }

  if (rest.location.state) {
    var {
      location: {
        state: { documentSubmitted },
      },
      history: { push },
    } = rest;
  }
  const handleDownload = () => {
    setLoading(true);
    if (documentInformation.fileType === 'application/pdf') {
      const linkSource = sealPactDownloadUrl
        ? sealPactDownloadUrl
        : documentDetails;
      console.log('linkSource ===> ', linkSource);
      // window.open(linkSource, '_blank');

      // const downloadLink = document.createElement('a');
      // const fileName = documentInformation.name + '.pdf';
      // downloadLink.href = linkSource + '.pdf';
      // downloadLink.download = fileName;
      // downloadLink.click();
      fetch(linkSource)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          console.log('linkSource ===> ', linkSource);
          fileDownload(
            blob,
            documentInformation.projectNumber +
              '-' +
              documentInformation.projectAddress +
              '-' +
              documentInformation.projectName +
              '-' +
              documentInformation.primarySealPactSigner +
              '-' +
              new Date(confirmationValues.dateSealPacted).toLocaleString() +
              '-SealPact' +
              '.pdf',
          );
          setLoading(false);
        });
    } else {
      const linkSource = sealPactDownloadUrl
        ? sealPactDownloadUrl
        : documentDetails;
      fetch(linkSource)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          console.log('linkSource ===> ', linkSource);
          mimeTypes.extension(blob.type);
          if (sealPactDownloadUrl) {
            fileDownload(blob, documentInformation.name + '.png');
          } else {
            fileDownload(
              blob,
              documentInformation.name + '.' + mimeTypes.extension(blob.type),
            );
          }
          setLoading(false);
        });
    }
  };
  const getScannedDocDetails = () => {
    if (documentInformation.subscribers && documentInformation.subscribers.length > 0) {
      push('/scannedDocumentDetails', {
        scanDetailsList: documentInformation.subscribers,
      });
    } else {
      store.addNotification({
        title: 'No Subscribers',
        message: 'No users have scanned this file yet',
        type: 'warning',
        ...NOTIFICATION_PROPS,
      });
    }
  };

  // const handleDalete = (status) => {
  //   if (status === 'pacted') {
  //     deleteSealPactedDocument();
  //   }
  //   if (status === undefined || status === 'pending') {
  //     deleteDocument();
  //   }
  //   if (status === 'invalidated') {
  //     deleteInvalidatedDocument();
  //   }
  // };

  return loading ? (
    <ProgressBarComponent />
  ) : (
    <div className={s.header}>
      <div className={s.controls}>
        <Button
          className={s.btnStyle}
          variant="text"
          onClick={handleDownload}
          disabled={disableControl}
        >
          <Image height={23} src={DownloadImg} alt="download" />
          Download
        </Button>
        {fileType === 'application/pdf' && <div className={s.bar} />}
        {fileType === 'application/pdf' && (
          <Button
            className={s.btnStyle}
            variant="text"
            onClick={previousPage}
            disabled={disableControl}
          >
            <Image height={19} src={ButtonUp} alt="btn-up" />
            Page Up
          </Button>
        )}
        {fileType === 'application/pdf' && (
          <Button
            className={s.btnStyle}
            variant="text"
            onClick={nextPage}
            disabled={disableControl}
          >
            <Image height={19} src={ButtonDown} alt="btn-down" />
            Page Down
          </Button>
        )}
        {fileType === 'application/pdf' && (
          <b>
            {pageNumber}/ {numPages}
          </b>
        )}
        {fileType === 'application/pdf' && <div className={s.bar} />}

        {/* <Button
          className={s.btnStyle}
          variant="text"
          onClick={enableMove}
          disabled={disableControl}
        >
          <Image height={19} src={ButtonMove} alt="move" />
          Move
        </Button> */}
        <Button
          className={s.btnStyle}
          variant="text"
          onClick={zoomIn}
          disabled={disableControl}
        >
          <Image height={19} src={ZoomIn} alt="zoom-in" />
          Zoom In
        </Button>
        <Button
          className={s.btnStyle}
          variant="text"
          onClick={zoomOut}
          disabled={disableControl}
        >
          <Image height={19} src={ZoomOut} alt="zoom-out" />
          Zoom Out
        </Button>
        <Button
          className={s.btnStyle}
          variant="text"
          onClick={resetTransform}
          disabled={disableControl}
        >
          <Image height={19} src={View} alt="view" />
          Whole Page
        </Button>

        {fileType === 'application/pdf' && (
          <div>
            <Button
              className={s.btnStyle}
              variant="text"
              onClick={() => {
                setRotateRightClicked(false)
                setRotateLeftClicked(true);
                setShowRotationModal(true);
              }}
              disabled={disableControl}
            >
              <Image height={19} src={RotateLeft} alt="rotate-left-all" />
              Rotate Left
            </Button>
            {/*{(documentInformation.status === undefined ||*/}
            {/*  documentInformation.status === 'pending') && (*/}
            {/*  <Button*/}
            {/*    className={s.btnStyle}*/}
            {/*    variant="text"*/}
            {/*    onClick={rotateOneLeft}*/}
            {/*    disabled={disableControl}*/}
            {/*  >*/}
            {/*    <Image height={19} src={RotateLeftOne} alt="rotate-left-one" />*/}
            {/*    Rotate Current Page Left*/}
            {/*  </Button>*/}
            {/*)}*/}
          </div>
        )}

        {fileType === 'application/pdf' && (
          <div>
            <Button
              className={s.btnStyle}
              variant="text"
              // onClick={rotateRight}
              onClick={() => {
                setRotateLeftClicked(false)
                setRotateRightClicked(true);
                setShowRotationModal(true);
              }}
              disabled={disableControl}
            >
              <Image height={19} src={RotateRight} alt="rotate-right-all" />
              Rotate Right
            </Button>
            {/*{(documentInformation.status === undefined ||*/}
            {/*  documentInformation.status === 'pending') && (*/}
            {/*  <Button*/}
            {/*    className={s.btnStyle}*/}
            {/*    variant="text"*/}
            {/*    onClick={rotateOneRight}*/}
            {/*    disabled={disableControl}*/}
            {/*  >*/}
            {/*    <Image height={19} src={RotateRightOne} alt="rotate-right-one" />*/}
            {/*    Rotate Current Page Right*/}
            {/*  </Button>*/}
            {/*)}*/}
          </div>
        )}
        {(documentInformation.status === undefined ||
          documentInformation.status === 'pacted' ||
          documentInformation.status === 'invalidated' ||
          documentInformation.status === 'pending') && (
          <Button
            className={s.btnStyle}
            variant="text"
            onClick={handleDeleteDocument}
            disabled={disableControl}
          >
            <Image height={19} src={Delete} alt="delete" />
            Delete
          </Button>
        )}

        {/* {documentSubmitted && (
         <Button
           variant="text"
           onClick={() =>
             push({
               pathname: '/documentPreview',
               state: {
                 showEmails: true,
                 documentDetails: documentDetails,
                 numPages: numPages,
                 fileType: fileType,
                 acceptedFile: documentInformation.data,
                 documentInformation: documentInformation,
               },
             })
           }
         >
           <Image height={19} src={Manage} alt="manage" />
           <span>Manage</span>
         </Button>
       )} */}
      </div>
      {/* {documentInformation.sealPacterType !== 'viewer' && ( */}
      <Fragment>
        <div className={s.actionBtns}>
          {isSealSelected &&
            !isSealConfirmed &&
            !isSealInvalidated &&
            !sealPactDownloadUrl && (
              <Button
                variant="outline-secondary"
                onClick={onCancel}
                disabled={disableControl}
              >
                Cancel
              </Button>
            )}
          {/* {!isSealSelected &&
           !isSealConfirmed &&
           !isSealInvalidated &&
           !sealPactDownloadUrl &&
           !shared && (
             <Button
               variant="outline-secondary"
               onClick={() =>
                 push({
                   pathname: '/documentPreview',
                   state: {
                     showEmails: false,
                     documentDetails: { documentDetails },
                     numPages: numPages,
                     fileType: fileType,
                     acceptedFile: documentInformation.data,
                   },
                 })
               }
             >
               Edit
             </Button>
           )} */}

          {documentStatus === 'invalidated' && (
            <Button
              onClick={toggleReSealPactModel}
              className={s.btnReSealPact}
              variant="outline-transparent"
            >
              Re-SealPact
            </Button>
          )}

          {documentStatus === 'pacted' && (
            <DropdownButton
              id={'dropdown-menu-align-right'}
              title={'Invalidate'}
              variant={'danger'}
              alignRight={true}
              className={s.btnInvalidate}
              onSelect={(eventKey, event) => {
                handleInvalidate(eventKey);
              }}
            >
              {INVALIDATION_REASON.map((item, i) => (
                <Dropdown.Item eventKey={i}>{item.value}</Dropdown.Item>
              ))}
            </DropdownButton>
          )}
          {(isSealConfirmed || documentStatus === 'pacted' || documentStatus === 'invalidated') && (
            <div className={s.btnScans}>
              <Button onClick={getScannedDocDetails}>Scans</Button>
              <CustomTooltip
                trigger={'hover'}
                location={'auto'}
                content={
                  'Clicking the Scans button will provide the IP addresses for each time your sealed document was clicked. If the user included their email when verifying the seal, this information will also appear.'
                }
              >
                <i
                  style={{
                    marginLeft: '3px',
                    marginTop: '3px',
                    alignSelf: 'center',
                  }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                ></i>
              </CustomTooltip>
            </div>
          )}
          {documentStatus === 'invalidated' ? (
            <Button variant="secondary" onClick={afterSealpacted}>
              Invalidated
            </Button>
          ) : isSealConfirmed ? (
            <>
              <Button
                className={s.btnSealPacted}
                variant="outline-transparent"
                onClick={afterSealpacted}
              >
                SealPacted
              </Button>
              <CustomTooltip
                trigger={'hover'}
                location={'auto'}
                content={
                  'Check out the information you attached to your digitally signed artifact here.'
                }
              >
                <i
                  style={{
                    marginLeft: '3px',
                    marginTop: '3px',
                    alignSelf: 'center',
                  }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                ></i>
              </CustomTooltip>
            </>
          ) : sealPactDownloadUrl ? (
            <CustomTooltip
              trigger={'hover'}
              location={'auto'}
              type="info"
              effect="float"
              content={
                'Check out the information you attached to your digitally signed artifact here.'
              }
            >
              <Button variant="outline-secondary" onClick={afterSealpacted}>
                SealPacted
              </Button>
            </CustomTooltip>
          ) : (
            <Button
              variant="primary"
              onClick={handleSealPactDrawer}
              disabled={disableControl}
            >
              {isSealSelected ? 'Confirm' : 'SealPact'}
            </Button>
          )}
        </div>
        <DynamicModal
          show={showRotationModal}
          title="Pages Rotation"
          hideModal={() => {
            setShowRotationModal(false)
            setRotateAllPagesModalOption(rotateAllPages)
          }}
          size="md"
        >
          <Container>
            <Row>
              {rotateRightClicked && (
                <Col>
                  <Form.Group controlId="forEntireDocumentRotation">
                    <Form.Check
                      className={s.checkboxLabel}
                      type="radio"
                      label="Rotate All Pages Right"
                      onChange={() => setRotateAllPagesModalOption(true)}
                      checked={rotateAllPagesModalOption}
                    />
                  </Form.Group>

                  {(documentInformation.status === undefined ||
                    documentInformation.status === 'pending') && (
                    <div className={'d-flex'}>
                      <Form.Group controlId="forCurrentPageRotation">
                        <Form.Check
                          className={s.checkboxLabel}
                          type="radio"
                          label="Rotate Current Page Right"
                          onChange={() => setRotateAllPagesModalOption(false)}
                          checked={!rotateAllPagesModalOption}
                        />
                      </Form.Group>
                    </div>
                  )}
                </Col>
              )}
              {rotateLeftClicked && (
                <Col>
                  <Form.Group controlId="forEntireDocumentRotation">
                    <Form.Check
                      className={s.checkboxLabel}
                      type="radio"
                      label="Rotate All Pages Left"
                      onChange={() => setRotateAllPagesModalOption(true)}
                      checked={rotateAllPagesModalOption}
                    />
                  </Form.Group>
                  {(documentInformation.status === undefined ||
                    documentInformation.status === 'pending') && (
                    <div className={'d-flex'}>
                      <Form.Group controlId="forCurrentPageRotation">
                        <Form.Check
                          className={s.checkboxLabel}
                          type="radio"
                          label="Rotate Current Page Left"
                          onChange={() => setRotateAllPagesModalOption(false)}
                          checked={!rotateAllPagesModalOption}
                        />
                      </Form.Group>
                    </div>
                  )}
                </Col>
              )}
            </Row>
            <Row className={s.contentEnd}>
              <Button onClick={handleConfirmRotate}>Confirm</Button>
            </Row>
          </Container>
        </DynamicModal>
      </Fragment>
      {/* )} */}
    </div>
  );
};

export default ImageControls;
