import React, { useState, Fragment, useEffect } from 'react';
import s from './style.scss';
import { Formik, Form } from 'formik';
import { Form as BForm, Button, Container, Row, Col } from 'react-bootstrap';
import LogoBrand from '../../LogoBrand';
import MaskedInput from '../../AppComponents/MaskedInput';
import { LOGINVALIDATION } from '../../../config/FormSchemas';
import DynamicModal from '../../DynamicModal';
import TermsAndConditions from '../../TermsAndPrivcay/TermsAndConditions';
import PrivacyPolicy from '../../TermsAndPrivcay/PrivacyPolicy';
import { withFirebase } from '../../../auth/firebase/context';
import { store } from 'react-notifications-component';
import Spinner from '../../Layout/Spinner';
import Header from '../../Header';

import withAuthorization from '../../../auth/session/withAuthorization';

class Pricing extends React.Component {
  componentDidMount() {
    console.log('this.props ====in pricing=> ', this.props);
    // (function () {
    //   var s = document.createElement('script');
    //   s.src =
    //     'https://js.servicebot.io/embeds/servicebot-billing-settings-embed.js';
    //   s.async = true;
    //   s.type = 'text/javascript';
    //   var x = document.getElementsByTagName('script')[0];
    //   x.parentNode.insertBefore(s, x);
    // })();
  }

  addUserPaymentDetatils = (userPaymentDetails) => {
    this.props.firebase.addUserSubscriptionDetails(
      this.props.user.uid,
      userPaymentDetails,
    );
  };

  render() {
    // window.servicebotSettings = {
    //   servicebot_id: 'd1iewotY0YEu',
    //   service: 'SB_DEMO',
    //   type: 'pricing',
    //   integration: 'html',
    //   email: this.props.user.email,
    //   handleResponse: async (payload) => {
    //     console.log('Event ', payload.event);
    //     console.log('Response Object ', payload.response);
    //     // Redirect on Signup example
    //     if (payload.event == 'create_subscription') {
    //       console.log('this.props => ', this.props);
    //       this.props.firebase
    //         .addUserSubscriptionDetails(this.props.user.uid, payload.response)
    //         .then((res) => {
    //           window.location.href = '/';
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     }
    //   },
    // };

    return (
      <React.Fragment>
        {/* <Header title="" /> */}
        <div id="servicebot-subscription-portal"></div>;
      </React.Fragment>
    );
  }
}

const condition = (user) => !!user;
export default withAuthorization(condition)(withFirebase(Pricing));
