/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Col, Form, InputGroup } from 'react-bootstrap';
import s from './style.scss'
import TagIcon from '../../../assets/Tag.svg'
import axios from 'axios';
import { NOTIFICATION_PROPS, NOTIFICATION_PROPS_LONGER, UPDATE_SUBSCRIPTION } from '../../../utils/constants';
import { store } from 'react-notifications-component';
import moment from 'moment';

const PlansForUpdateConfirm = ({
                                 setShowUpdatePlan,
                                 setShowUpdatePlanConfirm,
                                 confirmationPlanName,
                                 confirmationPlan,
                                 proration,
                                 subscriptionId,
                                 user,
                                 firebase,
                                 reloadUser,
}) => {
  const [addPromotionCode, setAddPromotionCode] = useState(false),
    [promotionCode, setPromotionCode] = useState(''),
    [loading, setLoading] = useState(false)

  const updateSubscription = () => {
    setLoading(true)
    axios.post(UPDATE_SUBSCRIPTION, {
      subscriptionId,
      newPlanId: confirmationPlan.id,
      couponCode: promotionCode !== '' ? promotionCode : null,
    }).then(async response => {
      console.log('updateSubscription response', response)
      if (response && response.data && response.data.success) {
        await firebase.addUserSubscriptionDetails(
          user.uid,
          response.data.data
        ).then(async response => {
          setLoading(false)
          store.addNotification({
            title: 'Updated Successfully!',
            message: 'Plan updated successfully.',
            type: 'default',
            ...NOTIFICATION_PROPS,
          });
          await reloadUser();
          // window.location.reload(false);
        }).catch(err => {
          console.log('err 2', err);
          setLoading(false);
          store.addNotification({
            title: 'Error',
            message: err.message,
            type: 'danger',
            ...NOTIFICATION_PROPS_LONGER,
          });
        })
      }
      else {
        setLoading(false)
        store.addNotification({
          title: 'Error',
          message: response.data.message,
          type: 'danger',
          ...NOTIFICATION_PROPS_LONGER,
        });
      }

    }).catch(err => {
        console.log('err', err);
        setLoading(false);
        store.addNotification({
          title: 'Error',
          message: err.message,
          type: 'danger',
          ...NOTIFICATION_PROPS_LONGER,
        });
      })
  }

  function incomingInvoiceDate (unixDate) {
    let subscriptionEndDate = new Date(unixDate * 1000)

    if (user.subscriptionDetails.status === 'trialing') {
      subscriptionEndDate.setDate(subscriptionEndDate.getDate() + 1)

      return Math.floor(subscriptionEndDate.getTime() / 1000)
    }

    let nextInvoiceDate = new Date()
    if (confirmationPlan.recurring.interval === 'month') {
     nextInvoiceDate.setMonth(nextInvoiceDate.getMonth() + 1)
    }
    else
      nextInvoiceDate.setFullYear(nextInvoiceDate.getFullYear() + 1)

    return Math.floor(nextInvoiceDate.getTime() / 1000)
  }

  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card className={s.confirmPlanCard}>
          <Card.Title className={s.title}>
            Confirm your updates
          </Card.Title>
          <Card.Body style={{ padding: '0 0 0 0', width: '100%' }}>
            {confirmationPlan && proration && (
              <>
                <div className={s.divOne}>
                  <div className={s.description}>
                    <div><b>{confirmationPlanName}</b></div>
                    <div>What you'll pay {confirmationPlan.recurring.interval === 'month' ? 'monthly' : 'yearly'} starting</div>
                    <div className={s.grey}>
                      {moment.unix(incomingInvoiceDate(user.subscriptionDetails.current_period_end)).format('MMMM D, YYYY')}
                    </div>
                  </div>
                  <div className={s.price}>
                    ${(confirmationPlan.unit_amount / 100).toFixed(2)}
                  </div>
                </div>

                <div className={s.divOne}>
                  <div className={s.description}>
                    <div><b>Amount due today</b></div>
                  </div>
                  <div className={s.price}>
                    ${(proration.amount_due / 100).toFixed(2)}
                  </div>
                </div>
                <div className={s.promotionCode}>
                  {addPromotionCode ? (
                    <Form.Group controlId="promotionCode" style={{ marginBottom: 0 }}>
                      <div
                        className={s.label}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setAddPromotionCode(false)
                          setPromotionCode('')
                        }}
                      >
                        Promotion Code
                      </div>
                      <Form.Control
                        className={''}
                        type="text"
                        placeholder={'Enter promotion code'}
                        defaultValue={promotionCode}
                        maxLength={20}
                        onChange={(e) => setPromotionCode(e.target.value)}
                        />
                    </Form.Group>
                  ): (
                    <div
                      className={s.promotionCode}
                      onClick={() => setAddPromotionCode(true)}
                      style={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        width: 'max-content',
                      }}
                    >
                      <img src={TagIcon} alt='TagIcon' style={{ marginRight: '8px', marginTop: '-10px' }}/>
                      <div className={s.label}>Add Promotion Code</div>
                    </div>
                  )}
                </div>
              </>
            )}
          </Card.Body>

          <Card.Footer style={{ padding: '0 0 0 0', background: 'white', width: '100%', border: 'none' }}>
            <div className={'d-flex justify-content-between align-items-center'}>
              {confirmationPlan && proration && (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setShowUpdatePlan(true)
                      setShowUpdatePlanConfirm(false)
                    }}
                    className={s.goBack}
                  >
                    Go Back
                  </Button>
                  {!loading ? (
                    <Button
                      variant="primary"
                      className={s.confirmButton}
                      onClick={updateSubscription}
                    >
                      Confirm
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className={s.confirmButton}
                    >
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                      Confirming
                    </Button>
                  )}

                </>
              )}
            </div>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  )
}

export default PlansForUpdateConfirm