import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import s from './style.scss';
import MaskedInput from '../../../AppComponents/MaskedInput';

const SignUp = ({ formikProps }) => {
  const { errors, handleChange, handleBlur } = formikProps;

  useEffect(() => {
    console.log('formikProps', formikProps)
  }, [])


  return (
    <div className={s.wrapper}>
      <Row style={{ marginTop: '32px' }}>
        <Col lg={6} md={12} xs={12}>
          <MaskedInput
            name="email"
            focus={true}
            formikProps={formikProps}
            label="Email"
            placeholder="example@email.com"
            isMandatory={true}
          />
        </Col>
        <Col lg={6} md={12} xs={12}>
          <MaskedInput
            name="phoneNumber"
            formikProps={formikProps}
            label="Mobile Number"
            type="tel"
            placeholder="xxxxxxxxxx"
            isMandatory={true}
            content={'Your phone number will not be shared with any third party venders, see Privacy Policy for more info. This is used exclusively for Two Factor Authentication, which can be turned on or off later.'}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} xs={12}>
          <MaskedInput
            name="password"
            formikProps={formikProps}
            label="Password"
            type="password"
            placeholder="Minimum 8 characters"
            isMandatory={true}
          />
        </Col>
        <Col lg={6} md={12} xs={12}>
          <MaskedInput
            name="verifyPassword"
            type="password"
            formikProps={formikProps}
            label="Confirm Password"
            placeholder="Minimum 8 characters"
            isMandatory={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SignUp;
