/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import Header from '../../Header';
import { Button, Row, Col, Table } from 'react-bootstrap';
import s from './style.scss';
import DetailsTable from './DetailsTable';
import UserCard from './UserCard';
import ChangePassword from './ChangePassword';
import Switch from 'react-switch';
import withAuthorization from '../../../auth/session/withAuthorization';
import withAuthentication from '../../../auth/session/withAuthentication';
import DynamicModal from '../../DynamicModal';
import FaqModel from './FaqModel';
import {view} from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';
import Spinner from '../../Layout/Spinner';
import OnboardingCompletion from './OnboardingCompletion';

const uncheckedIcon = (
  <div className={[s.label, s.offLabel].join(' ')}>OFF</div>
);
const checkedIcon = <div className={[s.label, s.onLabel].join(' ')}>ON</div>;

const MyAccount = ({ user, reloadUser, firebase }) => {
  // const [userData, setUserData] = useState(user || {})
  const [loading, setLoading] = useState(false);
  const [showUserModal, toggleUserModal] = useState(false);
  const [showPasswordModal, togglePasswordModal] = useState(false);
  const [isEmailNotificatioOn, toggleCheckbox] = useState(
    user.emailNotifications ? user.emailNotifications : false,
  );
  const [istwoFactorAuthOn, toggletwoFactorAuthCheckbox] = useState(
    user.twoFactorAuthentication ? user.twoFactorAuthentication : false,
  );
  const [showFaqModal, faqToggler] = useState(false);
  const [showContactUsModal, contactUsToggler] = useState(false);
  const [isFloridaSelected, setIsFloridaSelected] = useState(false);

  const [value, setValue] = useState(0); // integer state

  const toggleFaqModal = () => faqToggler(!showFaqModal);
  const toggleContactUsModal = () => contactUsToggler(!showContactUsModal);

  const handleUserModal = async () => {
    toggleUserModal(!showUserModal);
    if (showUserModal) {
     await reloadUser();
    }
  };
  const handlePasswordModal = () => togglePasswordModal(!showPasswordModal);

  useEffect(() => {
    // if (user.uid.localeCompare(appEasyState.userId) === 0 && appEasyState.profileUpdatedSuccessfully === 'true') {
    //   store.addNotification({
    //     title: 'Updated Successfully!',
    //     message: 'Profile updated successfully.',
    //     type: 'default',
    //     ...NOTIFICATION_PROPS,
    //   });
    //   appEasyState.profileUpdatedSuccessfully = 'false'
    //   localStorage.setItem('profileUpdatedSuccessfully', 'false')
    // }


    firebase
      .getUserPhoneNumberAgainstEmail(user.email.toLowerCase())
      .then(({ sealsState }) => {
        setIsFloridaSelected(sealsState);
      });
  }, []);

  useEffect(() => {
    firebase.updateUser(user.uid, { emailNotifications: isEmailNotificatioOn });
  }, [isEmailNotificatioOn]);

  useEffect(() => {
    firebase.updateUser(user.uid, {
      twoFactorAuthentication: istwoFactorAuthOn,
    });
  }, [istwoFactorAuthOn]);


  return loading ? (
    <Spinner/>
  ) : user && user.isOnboardingCompleted !== undefined && !user.isOnboardingCompleted ? (
    <React.Fragment>
      <Header title="Account Setup" />
      <OnboardingCompletion
        firebase={firebase}
        user={user}
        reloadUser={reloadUser}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Header title="My Account" />

      <div className={s.container}>
        <Row className="justify-content-end align-items-center" noGutters>
          <Button
            variant="transparent"
            className={s.boldLabel}
            onClick={toggleFaqModal}
          >
            FAQs
          </Button>
          <span className={s.divider} />
          <Button
            variant="transparent"
            className={s.boldLabel}
            onClick={toggleContactUsModal}
          >
            Contact Us
          </Button>
        </Row>
        <Row className={[s.bottomBorder, s.rowLabel]} noGutters>
          General Account Settings
        </Row>
        <Row noGutters className={s.bottomBorder}>
          <Col xs={12} md={5} className="pl-3">
            <UserCard
              handleModal={handleUserModal}
              show={showUserModal}
              user={user}
              reloadUser={reloadUser}
            />
          </Col>
          <Col xs={12} md={7}>
            <DetailsTable
              firebase={firebase}
              user={user}
              reloadUser={reloadUser}
              setValue={setValue}
              setLoading={setLoading}
            />
          </Col>
        </Row>
        <Row className={`${s.bottomBorder} align-items-center`} noGutters>
          <Col xs={12} md={5} className={s.rowLabel}>
            Two Factor Authentication Settings
          </Col>
          <Col xs={12} md={7}>
            <Table className={s.tbl} borderless responsive>
              <tbody>
              <tr>
                <th>Two Factor Authentication</th>
                <td className="pr-1 text-right">
                  <Switch
                    // disabled={isFloridaSelected}
                    checked={istwoFactorAuthOn}
                    height={22}
                    onChange={() => toggletwoFactorAuthCheckbox(!istwoFactorAuthOn)}
                    uncheckedIcon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    className="react-switch"
                    id="icon-switch"
                    offColor="#e64767"
                  />
                </td>
              </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className={`${s.bottomBorder} align-items-center`} noGutters>
          <Col xs={12} md={5} className={s.rowLabel}>
            Notifications Settings
          </Col>
          <Col xs={12} md={7}>
            <Table className={s.tbl} borderless responsive>
              <tbody>
              <tr>
                <th>Email Notifications</th>
                <td className="pr-1 text-right">
                  <Switch
                    checked={isEmailNotificatioOn}
                    height={22}
                    onChange={toggleCheckbox}
                    uncheckedIcon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    className="react-switch"
                    id="icon-switch"
                    offColor="#e64767"
                  />
                </td>
              </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className={`${s.bottomBorder} align-items-center`} noGutters>
          <Col xs={12} md={5} className={s.rowLabel}>
            Password
          </Col>
          <Col xs={12} md={7} className="pr-0">
            <Table responsive className={s.tbl} borderless>
              <tbody>
              <tr>
                <th>Current Password</th>
                <td className={s.passwordChar}>
                  &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
                </td>
                <td>
                  <Button
                    variant="transparent"
                    className="p-0"
                    onClick={handlePasswordModal}
                  >
                    Change
                  </Button>
                </td>
              </tr>
              </tbody>
            </Table>
            <ChangePassword
              show={showPasswordModal}
              firebase={firebase}
              handleModal={handlePasswordModal}
              user={user}
            />
          </Col>
        </Row>
      </div>

      <FaqModel showFaqModal={showFaqModal} toggleFaqModal={toggleFaqModal} />

      <DynamicModal
        size="lg"
        title="Contact Us"
        show={showContactUsModal}
        hideModal={toggleContactUsModal}
      >
        <Fragment>
          <p>
            Provide feedback or ask questions at{' '}
            <a href="mailto: support@sealpact.com">support@sealpact.com</a>
          </p>
        </Fragment>
      </DynamicModal>
    </React.Fragment>
  );
};

const condition = (user) => !!user;
export default withAuthentication(withAuthorization(condition)(view(MyAccount)));
