/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { view } from 'react-easy-state';
import s from './style.scss';
import { store } from 'react-notifications-component';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import GiftIcon from '../../../assets/GiftLarge.svg';
import CloseButtonIcon from '../../../assets/CloseButton.svg';
import CopyIcon from '../../../assets/Copy.svg';

const InstructionsModal = ({
  showInstructionsModal,
  setShowInstructionsModal,
}) => {
  return (
    <Modal
      show={showInstructionsModal}
      onHide={() => setShowInstructionsModal(false)}
      animation
      centered
      size={'xl'}
      backdrop
      dialogClassName="modal-90w"
      className={s.instructionsModal}
    >
      <Modal.Header
        style={{ borderBottom: '1px solid #F1F1F4', position: 'relative' }}
      >
        <Modal.Title id="modal" className={s.title}>
          <img src={GiftIcon} alt="GiftIcon" />
          <div>Refer Friends and Earn Rewards</div>
        </Modal.Title>
        <img
          src={CloseButtonIcon}
          alt={'CloseButton'}
          className={s.closeButton}
          onClick={() => setShowInstructionsModal(false)}
        />
      </Modal.Header>
      <Modal.Body style={{ padding: '24px' }}>
        <h6>
          SealPact now offers a referral plan for our Annual Subscribers to help
          business associates/colleagues collaborate more effectively!
        </h6>
        <p>
          Refer as many colleagues as you like to save money on your next
          subscription payment! If a colleague subscribes to SealPact from your
          referral, you will receive an additional $10 off your next
          subscription (up to $100/year). You can refer colleagues two ways:
        </p>
        <ol>
          <li>
            Manual - copying and pasting the link on the left into an email and
            sending manually
          </li>
          <br />
          <li>
            Automatic - using the referral/emailing feature to the right with
            pre-drafted text.
          </li>
        </ol>
        <p>
          <span style={{ display: 'block' }}>
            If you chose the automatic referral link to the right, SealPact will
            track Pending invites, Trialing invites as well as Subscribed
            invites.
          </span>
          <span style={{ display: 'block' }}>
            If you chose the manual referral link, SealPact will track Trialing
            and Subscribed invites only.
          </span>
          You will receive a subscription coupon one month prior to your
          renewal, which you can redeem at that time, to see your credits on the
          Subscription Tab.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default InstructionsModal;
