import * as Yup from 'yup';
const pswRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d.@$!%_!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~*#?&]{8,}$/;
// const phoneRegExp = /^((\\+[1-15]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /[0-9]{9,15}/;
var numberRegex = /^\d+$/;
const ChangePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('Current password is required')
    .matches(
      pswRegExp,
      'Password must be at least 8 characters with at least one letter and one number.',
    ),
  newPassword: Yup.string()
    .required('New password is required')
    .matches(
      pswRegExp,
      'Password must be at least 8 characters with at least one letter and one number.',
    ),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match")
    .matches(
      pswRegExp,
      'Password must be at least 8 characters with at least one letter and one number.',
    )
    .required('Password verification is required'),
});
const ProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required.')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed'),
  middleName: Yup.string().matches(
    /^[A-Za-z]+$/,
    'Only alpha-characters are allowed',
  ),
  lastName: Yup.string()
    .required('Last name is required.')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed'),
});
const OnboardingCompletionValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required.')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed.'),
  middleName: Yup.string().matches(
    /^[A-Za-z]+$/,
    'Only alpha-characters are allowed.',
  ),
  lastName: Yup.string()
    .required('Last name is required.')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed.'),
  adminEmail: Yup.string()
    .required('Administrator email is required.')
    .matches(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email format.'),
  // liability: Yup.string()
  //   .required('Professional liability insurer is required.')
  //   // .matches(/^[0-9+\- ]*$/, 'Invalid professional liability insurer.')
});
const EDIT_DETAILS_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Email is invalid'),
  secondaryEmail: Yup.string().email('Email is invalid'),
  phoneNumber: Yup.string()
    .min(10, 'Phone number must be at least 10 digits')
    .matches(phoneRegExp, 'Phone number is not valid'),
});

const SUBSCRIBE_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
});

const LOGINVALIDATION = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      pswRegExp,
      'Password must be at least 8 characters with at least one letter and one number.',
    ),
  // acceptance: Yup.string()
  //   .required('Please agree to Terms and Conditions and Privacy Policy')
  //   .oneOf(['on'], 'Please agree to Terms and Conditions and Privacy Policy'),
});

const TWOFAVERIFICATION = Yup.object().shape({
  verificationCode: Yup.string()
    .required('Verification code is required.')
    .matches(numberRegex, 'Only numbers are allowed.'),
});

const SignUpFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid.').required('Email is required'),
  password: Yup.string()
    .required('Password is required.')
    .matches(
      pswRegExp,
      'Password must be min 8 characters  with at least one letter and one number.',
    ),
  verifyPassword: Yup.string()
    .required('Password verification is required.')
    .oneOf([Yup.ref('password'), null], "Passwords don't match."),
  captchaVerified: Yup.string()
    .required('reCaptcha verification is required.')
    .oneOf(['true'], 'reCaptcha verification is required.'),
  terms: Yup.string()
    .required('Please agree to Terms and Conditions and Privacy Policy.')
    .oneOf(['on'], 'Please agree to Terms and Conditions and Privacy Policy.'),
  phoneNumber: Yup.string()
    .required('Phone number is required.')
    .min(10, 'Phone number must be at least 10 digits.')
    .matches(phoneRegExp, 'Phone number is not valid.'),
  firstName: Yup.string()
    .required('First name is required.')
    .max(15, 'More than 15 letters are not allowed.')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed.'),
  middleName: Yup.string()
    .max(15, 'More than 15 letters are not allowed.')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed.'),
  lastName: Yup.string()
    .required('Last name is required.')
    .max(15, 'More than 15 letters are not allowed.')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed.'),
  adminEmail: Yup.string()
    .email('Email is invalid.')
    .required('Administrator email is required. Use your email for now if there is no admin at your firm.'),
  website: Yup.string().optional(),
  signature: Yup.string().required('Signature is required.'),
  sealName: Yup.string()
    .required('Seal name is required.')
    .matches(
      /^[a-zA-Z](?:[ '.\-a-zA-Z]*[a-zA-Z])?$/,
      'Only alpha-characters are allowed',
    ),
  homeState: Yup.string().required('State is required !'),
  otherState: Yup.string().required('State is required !'),
  licenseNo: Yup.string().required('License number is required.'),
});

const OnboardingSignUpFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid.').required('Email is required.'),
  password: Yup.string()
    .required('Password is required.')
    .matches(
      pswRegExp,
      'Password must be min 8 characters with at least one letter and one number.',
    ),
  verifyPassword: Yup.string()
    .required('Password verification is required.')
    .oneOf([Yup.ref('password'), null], "Passwords don't match."),
  phoneNumber: Yup.string()
    .required('Mobile number is required.')
    .min(10, 'Mobile number must be 10 digits long.')
    .matches(/^\d{10}$/, 'Mobile number must and only be 10 digits long.')
    .test(
      'startsWithZeroOrOne',
      'Mobile number cannot start with 0 or 1.',
      (value) => !/^[01]/.test(value)
    ),
});

const FORGOTPASSWORDVALIDATIONSCHEMA = Yup.object().shape({
  email: Yup.string().email('Email is invalid'),
  newPassword: Yup.string()
    .required('Password is required')
    .matches(
      pswRegExp,
      'Password must be at least 8 characters with at least one letter and one number.',
    ),
  verifyPassword: Yup.string()
    .required('Password verification is required')
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match"),
});
const CREATENEWSEALVALIDATION = Yup.object().shape({
  sealName: Yup.string()
    .required('Seal name is required')
    .matches(
      /^[a-zA-Z](?:[ '.\-a-zA-Z]*[a-zA-Z])?$/,
      'Only alpha-characters are allowed',
    ),
  state: Yup.string().required('State is required !'),
  newLicenseNo: Yup.string().required('License number is required'),
});
const UPLOADNEWSEALVALIDATION = Yup.object().shape({
  sealName: Yup.string()
    .required('Seal name is required')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed'),
  state: Yup.string().required('State is required !'),

  newLicenseNo: Yup.string().required('License number is required'),
});
const UPLOADNEWSTAMPVALIDATION = Yup.object().shape({
  sealName: Yup.string()
    .required('Stamp name is required')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed'),
  approvalStatus: Yup.string().required('Approval status is required'),
});
const CREATENEWSEALVALIDATIONOTHERSTATE = Yup.object().shape({
  sealName: Yup.string()
    .required('Seal name is required')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed'),
  state: Yup.string().required('State is required !'),
  otherState: Yup.string().required('State is required !'),
  newLicenseNo: Yup.string().required('License number is required'),
});
const CREATENEWSIGNATUREVALIDATION = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[A-Za-z ]+$/, 'Only alpha-characters are allowed'),
});
export {
  ChangePasswordValidationSchema,
  ProfileValidationSchema,
  LOGINVALIDATION,
  OnboardingSignUpFormValidationSchema,
  SignUpFormValidationSchema,
  FORGOTPASSWORDVALIDATIONSCHEMA,
  CREATENEWSEALVALIDATION,
  CREATENEWSEALVALIDATIONOTHERSTATE,
  CREATENEWSIGNATUREVALIDATION,
  UPLOADNEWSEALVALIDATION,
  UPLOADNEWSTAMPVALIDATION,
  EDIT_DETAILS_SCHEMA,
  SUBSCRIBE_SCHEMA,
  TWOFAVERIFICATION,
  OnboardingCompletionValidationSchema,
};
