/* eslint-disable indent */
import React from 'react';
import s from './style.scss';
import Cards from './Cards';
import CustomTooltip from '../../AppComponents/CustomTooltip';
import SealIcon from '../../../assets/SealIcon.svg'
import SignatureIcon from '../../../assets/SignatureIcon.svg'
import StampIcon from '../../../assets/StampIcon.svg'
import SealBackground from '../../../assets/SealBackground.svg'
import SignatureBackground from '../../../assets/SignatureBackground.svg'
import StampBackground from '../../../assets/StampBackground.svg'


const PendingSealsCol = ({ array, docType }) => {
  let emptyCard = <div
    className={s.emptyCard}
    style={docType === 'Primary'
      ? { backgroundImage: `url(${SealBackground})` }
      : docType === 'Secondary'
        ? { backgroundImage: `url(${SignatureBackground})` }
        : { backgroundImage: `url(${StampBackground})` }
  }>No New Pending Documents</div>;
  const getDocName = () => {
    switch (docType) {
      case 'Primary':
        return 'Seals';
        break;
      case 'Secondary':
        return 'Signatures';
        break;
      case 'Viewer':
        return 'Submittal Review Stamps';
        break;
      default:
        return 'Seals';
        break;
    }
  };

  const getDocIcon = () => {
    switch (docType) {
      case 'Primary':
        return `${ SealIcon }`;
        break;
      case 'Secondary':
        return `${ SignatureIcon }`;
        break;
      case 'Viewer':
        return `${ StampIcon }`;
        break;
      default:
        return `${ SealIcon }`;
        break;
    }
  };

  const getContent = () => {
    switch (docType) {
      case 'Primary':
        return 'These are pending documents for you to Seal.';
        break;
      case 'Secondary':
        return 'These are pending documents for you to Sign.';
        break;
      case 'Viewer':
        return 'These are pending submittals for you to Stamp.';
        break;
      default:
        return 'Seals';
        break;
    }
  };

  return (
    <div className={s.oneColDiv}>
      <div className={s.blueTop} />
      <div className={s.innerDiv}>
        <div className={'d-flex'}>
          <p>
            <span><img src={getDocIcon()} className={s.icon}/></span>
            <span className={s.title}>{getDocName()}</span>
          </p>
          <CustomTooltip
            trigger={'hover'}
            location={'auto'}
            content={getContent()}
          >
            <i
              style={{ marginLeft: '3px', marginTop: '3px' }}
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
          </CustomTooltip>
        </div>
        {array.length > 0
          ? array.map((item, key) => {
              return <Cards item={item} key={key} docType={docType} />;
            })
          : emptyCard}
      </div>
    </div>
  );
};

export default PendingSealsCol;
