import React from 'react';
import { Form } from 'react-bootstrap';
import s from './styles.scss';
import CustomTooltip from '../CustomTooltip';

const MaskedDropDown = ({
  controlId,
  label,
  onChange,
  dataArray,
  user,
  value,
  props,
  edit,
  content,
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      {content && (
        <CustomTooltip trigger={'hover'} location={'auto'} content={content}>
          <i
            style={{ marginLeft: '3px', marginTop: '3px' }}
            className="fa fa-info-circle"
            aria-hidden="true"
          ></i>
        </CustomTooltip>
      )}
      {edit ? (
        <Form.Group>
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        </Form.Group>
      ) : (
        <Form.Control
          as="select"
          className={s.dropdown}
          onChange={(e) => {
            onChange(e.target.value);
            props.setFieldValue(controlId, e.target.value);
          }}
          value={value}
        >
          <option value="" selected disabled>
            Select
          </option>
          {dataArray.map((ctId, i) => (
            <option
              key={i}
              value={ctId.value ? ctId.value : ctId}
              selected={ctId.value === user[controlId] ? 'selected' : null}
            >
              {ctId.label ? ctId.label : ctId}
            </option>
          ))}
        </Form.Control>
      )}
    </Form.Group>
  );
};

export default MaskedDropDown;
