import React, { Fragment, useEffect, useState } from 'react';
import {
  Navbar,
  Nav,
  DropdownButton,
  Dropdown,
  Image,
  Button,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Account from '../../assets/my-account.png';
import Logout from '../../assets/logout.png';
import LogoutImg from '../../assets/logout-hd.png';
import s from './style.scss';
import withAuthorization from '../../auth/session/withAuthorization';
import CustomTooltip from '../AppComponents/CustomTooltip';
import { view } from 'react-easy-state';
import { appEasyState } from '../../easyStateStore';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../utils/constants';
import CaretLeft from '../../assets/CaretLeft.svg';
import ProfileAvatar from '../../assets/ProfileAvatar.png';
import LogoBrand from '../LogoBrand';

const Header = ({ user, title, edgePortal, ...rest }) => {
  const {
    history: { push },
    backButtonFunc,
  } = rest;

  console.log('user in header ::::>>> ', user);
  return (
    <Fragment>
      <div className={s.headerWrapper}>
        {edgePortal ? (
          <LogoBrand imgSize={40} className={s.logoFont} home {...rest} />
        ) : null}
        <div className={'d-flex'}>
          {backButtonFunc && (
            <img
              src={CaretLeft}
              alt=""
              className={s.backIcon}
              onClick={() => backButtonFunc()}
            />
          )}
          <p className={`${s.title} text-center`} style={{ marginTop: '10px' }}>
            {title}
          </p>

          {title === 'My Seals' && (
            <CustomTooltip
              trigger={'hover'}
              location={'auto'}
              content={
                'You should be able to upload and review documents in any browser; however, signing and sealing (“SealPacting”) is best in Google Chrome and Microsoft Edge. Actions in Safari may have limitations.'
              }
            >
              <i
                style={{
                  marginLeft: '3px',
                  marginTop: '3px',
                  alignSelf: 'center',
                }}
                className="fa fa-info-circle"
                aria-hidden="true"
              ></i>
            </CustomTooltip>
          )}
          {title === 'Document Preview' && (
            <CustomTooltip
              trigger={'hover'}
              location={'auto'}
              content={
                'You should be able to upload and review documents in any browser; however, signing and sealing (“SealPacting”) is best in Google Chrome and Microsoft Edge.  Actions in Safari may have limitations. File size may also impact speed of document handling.'
              }
            >
              <i
                style={{
                  marginLeft: '3px',
                  marginTop: '3px',
                  alignSelf: 'center',
                }}
                className="fa fa-info-circle"
                aria-hidden="true"
              ></i>
            </CustomTooltip>
          )}
        </div>
        {title !== 'My Account' ? (
          <Navbar brand="React-Bootstrap" className={s.dropdown}>
            <Nav eventKey={0} className={s.borderStyle}>
              <DropdownButton
                eventKey={1}
                size="md"
                variant="outlined-dark"
                className={s.dropdownStyle}
                title={
                  <span className={s.marginName}>
                    <Image
                      src={
                        appEasyState.userObject.image
                          ? appEasyState.userObject.image
                          : ProfileAvatar
                      }
                      height="30"
                      alt="Profile Picture"
                    />
                    {appEasyState.userObject.firstName}
                  </span>
                }
              >
                <Dropdown.Item
                  className={s.dropDownSetting}
                  activeKey={-1}
                  eventKey="1"
                  onClick={() => {
                    if (
                      !user.subscriptionDetails ||
                      user.subscriptionDetails.status === 'canceled' ||
                      (user.subscriptionDetails.status !== 'active' &&
                        user.subscriptionDetails.status !== 'trialing')
                    ) {
                      store.addNotification({
                        title: 'Please subscribe to a plan',
                        message:
                          'Please subscribe to a plan to continue using application.',
                        type: 'warning',
                        ...NOTIFICATION_PROPS,
                      });
                    } else if (
                      !window.location.href.includes('account') &&
                      user.isOnboardingCompleted !== undefined &&
                      user.isOnboardingCompleted === false
                    ) {
                      store.addNotification({
                        title: 'Onboarding Not Completed',
                        message:
                          'Please setup your account to continue using application.',
                        type: 'warning',
                        ...NOTIFICATION_PROPS,
                      });
                    } else push('/account');
                  }}
                >
                  <Image src={Account} className={s.setting} alt="settings" />
                  Settings
                </Dropdown.Item>
                <Dropdown.Divider className={s.divider} />
                <Dropdown.Item
                  eventKey="2"
                  className={s.dropDownLogout}
                  onClick={() =>
                    rest.firebase.doSignOut().then(() => {
                      localStorage.clear();
                      push('/');
                    })
                  }
                >
                  <Image src={Logout} className={s.logout} alt="logout" />
                  Logout
                </Dropdown.Item>
              </DropdownButton>
            </Nav>
          </Navbar>
        ) : (
          <Button
            variant="text"
            onClick={() =>
              rest.firebase.doSignOut().then(() => {
                localStorage.clear();
                push('/');
              })
            }
          >
            <Image src={LogoutImg} className={s.logoutIcon} alt="logout" />
            Logout
          </Button>
        )}
      </div>
      <hr className={s.line} />
    </Fragment>
  );
};

const condition = (user) => !!user;
export default withAuthorization(condition)(withRouter(view(Header)));
