/* eslint-disable indent */
import React from 'react';
import s from './style.scss';
import Logo from '../../assets/seal-logo.png';
import { Image } from 'react-bootstrap';
import moment from 'moment';

const LogoBrand = (props) => {
  const {
    imgSize = 60,
    className,
    home,
    history: { push },
  } = props;
  return (
    <div
      className={home ? `${s.flexRow} ${s.hover}` : s.flexRow}
      onClick={
        home
          ? () =>
              props.user.subscriptionDetails &&
              (props.user.subscriptionDetails.status === 'trialing' ||
                props.user.subscriptionDetails.status === 'active') &&
              moment
                .unix(props.user.subscriptionDetails.current_period_end)
                .isAfter(moment(new Date())) &&
              push('/dashboard')
          : undefined
      }
    >
      <Image height={imgSize} width={imgSize} src={Logo} alt="logo-sealpact" />
      <p className={`${s.alignText} ${className}`}>SealPact</p>
    </div>
  );
};

export default LogoBrand;
