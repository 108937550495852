import React from 'react';
import s from './style.scss';
import { ProgressBar } from 'react-bootstrap';

const DownloadingBar = (props) => {
  const now = 100;
  var label = 'Downloading';
  return (
    <div className={s.spinnerOuter}>
      <div>
        <ProgressBar animated now={now} label={label} />
      </div>
    </div>
  );
};

export default DownloadingBar;
