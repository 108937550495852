import React, { useState, useEffect } from 'react';
import DynamicModal from '../../DynamicModal';
import { Row, Container, Image, Button, Col } from 'react-bootstrap';
import s from './style.scss';
import { Formik, Form } from 'formik';
import { ProfileValidationSchema } from '../../../config/FormSchemas';
import MaskedInput from '../../AppComponents/MaskedInput';
import { withFirebase } from '../../../auth/firebase';
import { ImagePicker } from 'react-file-picker';
import Avatar from '../../../assets/avatar.png';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';
import {view} from 'react-easy-state'
import { appEasyState } from '../../../easyStateStore';


const EditProfile = ({
  show,
  handleModal,
  firebase,
  user,
  reloadUser,
  updateProfilePicture,
}) => {
  const [imageUrl, setImageUrl] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isUpdateImage, setIsUpdateImage] = useState(false);
  const [isDeleteImage, setIsDeleteImage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setImageUrl(user.image);
      setFirstName(user.firstName);
      setMiddleName(user.middleName);
      setLastName(user.lastName);
      appEasyState.anyProfileError = false;
    } else {
      setImageUrl([]);
      setFirstName('');
      setMiddleName('');
      setLastName('');
    }
  }, [show]);

  const uploadProfilePicture = async (base64String, firebase, uid) => {
    await firebase.user(user.uid).then((user) => {
      console.log('uploadProfilePicture user', user)
      debugger
    })
    await firebase
      .uploadUserProfilePicture(uid, base64String)
      .then((uploadRes) => {
        // store.addNotification({
        //   title: 'Uploaded',
        //   message: 'Picture Uploaded successfully.',
        //   type: 'default',
        //   ...NOTIFICATION_PROPS,
        // });
        // reloadUser();
        return;
      })
      .catch((errMsg) => {
        appEasyState.anyProfileError = true
        console.log('errMsg', errMsg)
        setLoading(false)
      });
  };

  const deleteProfilePicture = async (firebase, user) => {
    await firebase
      .deleteUserProfilePicture(user.uid)
      .then((deleteRes) => {
        setImageUrl(null);
        // reloadUser();
        // store.addNotification({
        //   title: 'Deleted',
        //   message: 'Profile picture deleted.',
        //   type: 'default',
        //   ...NOTIFICATION_PROPS,
        // });

        return;
      })
      .catch((error) => {
        appEasyState.anyProfileError = true
        setLoading(false)
        console.log('Errror in deleteing ', error);
        return;
      });
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    // user.firstName = e.target.value;
  };

  const handleMiddleNameChange = (e) => {
    setMiddleName(e.target.value);
    // user.middleName = e.target.value;
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    // user.lastName = e.target.value;
  };

  return (
    <DynamicModal title="Edit Profile" show={show} hideModal={handleModal}>
      <Formik
        initialValues={{
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
        }}
        validationSchema={ProfileValidationSchema}
        onSubmit={async (_values, { setSubmitting }) => {
          setLoading(true)
          console.log('editProfile isUpdateImage', isUpdateImage)
          console.log('editProfile isDeleteImage', isDeleteImage)
          console.log('editProfile imageUrl', imageUrl)
          console.log('editProfile isUpdateImage', isUpdateImage)
          console.log('editProfile isDeleteImage', isDeleteImage)

          debugger
          if (isUpdateImage)
            await uploadProfilePicture(imageUrl, firebase, user.uid)
          else if (isDeleteImage)
            await deleteProfilePicture(firebase, user)
          if (!appEasyState.anyProfileError) {
            console.log('_values', _values)

            firebase
              .updateUser(user.uid, _values)
              .then(async () => {
                console.log('imageUrl', imageUrl)
                debugger
                setSubmitting(false);
                updateProfilePicture(
                  imageUrl,
                  _values.firstName,
                  _values.lastName,
                  _values.middleName,
                );
                await reloadUser();
                setIsUpdateImage(false)
                setIsDeleteImage(false)
                handleModal();
                setLoading(false)
                appEasyState.profileUpdatedSuccessfully = 'true'
                localStorage.setItem('profileUpdatedSuccessfully', 'true')
                localStorage.setItem('userId', user.uid)
                store.addNotification({
                  title: 'Updated Successfully!',
                  message: 'Profile updated successfully.',
                  type: 'default',
                  ...NOTIFICATION_PROPS,
                });
                // window.location.reload(false);
              })
              .catch((error) => {
                setLoading(false)
                console.log('error', error);
                store.addNotification({
                  title: 'Error',
                  message: 'Profile updated unsuccessfully.',
                  type: 'danger',
                  ...NOTIFICATION_PROPS,
                });
                return;
              });
          }
          else {
            setLoading(false)
            store.addNotification({
              title: 'Error',
              message: 'Profile updated unsuccessfully.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
          }
        }}
      >
        {(props) => {
          return (
            <Form onSubmit={props.handleSubmit}>
              <Container>
                <Row className="d-flex flex-column align-items-center">
                  <Image
                    src={imageUrl || Avatar}
                    rounded
                    width="10%"
                    // className={s.rotate45deg}
                  />
                  <Row className="mt-1">
                    <Col
                      xs={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Button
                        variant="transparent"
                        className="p-0"
                        onClick={() => {
                          setImageUrl(null)
                          setIsDeleteImage(true)
                          setIsUpdateImage(false)
                          // deleteProfilePicture(firebase, user);
                        }}
                      >
                        <b>Delete</b>
                      </Button>
                      <div className={s.divider}></div>
                      <ImagePicker
                        extensions={['jpg', 'jpeg', 'png']}
                        dims={{
                          minWidth: 100,
                          maxWidth: 10000,
                          minHeight: 100,
                          maxHeight: 10000,
                        }}
                        onChange={(base64) => {
                          // uploadProfilePicture(base64, firebase, user.uid);
                          setImageUrl(base64);
                          // reloadUser();
                          setIsUpdateImage(true)
                          setIsDeleteImage(false)
                        }}
                        onError={(errMsg) =>
                          store.addNotification({
                            title: 'Error',
                            message: errMsg,
                            type: 'danger',
                            ...NOTIFICATION_PROPS,
                          })
                        }
                      >
                        <Button variant="transparent" className="p-0">
                          <b>Change</b>
                        </Button>
                      </ImagePicker>
                      {/* <Button variant="text">
                       <Image
                         height={19}
                         src={RotateRight}
                         alt="rotate-right"
                       />
                     </Button>
                     <Button variant="text">
                       <Image
                         height={19}
                         src={RotateLeft}
                         alt="rotate-right"
                       />
                     </Button> */}
                    </Col>
                    <Col xs={12} className="mt-3">
                      <MaskedInput
                        name="firstName"
                        formikProps={props}
                        label="First Name"
                        value={firstName}
                        onTextChange={handleFirstNameChange}
                        maxLength={15}
                      />
                      <MaskedInput
                        name="middleName"
                        formikProps={props}
                        label="Middle Name"
                        value={middleName}
                        onTextChange={handleMiddleNameChange}
                        maxLength={15}
                      />
                      <MaskedInput
                        name="lastName"
                        formikProps={props}
                        label="Last Name"
                        value={lastName}
                        onTextChange={handleLastNameChange}
                        maxLength={15}
                      />
                    </Col>
                  </Row>
                </Row>
                <Row className="pt-4 mt-4">
                  <Col
                    xs={12}
                    className={`d-flex justify-content-between ${s.btnStyle}`}
                  >
                    <Button variant="outline-dark" onClick={handleModal}>
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      {loading ? (
                          <div>
                            <span className="spinner-border spinner-border-sm mr-2" />
                            Updating
                          </div>
                        ) : (
                          <div>Confirm</div>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </DynamicModal>
  );
};

export default withFirebase(view(EditProfile));
