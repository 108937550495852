/* eslint-disable indent */
import React, { useState, Fragment, useEffect } from 'react';
import Header from '../../Header';
import UploadDocument from '../UploadDocument';
import SearchInput from '../../SearchInput';
import s from './style.scss';
import { Tabs, Tab, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { All_DOCUMENTS } from '../../../utils/constants';
import ColorLabels from './ColorLabels';
import COLUMNS from './COLUMNS';
import Spinner from '../../Layout/Spinner';
import withAuthorization from '../../../auth/session/withAuthorization';
import { set } from 'local-storage';

const ScannedDocumentDetails = (props) => {
  console.log(`props in user docs ===>`, props);
  const { user, firebase } = props;
  const [key, setKey] = useState('all');
  const [allDocuments, setAllDocuments] = useState(
    props.location.state.scanDetailsList,
  );
  const [originalData, setOriginalData] = useState(
    props.location.state.scanDetailsList,
  );
  const [loading, setLoading] = useState(false);

  const getUniqueCount = () => {
    const totalUniqueCount = [];
    // if (props.location.state.scanDetailsList) {
    //   props.location.state.scanDetailsList.forEach((sub) => {
    //     console.log(`sub ------------> `, sub);
    //     if (!totalUniqueCount.includes(sub)) {
    //       totalUniqueCount.push(sub);
    //     }
    //   });
    //   return totalUniqueCount.length;
    // } else {
    //   return totalUniqueCount.length;
    // }

    const unique = [
      ...new Set(props.location.state.scanDetailsList.map((item) => item.ip4)),
    ];

    return unique.length;
  };

  // useEffect(() => {
  //   setLoading(true);
  //   firebase
  //     .getAllDocuments(props.location.state.user)
  //     .then((userDocs) => {
  //       setLoading(false);
  //       setAllDocuments(userDocs);
  //       setOriginalData(userDocs);
  //       console.log('userDocs ===> ', userDocs);
  //       return;
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.log(`error ===> `, error);
  //     });
  // }, []);

  const showdocumentTab = (arg) => setKey(arg);
  const getFilteredData = (data) => {
    setAllDocuments(data);
  };

  const getTrProps = (table, rowInfo) => {
    return {
      onClick: (e) => {
        console.log('rowInfo ===> ', rowInfo);

        // if (rowInfo.original.status !== 'pacted') {
        //   props.history.push('/documentDetails', {
        //     documentSubmitted: true,
        //     documentDetails: rowInfo.original.data,
        //     backToDoc: false,
        //     numPages: rowInfo.original.totalPages,
        //     fileType: rowInfo.original.fileType,
        //     documentInformation: rowInfo.original,
        //     isMyDocuments: true,
        //   });
        // } else {
        //   props.history.push('/documentDetails', {
        //     documentSubmitted: true,
        //     documentDetails: rowInfo.original.sealPactDownloadUrl,
        //     backToDoc: false,
        //     numPages: rowInfo.original.totalPages,
        //     fileType: rowInfo.original.fileType,
        //     documentInformation: rowInfo.original,
        //     isMyDocuments: true,
        //   });
        //   // props.history.push('/sealpactDetails', {
        //   //   confirmationValues: rowInfo.original,
        //   //   documentInformation: rowInfo.original,
        //   // });
        // }
      },
      style: {
        borderRight: 0,
        borderBottom: rowInfo
          ? rowInfo.original
            ? 'Solid black 2px'
            : 'lightgray'
          : null,
        borderLeft: 'solid #0b71ff 5px',
      },
    };
  };

  const getTitle = () => {
    let title = 'Scan Details';
    return title;
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Header title={getTitle()} />
      <div className={`row no-gutters  ${s.features} `}>
        <div className="col text-left">
          <p className={s.countStyle}>
            Total Unique scan count: {getUniqueCount()}
          </p>
          <p className={s.countStyle}>
            Total scan count: {props.location.state.scanDetailsList.length}
          </p>
        </div>
        <div className="col-12 col-sm-8 col-md-5 col-lg-3 mt-1">
          {/* <SearchInput
            array={originalData}
            getFilteredData={getFilteredData}
            searchTarget="firstName"
          /> */}
        </div>
        <Col xs={12}>
          <div className={`tbl  ${s.tblContainer}`}>
            <ReactTable
              data={allDocuments}
              columns={COLUMNS}
              expanded={true}
              className={s.ReactTable}
              getTrProps={getTrProps}
              showPagination={true}
            />
          </div>
          <Tabs
            id="docs-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className={`docstable ${s.tabs}`}
          >
            <Tab eventKey="all" title="All" className="docsTab"></Tab>
          </Tabs>
        </Col>
      </div>
    </Fragment>
  );
};
const condition = (user) => !!user;
export default withAuthorization(condition)(ScannedDocumentDetails);
