/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react';

const TermsAndConditions = () => {
  return (
    <Fragment>
      <p className="margin-bottom: 0in;line-height: 100%;text-align: left;">
        <strong>Terms of Service</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <br />
        <br />
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Terms and Conditions</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        These terms and conditions (&quot;Terms&quot;, &quot;Agreement&quot;)
        are an agreement between SealPact, LLC (&quot;SealPact, LLC&quot;,
        &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you
        (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). This Agreement
        sets forth the general terms and conditions of your use of the{' '}
        <a href="http://www.sealpact.com/">
          <u>sealpact.com</u>
        </a>{' '}
        website and any of its products or services (collectively,
        &quot;Website&quot; or &quot;Services&quot;).
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        The Term &ldquo;<em>SealPact</em>&rdquo; refers to the digital signing,
        stamping and/or sealing of a document with software produced by
        SealPact, LLC and third-party providers.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        The Term &ldquo;<em>User</em>&rdquo; refers to person and/or entity
        providing information to and/or receiving information through software
        produced or provided by SealPact, LLC, whether a paying subscriber or
        not.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Accounts and Membership</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You must be at least 18 years of age to use this Website. By using this
        Website and by agreeing to this Agreement you warrant and represent that
        you are at least 18 years of age. If you create an account on the
        Website, you are responsible for maintaining the security of your
        account and you are fully responsible for all activities that occur
        under the account and any other actions taken in connection with it. We
        may monitor and review new accounts before you may sign in and use our
        Services. Providing false contact information of any kind may result in
        the termination of your account. You must immediately notify us of any
        unauthorized uses of your account or any other breaches of security. We
        will not be liable for any acts or omissions by you, including any
        damages of any kind incurred as a result of such acts or omissions. We
        may suspend, disable, or delete your account (or any part thereof) if we
        determine that you have violated any provision of this Agreement or that
        your conduct or content would tend to damage our reputation and
        goodwill. If we delete your account for the foregoing reasons, you may
        not re-register for our Services. We may block your email address and
        Internet protocol address to prevent further registration.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Billing and Payments</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You shall pay all fees or charges to your account in accordance with the
        fees, charges, and billing terms in effect at the time a fee or charge
        is due and payable. Where Services are offered on a free trial basis,
        payment may be required after the free trial period ends, and not when
        you enter your billing details (which may be required prior to the
        commencement of the free trial period). If auto-renewal is enabled for
        the Services you have subscribed for, you will be charged automatically
        in accordance with the term you selected. If, in our judgment, your
        purchase constitutes a high-risk transaction, we will require you to
        provide us with a copy of your valid government-issued photo
        identification, and possibly a copy of a recent bank statement for the
        credit or debit card used for the purchase. We reserve the right to
        change products, subscriptions and pricing at any time. We also reserve
        the right to refuse any order you place with us. We may, in our sole
        discretion, limit or cancel subscriptions purchased per person, per firm
        or per order. These restrictions may include orders placed by or under
        the same customer account, the same credit card, and/or orders that use
        the same billing and/or shipping address. In the event that we make a
        change to or cancel an order, we may attempt to notify you by contacting
        the e-mail and/or billing address/phone number provided at the time the
        order was made. You can cancel your SealPact, LLC subscription at any
        time, and you will continue to have access to the respective features of
        the subscription through the end of your monthly or annually billing
        period. To cancel, go to the &quot;Billing&quot; page within
        &quot;Settings&quot; on our website and follow the instructions for
        changing or downgrading your plan to the free option. Payments are
        nonrefundable and there are no refunds or credits for partially used
        periods. Following any cancellation; however, you will continue to have
        access to the service through the end of your current billing period. At
        any time, and for any reason, we may provide a refund, discount, or
        other consideration to some or all of our members (&quot;credits&quot;).
        The amount and form of such credits, and the decision to provide them,
        are at our sole and absolute discretion. The provision of credits in one
        instance does not entitle you to credits in the future for similar
        instances, nor does it obligate us to provide credits in the future,
        under any circumstance.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Accuracy of Information</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Occasionally there may be information on the Website that contains
        typographical errors, inaccuracies or omissions that may relate to
        product descriptions, pricing, availability, promotions and offers. We
        reserve the right to correct any errors, inaccuracies or omissions, and
        to change or update information or cancel orders if any information on
        the Website or on any related Service is inaccurate at any time without
        prior notice (including after you have submitted your order). We
        undertake no obligation to update, amend or clarify information on the
        Website including, without limitation, pricing information, except as
        required by law. No specified update or refresh date applied on the
        Website should be taken to indicate that all information on the Website
        or on any related Service has been modified or updated.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Backups</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We are not responsible for Content residing on the Website. In no event
        shall we be held liable for any loss of any Content. It is your sole
        responsibility to maintain appropriate backup of your Content.
        Notwithstanding the foregoing, on some occasions and in certain
        circumstances, with absolutely no obligation, we may be able to restore
        some or all of your data that has been deleted as of a certain date and
        time when we may have backed up data for our own purposes. We make no
        guarantee that the data you need will be available.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Links to Other Websites</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Although this Website may link to other websites, we are not, directly
        or indirectly, implying any approval, association, sponsorship,
        endorsement, or affiliation with any linked website, unless specifically
        stated herein. Some of the links on the Website may be &quot;affiliate
        links&quot;. This means if you click on the link and purchase an item,
        SealPact, LLC will receive an affiliate commission. We are not
        responsible for examining or evaluating, and we do not warrant the
        offerings of, any businesses or individuals or the content of their
        websites. We do not assume any responsibility or liability for the
        actions, products, services, and content of any other third-parties. You
        should carefully review the legal statements and other conditions of use
        of any website which you access through a link from this Website. Your
        linking to any other off-site websites is at your own risk.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Prohibited Uses</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        In addition to other terms as set forth in the Agreement, you are
        prohibited from using the Website or its Content: (a) for any unlawful
        purpose; (b) to solicit others to perform or participate in any unlawful
        acts; (c) to violate any international, federal, provincial or state
        regulations, rules, laws, or local ordinances; (d) to infringe upon or
        violate our intellectual property rights or the intellectual property
        rights of others; (e) to harass, abuse, insult, harm, defame, slander,
        disparage, intimidate, or discriminate based on gender, sexual
        orientation, religion, ethnicity, race, age, national origin, or
        disability; (f) to submit false or misleading information; (g) to upload
        or transmit viruses or any other type of malicious code that will or may
        be used in any way that will affect the functionality or operation of
        the Service or of any related website, other websites, or the Internet;
        (h) to collect or track the personal information of others; (i) to spam,
        phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or
        immoral purpose; or (k) to interfere with or circumvent the security
        features of the Service or any related website, other websites, or the
        Internet. We reserve the right to terminate your use of the Service or
        any related website for violating any of the prohibited uses.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Intellectual Property Rights</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        This Agreement does not transfer to you any intellectual property owned
        by SealPact, LLC or third-parties, and all rights, titles, and interests
        in and to such property will remain (as between the parties) solely with
        SealPact, LLC. All trademarks, service marks, graphics and logos used in
        connection with our Website or Services, are trademarks or registered
        trademarks of SealPact, LLC or SealPact, LLC licensors. Other
        trademarks, service marks, graphics and logos used in connection with
        our Website or Services may be the trademarks of other third-parties.
        Your use of our Website and Services grants you no right or license to
        reproduce or otherwise use any SealPact, LLC or third-party trademarks.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Disclaimer of Warranty</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You agree that your use of our Website or Services is solely at your own
        risk. You agree that such Service is provided on an &quot;as is&quot;
        and &quot;as available&quot; basis. We expressly disclaim all warranties
        of any kind, whether express or implied, including but not limited to
        the implied warranties of merchantability, fitness for a particular
        purpose and non-infringement. We make no warranty that the Services will
        meet your requirements, or that the Service will be uninterrupted,
        timely, secure, or error-free; nor do we make any warranty as to the
        results that may be obtained from the use of the Service or as to the
        accuracy or reliability of any information obtained through the Service
        or that defects in the Service will be corrected. You understand and
        agree that any material and/or data downloaded or otherwise obtained
        through the use of Service is done at your own discretion and risk and
        that you will be solely responsible for any damage to your computer
        system or loss of data that results from the download of such material
        and/or data. We make no warranty regarding any goods or services
        purchased or obtained through the Service or any transactions entered
        into through the Service. No advice or information, whether oral or
        written, obtained by you from us or through the Service shall create any
        warranty not expressly made herein. Furthermore, the user understands
        that SealPact, LLC may use API&rsquo;s to this party cloud services, and
        has no control over the functionality or reliability of these third
        party services and has absolutely no responsibility for technical
        performance of these third party services.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Limitation of Liability</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        To the fullest extent permitted by applicable law, in no event will
        SealPact, LLC, its affiliates, officers, directors, employees, agents,
        suppliers or licensors be liable to any person for (a): any indirect,
        incidental, special, punitive, cover or consequential damages
        (including, without limitation, damages for lost profits, revenue,
        sales, goodwill, use of content, impact on business, business
        interruption, loss of anticipated savings, loss of business opportunity)
        however caused, under any theory of liability, including, without
        limitation, contract, tort, warranty, breach of statutory duty,
        negligence or otherwise, even if SealPact, LLC has been advised as to
        the possibility of such damages or could have foreseen such damages. To
        the maximum extent permitted by applicable law, the aggregate liability
        of SealPact, LLC and its affiliates, officers, employees, agents,
        suppliers and licensors, relating to the services will be limited to an
        amount greater of one dollar or any amounts actually paid in cash by you
        to SealPact, LLC for the prior one month period prior to the first event
        or occurrence giving rise to such liability. The limitations and
        exclusions also apply if this remedy does not fully compensate you for
        any losses or fails of its essential purpose.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        Except for payment obligations, neither SealPact, LLC nor Customer will
        be liable by reason of any failure or delay in the performance of its
        obligations on account of events beyond the reasonable control of a
        party, which may include denial-of-service attacks, a failure by a third
        party hosting provider or utility provider, shortages, riots, fires,
        acts of God, war, strikes, terrorism, and governmental action.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        SealPact, LLC shall not be liable for any loss, deletion, removal and/or
        failure of delivery to the intended recipient of User Content, whether
        caused by computer virus, unauthorized access and/or otherwise. You are
        encouraged to retain a back-up copy of all User Content and you
        undertake that you shall do so in respect of all uploaded User Content.
        SealPact, LLC reserves the right to deny access to this site and delete
        User Content at any time without notice.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Indemnification</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You agree to indemnify and hold SealPact, LLC and its affiliates,
        directors, officers, employees, and agents harmless from and against any
        liabilities, losses, damages or costs, including reasonable
        attorneys&apos; fees, incurred in connection with or arising from any
        third-party allegations, claims, actions, disputes, or demands asserted
        against any of them as a result of or relating to your Content, your use
        of the Website or Services, including, but not limited to, claims of
        your negligence, breach of contract, and/or any willful misconduct on
        your part.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Intentions &amp; Contradictions</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        It is the intent of SealPact, LLC to provide users with the opportunity
        to bring clarity and transparency to digital signature agreements. It is
        the user&rsquo;s responsibility to ensure that any information provided
        to SealPact, LLC is accurate to the best of their knowledge, and
        consistent with any pre-existing contracts, either written or verbal.
        Potential users include, but are not limited to, design professionals,
        their clients, contractors, building officials and any applicable
        supervisors to the aforementioned. Any contradictions between
        pre-existing contracts between the aforementioned and information
        provided to SealPact, LLC shall be resolved directly by the applicable
        parties prior to document completion and acceptance. It is also the
        responsibility of each of the respective parties to use the services
        provided by SealPact, LLC in accordance with applicable state statutes.
        SealPact, LLC has no responsibility to provide information between third
        parties involved in a dispute; however, SealPact, LLC maintains the
        right to provide such information at its discretion on a voluntary or
        required basis.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Severability</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        All rights and restrictions contained in this Agreement may be exercised
        and shall be applicable and binding only to the extent that they do not
        violate any applicable laws and are intended to be limited to the extent
        necessary so that they will not render this Agreement illegal, invalid
        or unenforceable. If any provision or portion of any provision of this
        Agreement shall be held to be illegal, invalid or unenforceable by a
        court of competent jurisdiction, it is the intention of the parties that
        the remaining provisions or portions thereof shall constitute their
        agreement with respect to the subject matter hereof, and all such
        remaining provisions or portions thereof shall remain in full force and
        effect.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Dispute Resolution</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        The formation, interpretation, and performance of this Agreement and any
        disputes arising out of it shall be governed by the substantive and
        procedural laws of Connecticut, United States without regard to its
        rules on conflicts or choice of law and, to the extent applicable, the
        laws of United States. The exclusive jurisdiction and venue for actions
        related to the subject matter hereof shall be the state and federal
        courts located in Connecticut, United States, and you hereby submit to
        the personal jurisdiction of such courts. You hereby waive any right to
        a jury trial in any proceeding arising out of or related to this
        Agreement. The United Nations Convention on Contracts for the
        International Sale of Goods does not apply to this Agreement.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        All claims or disputes arising from use of services provided by
        SealPact, LLC shall be litigated on an individual basis and not on a
        class basis, and the claims of more than one customer or user cannot be
        arbitrated or litigated jointly or consolidated with those of any other
        customer or user. In the event that this Section is deemed invalid or
        unenforceable neither you nor we are entitled to arbitration and instead
        claims and disputes shall be resolved in a court located in Connecticut,
        United States.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Assignment</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You may not assign, resell, sub-license or otherwise transfer or
        delegate any of your rights or obligations hereunder, in whole or in
        part, without our prior written consent, which consent shall be at our
        own sole discretion and without obligation; any such assignment or
        transfer shall be null and void. We are free to assign any of its rights
        or obligations hereunder, in whole or in part, to any third-party as
        part of the sale of all or substantially all of its assets or stock or
        as part of a merger.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Changes and Amendments</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        We reserve the right to modify this Agreement or its policies relating
        to the Website or Services at any time, effective upon posting of an
        updated version of this Agreement on the Website. When we do, we will
        revise the updated date at the bottom of this page. Continued use of the
        Website after any such changes shall constitute your consent to such
        changes.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Acceptance of These Terms</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        You acknowledge that you have read this Agreement and agree to all its
        terms and conditions. By using the Website or its Services you agree to
        be bound by this Agreement. If you do not agree to abide by the terms of
        this Agreement, you are not authorized to use or access the Website and
        its Services.
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        <strong>Contacting us</strong>
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        If you would like to contact us to understand more about this Agreement
        or wish to contact us concerning any matter relating to it, you may send
        an email to support@sealpact.com
      </p>
      <p className="margin-bottom: 0.19in;line-height: 100%;text-align: left;">
        This document was last updated on February 9, 2021
      </p>
      <p className="margin-bottom: 0.11in;line-height: 108%;text-align: left;">
        <br />
        <br />
      </p>
    </Fragment>
  );
};

export default TermsAndConditions;
