import React from 'react';
import s from '../style.scss';
import { Row, Col } from 'react-bootstrap';


const QualityTabInfo = ({ confirmationValues }) => {
  // const renderValue = (arg) => (arg === true ? 'Yes' : 'No');
  const renderValue = (arg) => {
    if (arg !== undefined) {
      if (typeof  arg === 'string')
        return arg;
      return arg === true ? 'Yes' : 'No';
    }
    return 'N/A'
  };
  return (
    <Row>
      <Col>
        <div className={s.wrapper}>
          <p>Is Quality Control Needed</p>
          <span>{confirmationValues.qualityNeededWithDefault || renderValue(confirmationValues.qualityNeeded)}</span>
          <p>Independent Review Required</p>
          <span>{
            confirmationValues.independentReviewWithDefault || renderValue(confirmationValues.independentReview)}</span>
          <p>
            Are Shop Drawings Required? (stamped delegated/component designs?)
          </p>
          <span>{
            confirmationValues.shopDrawingsWithDefault || renderValue(confirmationValues.shopDrawings)}</span>
          <p>If so, which?</p>
          <span>
            {confirmationValues.shopDrawingsValue
              ? confirmationValues.shopDrawingsOtherValue
                ? confirmationValues.shopDrawingsValue +
                  ',' +
                  confirmationValues.shopDrawingsOtherValue
                : confirmationValues.shopDrawingsValue
              : 'N/A'}
          </span>
          <p>Construction Administration (CA)</p>
          <span>
            {confirmationValues.constructionAdministrationWithDefault ||
              renderValue(confirmationValues.constructionAdministration)}
          </span>
          <p>Site Visits</p>
          <span>{confirmationValues.constructionAdministrationWithDefault
            ? confirmationValues.constructionAdministrationWithDefault === 'Yes'
              ? confirmationValues.siteVisits : 'N/A'
            : confirmationValues.constructionAdministration ? confirmationValues.siteVisits : 'N/A'}</span>
        </div>
      </Col>
      <Col>
        <div className={s.wrapper}>
          <p>Special Inspections (SI)</p>
          <span>{confirmationValues.specialInspectionWithDefault || renderValue(confirmationValues.specialInspection)}</span>
          <p>Attach (SI) Form</p>
          {confirmationValues.specialInspectionWithDefault
              ? confirmationValues.specialInspectionWithDefault === 'Yes'
                ? (
                  <a
                    href={confirmationValues.specialInspectionForm}
                    target="_blank"
                  >
                    SI Form
                  </a>
                )
                : (<span>N/A</span>)
              : confirmationValues.specialInspectionForm
                ? (
                  <a
                    href={confirmationValues.specialInspectionForm}
                    target="_blank"
                  >
                    SI Form
                  </a>
                )
                : (<span>N/A</span>)
          }
        </div>
      </Col>
      <Col />
    </Row>
  );
};

export default QualityTabInfo;
