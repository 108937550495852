/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import imageType from 'image-type';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import htmlToImage from 'html-to-image';
import moment from 'moment';
import Logo from '../../../assets/seal-logo.png';
import QRCode from 'qrcode.react';
import mergeImages from 'merge-images';
import InvalidateSealModal from '../SealPactDetails/InvalidateSealModal';
import ReSealPactModal from '../SealPactDetails/ReSealPactModal';

import { Document, Page, pdfjs } from 'react-pdf';

import withAuthorization from '../../../auth/session/withAuthorization';

import Header from '../../Header';
import s from './style.scss';
// import ButtonClose from "../../assets/button-close.png";
import ButtonCheck from '../../../assets/check-mark.png';
import DangerButton from '../../../assets/error-mark.png';
import { Button, Image, Navbar } from 'react-bootstrap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import SealConfirmationFlow from './OnConfirmation';
import SelectSealPage from './SelectSealPages';
import ReviewSealPact from './ReviewSealPact';
import ConfirmSealModal from './ConfirmSealModal';
import WarningPopupModal from './WarningPopupModal';
import SealPactDetails from '../SealPactDetails';
import ImageControls from './ImageControls';
import SideDrawer from './SideDrawer';
import Draggable from 'react-draggable';
import {
  DRAW_CLICKABLE,
  NOTIFICATION_PROPS,
  NOTIFICATION_PROPS_LONGER,
} from '../../../utils/constants';
import Spinner from '../../Layout/Spinner';
import SealProgressBar from '../../Layout/sealProgressBar';
import DynamicModal from '../../DynamicModal';
// import {ResizableBox} from 'react-resizable';
import { Rnd } from 'react-rnd';
import { view, store as easyStateStore } from 'react-easy-state';
import Resizer from 'react-image-file-resizer';
import ButtonClose from '../../../assets/button-close.png';
import { getCroppedImg } from '../../Cropper/cropImage';
import { appEasyState } from '../../../easyStateStore';
import CustomTooltip from '../../AppComponents/CustomTooltip';

const DocumentDetails = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  console.log('props in document details ===>', props);

  let {
    history: { push },
    location: {
      invalidated,
      state: {
        documentDetails,
        backToDoc,
        isMyDocuments,
        numPages,
        fileType,
        documentInformation,
        sealPactValues,
        viewer,
        shared,
      },
    },
    user,
    firebase,
    reloadUser,
  } = props;

  let boxState = easyStateStore({
    sealPactWidth: 0,
    sealPactHeight: 0,
    sealPactImageWidth: 0,
    sealPactImageHeight: 0,
    sealPactImageMinWidth: 0,
    sealPactImageMinHeight: 0,
    sealPactLogoImageWidth: 16,
    sealPactLogoImageHeight: 16,
    sealPactTextLarge: 11,
    sealPactTextSmall: 7,

    resizableBoxWidth: 0,
    resizableBoxHeight: 0,
    resizableBoxMinWidth: 80,
    resizableBoxMinHeight: 120,
    resizableBoxMaxWidth: 800,
    resizableBoxMaxHeight: 800,

    qrCodeWidth: 0,
    qrCodeHeight: 0,
    qrCodeBoxWidth: 0,
    qrCodeBoxHeight: 0,
    qrCodeBoxMinWidth: 0,
    qrCodeBoxMinHeight: 0,
    qrCodeBoxMaxHWidth: 0,
    qrCodeBoxMaxHeight: 0,

    resizedSeal: null,
    resizedSealArrayBuffer: null,

    pdfDoc: undefined,
    qrCodeBuffer: '',

    zoomRatio: 1,

    x_seal: 60,
    y_seal: 30,
    x_QR: 60,
    y_QR: 180,

    pagesRotation: undefined,
    pages: undefined,

    qrCodeDragging: undefined,

    watermarkTextSize: 7,
    watermarkBottom: 30,
    watermarkLeft: 10,
  });

  useEffect(() => {
    setTimeout(() => {
      if (boxState.pagesRotation === undefined)
        boxState.pagesRotation = Array(numPages).fill(0);

      if (boxState.qrCodeDragging === undefined)
        boxState.qrCodeDragging = Array(numPages).fill(true);
      console.log('boxState.qrCodeDragging useEf', boxState.qrCodeDragging);
    }, 1000);
  }, []);

  const [warnDefaultSeal, setShowWarning] = useState(false);
  const [selectSealPages, setSelectSealPages] = useState(false);
  const [stopped, setStopped] = useState(true);
  const [afterDragged, setafterDragged] = useState(false);
  const [isDragging, setisDragging] = useState(false);
  const [enableZoom, setenableZoom] = useState(false);
  const [enableMove, setEnableMove] = useState(false);
  const [enablePan, setenablePan] = useState(false);
  const [drawerVisible, setdrawerVisible] = useState(false);
  const [drawerSlideOut, setdrawerSlideOut] = useState(false);
  const [isSealSelected, setisSealSelected] = useState(false);
  const [isSealInvalidated, setisSealInvalidated] = useState(false);
  const [selectedSealInfo, setselectedSealInfo] = useState({});
  const [showConfirmationPopup, setshowConfirmationPopup] = useState(false);
  const [isSealConfirmed, setisSealconfirmed] = useState(false);
  const [showConfirmModal, setshowConfirmModal] = useState(false);
  const [showReviewModal, setshowReviewModal] = useState(false);
  const [showDetailModal, setshowDetailModal] = useState(false);
  let [pageNumber, setPageNumber] = useState(1);
  const [allActiveAssets, setAllActiveAssets] = useState([]);
  const [activeSeals, setActiveSeals] = useState([]);
  const [activeSignatures, setActiveSignatures] = useState([]);
  const [activeStamps, setActiveStamps] = useState([]);
  const [confirmationValues, setConfirmationValues] = useState({});
  const [rotation, setRotation] = useState(0);
  const [sameRotation, setSameRotation] = useState(true);
  const [rotationOptionSelected, setRotationOptionSelected] = useState(false);
  const [inRotateRight, setInRotateRight] = useState(false);
  const [panX, setPanX] = useState(0);
  const [panY, setPanY] = useState(0);
  const [sealPactDownloadUrl, setSealPactDownloadUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [disableControl, setDisableControl] = useState(false);
  const [sealFactor, setSealFactor] = useState(3);
  const [invalidationReason, setInvalidationReason] = useState();
  const [invalidateConfirmaton, setInvalidateConfirmaton] = useState(false);
  const [reSealPactConfirmaton, setReSealPactConfirmaton] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedPagesAndSealPos, setSelectedPagesAndSealPos] = useState({
    isFirstPage: false,
    isAllPages: false,
    isOtherPages: false,
    isSealOnSamePos: null,
  });

  const [stopped_QR, setStopped_QR] = useState(true);
  const [afterDragged_QR, setafterDragged_QR] = useState(false);
  const [isDragging_QR, setisDragging_QR] = useState(false);
  const [isQRSelected, setisQRSelected] = useState(false);
  const [selectedQRInfo, setselectedQRInfo] = useState({});

  const [documentStatus, setDocumentStatus] = useState('pending');
  const [zoomLevel, setZoom] = useState(1.0);
  const [pdfRatios, setRatios] = useState({});
  let qrCodeRef = useRef();
  let sealImgRef = useRef();
  let imageDocRef = useRef();
  let watermarkRef = useRef();

  const [sealPages, setSealPages] = useState({
    pageFrom: 1,
    pageTo: 1,
    range: [],
  });

  const [cancelQRDiv, setCancelQRDiv] = useState(false);
  const [parallelMoveSealAndQR, setParallelMoveSealAndQR] = useState(true);
  const [sealFirstTime, setSealFirstTime] = useState(false);
  const [qrFirstTime, setQrFirstTime] = useState(false);

  const resizeFile = (file, width = 500, height = 500) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        'PNG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
        500,
        500,
      );
    });

  const defaultMeasurements = (type) => {
    boxState.sealPactLogoImageWidth = 16;
    boxState.sealPactLogoImageHeight = 16;
    boxState.sealPactTextLarge = 11;
    boxState.sealPactTextSmall = 7;

    if (type === 'signature') {
      boxState.sealPactWidth = 193;
      // boxState.sealPactHeight = 100;
      boxState.sealPactHeight = 50;

      boxState.sealPactImageWidth = 100;
      // boxState.sealPactImageHeight = 95;
      boxState.sealPactImageHeight = 45;

      boxState.resizableBoxWidth = 193;
      // boxState.resizableBoxHeight = 100;
      boxState.resizableBoxHeight = 50;

      // boxState.resizableBoxMinWidth = 200;
      // boxState.resizableBoxMinHeight = 80;

      // boxState.sealPactImageMinWidth = 60;
      // boxState.sealPactImageMinHeight = 60;

      boxState.resizableBoxMinWidth = 175;
      boxState.resizableBoxMinHeight = 45;

      boxState.sealPactImageMinWidth = 40;
      boxState.sealPactImageMinHeight = 40;

      boxState.resizableBoxMaxWidth = 500;
      boxState.resizableBoxMaxHeight = 100;
    } else {
      // boxState.sealPactWidth = 163 -25;
      // boxState.sealPactHeight = 163 + 20;
      boxState.sealPactWidth = 130;
      boxState.sealPactHeight = 130;
      boxState.sealPactImageWidth = 130;
      boxState.sealPactImageHeight = 130;
      // boxState.resizableBoxWidth = 163 -25;
      // boxState.resizableBoxHeight = 163 + 20;
      boxState.resizableBoxWidth = 138;
      boxState.resizableBoxHeight = 138;

      boxState.resizableBoxMinWidth = 68;
      boxState.resizableBoxMinHeight = 68;

      boxState.sealPactImageMinWidth = 60;
      boxState.sealPactImageMinHeight = 60;

      boxState.resizableBoxMaxWidth = 400;
      boxState.resizableBoxMaxHeight = 450;
    }

    boxState.qrCodeWidth = 38;
    boxState.qrCodeHeight = 38;
    // boxState.qrCodeBoxWidth = 138;
    boxState.qrCodeBoxWidth = 188;
    boxState.qrCodeBoxHeight = 43;

    boxState.watermarkLeft = 0.11 * boxState.sealPactWidth * zoomLevel;
    boxState.watermarkBottom = 0.4 * boxState.sealPactHeight * zoomLevel;
  };

  useEffect(() => {
    defaultMeasurements(selectedSealInfo.type);

    console.log('selectedSealInfo.type: ', selectedSealInfo.type);

    // if (selectedSealInfo.type !== 'signature' && selectedSealInfo.type !== 'submittalReviewStamp') {
    //   if (selectedSealInfo.data) {
    //     console.log('data &&: ', selectedSealInfo.data)
    //
    //     axios.get(selectedSealInfo.data, { responseType: 'arraybuffer' })
    //       .then(async (res) => {
    //         console.log('res &&: ', res);
    //         boxState.resizedSealArrayBuffer = res.data;
    //         const file = new File(
    //           [res.data],
    //           'sealResizedImage.png',
    //           { type: 'image/png', lastModified: new Date()}
    //         );
    //
    //         console.log('file *******', file)
    //
    //         resizeFile(file)
    //           .then(resized => {
    //             console.log('resized &&: ', resized);
    //             selectedSealInfo.data = resized;
    //             boxState.resizedSeal = resized;
    //             console.log('img &&: ', selectedSealInfo.data);
    //           }).catch(err => console.log('resize err &&: ', err))
    //
    //       }).catch(err => console.log('axios err &&: ', err))
    //   }
    // }

    if (documentInformation.fileType === 'application/pdf') {
      fetch(documentDetails)
        .then((res) => res.arrayBuffer())
        .then((pdfBuffer) => {
          fetch(documentInformation.selectedSealInfo.data)
            .then((res) => res.arrayBuffer())
            .then((imageBuffer) => {
              PDFDocument.load(pdfBuffer).then(async (pdfDoc) => {
                boxState.pdfDoc = pdfDoc;

                let pages = pdfDoc.getPages();
                console.log('boxState.pagesRotation', boxState.pagesRotation);

                if (boxState.pagesRotation) {
                  pages.forEach((page, index) => {
                    page.setRotation(degrees(boxState.pagesRotation[index]));
                  });

                  console.log('pages pdf', pages);
                  boxState.pages = pages;
                }

                // alert('sealPages.pageFrom ' + sealPages.pageFrom)
                // alert('selectedPagesAndSealPos.isOtherPages ' + selectedPagesAndSealPos.isOtherPages)

                if (selectedPagesAndSealPos.isOtherPages) {
                  if (sealPages.pageFrom !== 1) {
                    setPageNumber(sealPages.pageFrom);
                    setTimeout(async () => {
                      const qrCanvas = document.getElementById('QRCode');
                      if (qrCanvas) {
                        const qrURL = qrCanvas
                          .toDataURL('image/png')
                          .replace('image/png', 'image/octet-stream');
                        let qrCode = await fetch(qrURL);
                        qrCode = boxState.qrCodeBuffer =
                          await qrCode.arrayBuffer();
                      }
                    }, 200);
                  } else {
                    const qrCanvas = document.getElementById('QRCode');
                    if (qrCanvas) {
                      const qrURL = qrCanvas
                        .toDataURL('image/png')
                        .replace('image/png', 'image/octet-stream');
                      let qrCode = await fetch(qrURL);
                      qrCode = boxState.qrCodeBuffer =
                        await qrCode.arrayBuffer();
                    }
                  }
                } else {
                  const qrCanvas = document.getElementById('QRCode');
                  if (qrCanvas) {
                    const qrURL = qrCanvas
                      .toDataURL('image/png')
                      .replace('image/png', 'image/octet-stream');
                    let qrCode = await fetch(qrURL);
                    qrCode = boxState.qrCodeBuffer = await qrCode.arrayBuffer();
                  }
                }

                console.log('boxState.qrCode', boxState.qrCode);
              });
            });
        })
        .catch((err) => console.log({ err }));
    }
  }, [selectedSealInfo.type]);

  function findInPagesRange(pageNumber) {
    if (sealPages.range.length !== 0) {
      return !!sealPages.range.find((elm) => elm === pageNumber);
    }

    return true;
  }

  // Using a constant value for default zoom
  let defaultZoomWidth = 600;
  useEffect(() => {
    console.log('documentInformation in document details', documentInformation);
    // setLoading(true);
    console.log('user.uid **', user.uid);
    console.log('documentInformation.id **', documentInformation.id);

    firebase
      .getInvalidatedDocument(user.uid, documentInformation.id)
      .then((data) => {
        // setisSealconfirmed(true);
        console.log('data in document details ===> ', data);

        if (data) {
          console.log('invalidated Data', data);
          setselectedSealInfo(data.selectedSealInfo);
          setConfirmationValues(data);
          if (data.selectedSealInfo.type !== 'signature') {
            setselectedQRInfo(
              data.selectedQRInfo ? data.selectedQRInfo : data.selectedSealInfo,
            );
          }
          documentInformation = data;
          if (data.rotation) {
            if (typeof data.rotation === 'object') {
              boxState.pagesRotation = data.rotation;
            } else {
              boxState.pagesRotation = Array(numPages).fill(data.rotation);
            }
          } else {
            boxState.pagesRotation = Array(numPages).fill(0);
          }

          setRotation(data.rotation ? data.rotation : 0);

          setSameRotation(data.sameRotation ? data.sameRotation : true);
          setisSealSelected(true);
          setisSealInvalidated(true);
          setSealPactDownloadUrl(data.sealPactDownloadUrl);
          setDocumentStatus(data.status);
          return;
        } else if (
          data === false
          // documentInformation.sealPacterType === 'primary'
        ) {
          firebase
            .getSealPactedDocument(user.uid, documentInformation.id)
            .then((sealPactedData) => {
              setLoading(false);
              console.log(
                'sealPactedData in document details ===> ',
                sealPactedData,
              );
              documentInformation = sealPactedData;
              // documentDetails = data.sealPactDownloadUrl;
              setSealPactDownloadUrl(sealPactedData.sealPactDownloadUrl);
              setselectedSealInfo(sealPactedData.selectedSealInfo);
              if (sealPactedData.selectedSealInfo.type !== 'signature') {
                setselectedQRInfo(
                  sealPactedData.selectedQRInfo
                    ? sealPactedData.selectedQRInfo
                    : sealPactedData.selectedSealInfo,
                );
              }
              setConfirmationValues(sealPactedData);
              if (sealPactedData.rotation) {
                if (typeof sealPactedData.rotation === 'object') {
                  boxState.pagesRotation = sealPactedData.rotation;
                } else {
                  boxState.pagesRotation = Array(numPages).fill(
                    sealPactedData.rotation,
                  );
                }
              } else {
                boxState.pagesRotation = Array(numPages).fill(0);
              }

              setRotation(
                sealPactedData.rotation ? sealPactedData.rotation : 0,
              );

              setSameRotation(
                sealPactedData.sameRotation
                  ? sealPactedData.sameRotation
                  : true,
              );
              setisSealSelected(true);
              setisSealInvalidated(false);
              setisSealconfirmed(true);
              setDocumentStatus(sealPactedData.status);

              return;
            })
            .catch((error) => {
              setLoading(false);
              console.log('error ===> ', error);
              return;
            });
        } else if (
          data === false &&
          documentInformation.sealPacterType === 'secondary'
        ) {
          firebase
            .getSecondarySealPactedDocument(user.uid, documentInformation.id)
            .then((sealPactedData) => {
              setLoading(false);
              console.log(
                'sealPactedData in document details ===> ',
                sealPactedData,
              );
              documentInformation = sealPactedData;
              // documentDetails = data.sealPactDownloadUrl;

              console.log('data in document details ===> ', sealPactedData);
              // documentInformation = data;
              // documentDetails = data.sealPactDownloadUrl;
              setSealPactDownloadUrl(sealPactedData.sealPactDownloadUrl);
              if (sealPactedData.rotation) {
                if (typeof sealPactedData.rotation === 'object') {
                  boxState.pagesRotation = sealPactedData.rotation;
                } else {
                  boxState.pagesRotation = Array(numPages).fill(
                    sealPactedData.rotation,
                  );
                }
              } else {
                boxState.pagesRotation = Array(numPages).fill(0);
              }

              setRotation(
                sealPactedData.rotation ? sealPactedData.rotation : 0,
              );
              setSameRotation(
                sealPactedData.sameRotation
                  ? sealPactedData.sameRotation
                  : true,
              );

              if (sealPactedData.selectedSealInfo) {
                setselectedSealInfo(sealPactedData.selectedSealInfo);
                if (sealPactedData.selectedSealInfo.type !== 'signature') {
                  setselectedQRInfo(
                    sealPactedData.selectedQRInfo
                      ? sealPactedData.selectedQRInfo
                      : sealPactedData.selectedSealInfo,
                  );
                }
                setisSealSelected(true);
                setisSealconfirmed(true);
              }
              setConfirmationValues(sealPactedData);
              if (sealPactedData.status !== 'pending')
                setisSealInvalidated(true);

              return;
            })
            .catch((error) => {
              setLoading(false);
              console.log('error ===> ', error);
              return;
            });
        }

        return;
      })
      .catch((error) => {
        setLoading(false);
        console.log('error ===> ', error);
        return;
      });

    if (invalidated) {
      setselectedSealInfo(documentInformation.selectedSealInfo);
      if (documentInformation.selectedSealInfo.type !== 'signature') {
        setselectedQRInfo(
          documentInformation.selectedQRInfo
            ? documentInformation.selectedQRInfo
            : documentInformation.selectedSealInfo,
        );
      }
      setConfirmationValues(documentInformation);
      setisSealSelected(true);
      setisSealInvalidated(true);
    } else if (backToDoc) {
      setselectedSealInfo(documentInformation.selectedSealInfo);
      if (documentInformation.selectedSealInfo.type !== 'signature') {
        setselectedQRInfo(
          documentInformation.selectedQRInfo
            ? documentInformation.selectedQRInfo
            : documentInformation.selectedSealInfo,
        );
      }
      setisSealSelected(true);
      if (documentInformation.status === 'invalidated') {
        setisSealInvalidated(true);
      } else {
        setisSealconfirmed(true);
      }
    } else if (documentInformation.status === 'invalidated') {
      setselectedSealInfo(documentInformation.selectedSealInfo);
      if (documentInformation.selectedSealInfo.type !== 'signature') {
        setselectedQRInfo(
          documentInformation.selectedQRInfo
            ? documentInformation.selectedQRInfo
            : documentInformation.selectedSealInfo,
        );
      }
      setConfirmationValues(documentInformation);
      setisSealSelected(true);
      setisSealInvalidated(true);
    } else if (
      documentInformation.selectedSealInfo &&
      !documentInformation.sealPactDownloadUrl
    ) {
      setselectedSealInfo(documentInformation.selectedSealInfo);
      if (documentInformation.selectedSealInfo.type !== 'signature') {
        setselectedQRInfo(
          documentInformation.selectedQRInfo
            ? documentInformation.selectedQRInfo
            : documentInformation.selectedSealInfo,
        );
      }
      setisSealSelected(true);
      setisSealconfirmed(true);
    }
    getSealsAndSigns();
    documentInformation.data = documentDetails;
  }, []);

  const getSealsAndSigns = () => {
    const sealsAndSignature = [];
    const activeSealsAndSignature = [];

    firebase.getSeals(user.uid).then((seals) => {
      firebase
        .getSignatures(user.uid)
        .then((signatures) => {
          firebase.getStamps(user.uid).then((stamps) => {
            setLoading(false);
            sealsAndSignature.push(...seals, ...signatures, ...stamps);
            sealsAndSignature.sort((a, b) => b.createdAt - a.createdAt);
            const tempActiveSeals = [];
            const tempInactiveSeals = [];
            seals.forEach((seal) => {
              if (
                moment(seal.expirationDate).isBefore(moment(new Date()), 'day')
              ) {
                seal.status = false;
              }
              seal.status === true
                ? tempActiveSeals.push(seal)
                : tempInactiveSeals.push(seal);
            });
            console.log('tempActiveSeals ==> ', tempActiveSeals);
            tempActiveSeals.sort((a, b) => b.createdAt - a.createdAt);
            tempInactiveSeals.sort((a, b) => b.createdAt - a.createdAt);
            setActiveSeals(tempActiveSeals);
            // filter active and inactive signatures
            const tempActiveSignatures = [];
            const tempInactiveSignatures = [];
            signatures.forEach((sign) =>
              sign.status === true
                ? tempActiveSignatures.push(sign)
                : tempInactiveSignatures.push(sign),
            );
            tempActiveSignatures.sort((a, b) => b.createdAt - a.createdAt);
            tempInactiveSignatures.sort((a, b) => b.createdAt - a.createdAt);
            setActiveSignatures(tempActiveSignatures);

            const tempActiveStamps = [];
            const tempInactiveStamps = [];
            stamps.forEach((stamp) =>
              stamp.status === true
                ? tempActiveStamps.push(stamp)
                : tempInactiveStamps.push(stamp),
            );
            tempActiveStamps.sort((a, b) => b.createdAt - a.createdAt);
            tempInactiveStamps.sort((a, b) => b.createdAt - a.createdAt);
            setActiveStamps(tempActiveStamps);

            activeSealsAndSignature.push(
              ...tempActiveSeals,
              ...tempActiveSignatures,
              ...tempActiveStamps,
            );
            setAllActiveAssets(activeSealsAndSignature);
          });
        })
        .catch((error) => {
          console.log('error in getting seals and signs=== > ', error);
          setLoading(false);
        });
    });
  };

  const getBasicTabValue = (key, value) => {
    // alert(key + ' ' + value)
    setSealPages({ ...sealPages, [key]: value });
  };
  const setPageFromTo = (pageFrom, pageTo) => {
    setSealPages({ ...sealPages, pageFrom, pageTo });
  };
  const handleInvalidate = (eventKey) => {
    setInvalidationReason(eventKey);
    setInvalidateConfirmaton(!invalidateConfirmaton);
  };
  const toggleReSealPactModel = () => {
    setReSealPactConfirmaton(!reSealPactConfirmaton);
  };
  const nextPage = () => {
    if (pageNumber !== numPages) setPageNumber(pageNumber + 1);
  };
  const previousPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleDeleteDocument = () => {
    setDeleteConfirmation(true);
  };
  const handleConfirmDelete = () => {
    const { status } = documentInformation;
    if (status === 'pacted') {
      deleteSealPactedDocument();
    }
    if (status === undefined || status === 'pending') {
      deletePendingDocument();
    }
    if (status === 'invalidated') {
      deleteInvalidatedDocument();
    }
  };
  const deletePendingDocument = () => {
    setLoading(true);
    firebase
      .deleteDocument(user.uid, documentInformation)
      .then((res) => {
        setLoading(false);
        setDeleteConfirmation(false);
        push('/documents');
        console.log('res ===> ', res);
        store.addNotification({
          title: 'Deleted Successfully!',
          message: 'Document deleted successfully.',
          type: 'default',
          ...NOTIFICATION_PROPS,
        });
      })
      .catch((error) => {
        setLoading(false);
        setDeleteConfirmation(false);
        console.log('error ==> ', error);
        store.addNotification({
          title: 'Error',
          message: 'Not Found.  Could not delete object',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return;
      });
  };
  const deleteSealPactedDocument = () => {
    setLoading(true);
    firebase
      .deleteSealPactedDocument(user.uid, documentInformation)
      .then((res) => {
        setLoading(false);
        setDeleteConfirmation(false);
        push('/documents');
        console.log('res ===> ', res);
        store.addNotification({
          title: 'Deleted Successfully!',
          message: 'Document deleted successfully.',
          type: 'default',
          ...NOTIFICATION_PROPS,
        });
      })
      .catch((error) => {
        setLoading(false);
        setDeleteConfirmation(false);
        console.log('error ==> ', error);
        store.addNotification({
          title: 'Error',
          message: 'Not Found.  Could not delete object',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return;
      });
  };
  const deleteInvalidatedDocument = () => {
    setLoading(true);
    firebase
      .deleteInvalidatedDocument(user.uid, documentInformation)
      .then((res) => {
        setLoading(false);
        setDeleteConfirmation(false);
        push('/documents');
        console.log('res ===> ', res);
        store.addNotification({
          title: 'Deleted Successfully!',
          message: 'Document deleted successfully.',
          type: 'default',
          ...NOTIFICATION_PROPS,
        });
      })
      .catch((error) => {
        setLoading(false);
        setDeleteConfirmation(false);
        console.log('error ==> ', error);
        store.addNotification({
          title: 'Error',
          message: 'Not Found.  Could not delete object',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return;
      });
  };

  // useEffect(() => {
  //   alert(sealPages.pageFrom)
  // }, [sealPages.pageFrom])

  const setBasicTabPages = (pageFrom, pageTo, range = []) => {
    setSealPages({ ...sealPages, pageFrom, pageTo, range });
  };
  const handlePanning = (e) => {
    console.log('e', e);
    setPanX(e.positionX);
    setPanY(e.positionY);
  };

  const rotateRight = () => {
    setSameRotation(true);
    setInRotateRight(true);
    setRotationOptionSelected(true);
  };

  const rotateLeft = () => {
    setSameRotation(true);
    setRotationOptionSelected(true);
  };

  const rotateOneRight = () => {
    setSameRotation(false);
    setInRotateRight(true);
    setRotationOptionSelected(true);
  };

  const rotateOneLeft = () => {
    setSameRotation(false);
    setRotationOptionSelected(true);
  };

  useEffect(() => {
    if (rotationOptionSelected) {
      if (inRotateRight) {
        if (boxState.pagesRotation[pageNumber - 1] === 270) {
          if (sameRotation) {
            boxState.pagesRotation = Array(numPages).fill(0);
            if (boxState.pages) {
              boxState.pages.forEach((page, index) => {
                page.setRotation(degrees(boxState.pagesRotation[index]));
              });
            }
          } else {
            boxState.pagesRotation[pageNumber - 1] = 0;
            if (boxState.pages) {
              boxState.pages[pageNumber - 1].setRotation(
                degrees(boxState.pagesRotation[pageNumber - 1]),
              );
            }
          }

          setRotation(0);
          console.log('rotation: ', 0);
        } else {
          if (sameRotation) {
            boxState.pagesRotation = Array(numPages).fill(
              boxState.pagesRotation[pageNumber - 1] + 90,
            );
            if (boxState.pages) {
              boxState.pages.forEach((page, index) => {
                page.setRotation(degrees(boxState.pagesRotation[index]));
              });
            }
          } else {
            boxState.pagesRotation[pageNumber - 1] =
              boxState.pagesRotation[pageNumber - 1] + 90;
            if (boxState.pages) {
              boxState.pages[pageNumber - 1].setRotation(
                degrees(boxState.pagesRotation[pageNumber - 1]),
              );
            }
          }

          setRotation(rotation + 90);
          console.log('rotation: ', rotation + 90);
        }

        setInRotateRight(false);
      } else {
        if (boxState.pagesRotation[pageNumber - 1] === 0) {
          if (sameRotation) {
            boxState.pagesRotation = Array(numPages).fill(270);
            if (boxState.pages) {
              boxState.pages.forEach((page, index) => {
                page.setRotation(degrees(boxState.pagesRotation[index]));
              });
            }
          } else {
            boxState.pagesRotation[pageNumber - 1] = 270;
            if (boxState.pages) {
              boxState.pages[pageNumber - 1].setRotation(
                degrees(boxState.pagesRotation[pageNumber - 1]),
              );
            }
          }

          setRotation(270);
          console.log('rotation: ', 270);
        } else {
          if (sameRotation) {
            boxState.pagesRotation = Array(numPages).fill(
              boxState.pagesRotation[pageNumber - 1] - 90,
            );
            if (boxState.pages) {
              boxState.pages.forEach((page, index) => {
                page.setRotation(degrees(boxState.pagesRotation[index]));
              });
            }
          } else {
            boxState.pagesRotation[pageNumber - 1] =
              boxState.pagesRotation[pageNumber - 1] - 90;
            if (boxState.pages) {
              boxState.pages[pageNumber - 1].setRotation(
                degrees(boxState.pagesRotation[pageNumber - 1]),
              );
            }
          }

          setRotation(rotation - 90);
          console.log('rotation: ', rotation - 90);
        }
      }

      setRotationOptionSelected(false);
    }
  }, [rotationOptionSelected]);

  const scaleToFit = (targetX, targetY, image) => {
    const imgWidthScale = targetX / image.width;
    const imgHeightScale = targetY / image.height;
    return Math.min(imgWidthScale, imgHeightScale);
  };
  const getselectedSealInfo = (object) => {
    console.log('object &&&&&', object);
    let object_QR = { ...object };
    console.log('object_QR &&&&&', object_QR);
    // if (!object.x || !object.y) {
    //   object.x = 60 + panX * -1;
    //   object.y = 30 + panY * -1;
    // }
    object.x = boxState.x_seal + panX * -1;
    object.y = boxState.y_seal + panY * -1;
    console.log('object &&&&&', object);
    // alert(`boxState.y_seal getselect ${boxState.y_seal}`)
    // alert(`boxState.y_qr getselect ${boxState.y_QR}`)

    let page = {};
    for (let numDoc = 1; numDoc <= numPages; numDoc++) {
      page[numDoc] = { initial: true, x: boxState.x_seal, y: boxState.y_seal };
    }

    console.log('page &&&&&', page);

    object.page = page;
    documentInformation.selectedSealInfo = object;
    setdrawerVisible(!drawerVisible);
    setisSealSelected(true);
    setselectedSealInfo(object);

    if (documentInformation.selectedSealInfo.type !== 'signature') {
      // setParallelMoveSealAndQR(true);
      boxState.qrCodeDragging = Array(numPages).fill(true);
      let page_QR = {};
      // if (!object_QR.x || !object_QR.y) {
      //   object_QR.x = 60 + panX * -1;
      //   object_QR.y = 180 + panY * -1;
      // }
      object_QR.x = boxState.x_QR + panX * -1;
      object_QR.y = boxState.y_QR + panY * -1;
      console.log(zoomLevel);
      for (let numDoc = 1; numDoc <= numPages; numDoc++) {
        page_QR[numDoc] = { initial: true, x: boxState.x_QR, y: boxState.y_QR };
      }

      console.log('page_QR &&&&&', page_QR);

      object_QR.page = page_QR;
      console.log('object_QR.p &&&&&', object_QR);
      documentInformation.selectedQRInfo = object_QR;
      setisQRSelected(true);
      setselectedQRInfo(object_QR);
    }

    store.addNotification({
      title: 'Please wait!',
      message:
        'Drag and Drop Seal from Top-Left Hand Corner to Desired Location.',
      type: 'info',
      ...NOTIFICATION_PROPS,
    });
  };

  const handleSealPactDrawer = () => {
    // if (is.safari() && is.not.edge()) {
    //   store.addNotification({
    //     title: 'Error',
    //     message: 'Safari browser is not supported at the moment.',
    //     type: 'danger',
    //     ...NOTIFICATION_PROPS,
    //   });
    //   return;
    // }
    if (!isSealSelected && !drawerSlideOut) setSelectSealPages(true);
    else if (isSealSelected) {
      for (
        let index = sealPages.pageFrom;
        index < sealPages.pageTo + 1;
        index++
      ) {
        if (
          (selectedSealInfo.page[index].initial &&
            selectedPagesAndSealPos.isAllPages &&
            !selectedPagesAndSealPos.isSealOnSamePos) ||
          (selectedSealInfo.page[index].initial &&
            selectedPagesAndSealPos.isOtherPages &&
            !selectedPagesAndSealPos.isSealOnSamePos)
        ) {
          setShowWarning(true);
          return;
        }
      }
      setshowConfirmModal(true);
    }
  };
  const blackOverlayClick = () => {
    setdrawerSlideOut(true);
    setTimeout(() => {
      setdrawerVisible(false);
      setdrawerSlideOut(false);
    }, 1000);
  };

  const toggleConfirmModal = () => {
    setshowConfirmModal(!showConfirmModal);
  };
  const toggleReviewModal = () => {
    setshowReviewModal(!showReviewModal);
  };

  const reviewCall = () => {
    setshowReviewModal(!showReviewModal);
    toggleConfirmModal();
  };

  //On Review model submit
  const onSubmitCall = () => {
    setshowReviewModal(!showReviewModal);
    setshowConfirmationPopup(true);
  };
  const enableZoomHandler = () => {
    // setenableZoom(!enableZoom);
    setenablePan(!enablePan);
  };

  const enableMoveHandler = () => {
    setEnableMove(!enableMove);
  };

  const getSealValidatonData = () => {
    setshowReviewModal(!showReviewModal);
    // setshowConfirmationPopup(true);
  };
  const handleSubmitModal = () => {
    setshowConfirmationPopup(false);
  };
  const handleSubmitWarningModal = () => {
    setShowWarning(false);
  };
  const confirmSealPages = () => {
    setSelectSealPages(false);
    setdrawerVisible(true);
  };
  const hideConfirmModal = () => {
    setSelectSealPages(false);
  };
  const continueSeal = () => {
    setShowWarning(false);
    setshowConfirmModal(true);
  };
  const eventLogger = (e, event) => {
    boxState.boxXCoordinate = event.x;
    boxState.boxYCoordinate = event.y;
    console.log(selectedSealInfo);
    setafterDragged(true);
    setStopped(false);
    const { isAllPages, isOtherPages, isSealOnSamePos } =
      selectedPagesAndSealPos;

    console.log('selectedPagesAndSealPos', selectedPagesAndSealPos);
    console.log('boxState.qrCodeDragging', boxState.qrCodeDragging);

    const x = event.x;
    const y = event.y;

    const x_qr = event.x;
    const y_qr = event.y + (boxState.resizableBoxHeight + 12) * zoomLevel;

    console.log('x_qr', x_qr);
    console.log('y_qr', y_qr);

    if ((isAllPages && isSealOnSamePos) || (isOtherPages && isSealOnSamePos)) {
      console.log('in same pos');
      let page = {},
        page_QR = {};
      for (let numDoc = 1; numDoc <= numPages; numDoc++) {
        page[numDoc] = { x, y };
        if (
          selectedSealInfo.type !== 'signature' &&
          boxState.qrCodeDragging &&
          isSealSelected &&
          boxState.pages
        ) {
          if (boxState.qrCodeDragging[numDoc - 1]) {
            let page_width = boxState.pages[numDoc - 1].getWidth();
            let page_height = boxState.pages[numDoc - 1].getHeight();
            if (
              boxState.pagesRotation[numDoc - 1] === 90 ||
              boxState.pagesRotation[numDoc - 1] === 270
            ) {
              let temp = page_width;
              page_width = page_height;
              page_height = temp;
            }

            if (
              x_qr + boxState.qrCodeBoxWidth * zoomLevel <
                page_width * zoomLevel &&
              y_qr + boxState.qrCodeBoxHeight * zoomLevel <
                page_height * zoomLevel
            ) {
              page_QR[numDoc] = { x: x_qr, y: y_qr };
              console.log('page_QR[numDoc]', page_QR[numDoc]);
            } else {
              page_QR[numDoc] =
                selectedQRInfo.page && selectedQRInfo.page[numDoc]
                  ? selectedQRInfo.page[numDoc]
                  : { x: 60, y: 180 };
            }
          }
        }
      }

      if (
        selectedSealInfo.type !== 'signature' &&
        boxState.qrCodeDragging &&
        isSealSelected &&
        boxState.pages
      ) {
        if (boxState.qrCodeDragging[pageNumber - 1]) {
          console.log(
            'selectedQRInfo page_QR[pageNumber]',
            page_QR[pageNumber],
          );
          setselectedQRInfo({ ...selectedQRInfo, page: page_QR });
        }
      }

      setselectedSealInfo({ ...selectedSealInfo, page });
      console.log('selectedQRInfo', selectedQRInfo);
    } else {
      console.log('in diff');
      let page = {},
        page_QR = {};
      for (let numDoc = 1; numDoc <= numPages; numDoc++) {
        page[numDoc] =
          selectedSealInfo.page && selectedSealInfo.page[numDoc]
            ? selectedSealInfo.page[numDoc]
            : { x: 60, y: 30 };

        if (
          selectedSealInfo.type !== 'signature' &&
          boxState.qrCodeDragging &&
          isSealSelected &&
          boxState.pages
        ) {
          console.log('boxState.qrCodeDragging dragging');
          page_QR[numDoc] =
            selectedQRInfo.page && selectedQRInfo.page[numDoc]
              ? selectedQRInfo.page[numDoc]
              : { x: 60, y: 180 };
        }
      }
      page[pageNumber] = {
        x: event.x,
        y: event.y,
      };

      if (
        selectedSealInfo.type !== 'signature' &&
        boxState.qrCodeDragging &&
        isSealSelected &&
        boxState.pages
      ) {
        if (boxState.qrCodeDragging[pageNumber - 1]) {
          let page_width = boxState.pages[pageNumber - 1].getWidth();
          let page_height = boxState.pages[pageNumber - 1].getHeight();
          if (
            boxState.pagesRotation[pageNumber - 1] === 90 ||
            boxState.pagesRotation[pageNumber - 1] === 270
          ) {
            let temp = page_width;
            page_width = page_height;
            page_height = temp;
          }
          if (
            x_qr + boxState.qrCodeBoxWidth * zoomLevel <
              page_width * zoomLevel &&
            y_qr + boxState.qrCodeBoxHeight * zoomLevel <
              page_height * zoomLevel
          ) {
            page_QR[pageNumber] = {
              x: event.x,
              y: event.y + (boxState.resizableBoxHeight + 12) * zoomLevel,
            };
          } else {
            page_QR[pageNumber] =
              selectedQRInfo.page && selectedQRInfo.page[pageNumber]
                ? selectedQRInfo.page[pageNumber]
                : { x: 60, y: 180 };
          }

          setselectedQRInfo({ ...selectedQRInfo, page: page_QR });
        }
      }

      setselectedSealInfo({ ...selectedSealInfo, page });
    }
  };

  const eventLogger_QR = (e, event) => {
    if (boxState.qrCodeDragging) {
      if (boxState.qrCodeDragging[pageNumber - 1]) {
        if (selectedPagesAndSealPos.isSealOnSamePos === true)
          boxState.qrCodeDragging = Array(numPages).fill(false);
        else boxState.qrCodeDragging[pageNumber - 1] = false;
      }
    }

    console.log(selectedQRInfo);
    setafterDragged_QR(true);
    setStopped_QR(false);
    const { isAllPages, isOtherPages, isSealOnSamePos } =
      selectedPagesAndSealPos;

    const x = event.x;
    const y = event.y;

    if ((isAllPages && isSealOnSamePos) || (isOtherPages && isSealOnSamePos)) {
      let page = {};
      for (let numDoc = 1; numDoc <= numPages; numDoc++) {
        page[numDoc] = { x, y };
      }

      setselectedQRInfo({ ...selectedQRInfo, page });
    } else {
      let page = {};
      for (let numDoc = 1; numDoc <= numPages; numDoc++)
        page[numDoc] =
          selectedQRInfo.page && selectedQRInfo.page[numDoc]
            ? selectedQRInfo.page[numDoc]
            : { x: 60, y: 180 };
      page[pageNumber] = {
        x: event.x,
        y: event.y,
      };
      setselectedQRInfo({ ...selectedQRInfo, page });
    }
  };

  const handleSealCancel = () => {
    setisSealSelected(false);
    setisQRSelected(false);
    defaultMeasurements(selectedSealInfo.type);
    setselectedSealInfo({});
    setselectedQRInfo({});
  };

  const boxWidthCalculations = (type, e, position, parsedWidth, deltaX) => {
    eventLogger(e, position);
    if (type !== 'signature') {
      boxState.resizableBoxWidth = parsedWidth;
      boxState.sealPactWidth = parsedWidth;
      console.log('image width :', boxState.sealPactImageWidth + deltaX);
      boxState.sealPactImageWidth = boxState.sealPactImageWidth + deltaX;

      // boxState.sealPactLogoImageWidth = boxState.sealPactLogoImageWidth + (deltaX / 60);
      // boxState.sealPactTextLarge = boxState.sealPactTextLarge + (deltaX / 60);
      // boxState.sealPactTextSmall = boxState.sealPactTextSmall + (deltaX / 80);
    } else {
      boxState.resizableBoxWidth = boxState.sealPactWidth = parsedWidth;
      boxState.sealPactImageWidth = parsedWidth / 2;
      boxState.sealPactLogoImageWidth =
        boxState.sealPactLogoImageWidth + deltaX / 60;
      boxState.sealPactTextLarge = boxState.sealPactTextLarge + deltaX / 60;
      boxState.sealPactTextSmall = boxState.sealPactTextSmall + deltaX / 80;
    }
  };

  const boxHeightCalculations = (type, e, position, parsedHeight, deltaY) => {
    eventLogger(e, position);
    if (type !== 'signature') {
      boxState.resizableBoxHeight = parsedHeight;
      boxState.sealPactHeight = parsedHeight;
      console.log(
        'image width $$$$$$$$$$$$$ :',
        boxState.sealPactImageHeight + 0.85 * deltaY,
      );
      boxState.sealPactImageHeight = boxState.sealPactImageHeight + deltaY;

      // boxState.sealPactLogoImageHeight = boxState.sealPactLogoImageHeight + (deltaY / 60);
      // boxState.sealPactTextLarge = boxState.sealPactTextLarge + (deltaY / 60);
      // boxState.sealPactTextSmall = boxState.sealPactTextSmall + (deltaY / 80);
    } else {
      boxState.resizableBoxHeight = boxState.sealPactHeight = parsedHeight;
      boxState.sealPactImageHeight = parsedHeight - 5;
      boxState.sealPactLogoImageHeight =
        boxState.sealPactLogoImageHeight + deltaY / 60;
      boxState.sealPactTextLarge = boxState.sealPactTextLarge + deltaY / 60;
      boxState.sealPactTextSmall = boxState.sealPactTextSmall + deltaY / 80;
    }
  };

  // this same fun can be passed next line fun as parameter to calculate the x,y
  // coordinates of next line or not pass the next line fun to statically draw item
  const drawItem = (
    page,
    item,
    xCoord,
    yCoord,
    itemOptions,
    nextLine,
    callback,
    drawText = false,
    // height,
  ) => {
    callback && typeof callback === 'function' && callback(itemOptions.params);
    itemOptions['x'] = parseInt(xCoord);
    itemOptions['y'] = parseInt(yCoord);
    console.log('itemOptions', itemOptions);
    debugger;
    let x = xCoord,
      y = yCoord,
      nextLineOpts = {};
    if (nextLine && typeof nextLine === 'function') {
      nextLineOpts = {
        prevX: x,
        prevY: y,
        xDiff: itemOptions.xDiff,
        yDiff: itemOptions.yDiff,
      };
      delete itemOptions.xDiff;
      delete itemOptions.yDiff;
    }
    // if (offsetX < 0) {
    //   itemOptions.x = itemOptions.x + offsetX;
    // } else {
    //   itemOptions.x = itemOptions.x;
    // }
    // if (offsetY < 0) {
    //   itemOptions.y = itemOptions.y + offsetY;
    // } else {
    //   itemOptions.y = itemOptions.y;
    // }
    debugger;
    if (drawText) {
      itemOptions['color'] = rgb(38 / 255, 97 / 255, 156 / 255);
      page.drawText(item, itemOptions);
    } else if (typeof item === 'object') page.drawImage(item, itemOptions);
    else if (['rectangle'].includes(item)) page.drawRectangle(itemOptions);
    else page.drawText(item, itemOptions);
    if (nextLine && typeof nextLine === 'function') {
      [x, y] = nextLine(nextLineOpts);
    }
    return [x, y];
  };
  // correct way to handle pdf mapping is to have a single func to
  // draw each element with sanitizing func and iterate that func
  // by feeding a json stored in separate file.
  // Since we are not going to do too much mapping just writing func to draw seal
  const drawSeal = async (
    pages,
    pageIndex,
    image,
    logo,
    qrCodeImage,
    rotation,
    fonts,
    offsetX,
    offsetY,
    // height,
  ) => {
    // Generic fun to calculate next line X, Y
    debugger;
    const nextLine = function (args) {
      let x = args.prevX + args.xDiff;
      let y = args.prevY + args.yDiff;
      return [x, y];
    };

    let logoText = 11; // * (1/zoomLevel);
    let sealText = 7; // * (1/zoomLevel);
    let sealTextDiff = 15; // * (1/zoomLevel);

    if (
      selectedSealInfo.type !== 'signature' &&
      !cancelQRDiv &&
      qrCodeImage !== ''
    ) {
      let qrImageRatio = 38;
      let scaleFactor_QR = scaleToFit(qrImageRatio, qrImageRatio, qrCodeImage);
      let scaledDimsImg_QR = qrCodeImage.scale(scaleFactor_QR);

      let sealLogoRatio = boxState.sealPactLogoImageWidth;
      scaleFactor_QR = scaleToFit(
        boxState.sealPactLogoImageWidth,
        boxState.sealPactLogoImageHeight,
        logo,
      );
      let scaledDimsLogo = logo.scale(scaleFactor_QR);

      let xCoord_QR, yCoord_QR;

      let drawObject_QR = {
        qrCode: {
          val: qrCodeImage,
          options: {
            width: scaledDimsImg_QR.width,
            height: scaledDimsImg_QR.height,
            xDiff: boxState.qrCodeWidth + 5,
            // yDiff: 0.75 * parseInt(scaledDimsImg_QR.height),
            yDiff: 0.7 * parseInt(scaledDimsImg_QR.height),
          },
          callback: null,
        },
        t1: {
          val: `SealPact ${getTime()[0]} ${getTime()[1]} EST`,
          options: {
            size: boxState.sealPactTextSmall,
            // font: fonts.bold,
            xDiff: 0,
            yDiff: -10,
          },
          callback: null,
        },
        t2: {
          val: 'Click seal or scan QR Code for',
          options: {
            size: boxState.sealPactTextSmall,
            // font: fonts.bold,
            xDiff: 0,
            yDiff: -10,
          },
          callback: null,
        },
        t3: {
          val: 'verification; otherwise not valid.',
          options: {
            size: boxState.sealPactTextSmall,
            // font: fonts.bold,
            xDiff: 0,
            yDiff: -10,
          },
          callback: null,
        },
        // t4: {
        //   val: 'such verification.',
        //   options: {
        //     size: boxState.sealPactTextSmall,
        //     // font: fonts.bold,
        //     xDiff: 0,
        //     yDiff: -8,
        //   },
        //   callback: null,
        // },
      };

      // Handle the rotation of the pdf and scaling of x,y
      let calculateQRx = 0;
      let calculateQRy = 0;

      if (rotation === 0) {
        if (offsetX < 0) {
          calculateQRx =
            selectedQRInfo.page[pageIndex + 1].x / zoomLevel + offsetX - 1;
        } else {
          calculateQRx = selectedQRInfo.page[pageIndex + 1].x / zoomLevel - 1;
        }
        if (offsetY < 0) {
          calculateQRy =
            pages[pageIndex].getHeight() +
            offsetY -
            selectedQRInfo.page[pageIndex + 1].y * (1 / zoomLevel) -
            sealFactor -
            parseInt(scaledDimsImg_QR.height) +
            1;
        } else {
          calculateQRy =
            pages[pageIndex].getHeight() -
            selectedQRInfo.page[pageIndex + 1].y * (1 / zoomLevel) -
            sealFactor -
            parseInt(scaledDimsImg_QR.height) +
            1;
        }

        xCoord_QR = calculateQRx;
        // calculateQRx / zoomLevel - 1;

        yCoord_QR = calculateQRy;
        // calculateQRy * (1 / zoomLevel) -
        // sealFactor -
        // parseInt(scaledDimsImg_QR.height) +
        // 1;

        console.log({ xCoord_QR, yCoord_QR });
      } else if (rotation === 90) {
        if (offsetX < 0) {
          calculateQRx =
            selectedQRInfo.page[pageIndex + 1].x / zoomLevel + offsetY - 2;
        } else {
          calculateQRx = selectedQRInfo.page[pageIndex + 1].x / zoomLevel - 2;
        }
        if (offsetY < 0) {
          calculateQRy =
            selectedQRInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
            offsetX -
            sealFactor +
            parseInt(scaledDimsImg_QR.height) +
            3;
        } else {
          calculateQRy =
            selectedQRInfo.page[pageIndex + 1].y * (1 / zoomLevel) -
            sealFactor +
            parseInt(scaledDimsImg_QR.height) +
            3;
        }

        xCoord_QR = calculateQRy;
        // calculateQRy * (1 / zoomLevel) -
        // sealFactor +
        // parseInt(scaledDimsImg_QR.height) +
        // 3;

        yCoord_QR = calculateQRx;
        // calculateQRx / zoomLevel - 2;

        for (const [k, v] of Object.entries(drawObject_QR)) {
          [drawObject_QR[k].options.xDiff, drawObject_QR[k].options.yDiff] = [
            -1 * drawObject_QR[k].options.yDiff,
            drawObject_QR[k].options.xDiff,
          ];
        }
      } else if (rotation === 180) {
        if (offsetX < 0) {
          calculateQRx =
            pages[pageIndex].getWidth() -
            selectedQRInfo.page[pageIndex + 1].x * (1 / zoomLevel) +
            offsetX +
            2;
        } else {
          calculateQRx =
            pages[pageIndex].getWidth() -
            selectedQRInfo.page[pageIndex + 1].x * (1 / zoomLevel) +
            2;
        }
        if (offsetY < 0) {
          calculateQRy =
            selectedQRInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
            offsetY +
            sealFactor +
            parseInt(scaledDimsImg_QR.height) -
            2;
        } else {
          calculateQRy =
            selectedQRInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
            sealFactor +
            parseInt(scaledDimsImg_QR.height) -
            2;
        }
        xCoord_QR = calculateQRx;
        // calculateQRx * (1 / zoomLevel) + 2;

        yCoord_QR = calculateQRy;
        // calculateQRy * (1 / zoomLevel) +
        // sealFactor +
        // parseInt(scaledDimsImg_QR.height) -
        // 2;

        for (const [k, v] of Object.entries(drawObject_QR)) {
          [drawObject_QR[k].options.xDiff, drawObject_QR[k].options.yDiff] = [
            -1 * drawObject_QR[k].options.xDiff,
            -1 * drawObject_QR[k].options.yDiff,
          ];
        }
      } else if (rotation === 270) {
        if (offsetX < 0) {
          calculateQRx =
            pages[pageIndex].getHeight() -
            selectedQRInfo.page[pageIndex + 1].x * (1 / zoomLevel) +
            offsetY;
        } else {
          calculateQRx =
            pages[pageIndex].getHeight() -
            selectedQRInfo.page[pageIndex + 1].x * (1 / zoomLevel);
        }

        if (offsetY < 0) {
          calculateQRy =
            pages[pageIndex].getWidth() -
            selectedQRInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
            offsetX -
            parseInt(scaledDimsImg_QR.height) -
            1;
        } else {
          calculateQRy =
            pages[pageIndex].getWidth() -
            selectedQRInfo.page[pageIndex + 1].y * (1 / zoomLevel) -
            parseInt(scaledDimsImg_QR.height) -
            1;
        }
        xCoord_QR = calculateQRy;
        // calculateQRy * (1 / zoomLevel) -
        // parseInt(scaledDimsImg_QR.height) -
        // 1;

        yCoord_QR = calculateQRx;
        // calculateQRx * (1 / zoomLevel);

        for (const [k, v] of Object.entries(drawObject_QR)) {
          [drawObject_QR[k].options.xDiff, drawObject_QR[k].options.yDiff] = [
            drawObject_QR[k].options.yDiff,
            -1 * drawObject_QR[k].options.xDiff,
          ];
        }
      }

      selectedQRInfo.page[pageIndex + 1].xCoord = xCoord_QR;
      selectedQRInfo.page[pageIndex + 1].yCoord = yCoord_QR;
      selectedQRInfo.page[pageIndex + 1].height =
        parseInt(scaledDimsImg_QR.height) + 60 + 6;
      selectedQRInfo.page[pageIndex + 1].width =
        scaledDimsImg_QR.height > scaledDimsImg_QR.width
          ? scaledDimsImg_QR.width < 100
            ? 100
            : scaledDimsImg_QR.width + 10
          : 140;

      for (const [item, opt] of Object.entries(drawObject_QR)) {
        opt.options.rotate = degrees(rotation);
        [xCoord_QR, yCoord_QR] = drawItem(
          pages[pageIndex],
          opt.val,
          xCoord_QR,
          yCoord_QR,
          opt.options,
          nextLine,
          opt.callback,
          // offsetX,
          // offsetY,
          // height,
        );
      }
    }

    //Get all the ratios
    // let sealImageRatio = selectedSealInfo.type === 'signature' ? 100 : 135; // * (1/zoomLevel);
    let sealImageRatio = boxState.sealPactImageWidth;
    // let sealImageRatio = sealPactImageWidth / sealPactImageHeight;
    // let sealLogoRatio = 16; // * (1/zoomLevel);
    let sealLogoRatio = boxState.sealPactLogoImageWidth; // * (1/zoomLevel);
    let sealQrRatio = 50; // * (1/zoomLevel);
    let xCoord = 0,
      yCoord = 0;

    // let scaleFactor = scaleToFit(sealImageRatio, sealImageRatio, image);

    let scaleFactor = scaleToFit(
      boxState.sealPactImageWidth,
      boxState.sealPactImageHeight,
      image,
    );
    let scaledDimsImg = image.scale(scaleFactor);
    console.log('scaleFactor img: %%%%%%%%%%%%%%%%%', scaleFactor);
    console.log('scaledDimsImg: %%%%%%%%%%%%%%%%%%% ', scaledDimsImg);
    console.log(
      'sealPactImageWidth: %%%%%%%%%%%%%%%%%%% ',
      boxState.sealPactImageWidth,
    );
    console.log(
      'sealPactImageHeight: %%%%%%%%%%%%%%%%%%% ',
      boxState.sealPactImageHeight,
    );

    // scaleFactor = scaleToFit(sealLogoRatio, sealLogoRatio, logo);
    scaleFactor = scaleToFit(
      boxState.sealPactLogoImageWidth,
      boxState.sealPactLogoImageHeight,
      logo,
    );
    let scaledDimsLogo = logo.scale(scaleFactor);
    scaleFactor = scaleToFit(sealQrRatio, sealQrRatio, qrCodeImage);
    // let scaledDimsQr =
    //   selectedSealInfo.type === 'signature'
    //     ? { height: 0, width: 0 }
    //     : qrCodeImage.scale(scaleFactor);

    // We need to draw filled rectangle first and then draw everything to imitate seal's filled background.
    let drawObject, drawObject_Watermark;
    if (selectedSealInfo.type !== 'signature') {
      drawObject = {
        // 'shape_1' :{
        //   val:'rectangle',
        //   options : {
        //     width: selectedSealInfo.type === 'signature' ? 190 : 160,
        //     height: selectedSealInfo.type === 'signature' ? parseInt(scaledDimsImg.height) : ((parseInt(scaledDimsImg.height) + 60) + 6),
        //     borderWidth:3,
        //     borderColor:rgb(0.2,0.8,0.6),
        //     color:rgb(1,1,1),
        //     xDiff: selectedSealInfo.type === 'signature' ? 0 : scaledDimsImg.height > scaledDimsImg.width ? (160 - scaledDimsImg.width)/2 : 15,
        //     yDiff : selectedSealInfo.type === 'signature' ? 0 :((parseInt(scaledDimsImg.height) + 60) - scaledDimsImg.height)
        //   },
        //   callback : null
        // },
        seal: {
          val: image,
          options: {
            width:
              selectedSealInfo.type === 'signature'
                ? boxState.sealPactImageWidth
                : scaledDimsImg.width,
            height:
              selectedSealInfo.type === 'signature'
                ? boxState.sealPactImageHeight
                : scaledDimsImg.height,
            // xDiff: selectedSealInfo.type === 'signature'
            //   ? boxState.sealPactImageWidth + (( boxState.sealPactImageWidth - ((scaledDimsLogo.width + 5) + (scaledDimsLogo.width + 20))) / 2)
            //   : (boxState.sealPactImageWidth <= 130)
            //     ? scaledDimsImg.width / 4
            //     : scaledDimsImg.width / 3,
            // : ((scaledDimsImg.width / 3) + ( ( (scaledDimsImg.width / 5)  - (scaledDimsLogo.width + boxState.sealPactTextLarge))/2)),

            // : scaledDimsImg.width / 3,
            // selectedSealInfo.type === 'signature'
            //   ? sealImageRatio + 7
            //   : -1 *
            //     (scaledDimsImg.height > scaledDimsImg.width
            //       ?((85 - scaledDimsImg.width) / 2) + scaledDimsLogo.width + 10
            //       : -30 - scaledDimsLogo.width - 10),
            // yDiff: selectedSealInfo.type === 'signature'
            //   // ? 0.46 * parseInt(scaledDimsImg.height)
            //   ? boxState.sealPactHeight >= 55
            //     ? 0.53 * parseInt(scaledDimsImg.height)
            //     : 0.57 * parseInt(scaledDimsImg.height)
            //   : -1 * (scaledDimsLogo.height + 7),
          },
          callback: null,
        },
        // logo: {
        //   val: logo,
        //   options: {
        //     width: scaledDimsLogo.width,
        //     height: scaledDimsLogo.height,
        //     xDiff: scaledDimsLogo.width + 5,
        //     yDiff: scaledDimsLogo.height / 4,
        //   },
        //   callback: null,
        // },
        // t1: {
        //   val: 'SealPact',
        //   options: {
        //     size: boxState.sealPactTextLarge,
        //     // fontSize: sealPactTextLarge,
        //     // xDiff: -1 * (scaledDimsLogo.width + 3),
        //     // xDiff: -1 * (scaledDimsImg.width / 4.5),
        //     // xDiff: -1 * (scaledDimsLogo.width + 5),
        //     // xDiff:  -1 * (scaledDimsLogo.width + 20),
        //     xDiff:  -1 * (selectedSealInfo.type === 'signature'
        //       ? boxState.sealPactImageWidth <= 100
        //         ? scaledDimsLogo.width * 1.7
        //         : boxState.sealPactTextSmall * 3.5
        //       : boxState.sealPactImageWidth <= 130
        //         ? boxState.sealPactTextSmall * 3.8
        //         : boxState.sealPactTextSmall * 3.5),
        //     yDiff: (selectedSealInfo.type === 'signature'
        //       ? boxState.sealPactImageWidth <= 100
        //         ? boxState.sealPactHeight >= 55
        //           ? -logoText - 3
        //           : -logoText
        //         : -boxState.sealPactTextLarge
        //         : boxState.sealPactImageWidth <= 130
        //           ? -logoText - 3
        //           : -boxState.sealPactTextLarge),
        //   },
        //   callback: null,
        // },
        // t2: {
        //   val: `${getTime()[0]} ${getTime()[1]} EST`,
        //   // options: { size: sealPactTextSmall, xDiff: scaledDimsLogo.width + 3, yDiff: -sealTextDiff },
        //   options: {
        //     size: boxState.sealPactTextSmall,
        //     xDiff: (selectedSealInfo.type === 'signature'
        //       ? boxState.sealPactImageWidth <= 100
        //         ? (scaledDimsLogo.width / 2) - 2
        //         : (scaledDimsLogo.width / 2) + 3
        //       : boxState.sealPactImageWidth <= 130
        //         ? (scaledDimsLogo.width / 2) - 2
        //         : (scaledDimsLogo.width / 2) + 3),
        //     yDiff: (selectedSealInfo.type === 'signature'
        //       ? boxState.sealPactImageWidth <= 100
        //         ? boxState.sealPactHeight >= 55
        //           ? -sealTextDiff + 5
        //           : -sealTextDiff + 7
        //         : -boxState.sealPactTextLarge
        //       : boxState.sealPactImageWidth <= 130
        //         ? -sealTextDiff + 5
        //         : -boxState.sealPactTextLarge),
        //   },
        //   callback: null,
        // },
        // t3: {
        //   val: 'Click for Verification',
        //   options: {
        //     // size: sealText,
        //     size: boxState.sealPactTextSmall,
        //     // xDiff: scaledDimsLogo.width + 3,
        //     xDiff: 0,
        //     yDiff: -sealTextDiff,
        //     font: fonts.bold,
        //   },
        //   callback: null,
        // },

        // t4: {
        //   val: selectedSealInfo.type === 'signature' ? '' : '',
        //   options: { size: sealText, xDiff: 100, yDiff: -2, font: fonts.bold },
        //   callback: null,
        // },
        // t5: {
        //   val: ``,
        //   options: { size: sealText, xDiff: 0, yDiff: sealTextDiff },
        //   callback: null,
        // },
        // t6: {
        //   val: '',
        //   options: { size: sealText, xDiff: 0, yDiff: -sealTextDiff },
        //   callback: null,
        // },
        // t7: {
        //   val: ``,
        //   options: { size: sealText, xDiff: 0, yDiff: -sealTextDiff + 5 },
        //   callback: null,
        // },
        // qrCode: {
        //   val: qrCodeImage,
        //   options: {
        //     width: scaledDimsQr.width,
        //     height: scaledDimsQr.height,
        //     xDiff: -106,
        //     yDiff: -5,
        //   },
        //   callback: null,
        // },
      };

      if (
        selectedSealInfo.type === 'stamp' &&
        appEasyState.enableVerificationWatermark
      ) {
        drawObject_Watermark = {
          t1: {
            val: 'See SealPact Verification',
            options: {
              size: parseInt(0.07 * boxState.sealPactWidth),
              color: rgb(38 / 255, 97 / 255, 156 / 255),
              xDiff: 0,
              yDiff: 0,
            },
            callback: null,
          },
        };
      }
    } else {
      drawObject = {
        seal: {
          val: image,
          options: {
            width:
              selectedSealInfo.type === 'signature'
                ? boxState.sealPactImageWidth
                : scaledDimsImg.width,
            height:
              selectedSealInfo.type === 'signature'
                ? boxState.sealPactImageHeight
                : scaledDimsImg.height,
            xDiff:
              selectedSealInfo.type === 'signature'
                ? boxState.sealPactImageWidth +
                  (boxState.sealPactImageWidth -
                    (scaledDimsLogo.width + 5 + (scaledDimsLogo.width + 20))) /
                    2
                : boxState.sealPactImageWidth <= 130
                ? scaledDimsImg.width / 4
                : scaledDimsImg.width / 3,
            // : ((scaledDimsImg.width / 3) + ( ( (scaledDimsImg.width / 5)  - (scaledDimsLogo.width + boxState.sealPactTextLarge))/2)),

            // : scaledDimsImg.width / 3,
            // selectedSealInfo.type === 'signature'
            //   ? sealImageRatio + 7
            //   : -1 *
            //     (scaledDimsImg.height > scaledDimsImg.width
            //       ?((85 - scaledDimsImg.width) / 2) + scaledDimsLogo.width + 10
            //       : -30 - scaledDimsLogo.width - 10),
            yDiff:
              selectedSealInfo.type === 'signature'
                ? // ? 0.46 * parseInt(scaledDimsImg.height)
                  boxState.sealPactHeight >= 55
                  ? 0.53 *
                    (parseInt(scaledDimsImg.height) +
                      Math.abs(
                        boxState.sealPactImageHeight -
                          parseInt(scaledDimsImg.height),
                      ))
                  : 0.57 *
                    (parseInt(scaledDimsImg.height) +
                      Math.abs(
                        boxState.sealPactImageHeight -
                          parseInt(scaledDimsImg.height),
                      ))
                : -1 * (scaledDimsLogo.height + 7),
          },
          callback: null,
        },
        logo: {
          val: logo,
          options: {
            width: scaledDimsLogo.width,
            height: scaledDimsLogo.height,
            xDiff: scaledDimsLogo.width + 5,
            yDiff: scaledDimsLogo.height / 4,
          },
          callback: null,
        },
        t1: {
          val: 'SealPact',
          options: {
            size: boxState.sealPactTextLarge,
            // fontSize: sealPactTextLarge,
            // xDiff: -1 * (scaledDimsLogo.width + 3),
            // xDiff: -1 * (scaledDimsImg.width / 4.5),
            // xDiff: -1 * (scaledDimsLogo.width + 5),
            // xDiff:  -1 * (scaledDimsLogo.width + 20),
            xDiff:
              -1 *
              (selectedSealInfo.type === 'signature'
                ? boxState.sealPactImageWidth <= 100
                  ? scaledDimsLogo.width * 1.7
                  : boxState.sealPactTextSmall * 3.5
                : boxState.sealPactImageWidth <= 130
                ? boxState.sealPactTextSmall * 3.8
                : boxState.sealPactTextSmall * 3.5),
            yDiff:
              selectedSealInfo.type === 'signature'
                ? boxState.sealPactImageWidth <= 100
                  ? boxState.sealPactHeight >= 55
                    ? -logoText - 3
                    : -logoText
                  : -boxState.sealPactTextLarge
                : boxState.sealPactImageWidth <= 130
                ? -logoText - 3
                : -boxState.sealPactTextLarge,
          },
          callback: null,
        },
        t2: {
          val: `${getTime()[0]} ${getTime()[1]} EST`,
          // options: { size: sealPactTextSmall, xDiff: scaledDimsLogo.width + 3, yDiff: -sealTextDiff },
          options: {
            size: boxState.sealPactTextSmall,
            xDiff:
              selectedSealInfo.type === 'signature'
                ? boxState.sealPactImageWidth <= 100
                  ? scaledDimsLogo.width / 2 - 2
                  : scaledDimsLogo.width / 2 + 3
                : boxState.sealPactImageWidth <= 130
                ? scaledDimsLogo.width / 2 - 2
                : scaledDimsLogo.width / 2 + 3,
            yDiff:
              selectedSealInfo.type === 'signature'
                ? boxState.sealPactImageWidth <= 100
                  ? boxState.sealPactHeight >= 55
                    ? -sealTextDiff + 5
                    : -sealTextDiff + 5
                  : -boxState.sealPactTextLarge
                : boxState.sealPactImageWidth <= 130
                ? -sealTextDiff + 5
                : -boxState.sealPactTextLarge,
          },
          callback: null,
        },
        t3: {
          val: 'Click for Verification',
          options: {
            // size: sealText,
            size: boxState.sealPactTextSmall,
            // xDiff: scaledDimsLogo.width + 3,
            xDiff: 0,
            yDiff: -sealTextDiff,
            font: fonts.bold,
          },
          callback: null,
        },

        // t4: {
        //   val: selectedSealInfo.type === 'signature' ? '' : '',
        //   options: { size: sealText, xDiff: 100, yDiff: -2, font: fonts.bold },
        //   callback: null,
        // },
        // t5: {
        //   val: ``,
        //   options: { size: sealText, xDiff: 0, yDiff: sealTextDiff },
        //   callback: null,
        // },
        // t6: {
        //   val: '',
        //   options: { size: sealText, xDiff: 0, yDiff: -sealTextDiff },
        //   callback: null,
        // },
        // t7: {
        //   val: ``,
        //   options: { size: sealText, xDiff: 0, yDiff: -sealTextDiff + 5 },
        //   callback: null,
        // },
        // qrCode: {
        //   val: qrCodeImage,
        //   options: {
        //     width: scaledDimsQr.width,
        //     height: scaledDimsQr.height,
        //     xDiff: -106,
        //     yDiff: -5,
        //   },
        //   callback: null,
        // },
      };
    }

    console.log('rotation: ', rotation);

    // Handle the rotation of the pdf and scaling of x,y
    let calculateImagex = 0;
    let calculateImagey = 0;

    if (rotation === 0) {
      if (offsetX < 0) {
        calculateImagex =
          selectedSealInfo.page[pageIndex + 1].x / zoomLevel +
          offsetX +
          (selectedSealInfo.type === 'signature'
            ? -1
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      } else {
        calculateImagex =
          selectedSealInfo.page[pageIndex + 1].x / zoomLevel +
          (selectedSealInfo.type === 'signature'
            ? -1
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      }
      if (offsetY < 0) {
        calculateImagey =
          pages[pageIndex].getHeight() +
          offsetY -
          selectedSealInfo.page[pageIndex + 1].y * (1 / zoomLevel) -
          parseInt(scaledDimsImg.height) -
          (selectedSealInfo.type === 'signature'
            ? Math.abs(
                boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
              )
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageHeight - scaledDimsImg.height) / 2);
      } else {
        calculateImagey =
          pages[pageIndex].getHeight() -
          selectedSealInfo.page[pageIndex + 1].y * (1 / zoomLevel) -
          parseInt(scaledDimsImg.height) -
          (selectedSealInfo.type === 'signature'
            ? Math.abs(
                boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
              )
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageHeight - scaledDimsImg.height) / 2);
      }
      xCoord = calculateImagex;
      // calculateImagex / zoomLevel +
      // (selectedSealInfo.type === 'signature'
      //   ? -1
      //   : boxState.sealPactImageWidth === 130 &&
      //     boxState.sealPactImageHeight === 130
      //   ? 0
      //   : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);

      // : + (scaledDimsImg.width / 3.5) );
      //scaledDimsImg.width / 3);
      // scaledDimsImg.height === scaledDimsImg.width
      //   ? (150 - scaledDimsImg.width) / 2 + 2
      //   : 0);

      // + (selectedSealInfo.type === 'signature'
      //   ? 0
      //   : (sealPactImageWidth === 130 && sealPactImageHeight === 130)
      //     ? 0
      //     : zoomLevel >= 1
      //       ? scaledDimsImg.width / 2
      //       : scaledDimsImg.width * 3)

      // alert('boxState.sealPactImageHeight ' + boxState.sealPactImageHeight)
      // alert('pages[pageIndex].getHeight() ' + pages[pageIndex].getHeight())
      // alert('selectedSealInfo.page[pageIndex + 1].y ' + selectedSealInfo.page[pageIndex + 1].y)
      // alert('scaledDimsImg.height ' + scaledDimsImg.height)
      yCoord = calculateImagey;
      // calculateImagey * (1 / zoomLevel) -
      // parseInt(scaledDimsImg.height) -
      // (selectedSealInfo.type === 'signature'
      //   ? Math.abs(
      //       boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
      //     )
      //   : boxState.sealPactImageWidth === 130 &&
      //     boxState.sealPactImageHeight === 130
      //   ? 0
      //   : (boxState.sealPactImageHeight - scaledDimsImg.height) / 2);

      // alert('yCoord ' + yCoord)

      console.log('pages[pageIndex].getWidth(): ', pages[pageIndex].getWidth());
      console.log(
        'pages[pageIndex].getHeight(): ',
        pages[pageIndex].getHeight(),
      );
      console.log(
        'selectedSealInfo.page[pageIndex + 1].x: ',
        selectedSealInfo.page[pageIndex + 1].x,
      );
      console.log(
        'selectedSealInfo.page[pageIndex + 1].y: ',
        selectedSealInfo.page[pageIndex + 1].y,
      );
      console.log('scaledDimsImg.width: ', scaledDimsImg.width);
      console.log('scaledDimsImg.height: ', scaledDimsImg.height);
      console.log('coordinates: ', { xCoord, yCoord });
    } else if (rotation === 90) {
      if (offsetX < 0) {
        calculateImagex =
          selectedSealInfo.page[pageIndex + 1].x / zoomLevel +
          offsetY +
          (selectedSealInfo.type === 'signature'
            ? -1
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      } else {
        calculateImagex =
          selectedSealInfo.page[pageIndex + 1].x / zoomLevel +
          (selectedSealInfo.type === 'signature'
            ? -1
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      }
      if (offsetY < 0) {
        calculateImagey =
          selectedSealInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
          offsetX +
          parseInt(scaledDimsImg.height) +
          (selectedSealInfo.type === 'signature'
            ? Math.abs(
                boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
              )
            : 0);
      } else {
        calculateImagey =
          selectedSealInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
          parseInt(scaledDimsImg.height) +
          (selectedSealInfo.type === 'signature'
            ? Math.abs(
                boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
              )
            : 0);
      }
      xCoord = calculateImagey;
      // calculateImagey * (1 / zoomLevel) +
      // parseInt(scaledDimsImg.height) +
      // (selectedSealInfo.type === 'signature'
      //   ? Math.abs(
      //       boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
      //     )
      //   : 0);

      yCoord = calculateImagex;
      // calculateImagex / zoomLevel +
      // (selectedSealInfo.type === 'signature'
      //   ? -1
      //   : boxState.sealPactImageWidth === 130 &&
      //     boxState.sealPactImageHeight === 130
      //   ? 0
      //   : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);

      // (selectedSealInfo.page[pageIndex + 1].x ) / zoomLevel
      // +
      // (selectedSealInfo.type === 'signature'
      //   ? -1
      //   : scaledDimsImg.height > scaledDimsImg.width
      //     ? (150 - scaledDimsImg.width) / 2 + 2
      //     : 0);
      // (selectedSealInfo.page[pageIndex + 1].x + (((boxState.resizableBoxWidth - boxState.sealPactWidth) / 2) + panX * -1)) / zoomLevel
      // + (selectedSealInfo.type === 'signature'
      //   ? 0
      //   : (sealPactImageWidth !== 130 && sealPactImageHeight !== 130)
      //     ? scaledDimsImg.width / 2
      //     : 0)
      // +
      // (selectedSealInfo.type === 'signature'
      //   ? -1
      //   : scaledDimsImg.height > scaledDimsImg.width
      //     ? (150 - scaledDimsImg.width) / 2 + 2
      //     : 10);

      if (selectedSealInfo.type === 'signature') {
        for (const [k, v] of Object.entries(drawObject)) {
          [drawObject[k].options.xDiff, drawObject[k].options.yDiff] = [
            -1 * drawObject[k].options.yDiff,
            drawObject[k].options.xDiff,
          ];
        }
      }
    } else if (rotation === 180) {
      if (offsetX < 0) {
        calculateImagex =
          pages[pageIndex].getWidth() -
          selectedSealInfo.page[pageIndex + 1].x / zoomLevel +
          offsetX -
          (selectedSealInfo.type === 'signature'
            ? -1
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      } else {
        calculateImagex =
          pages[pageIndex].getWidth() -
          selectedSealInfo.page[pageIndex + 1].x / zoomLevel -
          (selectedSealInfo.type === 'signature'
            ? -1
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      }
      if (offsetY < 0) {
        calculateImagey =
          selectedSealInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
          offsetY +
          parseInt(scaledDimsImg.height) +
          (selectedSealInfo.type === 'signature'
            ? Math.abs(
                boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
              )
            : 0);
      } else {
        calculateImagey =
          selectedSealInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
          parseInt(scaledDimsImg.height) +
          (selectedSealInfo.type === 'signature'
            ? Math.abs(
                boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
              )
            : 0);
      }
      xCoord = calculateImagex;
      // calculateImagex / zoomLevel -
      // (selectedSealInfo.type === 'signature'
      //   ? -1
      //   : boxState.sealPactImageWidth === 130 &&
      //     boxState.sealPactImageHeight === 130
      //   ? 0
      //   : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      // (selectedSealInfo.page[pageIndex + 1].x + (((boxState.resizableBoxWidth - boxState.sealPactWidth) / 2) + panX * -1)) * (1 / zoomLevel) -
      // 16;
      yCoord = calculateImagey;
      // calculateImagey * (1 / zoomLevel) +
      // parseInt(scaledDimsImg.height) +
      // (selectedSealInfo.type === 'signature'
      //   ? Math.abs(
      //       boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
      //     )
      //   : 0);

      if (selectedSealInfo.type === 'signature') {
        for (const [k, v] of Object.entries(drawObject)) {
          [drawObject[k].options.xDiff, drawObject[k].options.yDiff] = [
            -1 * drawObject[k].options.xDiff,
            -1 * drawObject[k].options.yDiff,
          ];
        }
      }
    } else if (rotation === 270) {
      if (offsetX < 0) {
        calculateImagex =
          pages[pageIndex].getHeight() -
          selectedSealInfo.page[pageIndex + 1].x / zoomLevel +
          offsetY -
          (selectedSealInfo.type === 'signature'
            ? -1
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      } else {
        calculateImagex =
          pages[pageIndex].getHeight() -
          selectedSealInfo.page[pageIndex + 1].x / zoomLevel -
          (selectedSealInfo.type === 'signature'
            ? -1
            : boxState.sealPactImageWidth === 130 &&
              boxState.sealPactImageHeight === 130
            ? 0
            : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      }

      if (offsetY < 0) {
        calculateImagey =
          pages[pageIndex].getWidth() -
          selectedSealInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
          offsetX -
          parseInt(scaledDimsImg.height) -
          (selectedSealInfo.type === 'signature'
            ? Math.abs(
                boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
              )
            : 0);
      } else {
        calculateImagey =
          pages[pageIndex].getWidth() -
          selectedSealInfo.page[pageIndex + 1].y * (1 / zoomLevel) -
          parseInt(scaledDimsImg.height) -
          (selectedSealInfo.type === 'signature'
            ? Math.abs(
                boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
              )
            : 0);
      }
      xCoord = calculateImagey;
      // calculateImagey * (1 / zoomLevel) -
      // parseInt(scaledDimsImg.height) -
      // (selectedSealInfo.type === 'signature'
      //   ? Math.abs(
      //       boxState.sealPactImageHeight - parseInt(scaledDimsImg.height),
      //     )
      //   : 0);
      yCoord = calculateImagex;
      // calculateImagex / zoomLevel -
      // (selectedSealInfo.type === 'signature'
      //   ? -1
      //   : boxState.sealPactImageWidth === 130 &&
      //     boxState.sealPactImageHeight === 130
      //   ? 0
      //   : (boxState.sealPactImageWidth - scaledDimsImg.width) / 2);
      // selectedSealInfo.page[pageIndex + 1].x * (1 / zoomLevel);
      // (selectedSealInfo.page[pageIndex + 1].x + (((boxState.resizableBoxWidth - boxState.sealPactWidth) / 2) + panX * -1)) * (1 / zoomLevel) -
      // 16;

      if (selectedSealInfo.type === 'signature') {
        for (const [k, v] of Object.entries(drawObject)) {
          [drawObject[k].options.xDiff, drawObject[k].options.yDiff] = [
            drawObject[k].options.yDiff,
            -1 * drawObject[k].options.xDiff,
          ];
        }
      }
    }

    selectedSealInfo.page[pageIndex + 1].xCoord = xCoord;
    selectedSealInfo.page[pageIndex + 1].yCoord = yCoord;
    selectedSealInfo.page[pageIndex + 1].width =
      selectedSealInfo.type === 'signature'
        ? boxState.sealPactImageWidth * 2
        : scaledDimsImg.width;
    selectedSealInfo.page[pageIndex + 1].height = boxState.sealPactHeight;

    let xCoord_Watermark, yCoord_Watermark;

    if (rotation === 0) {
      selectedSealInfo.page[pageIndex + 1].yCoord =
        selectedSealInfo.type === 'signature'
          ? yCoord - 2.5
          : yCoord - (boxState.sealPactHeight - boxState.sealPactImageHeight);

      xCoord_Watermark = xCoord + boxState.watermarkLeft;
      yCoord_Watermark =
        yCoord +
        (boxState.sealPactHeight === 130 ? 0.43 : 0.4) *
          boxState.sealPactHeight;
    }

    if (rotation === 90) {
      selectedSealInfo.page[pageIndex + 1].yCordFactor =
        boxState.sealPactHeight +
        (selectedSealInfo.type === 'signature' ? 2.5 : 0);

      xCoord_Watermark =
        xCoord -
        (boxState.sealPactHeight === 130 ? 0.43 : 0.4) *
          boxState.sealPactHeight;
      yCoord_Watermark = yCoord + boxState.watermarkLeft;
    }

    if (rotation === 180) {
      selectedSealInfo.page[pageIndex + 1].xCoord = xCoord;
      if (offsetY < 0) {
        selectedSealInfo.page[pageIndex + 1].yCoord =
          selectedSealInfo.type === 'signature'
            ? yCoord + 2.5
            : (selectedSealInfo.page[pageIndex + 1].y + offsetY) *
                (1 / zoomLevel) +
              boxState.sealPactHeight;
      } else {
        selectedSealInfo.page[pageIndex + 1].yCoord =
          selectedSealInfo.type === 'signature'
            ? yCoord + 2.5
            : selectedSealInfo.page[pageIndex + 1].y * (1 / zoomLevel) +
              boxState.sealPactHeight;
      }

      xCoord_Watermark = xCoord - boxState.watermarkLeft;
      yCoord_Watermark =
        yCoord -
        (boxState.sealPactHeight === 130 ? 0.43 : 0.4) *
          boxState.sealPactHeight;
    }

    if (rotation === 270) {
      selectedSealInfo.page[pageIndex + 1].xCordFactor =
        boxState.sealPactHeight +
        (selectedSealInfo.type === 'signature' ? 2.5 : 0);

      xCoord_Watermark =
        xCoord +
        (boxState.sealPactHeight === 130 ? 0.43 : 0.4) *
          boxState.sealPactHeight;
      yCoord_Watermark = yCoord - boxState.watermarkLeft;
    }

    // selectedSealInfo.page[pageIndex + 1].xCoord =
    //   scaledDimsImg.height > scaledDimsImg.width && scaledDimsImg.width < 100
    //     ? xCoord - (85 - scaledDimsImg.width) / 2
    //     : xCoord;
    // selectedSealInfo.page[pageIndex + 1].yCoord = selectedSealInfo.type === 'signature'
    //   ? (yCoord + (scaledDimsImg.height / 4.5))
    //   : yCoord;
    // selectedSealInfo.page[pageIndex + 1].height = selectedSealInfo.type === 'signature'
    //   ? scaledDimsImg.height
    //   : scaledDimsImg.height + (scaledDimsImg.height / 5);
    // // parseInt(scaledDimsImg.height) + 60 + 6;
    // selectedSealInfo.page[pageIndex + 1].width = selectedSealInfo.type === 'signature'
    //   ? scaledDimsImg.width + (scaledDimsImg.width / 1.5)
    //   : scaledDimsImg.width + 10;

    // selectedSealInfo.page[pageIndex + 1].xCoord =
    //   scaledDimsImg.height > scaledDimsImg.width && scaledDimsImg.width < 100
    //     ? xCoord - (85 - scaledDimsImg.width) / 2
    //     : xCoord;
    // selectedSealInfo.page[pageIndex + 1].yCoord = yCoord;
    // selectedSealInfo.page[pageIndex + 1].height =
    //   parseInt(scaledDimsImg.height) + 60 + 6;
    // selectedSealInfo.page[pageIndex + 1].width =
    //   selectedSealInfo.type === 'signature'
    //     ? 180
    //     : scaledDimsImg.height > scaledDimsImg.width
    //       ? scaledDimsImg.width < 100
    //         ? 100
    //         : scaledDimsImg.width + 10
    //       : 140;

    // selectedSealInfo.type === 'signature'
    //   ? 180
    //   : scaledDimsImg.height > scaledDimsImg.width
    //   ? scaledDimsImg.width < 100
    //     ? 100
    //     : scaledDimsImg.width + 10
    //   : 140;
    console.log({
      xCoord,
      yCoord,
      width:
        selectedSealInfo.type === 'signature'
          ? boxState.sealPactImageWidth
          : scaledDimsImg.width,
      height:
        selectedSealInfo.type === 'signature'
          ? boxState.sealPactImageHeight
          : scaledDimsImg.height,
    });

    for (const [item, opt] of Object.entries(drawObject)) {
      opt.options.rotate = degrees(rotation);
      [xCoord, yCoord] = drawItem(
        pages[pageIndex],
        opt.val,
        xCoord,
        yCoord,
        opt.options,
        nextLine,
        opt.callback,
        // offsetX,
        // offsetY,
        // height,
      );
    }

    if (drawObject_Watermark) {
      for (const [item, opt] of Object.entries(drawObject_Watermark)) {
        console.log('opt', opt);
        opt.options.rotate = degrees(rotation);
        [xCoord, yCoord] = drawItem(
          pages[pageIndex],
          opt.val,
          xCoord_Watermark,
          yCoord_Watermark,
          opt.options,
          nextLine,
          opt.callback,
          true,
          // offsetX,
          // offsetY,
          // height,
        );

        console.log(xCoord, yCoord);
        debugger;
      }
    }
  };

  const handleSealConfirmationModal = () =>
    setshowDetailModal(!showDetailModal);
  const isSealConfirmedFunc = () => {
    debugger;
    console.log('confirmationValues ======> ', confirmationValues);
    console.log('documentInformation ==> ', documentInformation);
    setDisableControl(true);
    store.addNotification({
      title: 'Please wait!',
      message:
        'Please wait while a digital signature for your Document is created.',
      type: 'warning',
      ...NOTIFICATION_PROPS,
    });
    setLoading(true);
    setshowConfirmationPopup(false);
    setafterDragged(true);
    setafterDragged_QR(true);

    // Update the code to use async/await instead of callbacks
    // better maintainability
    if (documentInformation.fileType === 'application/pdf') {
      fetch(documentDetails)
        .then((res) => res.arrayBuffer())
        .then((pdfBuffer) => {
          fetch(documentInformation.selectedSealInfo.data)
            .then((res) => res.arrayBuffer())
            .then((imageBuffer) => {
              PDFDocument.load(pdfBuffer).then(async (pdfDoc) => {
                let fonts = {},
                  embedImage = {};
                fonts.normal = await pdfDoc.embedFont(StandardFonts.Helvetica);
                fonts.bold = await pdfDoc.embedFont(
                  StandardFonts.HelveticaBold,
                );

                console.log('fonts.normal: ', fonts.normal);
                console.log('fonts.bold: ', fonts.bold);
                if (imageType(imageBuffer).ext == 'png')
                  embedImage = await pdfDoc.embedPng(imageBuffer);
                else embedImage = await pdfDoc.embedJpg(imageBuffer);
                console.log('embedImage ****************', embedImage);
                debugger;
                const imageDims = embedImage.scale(0.25);
                // let temp = {...embedImage}
                // temp.width = 50
                // temp.height = 50
                // embedImage['width'] = 600;
                // embedImage.embedder['width'] = 600;
                // embedImage['height'] = 600;
                // embedImage.embedder['height'] = 600;
                // embedImage = [...temp]
                // console.log('embedImage ****************', embedImage)
                const pages = pdfDoc.getPages();
                // pages[0].drawText('You can modify PDFs too!');

                const { width, height } = pages[0].getSize();
                const contentBox = pages[0].getMediaBox();
                const offsetX = contentBox.x; // Left offset of the content
                const offsetY = contentBox.y; // Bottom offset of the content
                const pageRotation = pages[0].getRotation();
                let calculatex = 0;
                let calculatey = 0;
                if (offsetX < 0) {
                  calculatex = selectedSealInfo.page[1].x + offsetX;
                } else {
                  calculatex = selectedSealInfo.page[1].x - offsetX;
                }
                if (offsetY < 0) {
                  calculatey = height + offsetY - selectedSealInfo.page[1].y;
                } else {
                  calculatey = selectedSealInfo.page[1].y - offsetY;
                }

                console.log('selected seal info =====>', selectedSealInfo);
                console.log('calculatex =====>', calculatex);
                console.log('calculatey =====>', calculatey);
                debugger;

                // setselectedSealInfo((prevInfo) => ({
                //   ...prevInfo,
                //   page: {
                //     ...prevInfo.page, // Spread the previous page object
                //     1: { x: calculatex, y: calculatey },
                //   },
                // }));

                // const calculatex =
                debugger;
                console.log('page rotation =====>', pageRotation);
                // pages[0].drawImage(embedImage, {
                //   x: calculatex,
                //   y: calculatey,
                //   width: imageDims.width,
                //   height: imageDims.height,
                // });
                // let x;
                // pages.forEach((page) => {
                //   page.setRotation(degrees(rotation));
                // });
                console.log('boxState.pagesRotation', boxState.pagesRotation);
                if (boxState.pagesRotation) {
                  pages.forEach((page, index) => {
                    page.setRotation(degrees(boxState.pagesRotation[index]));
                  });
                }
                console.log('pages pdfx', pages);
                console.log('sealPages.range.length', sealPages.range.length);
                if (sealPages.range.length === 0) {
                  for (
                    let x = sealPages.pageFrom - 1;
                    x < sealPages.pageTo;
                    x++
                  ) {
                    let qrCodeImage = '';
                    // pages[x].setRotation(degrees(rotation));
                    pages[x].setRotation(degrees(boxState.pagesRotation[x]));
                    if (selectedSealInfo.type !== 'signature' && !cancelQRDiv) {
                      const qrCanvas = document.getElementById('QRCode');
                      if (qrCanvas) {
                        const qrURL = qrCanvas
                          .toDataURL('image/png')
                          .replace('image/png', 'image/octet-stream');
                        let qrCode = await fetch(qrURL);
                        qrCode = await qrCode.arrayBuffer();
                        qrCodeImage = await pdfDoc.embedPng(qrCode);
                        console.log('boxState.qrCode', qrCodeImage);
                      } else {
                        qrCodeImage = await pdfDoc.embedPng(
                          boxState.qrCodeBuffer,
                        );
                      }
                    }
                    const { isAllPages, isOtherPages, isSealOnSamePos } =
                      selectedPagesAndSealPos;
                    console.log(
                      'selectedPagesAndSealPos',
                      selectedPagesAndSealPos,
                    );
                    const logo = await pdfDoc.embedPng(Logo);
                    console.log('logo: ', logo);
                    if (
                      (isAllPages && isSealOnSamePos) ||
                      (isOtherPages && isSealOnSamePos)
                    ) {
                      drawSeal(
                        pages,
                        x,
                        embedImage,
                        logo,
                        qrCodeImage,
                        boxState.pagesRotation[x],
                        fonts,
                        offsetX,
                        offsetY,
                        // height,
                      );
                    } else {
                      drawSeal(
                        pages,
                        x,
                        embedImage,
                        logo,
                        qrCodeImage,
                        boxState.pagesRotation[x],
                        fonts,
                        offsetX,
                        offsetY,
                        // height,
                      );
                    }
                  }
                } else {
                  for (let i = 0; i < sealPages.range.length; i++) {
                    const x = sealPages.range[i] - 1;
                    let qrCodeImage = '';
                    // pages[x].setRotation(degrees(rotation));
                    pages[x].setRotation(degrees(boxState.pagesRotation[x]));
                    if (selectedSealInfo.type !== 'signature' && !cancelQRDiv) {
                      const qrCanvas = document.getElementById('QRCode');
                      if (qrCanvas) {
                        const qrURL = qrCanvas
                          .toDataURL('image/png')
                          .replace('image/png', 'image/octet-stream');
                        let qrCode = await fetch(qrURL);
                        qrCode = await qrCode.arrayBuffer();
                        qrCodeImage = await pdfDoc.embedPng(qrCode);
                        console.log('boxState.qrCode', qrCodeImage);
                      } else {
                        qrCodeImage = await pdfDoc.embedPng(
                          boxState.qrCodeBuffer,
                        );
                      }
                    }
                    const { isAllPages, isOtherPages, isSealOnSamePos } =
                      selectedPagesAndSealPos;
                    console.log(
                      'selectedPagesAndSealPos',
                      selectedPagesAndSealPos,
                    );
                    const logo = await pdfDoc.embedPng(Logo);
                    console.log('logo: ', logo);
                    if (
                      (isAllPages && isSealOnSamePos) ||
                      (isOtherPages && isSealOnSamePos)
                    ) {
                      drawSeal(
                        pages,
                        x,
                        embedImage,
                        logo,
                        qrCodeImage,
                        boxState.pagesRotation[x],
                        fonts,
                        offsetX,
                        offsetY,
                        // height,
                      );
                    } else {
                      drawSeal(
                        pages,
                        x,
                        embedImage,
                        logo,
                        qrCodeImage,
                        boxState.pagesRotation[x],
                        fonts,
                        offsetX,
                        offsetY,
                        // height,
                      );
                    }
                  }
                }
                pdfDoc.save().then(async (pdfBytes) => {
                  // download(pdfBytes, "pdf-lib_image_embedding_example.pdf", "application/pdf");
                  // console.log('pdfBytes => ', pdfBytes);
                  // let base64String = btoa(
                  //   String.fromCharCode.apply(
                  //     null,
                  //     new Uint8Array(pdfBytes),
                  //   ),
                  // );
                  // let binary = '';
                  // let bytes = new Uint8Array(pdfBytes);
                  // let len = bytes.byteLength;
                  // for (var i = 0; i < len; i++) {
                  //   binary += String.fromCharCode(bytes[i]);
                  // }
                  // const base64String = window.btoa(binary);
                  let base64String = btoa(
                    new Uint8Array(pdfBytes).reduce(
                      (data, byte) => data + String.fromCharCode(byte),
                      '',
                    ),
                  );

                  // console.log('base64String =====>', base64String);
                  debugger;
                  let result = await axios.post(DRAW_CLICKABLE, {
                    selectedSealInfo,
                    sealPages,
                    file: base64String,
                    rotation: boxState.pagesRotation,
                    is_signature: selectedSealInfo.type === 'signature' ? 1 : 0,
                    redirection_url: `${window.location.origin}/sealpactedDetails?${documentInformation.id}&${props.user.uid}`,
                    // redirection_url:`https://originstamp.com/verify/`
                  });
                  console.log(result.data);
                  // return
                  if (result.data.body) {
                    base64String = result.data.body;
                  }
                  debugger;

                  var hash;
                  if (result.data.hash && result.data.hash != '') {
                    hash = result.data.hash;
                  }
                  // base64String =
                  //   'data:application/pdf;base64,' + base64String;
                  // console.log('base64String => ', base64String);
                  // axios
                  //   .post(
                  //     'https://sealpact-bigchaindb.herokuapp.com/v0/sealpacts',
                  //     {
                  //       publicKey: selectedSealInfo.publicKey,
                  //       privateKey: selectedSealInfo.privateKey,
                  //       sealData: {
                  //         documentInformation,
                  //         confirmationValues,
                  //       },
                  //     },
                  //   )
                  //   .then(async function (response) {
                  //     console.log(response);

                  //originstamp blockchain verification hash timestamp create
                  let originstamp_response;
                  if (result.data.hash && result.data.hash != '') {
                    originstamp_response = await axios.post(
                      'https://api.originstamp.com/v4/timestamp/create',
                      {
                        comment: `${documentInformation.name}_${documentInformation.id}`,
                        hash: hash,
                      },
                      {
                        headers: {
                          Authorization: 'b6ae8755-eb3f-408d-8d50-ec6f5571ad5b',
                        },
                      },
                    );
                  }

                  console.log('originstamp_response', originstamp_response);

                  console.log({ originstamp_response: originstamp_response });
                  debugger;
                  confirmationValues.sealpactId =
                    originstamp_response.data.data.hash_string;
                  confirmationValues.dateSealPacted = new Date().getTime();
                  // if (
                  //   documentInformation.sealPacterType === 'primary'
                  // ) {
                  if (documentInformation.sealPacterType === 'seal') {
                    documentInformation.primarySealPactSigner =
                      documentInformation.selectedSealInfo.sealName;
                  } else if (
                    documentInformation.sealPacterType === 'signature'
                  ) {
                    documentInformation.primarySealPactSigner =
                      documentInformation.selectedSealInfo.signatureName;
                  } else if (
                    documentInformation.sealPacterType ===
                      'submittalReviewStamp' ||
                    documentInformation.sealPacterType === 'stamp'
                  ) {
                    documentInformation.primarySealPactSigner =
                      documentInformation.selectedSealInfo.sealName;
                  }

                  // documentInformation.primarySealPactSigner =
                  //   documentInformation.selectedSealInfo.sealName;
                  documentInformation.status = 'pacted';
                  confirmationValues.status = 'pacted';
                  documentInformation.rotation = boxState.pagesRotation;
                  documentInformation.sameRotation = sameRotation;
                  if (result.data.hash && result.data.hash != '') {
                    documentInformation.originstamp =
                      originstamp_response.data.data;
                  }
                  // documentInformation.range = appEasyState.customPages;

                  firebase
                    .uploadSealPactedDocument(
                      user.uid,
                      documentInformation,
                      confirmationValues,
                      base64String,
                    )
                    .then((res) => {
                      setisSealconfirmed(true);
                      setLoading(false);
                      setSealPactDownloadUrl(res.sealPactDownloadUrl);
                      confirmationValues.sealPactDownloadUrl =
                        res.sealPactDownloadUrl;
                      setDisableControl(false);
                      setDocumentStatus('pacted');
                      store.addNotification({
                        title: 'Uploaded Successfully!',
                        message: 'Sealpact uploaded Successfully.',
                        type: 'default',
                        ...NOTIFICATION_PROPS,
                      });
                      appEasyState.customPages = [];
                      setafterDragged(false);

                      //     //ls.set('sealInfo', selectedSealInfo);
                      //     // debugger;
                      return;
                    })
                    .catch((error) => {
                      console.log('in firebase error');
                      setLoading(false);
                      store.addNotification({
                        title: 'Error',
                        message:
                          'Please flatten file and re-upload document for best SealPact experience. Email support@sealpact.com if issue persists.',
                        type: 'danger',
                        ...NOTIFICATION_PROPS_LONGER,
                      });
                      console.log('error', error);
                      return;
                    });
                  // } else {
                  //   documentInformation.secondarySealPactSigner =
                  //     confirmationValues.name;
                  //   documentInformation.status = 'pacted';
                  //   documentInformation.rotation = rotation;

                  //   firebase
                  //     .uploadSealPactedDocument(
                  //       user.uid,
                  //       documentInformation,
                  //       confirmationValues,
                  //       base64String,
                  //     )
                  //     .then((res) => {
                  //       setisSealconfirmed(true);
                  //       setLoading(false);
                  //       setSealPactDownloadUrl(
                  //         res.sealPactDownloadUrl,
                  //       );
                  //       confirmationValues.sealPactDownloadUrl =
                  //         res.sealPactDownloadUrl;
                  //       store.addNotification({
                  //         title: 'Uploaded Successfully!',
                  //         message: 'Sealpact uploaded Successfully.',
                  //         type: 'default',
                  //         ...NOTIFICATION_PROPS,
                  //       });

                  //       //ls.set('sealInfo', selectedSealInfo);
                  //       debugger;
                  //       return;
                  //     })
                  //     .catch((error) => {
                  //       setLoading(false);
                  //       store.addNotification({
                  //         title: 'Error',
                  //         message:
                  //           'Error uploading sealpact document',
                  //         type: 'danger',
                  //         ...NOTIFICATION_PROPS,
                  //       });
                  //       console.log('error', error);
                  //       return;
                  //     });
                  // }
                  // debugger;
                  // })
                  // .catch(function (error) {
                  //   console.log(error);
                  //   setLoading(false);
                  //   store.addNotification({
                  //     title: 'Error',
                  //     message: 'Error in api while sealpacting.',
                  //     type: 'danger',
                  //     ...NOTIFICATION_PROPS,
                  //   });
                  // });
                });
              });
            });
        })
        .catch((err) => {
          console.log('err 1');
          console.log({ err });
        });
    } else {
      htmlToImage.toSvgDataURL(imageDocRef.current).then((baseDataUrl) => {
        htmlToImage
          .toSvgDataURL(sealImgRef.current, {
            style: {
              // opacity: 0.7,
            },
          })
          .then((sealDataUrl) => {
            mergeImages([
              { src: baseDataUrl },
              {
                src: sealDataUrl,
                x: selectedSealInfo.x,
                y: selectedSealInfo.y,
              },
            ])
              .then((b64) => {
                console.log('b64 ===> ', b64);
                // axios
                //   .post(
                //     'https://sealpact-bigchaindb.herokuapp.com/v0/sealpacts',
                //     {
                //       publicKey: selectedSealInfo.publicKey,
                //       privateKey: selectedSealInfo.privateKey,
                //       sealData: {
                //         documentInformation,
                //         confirmationValues,
                //       },
                //     },
                //   )
                //   .then(function (response) {
                //     console.log(response);
                //     confirmationValues.sealpactId = response.data.id;
                confirmationValues.dateSealPacted = new Date().getTime();
                documentInformation.status = 'pacted';
                confirmationValues.status = 'pacted';
                setDocumentStatus('pacted');
                setDisableControl(false);

                // documentInformation.rotation = rotation;

                // if (documentInformation.sealPacterType === 'primary') {
                documentInformation.primarySealPactSigner =
                  confirmationValues.name;
                // documentInformation.rotation = rotation;

                firebase
                  .uploadSealPactedDocument(
                    user.uid,
                    documentInformation,
                    confirmationValues,
                    b64,
                  )
                  .then((res) => {
                    setisSealconfirmed(true);
                    setLoading(false);
                    setSealPactDownloadUrl(res.sealPactDownloadUrl);
                    store.addNotification({
                      title: 'Uploaded Successfully!',
                      message: 'Sealpact uploaded Successfully.',
                      type: 'default',
                      ...NOTIFICATION_PROPS,
                    });
                    appEasyState.customPages = [];
                    setafterDragged(false);

                    //ls.set('sealInfo', selectedSealInfo);
                    // debugger;
                    return;
                  })
                  .catch((error) => {
                    setLoading(false);
                    store.addNotification({
                      title: 'Error',
                      message:
                        'Please flatten file and re-upload document for best SealPact experience. Email support@sealpact.com if issue persists.',
                      type: 'danger',
                      ...NOTIFICATION_PROPS,
                    });
                    console.log('error', error);
                    return;
                  });
                // } else {
                //   documentInformation.secondarySealPactSigner =
                //     confirmationValues.name;
                //   documentInformation.status = 'pacted';
                //   // documentInformation.rotation = rotation;

                //   firebase
                //     .uploadSealPactedDocument(
                //       user.uid,
                //       documentInformation,
                //       confirmationValues,
                //       b64,
                //     )
                //     .then((res) => {
                //       setisSealconfirmed(true);
                //       setLoading(false);
                //       setSealPactDownloadUrl(res.sealPactDownloadUrl);
                //       store.addNotification({
                //         title: 'Uploaded Successfully!',
                //         message: 'Sealpact uploaded Successfully.',
                //         type: 'default',
                //         ...NOTIFICATION_PROPS,
                //       });

                //       //ls.set('sealInfo', selectedSealInfo);
                //       debugger;
                //       return;
                //     })
                //     .catch((error) => {
                //       setLoading(false);
                //       store.addNotification({
                //         title: 'Error',
                //         message: 'Error uploading sealpact document',
                //         type: 'danger',
                //         ...NOTIFICATION_PROPS,
                //       });
                //       console.log('error', error);
                //       return;
                //     });
                // }
                // })
                // .catch(function (error) {
                //   console.log(error);
                //   setLoading(false);
                //   store.addNotification({
                //     title: 'Error',
                //     message: 'Error in api while sealpacting.',
                //     type: 'danger',
                //     ...NOTIFICATION_PROPS,
                //   });
                // });
              })
              .catch((error) => {});
          });
      });

      // fetch(documentDetails)
      //   .then(res => res.arrayBuffer())
      //   .then(imageBuffer => {
      //     domtoimage
      //       .toPng(sealImgRef.current, {
      //         style: {
      //           opacity: 0.7,
      //         },
      //       })
      //       .then(dataUrl => {
      //         fetch(dataUrl)
      //           .then(res => res.arrayBuffer())
      //           .then(pngImageBytes => {
      //             PDFDocument.create().then(pdfDoc => {
      //               const page = pdfDoc.addPage();
      //               pdfDoc.embedJpg(imageBuffer).then(image => {
      //                 // const seal = await pdfDoc.embedPng(pngImageBytes)
      //                 console.log('image ---> ', image);
      //                 const imageDims = image.scale(0.8);
      //                 page.drawImage(image, {
      //                   x: 0,
      //                   y: page.getHeight() - imageDims.height,
      //                   width: imageDims.width,
      //                   height: imageDims.height,
      //                 });

      //                 pdfDoc.embedPng(pngImageBytes).then(jpgImage => {
      //                   page.drawImage(jpgImage, {
      //                     x: documentInformation.selectedSealInfo.x,
      //                     y:
      //                       page.getHeight() -
      //                       documentInformation.selectedSealInfo.y -
      //                       200,
      //                     width: 200,
      //                     height: 200,
      //                   });
      //                   pdfDoc.save().then(pdfBytes => {
      //                     console.log('pdfBytes => ', pdfBytes);
      //                     let b4e = btoa(
      //                       new Uint8Array(pdfBytes).reduce(function(
      //                         data,
      //                         byte,
      //                       ) {
      //                         return data + String.fromCharCode(byte);
      //                       },
      //                       ''),
      //                     );

      //                     console.log('b64 => ', b4e);
      //                   });
      //                 });
      //               });
      //             });
      //           });
      //       })
      //       .catch(error =>
      //         console.error('oops, something went wrong!', error),
      //       );
      //   });
      // domtoimage
      //   .toPng(imageDocRef.current)
      //   .then(dataUrl => {
      //     console.log('dataUrl ===> ', dataUrl);
      //   })
      //   .catch(error => console.error('oops, something went wrong!', error));
    }

    // Draw a string of text diagonally across the first page

    // making the document pacted (by adding seal stamp data in document object)
    // if (this.props.location.state) {
    //   let obj = this.props.location.state.item;
    //   obj["pacted"] = this.state.selectedSealInfo;
    // }
  };

  const getConfirmationValues = (values) => {
    setConfirmationValues(values);
  };

  const handleViewed = () => {
    // setLoading(true);
    // firebase
    //   .uploadViewedDocument(user.uid, documentInformation)
    //   .then((res) => {
    //     setLoading(false);
    //     store.addNotification({
    //       title: 'Updated Successfully!',
    //       message: 'Document updated Successfully.',
    //       type: 'default',
    //       ...NOTIFICATION_PROPS,
    //     });
    //     push('/documents');
    //     //ls.set('sealInfo', selectedSealInfo);
    //     debugger;
    //     return;
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     store.addNotification({
    //       title: 'Error',
    //       message: 'Error in updating document',
    //       type: 'danger',
    //       ...NOTIFICATION_PROPS,
    //     });
    //     console.log('error', error);
    //     return;
    //   });
  };

  const incZoom = () => {
    if (zoomLevel < 3) {
      let page = selectedSealInfo.page ? selectedSealInfo.page : {};
      let page_QR = selectedQRInfo.page ? selectedQRInfo.page : {};
      const lastZoom = zoomLevel;
      const cr_zoom = zoomLevel + 0.5;
      const zoomRatio = cr_zoom / lastZoom;
      boxState.zoomRatio = zoomRatio;

      if (page && page[pageNumber]) {
        for (let numDoc = 1; numDoc <= numPages; numDoc++) {
          if (page && page[numDoc]) {
            page[numDoc] = {
              x: page[numDoc].x * zoomRatio,
              y: page[numDoc].y * zoomRatio,
            };
          }

          if (page_QR && page_QR[numDoc]) {
            page_QR[numDoc] = {
              x: page_QR[numDoc].x * zoomRatio,
              y: page_QR[numDoc].y * zoomRatio,
            };
          }
        }

        // page[pageNumber] = {
        //   x: page[pageNumber].x * zoomRatio,
        //   y: page[pageNumber].y * zoomRatio,
        // };
        boxState.x_seal = boxState.x_seal * zoomRatio;
        boxState.y_seal = boxState.y_seal * zoomRatio;
        setselectedSealInfo({
          ...selectedSealInfo,
          page,
        });
      }

      if (page_QR && page_QR[pageNumber]) {
        // page_QR[pageNumber] = {
        //   x: page_QR[pageNumber].x * zoomRatio,
        //   y: page_QR[pageNumber].y * zoomRatio,
        // };
        boxState.x_QR = boxState.x_QR * zoomRatio;
        boxState.y_QR = boxState.y_QR * zoomRatio;
        setselectedQRInfo({
          ...selectedQRInfo,
          page: page_QR,
        });
      } else {
        boxState.x_QR = boxState.x_QR * zoomRatio;
        boxState.y_QR = boxState.y_QR * zoomRatio;
      }

      setZoom(zoomLevel + 0.5);
    }
  };
  const decZoom = () => {
    if (zoomLevel.toPrecision(1) > 0.5) {
      const lastZoom = zoomLevel;
      const cr_zoom = zoomLevel - 0.5;
      const zoomRatio = cr_zoom / lastZoom;
      boxState.zoomRatio = zoomRatio;
      let page = selectedSealInfo.page ? selectedSealInfo.page : {};
      let page_QR = selectedQRInfo.page ? selectedQRInfo.page : {};

      if (page && page[pageNumber]) {
        for (let numDoc = 1; numDoc <= numPages; numDoc++) {
          if (page && page[numDoc]) {
            page[numDoc] = {
              x: page[numDoc].x * zoomRatio,
              y: page[numDoc].y * zoomRatio,
            };
          }

          if (page_QR && page_QR[numDoc]) {
            page_QR[numDoc] = {
              x: page_QR[numDoc].x * zoomRatio,
              y: page_QR[numDoc].y * zoomRatio,
            };
          }
        }

        // page[pageNumber] = {
        //   x: page[pageNumber].x * zoomRatio,
        //   y: page[pageNumber].y * zoomRatio,
        // };
        boxState.x_seal = boxState.x_seal * zoomRatio;
        boxState.y_seal = boxState.y_seal * zoomRatio;
        setselectedSealInfo({
          ...selectedSealInfo,
          page,
        });

        if (page_QR && page_QR[pageNumber]) {
          // page_QR[pageNumber] = {
          //   x: page_QR[pageNumber].x * zoomRatio,
          //   y: page_QR[pageNumber].y * zoomRatio,
          // };
          boxState.x_QR = boxState.x_QR * zoomRatio;
          boxState.y_QR = boxState.y_QR * zoomRatio;
          // alert('y_QR: decZoom ' + boxState.y_QR)
          setselectedQRInfo({
            ...selectedQRInfo,
            page: page_QR,
          });
        } else {
          boxState.x_QR = boxState.x_QR * zoomRatio;
          boxState.y_QR = boxState.y_QR * zoomRatio;
        }
      }
      setZoom(zoomLevel - 0.5);
    }
  };

  useEffect(() => {
    console.log('box width $$$$$$$$$$$$$: ', boxState.resizableBoxWidth);
    console.log('box height $$$$$$$$$$$$$: ', boxState.resizableBoxHeight);
    console.log('image width $$$$$$$$$$$$$: ', boxState.sealPactImageWidth);
    console.log('image height $$$$$$$$$$$$$: ', boxState.sealPactImageHeight);
    console.log(
      'x $$$$$$$$$$$$$: ',
      selectedSealInfo.page ? selectedSealInfo.page[pageNumber].x : '',
    );
    console.log(
      'y $$$$$$$$$$$$$: ',
      selectedSealInfo.page ? selectedSealInfo.page[pageNumber].y : '',
    );
  }, [boxState.sealPactImageWidth, boxState.sealPactImageHeight]);

  const defaultZoom = () => {
    const oldZoom = zoomLevel;
    const newZoom = defaultZoomWidth / pdfRatios.width;
    let page = selectedSealInfo.page ? selectedSealInfo.page : {};
    let page_QR = selectedQRInfo.page ? selectedQRInfo.page : {};
    const lastZoom = oldZoom;
    const cr_zoom = newZoom;
    const zoomRatio = cr_zoom / lastZoom;

    if (page && page[pageNumber]) {
      for (let numDoc = 1; numDoc <= numPages; numDoc++) {
        if (page && page[numDoc]) {
          page[numDoc] = {
            x: page[numDoc].x * zoomRatio,
            y: page[numDoc].y * zoomRatio,
          };
        }

        if (page_QR && page_QR[numDoc]) {
          page_QR[numDoc] = {
            x: page_QR[numDoc].x * zoomRatio,
            y: page_QR[numDoc].y * zoomRatio,
          };
        }
      }

      boxState.zoomRatio = zoomRatio;
      // page[pageNumber] = {
      //   x: page[pageNumber].x * zoomRatio,
      //   y: page[pageNumber].y * zoomRatio,
      // };
      boxState.x_seal = boxState.x_seal * zoomRatio;
      boxState.y_seal = boxState.y_seal * zoomRatio;
      setselectedSealInfo({
        ...selectedSealInfo,
        page,
      });

      if (page_QR && page_QR[pageNumber]) {
        // page_QR[pageNumber] = {
        //   x: page_QR[pageNumber].x * zoomRatio,
        //   y: page_QR[pageNumber].y * zoomRatio,
        // };
        boxState.x_QR = boxState.x_QR * zoomRatio;
        boxState.y_QR = boxState.y_QR * zoomRatio;
        setselectedQRInfo({
          ...selectedQRInfo,
          page: page_QR,
        });
      } else {
        boxState.x_QR = boxState.x_QR * zoomRatio;
        boxState.y_QR = boxState.y_QR * zoomRatio;
      }

      setZoom(newZoom);
    }
  };

  const getDocumentRatios = (pdf) => {
    setRatios({ height: pdf.originalHeight, width: pdf.originalWidth });
  };
  const onImgClick = () => {
    if (isSealConfirmed || isSealInvalidated) {
      setshowDetailModal(!showDetailModal);
    } else setdrawerVisible(!drawerVisible);
  };
  const imgClickDecider = (e, event) => {
    boxState.boxXCoordinate = event.x;
    boxState.boxYCoordinate = event.y;

    console.log('event imgClick =====> ', event);
    setisDragging(false);
    if (afterDragged) {
      setStopped(true);
      if (!isDragging && stopped && !isSealInvalidated) {
        onImgClick();
      }
    } else if (!afterDragged) {
      onImgClick();
    }
    setafterDragged(false);
    let page = selectedSealInfo.page ? selectedSealInfo.page : {};
    page[pageNumber] = {
      x: event.x,
      y: event.y,
    };
    setselectedSealInfo({
      ...selectedSealInfo,
      page,
    });

    documentInformation.selectedSealInfo = {
      ...selectedSealInfo,
      page,
    };
  };

  const imgClickDecider_QR = (e, event) => {
    // boxState.boxXCoordinate = event.x;
    // boxState.boxYCoordinate = event.y;

    console.log('event imgClick =====> ', event);
    setisDragging_QR(false);
    if (afterDragged_QR) {
      setStopped_QR(true);
      // if (!isDragging_QR && stopped_QR && !isSealInvalidated) {
      //   onImgClick();
      // }
    }
    // else if (!afterDragged_QR) {
    //   onImgClick();
    // }
    setafterDragged_QR(false);
    let page = selectedQRInfo.page ? selectedQRInfo.page : {};
    page[pageNumber] = {
      x: event.x,
      y: event.y,
    };
    setselectedQRInfo({
      ...selectedQRInfo,
      page,
    });

    documentInformation.selectedQRInfo = {
      ...selectedQRInfo,
      page,
    };
  };

  const getPagePosition = {
    x:
      selectedSealInfo &&
      selectedSealInfo.page &&
      selectedSealInfo.page[pageNumber]
        ? selectedSealInfo.page[pageNumber].x
        : 60 + panX * -1,
    y:
      selectedSealInfo &&
      selectedSealInfo.page &&
      selectedSealInfo.page[pageNumber]
        ? selectedSealInfo.page[pageNumber].y
        : 30 + panY * -1,
  };
  // useEffect(getPagePosition,[selectedSealInfo]);

  const getPagePosition_QR = {
    x:
      selectedQRInfo && selectedQRInfo.page && selectedQRInfo.page[pageNumber]
        ? selectedQRInfo.page[pageNumber].x
        : 60 + panX * -1,
    y:
      selectedQRInfo && selectedQRInfo.page && selectedQRInfo.page[pageNumber]
        ? selectedQRInfo.page[pageNumber].y
        : 180 + panY * -1,
  };

  useEffect(() => {
    if (!sealFirstTime) {
      if (selectedSealInfo) {
        if (selectedSealInfo.page) {
          // alert(zoomLevel)
          boxState.x_seal = selectedSealInfo.page[pageNumber].x;
          boxState.y_seal = selectedSealInfo.page[pageNumber].y;
          setSealFirstTime(true);
          // alert('y_QR: seal ' + boxState.y_QR)
          // alert('y_seal: ' + boxState.y_seal)
        }
      }
    }
  }, [selectedSealInfo]);

  // useEffect(() => {
  //   if (selectedSealInfo.page) {
  //     if (selectedSealInfo.page[pageNumber])
  //       alert(selectedSealInfo.page[pageNumber].x)
  //       alert(selectedSealInfo.page[pageNumber].y)
  //   }
  // }, [selectedSealInfo])

  useEffect(() => {
    if (!qrFirstTime) {
      if (selectedQRInfo) {
        if (selectedQRInfo.page) {
          if (
            selectedQRInfo.page[pageNumber] &&
            selectedQRInfo.page[pageNumber].x &&
            selectedQRInfo.page[pageNumber].y
          ) {
            boxState.x_QR = selectedQRInfo.page[pageNumber].x;
            boxState.y_QR = selectedQRInfo.page[pageNumber].y;
            setQrFirstTime(true);
          }
          // alert('y_QR: !firstTime ' + boxState.y_QR)
        }
      }
    }
    console.log('selectedQRInfo use', selectedQRInfo);
  }, [selectedQRInfo]);

  const getPosition = {
    x: invalidated
      ? invalidated
      : backToDoc
      ? backToDoc // sealPactDownloadUrl ||
      : documentInformation &&
        documentInformation.status === 'invalidated' &&
        documentInformation.selectedSealInfo
      ? documentInformation.selectedSealInfo.x
      : 60 + panX * -1,
    y: invalidated
      ? invalidated
      : backToDoc
      ? backToDoc // sealPactDownloadUrl ||
      : documentInformation &&
        documentInformation.status === 'invalidated' &&
        documentInformation.selectedSealInfo
      ? documentInformation.selectedSealInfo.y
      : 30 + panY * -1,
  };

  const getPositionQR = {
    x: invalidated
      ? invalidated
      : backToDoc
      ? backToDoc // sealPactDownloadUrl ||
      : documentInformation &&
        documentInformation.status === 'invalidated' &&
        documentInformation.selectedQRInfo
      ? documentInformation.selectedQRInfo.x
        ? documentInformation.selectedQRInfo.x
        : 60 + panX * -1
      : 60 + panX * -1,
    y: invalidated
      ? invalidated
      : backToDoc
      ? backToDoc // sealPactDownloadUrl ||
      : documentInformation &&
        documentInformation.status === 'invalidated' &&
        documentInformation.selectedQRInfo
      ? documentInformation.selectedQRInfo.y
        ? documentInformation.selectedQRInfo.y
        : boxState.y_QR + panY * -1
      : boxState.y_QR + panY * -1,
  };

  let imgStyle = isSealConfirmed
    ? ButtonCheck
    : isSealInvalidated
    ? DangerButton
    : null;
  let zoomedDrag = afterDragged;
  console.log('selectedSealInfo', selectedSealInfo);
  console.log('props in name ======================>', documentDetails);
  console.log('sealPactDownloadUrl ============> ', sealPactDownloadUrl);

  // const getSealBuffer = (sealdata) => {
  //   axios
  //     .get(sealdata, { responseType: 'blob' }) // replace this with 'arraybuffer' and response.data will be a buffer
  //     .then((response) => {
  //       console.log('response == => ', response);
  //       return response.data;
  //     })
  //     .catch((error) => {});
  // };

  const getTime = () => {
    const time = moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
    return time.split(' ');
  };

  useEffect(() => {
    eventLogger({}, getPosition);
    // eventLogger_QR({}, getPagePosition_QR);
    defaultZoom();
    appEasyState.customPages = [];
  }, []);

  return (
    <Fragment>
      {loading && (
        <SealProgressBar
          message={
            deleteConfirmation
              ? 'Your document is being deleted.'
              : 'Your document is being digitally signed/sealed.'
          }
        />
      )}
      <div className={s.goBack}>
        <Button
          variant="text"
          onClick={() =>
            isMyDocuments ? push('/documents') : push('/dashboard')
          }
        >
          <i className={s.left} />
          {isMyDocuments ? 'My Documents' : 'My Dashboard'}
        </Button>
      </div>
      <Header title={documentInformation.name} />
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={400}
        defaultPositionY={400}
        ref={imageDocRef}
        zoomingEnabled={true}
        onPanning={handlePanning}
        wheel={{ wheelEnabled: false }}
        options={{ limitToBounds: enableMove }}
        limitToWrapper={enableMove}
        pan={{
          disabled: true,
          lockAxisX: zoomedDrag,
          lockAxisY: zoomedDrag,
        }}

        // limitToWrapper={true}
      >
        {({
          zoomIn,
          zoomOut,
          resetTransform,
          positionX,
          positionY,
          ...rest
        }) => (
          <React.Fragment>
            <Navbar
              className={s.navBar}
              sticky="top"
              expand="lg"
              variant="light"
              bg="light"
            >
              <ImageControls
                disableControl={disableControl}
                nextPage={nextPage}
                previousPage={previousPage}
                zoomIn={incZoom}
                zoomOut={decZoom}
                resetTransform={async () => {
                  eventLogger({}, getPosition);
                  // eventLogger_QR({}, getPagePosition_QR);
                  resetTransform();
                  defaultZoom();
                }}
                enableZoom={enableZoomHandler}
                enableMove={enableMoveHandler}
                moveEnabled={enableMove}
                handleSealPactDrawer={handleSealPactDrawer}
                isSealSelected={isSealSelected}
                isSealConfirmed={isSealConfirmed}
                isSealInvalidated={isSealInvalidated}
                onCancel={handleSealCancel}
                afterSealpacted={onImgClick}
                afterInvalidated
                rotateRight={rotateRight}
                rotateOneRight={rotateOneRight}
                rotateLeft={rotateLeft}
                rotateOneLeft={rotateOneLeft}
                handleDeleteDocument={handleDeleteDocument}
                // deleteSealPactedDocument={handleDeleteDocument}
                // deleteInvalidatedDocument={handleDeleteDocument}
                documentDetails={documentDetails}
                numPages={numPages}
                fileType={fileType}
                documentInformation={documentInformation}
                confirmationValues={confirmationValues}
                shared={shared}
                viewer={viewer}
                pageNumber={pageNumber}
                sealPactDownloadUrl={sealPactDownloadUrl}
                handleViewed={handleViewed}
                handleInvalidate={handleInvalidate}
                toggleReSealPactModel={toggleReSealPactModel}
                documentStatus={documentStatus}
                {...props}
              />
            </Navbar>
            <div>
              <TransformComponent wrapperclassName={s.grab}>
                {fileType === 'application/pdf' ? (
                  <Document
                    className={s.page}
                    file={
                      sealPactDownloadUrl
                        ? sealPactDownloadUrl
                        : documentDetails
                    }
                    style={{ cursor: enableMove ? 'grab' : 'default' }}
                  >
                    <Page
                      pageNumber={pageNumber}
                      rotate={
                        boxState.pagesRotation &&
                        boxState.pagesRotation[pageNumber - 1]
                      }
                      scale={zoomLevel}
                      onLoadSuccess={getDocumentRatios}
                    />
                  </Document>
                ) : (
                  // <Document
                  //   file={
                  //     sealPactDownloadUrl
                  //       ? sealPactDownloadUrl
                  //       : documentDetails
                  //   }
                  //   onLoadSuccess={getDocumentRatios}
                  //   className={s.pdfDoc}
                  // >
                  //   <Page
                  //     // style={styles.page}
                  //     // width={800}
                  //     scale={zoomLevel}
                  //     pageNumber={pageNumber}
                  //     className={s.page}
                  //   />
                  // </Document>
                  <Image
                    src={
                      sealPactDownloadUrl
                        ? sealPactDownloadUrl
                        : documentDetails
                    }
                    className={s.docImg}
                    alt="doc-image"
                    ref={imageDocRef}
                  />
                )}
                {!sealPactDownloadUrl &&
                pageNumber > sealPages.pageFrom - 1 &&
                pageNumber < sealPages.pageTo + 1 ? (
                  <>
                    {/*{isSealSelected && (*/}
                    {/*  <div style={{ position: 'relative' }}>*/}
                    {/*    <div*/}
                    {/*      style={{*/}
                    {/*        width: boxState.sealPactWidth,*/}
                    {/*        height: boxState.sealPactHeight,*/}
                    {/*        border: '1px dashed grey',*/}
                    {/*        borderRadius: '50%',*/}
                    {/*        position: 'absolute',*/}
                    {/*        top: '20px',*/}
                    {/*        right: '20px'*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*)}*/}
                    <div className={s.imgDiv}>
                      {isSealSelected && findInPagesRange(pageNumber) && (
                        <Rnd
                          default={{
                            x: getPosition.x,
                            y: getPosition.y,
                          }}
                          size={{
                            width: boxState.resizableBoxWidth * zoomLevel,
                            height: boxState.resizableBoxHeight * zoomLevel,
                          }}
                          minWidth={boxState.resizableBoxMinWidth * zoomLevel}
                          minHeight={boxState.resizableBoxMinHeight * zoomLevel}
                          maxWidth={boxState.resizableBoxMaxWidth * zoomLevel}
                          maxHeight={boxState.resizableBoxMaxHeight * zoomLevel}
                          onDrag={eventLogger}
                          onDragStop={imgClickDecider}
                          bounds="parent"
                          position={getPagePosition}
                          onResizeStop={(
                            e,
                            direction,
                            ref,
                            delta,
                            position,
                          ) => {
                            let parsedWidth =
                              parseInt(ref.offsetWidth) / zoomLevel;
                            let parsedHeight =
                              parseInt(ref.offsetHeight) / zoomLevel;
                            let deltaX =
                              parsedWidth - boxState.resizableBoxWidth;
                            let deltaY =
                              parsedHeight - boxState.resizableBoxHeight;
                            console.log(
                              'parsedWidth $$$$$$$$$$$$$ :',
                              parsedWidth,
                            );
                            console.log(
                              'parsedHeight $$$$$$$$$$$$$ :',
                              parsedHeight,
                            );
                            console.log(
                              'parsedWidth - parsedHeight $$$$$$$$$$$$$ :',
                              parsedWidth - parsedHeight,
                            );

                            if (selectedSealInfo.type === 'signature') {
                              if (deltaX !== 0) {
                                if (deltaY === 0) {
                                  if (deltaX > 0) {
                                    if (
                                      parsedHeight >
                                      1.75 * boxState.resizableBoxMinHeight
                                    ) {
                                      imgClickDecider(e, position);
                                    } else if (
                                      parsedHeight ===
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 175
                                    ) {
                                      imgClickDecider(e, position);
                                    }
                                  } else {
                                    if (
                                      parsedHeight >
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 195
                                    ) {
                                      console.log(
                                        'parsedWidth **:2',
                                        parsedWidth,
                                      );
                                      console.log(
                                        'parsedWidth **:2H',
                                        parsedHeight,
                                      );
                                      // alert('parsedWidth **')
                                      imgClickDecider(e, position);
                                    } else if (
                                      parsedHeight ===
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 175
                                    ) {
                                      imgClickDecider(e, position);
                                    }
                                    // else if (parsedHeight === boxState.resizableBoxMinHeight && parsedWidth === boxState.resizableBoxMinWidth) {
                                    //   boxWidthCalculations(selectedSealInfo.type, e, position, parsedWidth, deltaX);
                                    // }
                                  }
                                } else {
                                  if (deltaX < 0) {
                                    if (
                                      parsedHeight >
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 195
                                    ) {
                                      console.log(
                                        'parsedWidth **:w',
                                        parsedWidth,
                                      );
                                      console.log(
                                        'parsedWidth **:wH',
                                        parsedHeight,
                                      );
                                      imgClickDecider(e, position);
                                    } else if (
                                      parsedHeight ===
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 175
                                    )
                                      imgClickDecider(e, position);
                                    // else if (parsedHeight === boxState.resizableBoxMinHeight && parsedWidth === boxState.resizableBoxMinWidth) {
                                    //   boxWidthCalculations(selectedSealInfo.type, e, position, parsedWidth, deltaX);
                                    // }
                                  } else {
                                    imgClickDecider(e, position);
                                  }
                                }
                              }

                              if (deltaY !== 0) {
                                if (deltaX === 0) {
                                  if (deltaY > 0) {
                                    if (
                                      parsedWidth >=
                                      1.5 * boxState.resizableBoxMinWidth
                                    ) {
                                      imgClickDecider(e, position);
                                    }
                                  } else {
                                    if (
                                      parsedHeight >=
                                      1.3 * boxState.resizableBoxMinHeight
                                    ) {
                                      imgClickDecider(e, position);
                                    }
                                  }
                                } else {
                                  if (deltaX < 0) {
                                    if (
                                      parsedHeight >
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 185
                                    ) {
                                      console.log(
                                        'parsedWidth **:1',
                                        parsedWidth,
                                      );
                                      console.log(
                                        'parsedWidth **:1H',
                                        parsedHeight,
                                      );
                                      imgClickDecider(e, position);
                                    } else if (
                                      parsedHeight ===
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 175
                                    )
                                      imgClickDecider(e, position);
                                    // else if (parsedHeight === boxState.resizableBoxMinHeight && parsedWidth === boxState.resizableBoxMinWidth) {
                                    //   boxHeightCalculations(selectedSealInfo.type, e, position, parsedHeight, deltaY);
                                    // }
                                  } else {
                                    imgClickDecider(e, position);
                                  }
                                }
                              }
                              // if ((parsedWidth >=150 && parsedWidth <= 170) &&
                              //   (parsedHeight >= 50 && parsedHeight <= 160) &&
                              //   (parsedWidth - parsedHeight <= 60))
                              // {}
                              // else {
                              //
                              // }
                            } else {
                              if (parsedHeight > parsedWidth) {
                                if (deltaX !== 0) {
                                  if (deltaX > 0 && deltaY === 0) {
                                    if (
                                      boxState.resizableBoxHeight <=
                                      boxState.resizableBoxMinHeight + 10
                                    ) {
                                      if (
                                        parsedWidth <=
                                        1.5 * boxState.resizableBoxMinWidth
                                      ) {
                                        imgClickDecider(e, position);
                                      }
                                    } else {
                                      if (
                                        parsedWidth <=
                                        2 * boxState.resizableBoxMinWidth
                                      ) {
                                        imgClickDecider(e, position);
                                      }
                                    }
                                  } else if (deltaX < 0 && deltaY === 0) {
                                    if (
                                      parsedWidth >=
                                        boxState.resizableBoxHeight / 1.25 &&
                                      parsedHeight !==
                                        boxState.resizableBoxMinHeight
                                    ) {
                                      imgClickDecider(e, position);
                                    }
                                  } else {
                                    if (parsedWidth / parsedHeight >= 0.7) {
                                      imgClickDecider(e, position);
                                    }
                                  }
                                }

                                if (deltaY !== 0) {
                                  if (deltaY > 0 && deltaX === 0) {
                                    if (
                                      parsedHeight <=
                                      1.5 * boxState.resizableBoxWidth
                                    ) {
                                      imgClickDecider(e, position);
                                    }
                                  } else {
                                    if (parsedWidth / parsedHeight >= 0.7) {
                                      imgClickDecider(e, position);
                                    }
                                  }
                                }
                              }
                            }

                            if (
                              watermarkRef &&
                              watermarkRef.current &&
                              watermarkRef.current.offsetWidth
                            ) {
                              console.log(
                                'watermarkRef',
                                watermarkRef.current.offsetWidth,
                              );
                              let waterMarkTextWidth =
                                parseInt(watermarkRef.current.offsetWidth) /
                                zoomLevel;

                              boxState.watermarkLeft =
                                (boxState.sealPactWidth - waterMarkTextWidth) /
                                2;
                            }
                          }}
                          onResize={(e, direction, ref, delta, position) => {
                            let parsedWidth =
                              parseInt(ref.offsetWidth) / zoomLevel;
                            console.log(
                              'parsedWidth $$$$$$$$$$$$$ :',
                              parsedWidth,
                            );
                            let deltaX =
                              parsedWidth - boxState.resizableBoxWidth;
                            console.log('deltaX: ', deltaX);

                            let parsedHeight =
                              parseInt(ref.offsetHeight) / zoomLevel;
                            console.log(
                              'parsedHeight $$$$$$$$$$$$$ :',
                              parsedHeight,
                            );
                            let deltaY =
                              parsedHeight - boxState.resizableBoxHeight;
                            console.log('deltaY: ', deltaY);

                            if (selectedSealInfo.type !== 'signature') {
                              if (parsedHeight > parsedWidth) {
                                if (deltaX !== 0) {
                                  if (deltaX > 0 && deltaY === 0) {
                                    if (
                                      boxState.resizableBoxHeight <=
                                      boxState.resizableBoxMinHeight + 10
                                    ) {
                                      if (
                                        parsedWidth <=
                                        1.5 * boxState.resizableBoxMinWidth
                                      ) {
                                        boxWidthCalculations(
                                          selectedSealInfo.type,
                                          e,
                                          position,
                                          parsedWidth,
                                          deltaX,
                                        );
                                      }
                                    } else {
                                      if (
                                        parsedWidth <=
                                        2 * boxState.resizableBoxMinWidth
                                      ) {
                                        boxWidthCalculations(
                                          selectedSealInfo.type,
                                          e,
                                          position,
                                          parsedWidth,
                                          deltaX,
                                        );
                                      }
                                    }
                                  } else if (deltaX < 0 && deltaY === 0) {
                                    if (
                                      parsedWidth >=
                                        boxState.resizableBoxHeight / 1.25 &&
                                      parsedHeight !==
                                        boxState.resizableBoxMinHeight
                                    ) {
                                      boxWidthCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedWidth,
                                        deltaX,
                                      );
                                    }
                                  } else {
                                    if (parsedWidth / parsedHeight >= 0.7) {
                                      boxWidthCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedWidth,
                                        deltaX,
                                      );
                                    }
                                  }
                                }

                                if (deltaY !== 0) {
                                  if (deltaY > 0 && deltaX === 0) {
                                    if (
                                      parsedHeight <=
                                      1.5 * boxState.resizableBoxWidth
                                    ) {
                                      boxHeightCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedHeight,
                                        deltaY,
                                      );
                                    }
                                  } else {
                                    if (parsedWidth / parsedHeight >= 0.7) {
                                      boxHeightCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedHeight,
                                        deltaY,
                                      );
                                    }
                                  }
                                }
                              }
                            } else {
                              if (deltaX !== 0) {
                                if (deltaY === 0) {
                                  if (deltaX > 0) {
                                    if (
                                      parsedHeight >
                                      1.75 * boxState.resizableBoxMinHeight
                                    ) {
                                      boxWidthCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedWidth,
                                        deltaX,
                                      );
                                    } else if (
                                      parsedHeight ===
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 175
                                    ) {
                                      boxWidthCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedWidth,
                                        deltaX,
                                      );
                                    }
                                  } else {
                                    if (
                                      parsedHeight >
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 195
                                    ) {
                                      console.log(
                                        'parsedWidth **:2',
                                        parsedWidth,
                                      );
                                      console.log(
                                        'parsedWidth **:2H',
                                        parsedHeight,
                                      );
                                      // alert('parsedWidth **')
                                      boxWidthCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedWidth,
                                        deltaX,
                                      );
                                    } else if (
                                      parsedHeight ===
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 175
                                    ) {
                                      boxWidthCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedWidth,
                                        deltaX,
                                      );
                                    }
                                    // else if (parsedHeight === boxState.resizableBoxMinHeight && parsedWidth === boxState.resizableBoxMinWidth) {
                                    //   boxWidthCalculations(selectedSealInfo.type, e, position, parsedWidth, deltaX);
                                    // }
                                  }
                                } else {
                                  if (deltaX < 0) {
                                    if (
                                      parsedHeight >
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 195
                                    ) {
                                      console.log(
                                        'parsedWidth **:w',
                                        parsedWidth,
                                      );
                                      console.log(
                                        'parsedWidth **:wH',
                                        parsedHeight,
                                      );
                                      boxWidthCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedWidth,
                                        deltaX,
                                      );
                                    } else if (
                                      parsedHeight ===
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 175
                                    )
                                      boxWidthCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedWidth,
                                        deltaX,
                                      );
                                    // else if (parsedHeight === boxState.resizableBoxMinHeight && parsedWidth === boxState.resizableBoxMinWidth) {
                                    //   boxWidthCalculations(selectedSealInfo.type, e, position, parsedWidth, deltaX);
                                    // }
                                  } else {
                                    boxWidthCalculations(
                                      selectedSealInfo.type,
                                      e,
                                      position,
                                      parsedWidth,
                                      deltaX,
                                    );
                                  }
                                }
                              }

                              if (deltaY !== 0) {
                                if (deltaX === 0) {
                                  if (deltaY > 0) {
                                    if (
                                      parsedWidth >=
                                      1.5 * boxState.resizableBoxMinWidth
                                    ) {
                                      boxHeightCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedHeight,
                                        deltaY,
                                      );
                                    }
                                  } else {
                                    if (
                                      parsedHeight >=
                                      1.3 * boxState.resizableBoxMinHeight
                                    ) {
                                      boxHeightCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedHeight,
                                        deltaY,
                                      );
                                    }
                                  }
                                } else {
                                  if (deltaX < 0) {
                                    if (
                                      parsedHeight >
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 185
                                    ) {
                                      console.log(
                                        'parsedWidth **:1',
                                        parsedWidth,
                                      );
                                      console.log(
                                        'parsedWidth **:1H',
                                        parsedHeight,
                                      );
                                      boxHeightCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedHeight,
                                        deltaY,
                                      );
                                    } else if (
                                      parsedHeight ===
                                        boxState.resizableBoxMinHeight &&
                                      parsedWidth >= 175
                                    )
                                      boxHeightCalculations(
                                        selectedSealInfo.type,
                                        e,
                                        position,
                                        parsedHeight,
                                        deltaY,
                                      );
                                    // else if (parsedHeight === boxState.resizableBoxMinHeight && parsedWidth === boxState.resizableBoxMinWidth) {
                                    //   boxHeightCalculations(selectedSealInfo.type, e, position, parsedHeight, deltaY);
                                    // }
                                  } else {
                                    boxHeightCalculations(
                                      selectedSealInfo.type,
                                      e,
                                      position,
                                      parsedHeight,
                                      deltaY,
                                    );
                                  }
                                }
                              }

                              // if ((parsedWidth >=150 && parsedWidth <= 170) &&
                              //   (parsedHeight >= 50 && parsedHeight <= 160) &&
                              //   (parsedWidth - parsedHeight <= 60))
                              // {}
                              // else {
                              //
                              // }
                            }
                          }}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: `${2 * zoomLevel}px dashed grey`,
                            borderSpacing: 2 * zoomLevel,
                            padding: 0,
                            zIndex: 9999999,
                          }}
                        >
                          {isSealSelected && (
                            <div
                              className={
                                isSealConfirmed || isSealInvalidated
                                  ? s.confirmedImg
                                  : selectedSealInfo.type === 'signature'
                                  ? s.unConfirmedSignature
                                  : s.unConfirmedImg
                              }
                              onClick={
                                isSealConfirmed || isSealInvalidated
                                  ? onImgClick
                                  : undefined
                              }
                              style={{
                                width: boxState.sealPactWidth * zoomLevel,
                                height: 'auto',
                                maxWidth: boxState.sealPactWidth * zoomLevel,
                              }}
                              id={'sealimgref'}
                              ref={sealImgRef}
                            >
                              <div
                                className={s.flash}
                                style={{ position: 'relative' }}
                              >
                                {selectedSealInfo.type === 'stamp' &&
                                  appEasyState.enableVerificationWatermark && (
                                    <span
                                      className={s.watermark}
                                      style={{
                                        fontSize: `${
                                          parseInt(
                                            0.07 * boxState.sealPactWidth,
                                          ) * zoomLevel
                                        }px`,
                                        bottom: `${
                                          0.4 *
                                          boxState.sealPactHeight *
                                          zoomLevel
                                        }px`,
                                        left: `${
                                          boxState.watermarkLeft * zoomLevel
                                        }px`,
                                      }}
                                      id={'watermarkRef'}
                                      ref={watermarkRef}
                                    >
                                      See SealPact Verification
                                    </span>
                                  )}
                                <Image
                                  alt="seal-img"
                                  className={
                                    selectedSealInfo.type === 'signature'
                                      ? s.selectedSignature
                                      : s.selectedStamp
                                  }
                                  height={
                                    boxState.sealPactImageHeight * zoomLevel
                                  }
                                  width={
                                    boxState.sealPactImageWidth * zoomLevel
                                  }
                                  style={{
                                    objectFit:
                                      selectedSealInfo.type !== 'signature'
                                        ? 'contain'
                                        : 'fill',
                                    width:
                                      boxState.sealPactImageWidth * zoomLevel,
                                    height:
                                      boxState.sealPactImageHeight * zoomLevel,
                                    minWidth:
                                      boxState.sealPactImageMinWidth *
                                      zoomLevel,
                                    minHeight:
                                      boxState.sealPactImageMinHeight *
                                      zoomLevel,
                                    maxWidth:
                                      boxState.sealPactImageWidth * zoomLevel,
                                    maxHeight:
                                      boxState.sealPactImageHeight * zoomLevel,
                                  }}
                                  src={selectedSealInfo.data}
                                />
                              </div>
                              {selectedSealInfo.type === 'signature' && (
                                <div
                                  className={s.sealFlex}
                                  style={
                                    {
                                      // marginLeft:
                                      //   selectedSealInfo.xtype === 'signature'
                                      //     ? 0
                                      //     : '60%',
                                    }
                                  }
                                >
                                  <div
                                    className={s.sealpactFlex}
                                    style={{
                                      display: 'inline-flex',
                                      justifyContent: 'space-around',
                                      alignItems: 'center',
                                      marginTop:
                                        selectedSealInfo.type === 'signature'
                                          ? -10
                                          : 0,

                                      // margin: '0 auto',
                                      // textAlign: 'center',
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginTop:
                                          selectedSealInfo.type === 'signature'
                                            ? boxState.sealPactHeight <= 60
                                              ? 0
                                              : '-5px'
                                            : '-5px',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Image
                                          width={
                                            boxState.sealPactLogoImageWidth *
                                            zoomLevel
                                          }
                                          height={
                                            boxState.sealPactLogoImageHeight *
                                            zoomLevel
                                          }
                                          style={{
                                            width:
                                              boxState.sealPactLogoImageWidth *
                                              zoomLevel,
                                            height:
                                              boxState.sealPactLogoImageHeight *
                                              zoomLevel,
                                            // minWidth: 8,
                                            // minHeight: 8,
                                            // maxWidth: 300,
                                            // maxHeight: 300
                                          }}
                                          src={Logo}
                                          // className={s.sealRadius}
                                          alt="logo-sealpact"
                                        />
                                        <span
                                          style={{
                                            fontSize: `${
                                              boxState.sealPactTextLarge *
                                              zoomLevel
                                            }px`,
                                            marginLeft: '3px',
                                          }}
                                        >
                                          SealPact
                                        </span>
                                      </div>

                                      <div
                                        style={{
                                          fontSize: `${
                                            boxState.sealPactTextSmall *
                                            zoomLevel
                                          }px`,
                                          letterSpacing: -0.5,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {getTime()[0]} {getTime()[1]} EST
                                      </div>

                                      <div
                                        style={{
                                          fontSize: `${
                                            boxState.sealPactTextSmall *
                                            zoomLevel
                                          }px`,
                                          fontWeight: 'bold',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        Click for Verification
                                      </div>
                                    </div>
                                    {/* {selectedSealInfo.type !== 'signature' ? (
                                  <div className={s.qrcodeDiv}>
                                    <QRCode
                                      style={{
                                        height: '33%',
                                        width: '33%',
                                        marginLeft: '15%',
                                      }}
                                      value={
                                        window.location.origin +
                                        '/sealpactedDetails?' +
                                        documentInformation.id +
                                        '&' +
                                        props.user.uid
                                      }
                                      level={'L'}
                                      // size={20 * zoomLevel}
                                      renderAs={'canvas'}
                                      fgColor={'#000000'}
                                      id={'QRCode'}
                                      // imageSettings={{
                                      //   src: Logo,
                                      //   width: 25,
                                      //   height: 25,
                                      //   excavate: true,
                                      // }}
                                    />
                                  </div>
                                ) : null} */}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </Rnd>
                      )}
                      {selectedSealInfo.type !== 'signature' &&
                        isSealSelected &&
                        findInPagesRange(pageNumber) &&
                        !cancelQRDiv && (
                          <Rnd
                            default={{
                              x: getPositionQR.x,
                              y: getPositionQR.y,
                            }}
                            size={{
                              width: boxState.qrCodeBoxWidth * zoomLevel,
                              height: boxState.qrCodeBoxHeight * zoomLevel,
                            }}
                            onDrag={eventLogger_QR}
                            onDragStop={imgClickDecider_QR}
                            bounds="parent"
                            position={getPagePosition_QR}
                            enableResizing={false}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: `${2 * zoomLevel}px dashed grey`,
                              borderSpacing: 2 * zoomLevel,
                              padding: 0,
                            }}
                          >
                            <div
                              className={
                                isSealConfirmed || isSealInvalidated
                                  ? s.confirmedImg
                                  : s.unConfirmedImg
                              }
                              style={{
                                width: boxState.qrCodeBoxWidth * zoomLevel,
                                height: boxState.qrCodeBoxHeight * zoomLevel,
                              }}
                            >
                              <div className={s.qrFlex}>
                                <div
                                  className={s.qrCodeDeleteIconDiv}
                                  onClick={() => setCancelQRDiv(true)}
                                  style={{
                                    width: `${15 * zoomLevel}px`,
                                    height: `${15 * zoomLevel}px`,
                                    cursor: 'pointer',
                                  }}
                                >
                                  <Image
                                    src={ButtonClose}
                                    alt="close"
                                    style={{
                                      width: `${15 * zoomLevel}px`,
                                      height: `${15 * zoomLevel}px`,
                                    }}
                                  />
                                </div>
                                <QRCode
                                  style={{
                                    // height: '97%',
                                    // width: '97%',
                                    width: boxState.qrCodeWidth * zoomLevel,
                                    height: boxState.qrCodeHeight * zoomLevel,
                                    // marginLeft: `${0 * zoomLevel}px`,
                                  }}
                                  value={
                                    window.location.origin +
                                    '/sealpactedDetails?' +
                                    documentInformation.id +
                                    '&' +
                                    props.user.uid
                                  }
                                  level={'L'}
                                  // size={20 * zoomLevel}
                                  renderAs={'canvas'}
                                  fgColor={'#000000'}
                                  id={'QRCode'}
                                  imageSettings={{
                                    src: Logo,
                                    width: 20,
                                    height: 20,
                                    excavate: true,
                                  }}
                                />
                                <div
                                  className={s.sealpactFlex}
                                  style={{
                                    display: 'inline-flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    marginLeft: `${5 * zoomLevel}px`,
                                    // marginTop:
                                    //   selectedSealInfo.type === 'signature'
                                    //     ? -10
                                    //     : 0,
                                  }}
                                >
                                  <div
                                    style={
                                      {
                                        // marginTop: selectedSealInfo.type === 'signature'
                                        //   ? boxState.sealPactHeight <= 60
                                        //     ? 0
                                        //     : '-5px'
                                        //   : '-5px',
                                      }
                                    }
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        lineHeight: '1.4',
                                      }}
                                    >
                                      {/*<Image*/}
                                      {/*  width={boxState.sealPactLogoImageWidth * zoomLevel}*/}
                                      {/*  height={boxState.sealPactLogoImageHeight * zoomLevel}*/}
                                      {/*  style={{*/}
                                      {/*    width: boxState.sealPactLogoImageWidth * zoomLevel,*/}
                                      {/*    height: boxState.sealPactLogoImageHeight * zoomLevel,*/}
                                      {/*  }}*/}
                                      {/*  src={Logo}*/}
                                      {/*  alt="logo-sealpact"*/}
                                      {/*/>*/}
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: `${
                                            boxState.sealPactTextSmall *
                                            zoomLevel
                                          }px`,
                                          // marginLeft: '3px',
                                        }}
                                      >
                                        SealPact {getTime()[0]} {getTime()[1]}{' '}
                                        EST
                                      </span>
                                    </div>

                                    {/*<div*/}
                                    {/*  style={{*/}
                                    {/*    fontSize: `${boxState.sealPactTextSmall * zoomLevel}px`,*/}
                                    {/*    letterSpacing: -0.5,*/}
                                    {/*    display: 'flex',*/}
                                    {/*    justifyContent: 'center',*/}
                                    {/*    alignItems: 'center'*/}
                                    {/*  }}*/}
                                    {/*>*/}
                                    {/*  {getTime()[0]} {getTime()[1]} EST*/}
                                    {/*</div>*/}

                                    <div
                                      style={{
                                        fontSize: `${
                                          boxState.sealPactTextSmall * zoomLevel
                                        }px`,
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        lineHeight: '1.4',
                                      }}
                                    >
                                      Click seal or scan QR Code for
                                      verification; otherwise not valid.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Rnd>
                        )}
                    </div>
                  </>
                ) : null}
              </TransformComponent>
            </div>
          </React.Fragment>
        )}
      </TransformWrapper>
      {drawerVisible && (
        <SideDrawer
          drawerSlideOut={drawerSlideOut}
          getselectedSealInfo={getselectedSealInfo}
          handleDrawer={blackOverlayClick}
          allActiveAssets={allActiveAssets}
          activeSeals={activeSeals}
          activeSignatures={activeSignatures}
          activeStamps={activeStamps}
          sealPacterType={documentInformation.sealPacterType}
        />
      )}
      <SealPactDetails
        show={showDetailModal}
        hideModal={handleSealConfirmationModal}
        confirmationValues={confirmationValues}
        documentInformation={documentInformation}
        sealPactDownloadUrl={sealPactDownloadUrl}
        documentStatus={documentStatus}
        {...props}
      />
      <ConfirmSealModal
        showModal={showConfirmationPopup}
        hideModal={handleSubmitModal}
        toggleConfirmModal={toggleConfirmModal}
        isSealConfirmed={isSealConfirmedFunc}
      />
      <WarningPopupModal
        showModal={warnDefaultSeal}
        hideModal={handleSubmitWarningModal}
        continueSeal={continueSeal}
      />
      <SelectSealPage
        showModal={selectSealPages}
        hideModal={hideConfirmModal}
        confirmSealPages={confirmSealPages}
        documentInformation={documentInformation}
        selectedSealInfo={selectedSealInfo}
        getBasicTabValue={getBasicTabValue}
        setPageFromTo={setPageFromTo}
        setBasicTabPages={setBasicTabPages}
        sealPages={sealPages}
        setSelectedPagesAndSealPos={setSelectedPagesAndSealPos}
      />
      <SealConfirmationFlow
        show={showConfirmModal}
        getSealValidatonData={getSealValidatonData}
        hideModal={toggleConfirmModal}
        hidePopup={handleSubmitModal}
        documentInformation={documentInformation}
        confirmationValues={confirmationValues}
        getConfimationValues={getConfirmationValues}
        firebase={props.firebase}
        user={props.user}
        selectedSealInfo={selectedSealInfo}
        sealPages={sealPages}
      />
      <InvalidateSealModal
        handleInvalidate={handleInvalidate}
        show={invalidateConfirmaton}
        backToDoc={true}
        documentInformation={documentInformation}
        documentDetails={documentInformation.data}
        confirmationValues={confirmationValues}
        fileType={documentInformation.fileType}
        numPages={documentInformation.totalPages}
        invalidationReasonKey={invalidationReason}
        setDocumentStatus={setDocumentStatus}
        setConfirmationValues={setConfirmationValues}
        setLoading={setLoading}
        {...props}
      />
      <ReSealPactModal
        toggleReSealPactModel={toggleReSealPactModel}
        show={reSealPactConfirmaton}
        backToDoc={true}
        documentInformation={documentInformation}
        documentStatus={documentStatus}
        confirmationValues={confirmationValues}
        setDocumentStatus={setDocumentStatus}
        setLoading={setLoading}
        {...props}
      />

      <ReviewSealPact
        show={showReviewModal}
        hideModal={toggleReviewModal}
        hidePopup={handleSubmitModal}
        documentInformation={documentInformation}
        confirmationValues={{
          ...confirmationValues,
          pageFrom: sealPages.pageFrom,
          pageTo: sealPages.pageTo,
          range: sealPages.range,
        }}
        selectedSealInfo={selectedSealInfo}
        onSubmitCall={onSubmitCall}
        reviewCall={reviewCall}
      />
      <DynamicModal
        show={deleteConfirmation}
        title="SealPact - Confirmation"
        hideModal={() => setDeleteConfirmation(false)}
        size="lg"
      >
        <div className={s.modalContent}>
          <p>{`Are you sure you want to delete this document? Once a document has been deleted, there is no way to undo this action, and you will lose control over any dynamic components, including, but not limited to, the ability to manually invalidate/re-validate your SealPact on this particular document.`}</p>
        </div>
        <div className={s.modalBtnStyle}>
          <Button
            variant="secondary"
            onClick={() => setDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="text" onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </div>
      </DynamicModal>
    </Fragment>
  );
};
const condition = (user) => !!user;
export default withAuthorization(condition)(view(DocumentDetails));
