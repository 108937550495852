/* eslint-disable no-debugger */
/* eslint-disable indent */
import React, { Component } from 'react';
import FontPicker from 'font-picker-react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import s from './style.scss';
import style from '../style.scss';
import { Formik } from 'formik';
import { CREATENEWSIGNATUREVALIDATION } from '../../../../config/FormSchemas';
import { ImagePicker } from 'react-file-picker';
import { store } from 'react-notifications-component';
import Spinner from '../../../Layout/Spinner';
import Cropper from '../../../Cropper/Cropper';
import { getCroppedImg } from '../../../Cropper/cropImage';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import domtoimage from 'dom-to-image';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../../easyStateStore';

class SignatureForm extends Component {
  constructor(props) {
    super(props);
    this.signatureRef = React.createRef();
    this.state = {
      applyFont: false,
      activeFontFamily: 'Select a Font',
      signature: '',
      loading: false,
      signatureImage: '',
      imageUploaded: false,
      showCropper: false,
      showCropOption: false,
      croppedAreaPixels: null,
      rotation: 0,
    };
  }
  makeBase64Img = async () => {
    const { signature, activeFontFamily, imageUploaded } = this.state;
    this.setState({ loading: true });
    if (imageUploaded) {
      console.log('his.state.signatureImag ====> ', this.state.signatureImage);
      var img = new Image();
      img.src = this.state.signatureImage;
      await this.props.getSignatureData(
        this.state.signatureImage,
        activeFontFamily,
        signature,
      );
    }
    else {
      const node = document.getElementById("signText");
      console.log('node', node)
      await domtoimage
        .toPng(node, {})
        .then(async (dataUrl) => {
          console.log('dataUrl =====> ', dataUrl);
          await this.props.getSignatureData(dataUrl, activeFontFamily, signature);
        })
        .catch((error) => console.error('oops, something went wrong!', error));


      // domtoimage
      //   .toPng(this.signatureRef)
      //   .then((dataUrl) => {
      //     this.setState({
      //       signatureImage: dataUrl
      //     })
      //   })
      //   .catch((error) =>
      //     console.error(
      //       'oops, something went wrong while capturing signature !',
      //       error,
      //     ),
      //   );
    }
    this.props.handleModal();
  };
  handleModal = () => {
    this.props.handleModal();

    this.setState({ signature: '',  activeFontFamily: 'Select a Font', imageUploaded: false, });
  };

  handleInput = (e) => {
    this.setState({ signature: e.target.value, applyFont: false });
  };

  setFont = (font) => {
    this.setState({ activeFontFamily: font.family, applyFont: true });
  };

  onCropComplete = (croppedImg) => {
    this.setState({ signatureImage: croppedImg, showCropper: false, showCropOption: false, });
  };

  setShowCropper = (value) => {
    this.setState({
      showCropper: value
    })
  }

  setCroppedAreaPixels = (value) => {
    this.setState({
      croppedAreaPixels: value
    })
  }

  setRotation = (value) => {
    this.setState({
      rotation: value
    })
  }

  showCroppedImage = async () => {
    try {
      if (appEasyState.cropperWidthTextValue === '' || appEasyState.cropperHeightTextValue === '') {
        store.addNotification({
          title: 'Error',
          message: 'Size fields cannot be empty.',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        return
      }

      appEasyState.cropperWidthTextValue = ''
      appEasyState.cropperHeightTextValue = ''

      const croppedImage = await getCroppedImg(
        this.state.signatureImage,
        this.state.croppedAreaPixels,
        this.state.rotation,
      );
      console.log('done', { croppedImage });
      this.onCropComplete(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const { activeFontFamily, applyFont, signature, disableSubmit } =
      this.state;
    return (
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={CREATENEWSIGNATUREVALIDATION}
        onSubmit={(_values, { setSubmitting }) => {
          this.setState({ disableSubmit: true });
          this.makeBase64Img();
          // this.props.handleModal();
          setSubmitting(false);
        }}
      >
        {(formikProps) => {
          const hasErrors = Object.keys(formikProps.errors).length > 0;
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <Container>
                <div className={s.content}>
                  <Row>
                    <Col
                      sm={6}
                      md={6}
                      lg={{ span: 4, offset: 1 }}
                      xl={{ span: 4, offset: 1 }}
                    >
                      {this.state.showCropOption ? (
                        <Cropper
                          image={this.state.signatureImage}
                          handleComplete={this.onCropComplete}
                          isMandatory={false}
                          isSignature={true}
                          isStamp={false}
                          setShowCropper={this.setShowCropper}
                          setCroppedAreaPixels={this.setCroppedAreaPixels}
                          rotation={this.state.rotation}
                          setRotation={this.setRotation}
                        />
                      ) : (
                        <div>
                          <div className={s.leftContent}>
                            {!this.state.signatureImage && (
                              <>
                                <p>Signature Preview</p>
                                <p
                                  ref={(sigBox) => {
                                    this.sigBox = sigBox;
                                  }}
                                  className={`apply-font ${s.blueColor}`}
                                  id={"signText"}
                                >
                                  {applyFont && signature}
                                </p>
                              </>
                            )}
                          </div>

                          <div className={s.imagePickerDiv}>
                            {this.state.signatureImage && (
                              <>
                                <p>Signature Preview</p>

                                <img
                                  ref={(sigBox) => {
                                    this.sigBox = sigBox;
                                  }}
                                  className={s.uploadSealImg}
                                  src={this.state.signatureImage}
                                  alt={'signature-pic'}
                                ></img>
                              </>
                            )}
                            <ImagePicker
                              extensions={[
                                'jpg',
                                'jpeg',
                                'png',
                                'tif',
                                'gif',
                                'bmp',
                                'svg',
                                'tiff',
                                'dib',
                                'svg',
                              ]}
                              dims={{
                                minWidth: 200,
                                maxWidth: 50000,
                                minHeight: 200,
                                maxHeight: 50000,
                              }}
                              maxSize={5}
                              onChange={(base64) => {
                                this.setState({
                                  signatureImage: base64,
                                  showCropOption: true,
                                  imageUploaded: true,
                                });
                              }}
                              onError={(errMsg) => {
                                store.addNotification({
                                  title: 'Error',
                                  message: errMsg,
                                  type: 'danger',
                                  ...NOTIFICATION_PROPS,
                                });
                              }}
                            >
                              <Button className={s.uploadSealButton}>
                                Upload Image
                              </Button>
                            </ImagePicker>
                            <span className={s.optionalText}>(Optional)</span>
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col
                      sm={6}
                      md={6}
                      lg={{ span: 4, offset: 2 }}
                      xl={{ span: 4, offset: 2 }}
                    >
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          onBlur={formikProps.handleChange}
                          onChange={(e) => this.handleInput(e)}
                          maxLength={30}
                        />
                        {formikProps.errors.name && (
                          <p className={`input-feedback ${s.error}`}>
                            {formikProps.errors.name}
                          </p>
                        )}
                      </Form.Group>
                      {!this.state.signatureImage && (
                        <>
                          <Form.Label className={s.cutomLabel}>Font</Form.Label>
                          <div className={s.fontPickerStyle}>
                            <FontPicker
                              apiKey="AIzaSyC2eqKR6_lTLUpaizgbEozLNsxpc5pQCmM"
                              activeFontFamily={activeFontFamily}
                              limit={7}
                              families={[
                                'Caveat',
                                'Pacifico',
                                'Nanum Brush Script',
                                'Great Vibes',
                                'Arizonia',
                                'Condiment',
                                'Crafty Girls',
                              ]}
                              onChange={(e) => this.setFont(e)}
                            />
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className={style.footerBtn}>
                  <Button
                    variant="text"
                    className={s.btnStyle}
                    onClick={this.props.handleModal}
                  >
                    Cancel
                  </Button>
                  {!this.state.showCropper ? (
                    <>
                      {this.state.loading ? (
                        <Button className="btn btn-primary">
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                          Creating
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          disabled={
                            hasErrors || disableSubmit || this.state.signatureImage
                              ? false
                              : !applyFont
                          }
                        >
                          Create
                        </Button>
                      )}
                    </>
                  ): (
                    <Button onClick={this.showCroppedImage}>
                      Crop
                    </Button>
                  )}
                </div>
              </Container>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default view(SignatureForm);
