/* eslint-disable no-extra-boolean-cast */
import React, { useState, useEffect, useReducer } from 'react';
import {
  Row,
  Col,
  Form,
  ToggleButtonGroup,
  ToggleButton, Button,
} from 'react-bootstrap';
import s from './style.scss';
// import DatePicker from 'react-datepicker';
import style from '../style.scss';
import {
  CLIENTTYPE,
  OWNERTYPE,
  PROJECTROLE,
  DISCIPLINE,
  WORKNATURE,
  CONCENTRATION, NOTIFICATION_PROPS,
} from '../../../../../utils/constants';
import { FilePicker } from 'react-file-picker';
import { store } from 'react-notifications-component';

const AdvancedTab = ({ getAdvanceTabValue, uploadFormToFirebase, confirmationValues = {} }) => {
  const [concentrationList, setConcentrationList] = useState(CONCENTRATION);
  const [otherConcentration, setOtherConcentration] = useState(
    confirmationValues.concentration === 'Other' ? true : false,
  );
  const [otherProjectRole, setOtherProjectRole] = useState(false);
  const [specifyRoleType, setSpecifyRoleType] = useState(false);
  // const [consulantConcentrationItems, setconsultantConcentrationItems] =
  //   useState(CONCENTRATION);
  // const [showconsultantConcentrationField, setConsultantConcentrationField] =
  //   useState(confirmationValues.subConsultantDiscipline ? true : false);
  // const [otherConsultantConcentration, setotherConsultantConcentration] =
  //   useState(
  //     confirmationValues.subConsultantConcentration === 'Other' ? true : false,
  //   );

  useEffect(() => {
    if (confirmationValues.discipline) {
      const filteredItems = CONCENTRATION.filter(
        (e) => e.id === confirmationValues.discipline,
      );
      setConcentrationList(filteredItems);
    }

    // if (confirmationValues.subConsultantDiscipline) {
    //   const filteredItems = CONCENTRATION.filter(
    //     (e) => e.label === confirmationValues.subConsultantDiscipline,
    //   );
    //   setconsultantConcentrationItems(filteredItems);
    // }
  }, []);

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      clientName: confirmationValues.clientName,
      clientEmail: confirmationValues.clientEmail,
      clientType: confirmationValues.clientType,
      ownerType: confirmationValues.ownerType,
      workNature: confirmationValues.workNature,
      discipline: confirmationValues.discipline,
      concentration: confirmationValues.concentration,
      projectRole: confirmationValues.projectRole,
      scopeInclusions: confirmationValues.scopeInclusions,
      scopeExclusions: confirmationValues.scopeExclusions,
      // retainConsultants: confirmationValues.retainConsultants,
      // retainConsultantsWithDefault: confirmationValues.retainConsultantsWithDefault
      //   ? confirmationValues.retainConsultantsWithDefault
      //   : 'N/A',
      // subConsultantDiscipline: confirmationValues.subConsultantDiscipline,
      // subConsultantConcentration: confirmationValues.subConsultantConcentration,
      // otherConsultantConcentration:
      //   confirmationValues.otherConsultantConcentration,
      otherWorkNature: confirmationValues.otherWorkNature,
      otherConcentration: confirmationValues.otherConcentration,
      // subConsultantContract: confirmationValues.subConsultantContract ? confirmationValues.subConsultantContract : null,
    },
  );

  useEffect(() => {
    if (userInput.discipline === 'landSurveyor') {
      console.log('advanceTabValues in useEffect')
      setOtherConcentration(false);
      setUserInput({ ['concentration']: null });
      getAdvanceTabValue('concentration', null);
      setUserInput({ ['otherConcentration']: null });
      getAdvanceTabValue('otherConcentration', false);
      console.log('advanceTabValues userInput', userInput)
    }
  }, [userInput.discipline])

  useEffect(() => {
    console.log('advanceTabValues userInput', userInput)
  }, [userInput.concentration])

  // useEffect(() => {
  //   if (userInput.subConsultantDiscipline === 'Land Surveyor') {
  //     setConsultantConcentrationField(false);
  //     setotherConsultantConcentration(false);
  //     setUserInput({ ['subConsultantConcentration']: null });
  //     getAdvanceTabValue('subConsultantConcentration', null);
  //     setUserInput({ ['otherConsultantConcentration']: null });
  //     getAdvanceTabValue('otherConsultantConcentration', false);
  //   }
  // }, [userInput.subConsultantDiscipline])

  const handleChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    console.log('advanceTabValues name', name);
    console.log('advanceTabValues newValue', newValue);
    setUserInput({ [name]: newValue });
    getAdvanceTabValue(name, newValue);

    if (name === 'discipline') {
      const filteredItems = CONCENTRATION.filter((e) => e.id === newValue);
      console.log('filteredItems: ', filteredItems)
      setConcentrationList(filteredItems);
    } else if (name === 'concentration') {
      if (newValue === 'Other') {
        setOtherConcentration(true);
      } else {
        setOtherConcentration(false);
      }
    }
    // else if (name === 'subConsultantDiscipline') {
    //   setConsultantConcentrationField(true);
    //   const filteredItems = CONCENTRATION.filter((e) => e.label === newValue);
    //   setconsultantConcentrationItems(filteredItems);
    // }
    // else if (name === 'subConsultantConcentration') {
    //   if (newValue === 'Other') {
    //     setotherConsultantConcentration(true);
    //   } else {
    //     setotherConsultantConcentration(false);
    //   }
    // }
    else if (name === 'projectRole' && newValue === 'Other') {
      setOtherProjectRole(true);
    } else if (name === 'componentDesign') {
      setSpecifyRoleType(true);
    }

    // if (name === 'clientType') {
    //   CLIENTTYPE.forEach((element) => {
    //     if (element.value === newValue) {
    //       getAdvanceTabValue('clientType', element.label);
    //     }
    //   });
    // } else if (name === 'ownerType') {
    //   OWNERTYPE.forEach((element) => {
    //     if (element.value === newValue) {
    //       getAdvanceTabValue('ownerType', element.label);
    //     }
    //   });
    // } else if (name === 'discipline') {
    //   setUserInput({ [name]: newValue });
    //   getAdvanceTabValue(name, newValue);
    //   const filteredItems = CONCENTRATION.filter((e) => e.id === newValue);
    //   setConcentrationList(filteredItems);
    // } else if (name === 'subConsultantDiscipline') {
    //   setUserInput({ [name]: newValue });
    //   getAdvanceTabValue(name, newValue);
    //   setConsultantConcentrationField(true);
    //   const filteredItems = CONCENTRATION.filter((e) => e.label === newValue);
    //   console.log('filteredItems ===> ', filteredItems);
    //   setconsultantConcentrationItems(filteredItems);
    // } else if (name === 'subConsultantConcentration') {
    //   setUserInput({ [name]: newValue });
    //   getAdvanceTabValue(name, newValue);
    //   if (newValue === 'Other') {
    //     setotherConsultantConcentration(true);
    //   }
    // } else if (name === 'projectRole') {
    //   newValue === 'Other'
    //     ? setOtherProjectRole(true)
    //     : setOtherProjectRole(false);
    //   name === 'componentDesign'
    //     ? setSpecifyRoleType(true)
    //     : setSpecifyRoleType(false);
    //   if (newValue !== 'Other')
    //     PROJECTROLE.forEach((element) => {
    //       if (element.value === newValue) {
    //         getAdvanceTabValue('projectRole', element.label);
    //       }
    //     });
    // } else {
    //   setUserInput({ [name]: newValue });
    //   getAdvanceTabValue(name, newValue);
    // }
  };

  return (
    <Row>
      <Col md={6} lg={4}>
        <Form.Group controlId="clientName">
          <Form.Label>Client Name</Form.Label>
          <Form.Control
            name="clientName"
            type="text"
            onChange={handleChange}
            value={userInput.clientName}
          />
        </Form.Group>
        <Form.Group controlId="clientEmail">
          <Form.Label>Client Email</Form.Label>
          <Form.Control
            type="text"
            name="clientEmail"
            onChange={handleChange}
            value={userInput.clientEmail}
          />
        </Form.Group>
        <Form.Group controlId="clientType">
          <Form.Label>Client Type</Form.Label>
          <Form.Control
            as="select"
            name="clientType"
            onChange={handleChange}
            value={userInput.clientType}
          >
            <option value="" selected disabled>
              Select
            </option>
            {CLIENTTYPE.map((item, i) => (
              <option key={i} value={item.label}>
                {item.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="ownerType">
          <Form.Label>Owner Type (if different than client)</Form.Label>
          <Form.Control
            as="select"
            name="ownerType"
            onChange={handleChange}
            value={userInput.ownerType}
          >
            <option value="" selected disabled>
              Select
            </option>
            {OWNERTYPE.map((item, i) => (
              <option key={i} value={item.label}>
                {item.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="workNature">
          <Form.Label>Nature of Work</Form.Label>
          <Form.Control
            as="select"
            name="workNature"
            value={userInput.workNature}
            onChange={handleChange}
          >
            <option value="" selected disabled>
              Select
            </option>
            {WORKNATURE.map((item, i) => (
              <option key={i}>{item.label}</option>
            ))}
          </Form.Control>
        </Form.Group>

        {userInput.workNature === 'Other' && (
          <Form.Group controlId="otherField">
            <Form.Label>Enter Nature of Work</Form.Label>
            <Form.Control
              type="text"
              name="otherWorkNature"
              value={userInput.otherWorkNature}
              onChange={handleChange}
            />
          </Form.Group>
        )}
      </Col>
      <Col md={6} lg={4}>
        <Form.Group controlId="discipline">
          <Form.Label>Discipline</Form.Label>
          <Form.Control
            as="select"
            name="discipline"
            value={userInput.discipline}
            onChange={handleChange}
          >
            <option value="" selected disabled>
              Select
            </option>
            {DISCIPLINE.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {concentrationList[0].items.length !== 0 && (
          <Form.Group controlId="concentration">
          <Form.Label>Concentration</Form.Label>
          <Form.Control
          as="select"
          disabled={!!userInput.discipline ? false : true}
          name="concentration"
          value={userInput.concentration}
          onChange={handleChange}
          >
        {!!!userInput.discipline ? (
          <option value="" selected disabled>
            Select Discipline First
          </option>
          ) : (
          <option value="" selected disabled>
           Select
          </option>
        )}
        {concentrationList[0].items.map((item, i) => (
          <option key={i} value={item.value}>
        {item}
          </option>
          ))}
          </Form.Control>
          </Form.Group>
        )}
        {otherConcentration && (
          <Form.Group controlId="otherConcentration">
            <Form.Label>Enter Concentration</Form.Label>
            <Form.Control
              type="text"
              name="otherConcentration"
              value={userInput.otherConcentration}
              onChange={handleChange}
            />
          </Form.Group>
        )}
        <Form.Group controlId="projectRole">
          <Form.Label>Project Role</Form.Label>
          <Form.Control
            as="select"
            name="projectRole"
            value={userInput.projectRole}
            onChange={handleChange}
          >
            <option value="" selected disabled>
              Select
            </option>
            {PROJECTROLE.map((item, i) => (
              <option key={i} value={item.label}>
                {item.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {specifyRoleType && (
          <Form.Group controlId="componentDesign">
            <Form.Label>Enter Design Type</Form.Label>
            <Form.Control
              type="text"
              name="componentDesign"
              value={userInput.componentDesign}
              onChange={handleChange}
            />
          </Form.Group>
        )}
        {otherProjectRole && (
          <Form.Group controlId="otherProjectRole">
            <Form.Label>Enter Role</Form.Label>
            <Form.Control
              type="text"
              name="otherProjectRole"
              value={userInput.otherProjectRole}
              onChange={handleChange}
            />
          </Form.Group>
        )}
        {/* <Form.Group controlId="dateOfIssuance">
          <Form.Label>Date of Issuance</Form.Label>
          <DatePicker
            selected={date}
            onChange={handleChange}
            startDate={date}
            minDate={Date.now()}
          />
        </Form.Group> */}
      </Col>
      <Col md={6} lg={4}>
        <Form.Group controlId="scopeInclusions">
          <Form.Label>Scope Inclusions</Form.Label>
          <Form.Control
            type="text"
            name="scopeInclusions"
            value={userInput.scopeInclusions}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="scopeExclusions">
          <Form.Label>Scope Exclusions</Form.Label>
          <Form.Control
            type="text"
            name="scopeExclusions"
            value={userInput.scopeExclusions}
            onChange={handleChange}
          />
        </Form.Group>
        {/*<Form.Group controlId="retainConsultants">*/}
        {/*  <Form.Label>*/}
        {/*    Have you retained sub-consultants for this project?*/}
        {/*  </Form.Label>*/}
        {/*  <ToggleButtonGroup*/}
        {/*    type="checkbox"*/}
        {/*    value="value"*/}
        {/*    onChange={(e) => {*/}
        {/*      console.log('tab e',e)*/}
        {/*      if (e[1] === 'Yes') {*/}
        {/*        getAdvanceTabValue('retainConsultants', true);*/}
        {/*        setUserInput({ retainConsultants: true });*/}
        {/*      }*/}
        {/*      else {*/}
        {/*        getAdvanceTabValue('retainConsultants', false);*/}
        {/*        setUserInput({ retainConsultants: false });*/}
        {/*        setUserInput({ subConsultantDiscipline: null });*/}
        {/*        getAdvanceTabValue('subConsultantDiscipline', 'N/A');*/}
        {/*        setUserInput({ subConsultantConcentration: null });*/}
        {/*        getAdvanceTabValue('subConsultantConcentration', 'N/A');*/}
        {/*      }*/}

        {/*      getAdvanceTabValue('retainConsultantsWithDefault', e[1]);*/}
        {/*      setUserInput({ retainConsultantsWithDefault: e[1] });*/}
        {/*    }}*/}
        {/*    className={style.inlineBtn}*/}
        {/*  >*/}
        {/*    <ToggleButton*/}
        {/*      value={'Yes'}*/}
        {/*      className={userInput.retainConsultantsWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}*/}
        {/*    >*/}
        {/*      Yes*/}
        {/*    </ToggleButton>*/}
        {/*    <ToggleButton*/}
        {/*      value={'No'}*/}
        {/*      className={userInput.retainConsultantsWithDefault === 'No' ? s.bgBlue : s.bgWhite}*/}
        {/*    >*/}
        {/*      No*/}
        {/*    </ToggleButton>*/}
        {/*    <ToggleButton*/}
        {/*      value={'N/A'}*/}
        {/*      className={userInput.retainConsultantsWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}*/}
        {/*    >*/}
        {/*      N/A*/}
        {/*    </ToggleButton>*/}
        {/*  </ToggleButtonGroup>*/}
        {/*</Form.Group>*/}
        {/*{userInput.retainConsultantsWithDefault === 'Yes' && (*/}
        {/*  <div>*/}
        {/*    <Form.Group controlId="subConsultantDiscipline">*/}
        {/*      <Form.Label>Sub-consultant Discipline (if applicable)</Form.Label>*/}
        {/*      <Form.Control*/}
        {/*        as="select"*/}
        {/*        name="subConsultantDiscipline"*/}
        {/*        value={userInput.subConsultantDiscipline}*/}
        {/*        onChange={handleChange}*/}
        {/*      >*/}
        {/*        <option value="" selected disabled>*/}
        {/*          Select*/}
        {/*        </option>*/}
        {/*        {DISCIPLINE.map((item, i) => (*/}
        {/*          <option key={i} value={item.label}>*/}
        {/*            {item.label}*/}
        {/*          </option>*/}
        {/*        ))}*/}
        {/*      </Form.Control>*/}
        {/*    </Form.Group>*/}
        {/*    {showconsultantConcentrationField && (*/}
        {/*      <Form.Group controlId="subConsultantConcentration">*/}
        {/*        <Form.Label>Enter Consultant Concentration</Form.Label>*/}
        {/*        <Form.Control*/}
        {/*          as="select"*/}
        {/*          name="subConsultantConcentration"*/}
        {/*          value={userInput.subConsultantConcentration}*/}
        {/*          onChange={handleChange}*/}
        {/*        >*/}
        {/*          <option value="" selected disabled>*/}
        {/*            Select*/}
        {/*          </option>*/}
        {/*          {consulantConcentrationItems[0].items.map((item, i) => (*/}
        {/*            <option key={i} value={item.value}>*/}
        {/*              {item}*/}
        {/*            </option>*/}
        {/*          ))}*/}
        {/*        </Form.Control>*/}
        {/*      </Form.Group>*/}
        {/*    )}*/}
        {/*    {otherConsultantConcentration && (*/}
        {/*      <Form.Group controlId="otherConsultantConcentration">*/}
        {/*        <Form.Label>Enter Concentration</Form.Label>*/}
        {/*        <Form.Control*/}
        {/*          type="text"*/}
        {/*          name="otherConsultantConcentration"*/}
        {/*          value={userInput.otherConsultantConcentration}*/}
        {/*          onChange={handleChange}*/}
        {/*        />*/}
        {/*      </Form.Group>*/}
        {/*    )}*/}
        {/*    <div>*/}
        {/*      <Form.Label>Upload Contract (if applicable)</Form.Label>*/}
        {/*      <div>{<span>{userInput.subConsultantContract}</span>}</div>*/}
        {/*      <FilePicker*/}
        {/*        extensions={['pdf']}*/}
        {/*        dims={{*/}
        {/*          minWidth: 100,*/}
        {/*          maxWidth: 50000,*/}
        {/*          minHeight: 100,*/}
        {/*          maxHeight: 50000,*/}
        {/*        }}*/}
        {/*        maxSize={5}*/}
        {/*        onChange={async (FileObject) => {*/}
        {/*          const link = await uploadFormToFirebase(FileObject, true);*/}
        {/*          setUserInput({ subConsultantContract: link });*/}
        {/*          getAdvanceTabValue('subConsultantContract', link);*/}
        {/*        }}*/}
        {/*        onError={(errMsg) =>*/}
        {/*          store.addNotification({*/}
        {/*            title: 'Error',*/}
        {/*            message: errMsg,*/}
        {/*            type: 'danger',*/}
        {/*            ...NOTIFICATION_PROPS,*/}
        {/*          })*/}
        {/*        }*/}
        {/*      >*/}
        {/*        <Button variant="secondary" block>*/}
        {/*          Upload*/}
        {/*        </Button>*/}
        {/*      </FilePicker>*/}
        {/*      <br/>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </Col>
    </Row>
  );
};

export default AdvancedTab;
