import React, { useState, useReducer, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from 'react-bootstrap';
import s from './style.scss';
import style from '../style.scss';
import { CONCENTRATION, CONTRACTOWNER, DISCIPLINE } from '../../../../../utils/constants';
import { FilePicker } from 'react-file-picker';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../../utils/constants';
import CurrencyInput from 'react-currency-input-field';

const ContractualInfoTab = ({
  getContractualInfoTabItems,
  uploadFormToFirebase,
  confirmationValues = {},
}) => {
  const [liabilityLimit, setLiabilityLimit] = useState(
    confirmationValues ? confirmationValues.liabilityLimit : false,
  );
  const [liabilityLimitWithDefault, setLiabilityLimitWithDefault] = useState(confirmationValues.liabilityLimitWithDefault
    ? confirmationValues.liabilityLimitWithDefault
    : 'N/A');
  const [showAdditionalField, setShowAdditionalField] = useState(
    confirmationValues.contractOwner === 'Other' ? true : false,
  );
  const [waiver, setWaiver] = useState(
    confirmationValues ? confirmationValues.waiver : false,
  );
  const [waiverWithDefault, setWaiverWithDefault] = useState(confirmationValues.waiverWithDefault
    ? confirmationValues.waiverWithDefault
    : 'N/A');
  const [consultantConcentrationItems, setConsultantConcentrationItems] =
    useState(CONCENTRATION);
  const [showConsultantConcentrationField, setConsultantConcentrationField] =
    useState(confirmationValues.subConsultantDiscipline ? true : false);
  const [otherConsultantConcentration, setOtherConsultantConcentration] =
    useState(
      confirmationValues.subConsultantConcentration === 'Other' ? true : false,
    );

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fees: confirmationValues.fees || null,
      ifContract: confirmationValues.ifContract || null,
      contract: confirmationValues.contractName || confirmationValues.contract || null,
      contractName: confirmationValues.contractName || null,
      contractOwner: confirmationValues.contractOwner || null,
      otherContractOwner: confirmationValues.otherContractOwner || null,
      liabilityLimit: confirmationValues.liabilityLimit || null,
      liabilityLimitWithDefault: confirmationValues.liabilityLimitWithDefault || 'N/A',
      liabilityAmount: confirmationValues.liabilityAmount || null,
      waiver: confirmationValues.waiver || null,
      waiverWithDefault: confirmationValues.waiverWithDefault || 'N/A',
      retainConsultants: confirmationValues.retainConsultants || null,
      retainConsultantsWithDefault: confirmationValues.retainConsultantsWithDefault || 'N/A',
      subConsultantDiscipline: confirmationValues.subConsultantDiscipline || null,
      subConsultantConcentration: confirmationValues.subConsultantConcentration || null,
      otherConsultantConcentration: confirmationValues.otherConsultantConcentration || null,
      subConsultantContract: confirmationValues.subConsultantContractName || confirmationValues.subConsultantContract ||  null,
      subConsultantContractName: confirmationValues.subConsultantContractName || null,
    },
  );

  useEffect(() => {
    if (confirmationValues.subConsultantDiscipline) {
      const filteredItems = CONCENTRATION.filter(
        (e) => e.label === confirmationValues.subConsultantDiscipline,
      );
      setConsultantConcentrationItems(filteredItems);
    }
  }, []);

  useEffect(() => {
    if (userInput.subConsultantDiscipline === 'Land Surveyor') {
      setConsultantConcentrationField(false);
      setOtherConsultantConcentration(false);
      setUserInput({ ['subConsultantConcentration']: null, ['otherConsultantConcentration']: false });
    }
  }, [userInput.subConsultantDiscipline])

  useEffect(() => {
    getContractualInfoTabItems(userInput);
  }, [userInput]);

  const handleChange = (e) => {
    console.log('e.target.name', e.target.name)
    console.log('e.target.value', e.target.value)
    console.log('typeof e.target.value', typeof e.target.value)
    const name = e.target.name;
    const newValue = e.target.value;

    if (name === 'liabilityAmount' || name === 'fees') {
      const float_value = parseFloat(newValue)
      if (float_value >= 0 && /^\d*\.?\d*$/.test(newValue) && /^[-+]?\d+(\.\d{1,2})?$/.test(float_value.toString())) {
        setUserInput({ [name]: newValue });
      }
      else if (newValue === '') {
        setUserInput({ [name]: '' });
      }
    }
    else if (name === 'subConsultantDiscipline') {
      setConsultantConcentrationField(true);
      const filteredItems = CONCENTRATION.filter((e) => e.label === newValue);
      setConsultantConcentrationItems(filteredItems);

      setUserInput({ [name]: newValue });
    } else if (name === 'subConsultantConcentration') {
      if (newValue === 'Other') {
        setOtherConsultantConcentration(true);
      } else {
        setOtherConsultantConcentration(false);
      }
      setUserInput({ [name]: newValue });
    }
    else {
      setUserInput({ [name]: newValue });
    }

  };

  return (
    <div className={s.root}>
      <Row>
        <Col md={6} lg={4} xl={4}>
          <Form.Group controlId="fees">
            <Form.Label>Fees ($)</Form.Label>
            <div>
              <CurrencyInput
                id="fees"
                name="fees"
                defaultValue={userInput.fees}
                decimalsLimit={2}
                allowNegativeValue={false}
                onValueChange={(value, name) => {
                  setUserInput({ [name]: value });
                }}
                maxLength={15}
                className={s.currencyInput}
              />
            </div>
          </Form.Group>
          <Form.Group controlId="ifContract">
            <Form.Label>Contract</Form.Label>
            <ToggleButtonGroup
              type="checkbox"
              name="ifContract"
              onChange={(e) => {
                setUserInput({ ifContract: !!e[1] });
              }}
              value="value"
              className={style.inlineBtn}
            >
              <ToggleButton
                value={1}
                className={userInput.ifContract ? s.bgBlue : s.bgWhite}
              >
                Written
              </ToggleButton>
              <ToggleButton
                value={0}
                className={!userInput.ifContract ? s.bgBlue : s.bgWhite}
              >
                Verbal
              </ToggleButton>
            </ToggleButtonGroup>
          </Form.Group>
          <Form.Label>Upload Contract (if applicable)</Form.Label>
          <div>{<span>{userInput.contractName || userInput.contract}</span>}</div>
          <FilePicker
            extensions={['pdf']}
            dims={{
              minWidth: 100,
              maxWidth: 50000,
              minHeight: 100,
              maxHeight: 50000,
            }}
            maxSize={5}
            onChange={async (FileObject) => {
              console.log('FileObject', FileObject)
              const link = await uploadFormToFirebase(FileObject, 'contract');
              setUserInput({ contract: link, contractType: FileObject.type, contractName: FileObject.name });
            }}
            onError={(errMsg) =>
              store.addNotification({
                title: 'Error',
                message: errMsg,
                type: 'danger',
                ...NOTIFICATION_PROPS,
              })
            }
          >
            <Button variant="secondary" block>
              Upload
            </Button>
          </FilePicker>
          <Form.Group controlId="contractOwner">
            <Form.Label>Whose Contract?</Form.Label>
            <Form.Control
              as="select"
              name="contractOwner"
              defaultValue={userInput.contractOwner}
              onChange={(e) => {
                const value = e.target.value;
                if (value === 'Other') {
                  setShowAdditionalField(true);
                } else {
                  setShowAdditionalField(false);
                }
                setUserInput({ contractOwner: value });
              }}
            >
              <option value="" selected disabled>
                Select
              </option>
              {CONTRACTOWNER.map((item, i) => (
                <option key={i} value={item.label}>
                  {item.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {showAdditionalField && (
            <Form.Group controlId="otherOwner">
              <Form.Label>Enter Contract Owner</Form.Label>
              <Form.Control
                type="text"
                defaultValue={userInput.otherContractOwner}
                onChange={(e) => {
                  const value = e.target.value;
                  setUserInput({ otherContractOwner: value });
                }}
              />
            </Form.Group>
          )}
        </Col>
        <Col md={6} lg={4} xl={4}>
          <Form.Label>Limitation of Liability</Form.Label>
          <ToggleButtonGroup
            type="checkbox"
            value="value"
            onChange={(e) => {
              // setLiabilityLimit(!!e[1]);
              // contractualInfoTabData.liabilityLimit = Boolean(!!e[1]);
              if (e[1] === 'Yes') {
                setLiabilityLimit(true);
                setUserInput({ ['liabilityLimit']: true });
              }
              else {
                setLiabilityLimit(false);
                setUserInput({ ['liabilityLimit']: false });
              }
              setLiabilityLimitWithDefault(e[1]);
              setUserInput({ ['liabilityLimitWithDefault']: e[1] });
            }}
            className={style.inlineBtn}
          >
            <ToggleButton
              value={'Yes'}
              className={liabilityLimitWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={'No'}
              className={liabilityLimitWithDefault === 'No' ? s.bgBlue : s.bgWhite}
            >
              No
            </ToggleButton>
            <ToggleButton
              value={'N/A'}
              className={liabilityLimitWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
            >
              N/A
            </ToggleButton>
          </ToggleButtonGroup>
          <Form.Group controlId="liabilityAmount">
            <Form.Label>Limit of Liability Amount (if applicable)</Form.Label>
            {/*<Form.Control*/}
            {/*  type="number"*/}
            {/*  name="liabilityAmount"*/}
            {/*  defaultValue={userInput.liabilityAmount}*/}
            {/*  onChange={handleChange}*/}
            {/*/>*/}
            <CurrencyInput
              id="liabilityAmount"
              name="liabilityAmount"
              defaultValue={userInput.liabilityAmount}
              decimalsLimit={2}
              allowNegativeValue={false}
              onValueChange={(value, name) => {
                setUserInput({ [name]: value });
              }}
              maxLength={15}
              className={s.currencyInput}
            />
          </Form.Group>
          <Form.Label>Waiver of Consequential Damages</Form.Label>
          <ToggleButtonGroup
            type="checkbox"
            value="value"
            onChange={(e) => {
              // setWaiver(!!e[1]);
              // contractualInfoTabData.waiver = Boolean(!!e[1]);
              if (e[1] === 'Yes') {
                setWaiver(true);
                setUserInput({ ['waiver']: true });
              }
              else {
                setWaiver(false);
                setUserInput({ ['waiver']: false });
              }
              setWaiverWithDefault(e[1]);
              setUserInput({ ['waiverWithDefault']: e[1] });
            }}
            className={style.inlineBtn}
          >
            <ToggleButton value={'Yes'} className={waiverWithDefault === "Yes" ? s.bgBlue : s.bgWhite}>
              Yes
            </ToggleButton>
            <ToggleButton value={'No'} className={waiverWithDefault === "No" ? s.bgBlue : s.bgWhite}>
              No
            </ToggleButton>
            <ToggleButton value={'N/A'} className={waiverWithDefault === "N/A" ? s.bgBlue : s.bgWhite}>
              N/A
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
        <Col md={6} lg={4} xl={4}>
          <Form.Group controlId="retainConsultants">
            <Form.Label>
              Have you retained sub-consultants for this project?
            </Form.Label>
            <ToggleButtonGroup
              type="checkbox"
              value="value"
              onChange={(e) => {
                console.log('tab e',e)
                if (e[1] === 'Yes') {
                  setUserInput({ retainConsultants: true });
                }
                else {
                  setUserInput({ retainConsultants: false, subConsultantDiscipline: null, subConsultantConcentration: null });
                }

                setUserInput({ retainConsultantsWithDefault: e[1] });
              }}
              className={style.inlineBtn}
            >
              <ToggleButton
                value={'Yes'}
                className={userInput.retainConsultantsWithDefault === 'Yes' ? s.bgBlue : s.bgWhite}
              >
                Yes
              </ToggleButton>
              <ToggleButton
                value={'No'}
                className={userInput.retainConsultantsWithDefault === 'No' ? s.bgBlue : s.bgWhite}
              >
                No
              </ToggleButton>
              <ToggleButton
                value={'N/A'}
                className={userInput.retainConsultantsWithDefault === 'N/A' ? s.bgBlue : s.bgWhite}
              >
                N/A
              </ToggleButton>
            </ToggleButtonGroup>
          </Form.Group>
          {userInput.retainConsultantsWithDefault === 'Yes' && (
            <div>
              <Form.Group controlId="subConsultantDiscipline">
                <Form.Label>Sub-consultant Discipline (if applicable)</Form.Label>
                <Form.Control
                  as="select"
                  name="subConsultantDiscipline"
                  value={userInput.subConsultantDiscipline}
                  onChange={handleChange}
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  {DISCIPLINE.map((item, i) => (
                    <option key={i} value={item.label}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {showConsultantConcentrationField && (
                <Form.Group controlId="subConsultantConcentration">
                  <Form.Label>Enter Sub-consultant Concentration</Form.Label>
                  <Form.Control
                    as="select"
                    name="subConsultantConcentration"
                    value={userInput.subConsultantConcentration}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {consultantConcentrationItems[0].items.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              {otherConsultantConcentration && (
                <Form.Group controlId="otherConsultantConcentration">
                  <Form.Label>Enter Concentration</Form.Label>
                  <Form.Control
                    type="text"
                    name="otherConsultantConcentration"
                    value={userInput.otherConsultantConcentration}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
              <div>
                <Form.Label>Upload Contract (if applicable)</Form.Label>
                <div>{<span>{userInput.subConsultantContractName || userInput.subConsultantContract}</span>}</div>
                <FilePicker
                  extensions={['pdf']}
                  dims={{
                    minWidth: 100,
                    maxWidth: 50000,
                    minHeight: 100,
                    maxHeight: 50000,
                  }}
                  maxSize={5}
                  onChange={async (FileObject) => {
                    const link = await uploadFormToFirebase(FileObject, 'subConsultantContract');
                    console.log('link', link)
                    setUserInput({ subConsultantContract: link, subConsultantContractName: FileObject.name });
                  }}
                  onError={(errMsg) =>
                    store.addNotification({
                      title: 'Error',
                      message: errMsg,
                      type: 'danger',
                      ...NOTIFICATION_PROPS,
                    })
                  }
                >
                  <Button variant="secondary" block>
                    Upload
                  </Button>
                </FilePicker>
                <br/>
              </div>
            </div>
          )}
        </Col>
        <Col />
      </Row>
    </div>
  );
};

export default ContractualInfoTab;
