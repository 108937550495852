import React from 'react';
import DynamicModal from '../../../DynamicModal';
import { Container, Button } from 'react-bootstrap';
import s from './style.scss';

const WarningPopupModal = ({ hideModal, continueSeal, showModal }) => {
  return (
    <DynamicModal
      show={showModal}
      title="Warning"
      hideModal={hideModal}
      size="lg"
    >
      <Container>
        <p>
          Please make sure that your seals are correctly placed. If you are unsure about your page selections or the locations of your seal/signature, then please select "Review Document" and scroll through the pages of your document to check that its presentation matches your expectations. If you see an error, please "Cancel" and sign again. Once you are confident in your page selections, please select "Confirm".
        </p>
        <div className={s.footer}>
          <Button
            variant="outline-secondary"
            style={{ width: 170 }}
            onClick={hideModal}
          >
            Review Document
          </Button>
          <Button onClick={continueSeal}>Confirm</Button>
        </div>
      </Container>
    </DynamicModal>
  );
};

export default WarningPopupModal;
