/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withFirebase } from '../../../auth/firebase';
import withAuthorization from '../../../auth/session/withAuthorization';
import Spinner from '../../Layout/Spinner';
import {
  SERVICE_BOT_SECRECT_KEY,
  CREATE_CUSTOMER_PORTAL_LINK,
  GET_STRIPE_PRODUCTS,
  CREATE_STRIPE_SUBSCRIPTION,
  CARD_SETUP,
  GET_CUSTOMER,
  STRIPE_PUBLISHED_KEY,
  PREVIEW_PRORATION,
  LIST_PAYMENT_METHODS,
  NOTIFICATION_PROPS, ATTACH_PAYMENT_METHOD, NOTIFICATION_PROPS_LONGER, UPDATE_SUBSCRIPTION, APPLY_COUPON_CODE,
} from '../../../utils/constants';
import axios from 'axios';
import {view} from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import Header from '../../Header';
import s from './style.scss';
import { store } from 'react-notifications-component';
import MainScreen from './MainScreen';


const Referrals = (props) => {
  const { user, firebase, reloadUser } = props
  const [loading, setLoading] = useState(false)


  return loading ? <Spinner/> : (
    <React.Fragment>
      <Header title="Referrals & Rewards" />
      <MainScreen {...props} />
    </React.Fragment>
  )
}

const condition = (user) => !!user;
export default withAuthorization(condition)(withFirebase(view(Referrals)));
