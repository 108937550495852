import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from 'react-bootstrap';
import s from './style.scss';
import style from '../style.scss';
import { CONTRACTOWNER } from '../../../../../utils/constants';
import { FilePicker } from 'react-file-picker';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../../utils/constants';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../../../easyStateStore';

const ContractualInfoTab = ({ confirmationValues }) => {
  console.log('confirmationValues  ====> ', confirmationValues);

  const renderValue = (arg) => {
    if (arg !== undefined) {
      if (typeof  arg === 'string')
        return arg;
      return arg === true ? 'Yes' : 'No';
    }
    return 'N/A'
  };

  return (
    <div className={s.root}>
      <Row>
        <Col md={6} lg={4} xl={4}>
          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              <p className={s.pages}>Fees:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {appEasyState.getCurrencyValue(confirmationValues.fees)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              <p className={s.pages}>Contract:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.ifContract ? 'Written' : 'Verbal'}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              <p className={s.pages}>Upload Contract:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.contractName || renderValue(confirmationValues.contract)}
            </Form.Label>
          </div>


          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              <p className={s.pages}>Whose Contract:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.contractOwner || 'N/A'}
            </Form.Label>
          </div>
        </Col>

        <Col md={6} lg={4} xl={4}>
          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              <p className={s.pages}>Limitation of Liability:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.liabilityLimitWithDefault || 'N/A'}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              <p className={s.pages}>Limit of Liability Amount:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {appEasyState.getCurrencyValue(confirmationValues.liabilityAmount)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              <p className={s.pages}>Waiver of Consequential Damages:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.waiverWithDefault
                ? confirmationValues.waiverWithDefault : 'N/A'}
            </Form.Label>
          </div>
        </Col>
        <Col  md={6} lg={4} xl={4}>
          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              Have you retained sub-consultants for this project? :
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.retainConsultantsWithDefault ||
                renderValue(confirmationValues.retainConsultants)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              Sub-consultant Discipline:
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.retainConsultantsWithDefault
                ? confirmationValues.retainConsultantsWithDefault === 'Yes'
                  ? renderValue(confirmationValues.subConsultantDiscipline) : 'N/A'
                : renderValue(confirmationValues.subConsultantDiscipline)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              Sub-consultant Concentration:
            </Form.Label>
            <Form.Label className={s.valuesText}>
              { confirmationValues.retainConsultantsWithDefault
                ? confirmationValues.retainConsultantsWithDefault === 'Yes'
                  ? confirmationValues.subConsultantDiscipline
                    ? confirmationValues.subConsultantDiscipline !== 'Land Surveyor'
                      ? confirmationValues.subConsultantConcentration === 'Other'
                        ? confirmationValues.otherConsultantConcentration
                        : confirmationValues.subConsultantConcentration
                      : 'N/A'
                    : 'N/A'
                  : 'N/A'
                : 'N/A'
              }
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              Sub-consultant Contract:
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.subConsultantContractName || renderValue(confirmationValues.subConsultantContract)}
            </Form.Label>
          </div>

        </Col>
        <Col />
      </Row>
    </div>
  );
};

export default view(ContractualInfoTab);
