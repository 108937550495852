import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
// import DatePicker from 'react-datepicker';
import s from '../style.scss';
import { PAGESFROM, PAGESTO } from '../../../../../utils/constants';

const BasicTab = ({ selectedSealInfo, confirmationValues }) => {
  console.log(
    'selectedSealInfo basic tab ==============>>>>>> ',
    selectedSealInfo,
  );
  const getValue = (value) => {
    if (value) {
      return value;
    }
    return 'N/A';
  };

  const formatDate = (date) => {
    //console.log('date == = = = =  >', date ? date.toDate() : date);
    const dateObj = new Date(date);
    //console.log('dateObj =============>', dateObj);
    var year = dateObj.getFullYear();
    var month = (1 + dateObj.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = dateObj.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  };

  const getNameOfSeal = (selectedSealInfo) => {
    if (selectedSealInfo.type === 'stamp') {
      return selectedSealInfo.sealName;
    } else if (selectedSealInfo.type === 'signature') {
      return selectedSealInfo.signatureName;
    } else if (selectedSealInfo.type === 'submittalReviewStamp') {
      return selectedSealInfo.sealName;
    }
  };

  return (
    <div className={s.contentPadding}>
      <Row>
        <Col sm={6} md={6} lg={4} xl={{ span: 4, offset: 0 }}>
          <p className={`${s.pages} ${s.marginBottom}`}>SealPact Pages</p>
          {confirmationValues.range && confirmationValues.range.length > 0 ? (
            <>
              <div className={s.leftContent}>
                <Form.Label>
                  <p className={s.pages}>Pages:</p>
                </Form.Label>
                <Form.Label className={s.valuesText}>
                  {confirmationValues.range.join(', ')}
                </Form.Label>
              </div>
            </>
          ) : (
            <>
              <div className={s.leftContent}>
                <Form.Label>
                  <p className={s.pages}>From:</p>
                </Form.Label>
                <Form.Label className={s.valuesText}>
                  {getValue(confirmationValues.pageFrom)}
                </Form.Label>
              </div>
              <div className={s.leftContent}>
                <Form.Label>
                  <p className={s.pages}>To:</p>
                </Form.Label>
                <Form.Label className={s.valuesText}>
                  {getValue(confirmationValues.pageTo)}
                </Form.Label>
              </div>
            </>
          )}
        </Col>

        <Col sm={6} md={6} lg={4} xl={{ span: 4, offset: 0 }}>
          <div className={s.leftContent}>
            <Form.Label>
              <p className={s.pages}>Name:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(getNameOfSeal(selectedSealInfo))}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label>
              <p className={s.pages}>License Number:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.licenseNo)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label>
              <p className={s.pages}>Firm Name:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.firmName)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label>
              <p className={s.pages}>Company Website:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.website)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label>
              <p className={s.pages}>Project Name:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.projectName)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label>
              <p className={s.pages}>Project Number:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.projectNumber)}
            </Form.Label>
          </div>

          <div className={s.leftContent}>
            <Form.Label>
              <p className={s.pages}>Project Address:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {getValue(confirmationValues.projectAddress)}
            </Form.Label>
          </div>
        </Col>

        <Col sm={6} md={6} lg={4} xl={{ span: 4, offset: 0 }}>
          <div className={s.leftContent}>
            <Form.Label>
              <p className={s.pages}>Date of SealPact:</p>
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {formatDate(Date.now())}
            </Form.Label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BasicTab;
