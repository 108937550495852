import React, { useState } from 'react';
import ButtonClose from '../../../assets/button-close.png';
import s from './style.scss';
import CreateNewSeal from '../MySeals/CreateNewSeal';
import CreateNewSignature from '../MySeals/CreateNewSignature';
import { Button, Tabs, Tab, Image } from 'react-bootstrap';
import ShowAllSeals from '../MySeals/ShowAllSeals';

const SideDrawer = ({
  drawerSlideOut,
  handleDrawer,
  getselectedSealInfo,
  allActiveAssets,
  activeSeals,
  activeSignatures,
  activeStamps,
  sealPacterType,
}) => {
  const [key, setKey] = useState(1);
  return (
    <div className={drawerSlideOut ? s.drawerAnimation : s.drawer}>
      <div className={s.blackOverlay} onClick={handleDrawer}>
        <Button variant="text" className="float-right" onClick={handleDrawer}>
          <Image src={ButtonClose} alt="close" />
        </Button>
      </div>
      <div className={s.drawerContent}>
        {/* <div className={s.flexRow}>
          <CreateNewSeal />
          <CreateNewSignature />
        </div> */}
        <Tabs
          id="docs-tab"
          activeKey={key}
          onSelect={(key) => setKey(key)}
          className={`docstable ${s.tabs}`}
        >
          {/* <Tab eventKey={1} title="All" className="docsTab">
            <div className={`tbl ${s.tblContainer}`}>
              <ShowAllSeals
                enableControl={false}
                getselectedSealInfo={getselectedSealInfo}
                assetArray={allActiveAssets}
                handleDrawer={handleDrawer}
              />
            </div>
          </Tab> */}
          {sealPacterType === 'seal' && (
            <Tab
              eventKey={1}
              title={
                <div className={'d-flex flex-column'}>
                  <p>Seals</p>
                  <p style={{ fontSize: '9px' }}>
                    Click on the Seal, Signature or Stamp that you would like to
                    use for this document. After Clicking, “drag and drop” it to
                    where you would like to place it on the artifact.
                  </p>
                </div>
              }
              className="docsTab"
            >
              <ShowAllSeals
                hideSealDeleteBtn={true}
                enableControl={false}
                getselectedSealInfo={getselectedSealInfo}
                assetArray={activeSeals}
                handleDrawer={handleDrawer}
              />
            </Tab>
          )}
          {sealPacterType === 'signature' && (
            <Tab
              eventKey={1}
              title={
                <div className={'d-flex flex-column'}>
                  <p>Signature</p>
                  <p style={{ fontSize: '9px' }}>
                    Click on the Seal, Signature or Stamp that you would like to
                    use for this document. After Clicking, “drag and drop” it to
                    where you would like to place it on the artifact.
                  </p>
                </div>
              }
              className="docsTab"
            >
              <ShowAllSeals
                hideSealDeleteBtn={true}
                enableControl={false}
                getselectedSealInfo={getselectedSealInfo}
                assetArray={activeSignatures}
                handleDrawer={handleDrawer}
              />
            </Tab>
          )}
          {sealPacterType === 'stamp' && (
            <Tab
              eventKey={1}
              title={
                <div className={'d-flex flex-column'}>
                  <p>Stamps</p>
                  <p style={{ fontSize: '9px' }}>
                    Click on the Seal, Signature or Stamp that you would like to
                    use for this document. After Clicking, “drag and drop” it to
                    where you would like to place it on the artifact.
                  </p>
                </div>
              }
              className="docsTab"
            >
              <ShowAllSeals
                hideSealDeleteBtn={true}
                enableControl={false}
                getselectedSealInfo={getselectedSealInfo}
                assetArray={activeStamps}
                handleDrawer={handleDrawer}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default SideDrawer;
