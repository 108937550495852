/* eslint-disable indent */
import React, { useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import {
  Row,
  ToggleButtonGroup,
  ToggleButton,
  Form,
  Col,
  Button,
} from 'react-bootstrap';
import s from './style.scss';
import style from '../style.scss';
import { FilePicker } from 'react-file-picker';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../../utils/constants';
import {
  SHOPDESIGNS,
  SHOP_DESIGNS_VALUES,
} from '../../../../../utils/constants';

const QualityControlTab = ({ confirmationValues }) => {
  console.log('confirmationValues in QA ===> ', confirmationValues);
  const renderValue = (arg) => {
    if (arg !== undefined) {
      if (typeof  arg === 'string')
        return arg;
      return arg === true ? 'Yes' : 'No';
    }
    return 'N/A'
  };

  return (
    <div className={s.contentPadding}>
      <Row>
        <Col lg={4}>
          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              Did the client and/or owner include quality control with this
              project?
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.qualityNeededWithDefault || renderValue(confirmationValues.qualityNeeded)}
            </Form.Label>
          </div>
        </Col>

        <Col lg={4}>
          <div className={s.displayBlock}>
            <div className={s.leftContent}>
              <Form.Label className={s.label}>
                Independent Review Required?
              </Form.Label>
              <Form.Label className={s.valuesText}>
                {confirmationValues.independentReviewWithDefault || renderValue(confirmationValues.independentReview)}
              </Form.Label>
            </div>

            <div className={s.leftContent}>
              <Form.Label className={s.label}>
                Are Shop Drawings Required? (stamped delegated/component
                designs?)
              </Form.Label>
              <Form.Label className={s.valuesText}>
                {confirmationValues.shopDrawingsWithDefault || renderValue(confirmationValues.shopDrawings)}
              </Form.Label>
            </div>

            <div className={s.leftContent}>
              <Form.Label className={s.label}>
                If so, which?
              </Form.Label>
              <Form.Label className={s.valuesText}>
                {confirmationValues.shopDrawingsValue
                  ? confirmationValues.shopDrawingsOtherValue
                    ? confirmationValues.shopDrawingsValue +
                    ',' +
                    confirmationValues.shopDrawingsOtherValue
                    : confirmationValues.shopDrawingsValue
                  : 'N/A'}
              </Form.Label>
            </div>

            <div className={s.leftContent}>
              <Form.Label className={s.label}>
                Construction Administration (CA):
              </Form.Label>
              <Form.Label className={s.valuesText}>
                {confirmationValues.constructionAdministrationWithDefault ||
                  renderValue(confirmationValues.constructionAdministration)}
              </Form.Label>
            </div>

            <div className={s.leftContent}>
              <Form.Label className={s.label}>Site Visits:</Form.Label>
              <Form.Label className={s.valuesText}>
                {confirmationValues.constructionAdministrationWithDefault
                  ? confirmationValues.constructionAdministrationWithDefault === 'Yes'
                    ? confirmationValues.siteVisits : 'N/A'
                  : confirmationValues.constructionAdministration ? confirmationValues.siteVisits : 'N/A'}
              </Form.Label>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className={s.leftContent}>
            <Form.Label className={s.label}>
              Special Inspections (SI):
            </Form.Label>
            <Form.Label className={s.valuesText}>
              {confirmationValues.specialInspectionWithDefault || renderValue(confirmationValues.specialInspection)}
            </Form.Label>
          </div>
          {confirmationValues.SIFormName && (
            <div className={s.leftContent}>
              <Form.Label className={s.label}>
                SI Form:
              </Form.Label>
              <Form.Label className={s.valuesText}>
                {confirmationValues.SIFormName}
              </Form.Label>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default QualityControlTab;
