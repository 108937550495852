import React from 'react';
import s from './style.scss';

const Spinner = (props) => {
  return (
    <div className={s.spinnerOuter}>
      <div className={s.spinnerInner}>
        <div className="spinner-border text-primary " role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
