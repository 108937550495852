/* eslint-disable indent */
import React, { Fragment, useState, useRef, useEffect } from 'react';
import Header from '../../Header';
import { Button, Row, Col, Tab, Nav, Image, Container } from 'react-bootstrap';
import s from './style.scss';
import style from '../DocumentDetails/OnConfirmation/style.scss';
import BasicTabInfo from './BasicTabInfo';
import AdvancedTabInfo from './AdvancedTabInfo';
import PrintBtn from '../../../assets/print-btn.png';
import QualityTabInfo from './QualityTabInfo';
import ContractualTabInfo from './ContractualTabInfo';
import PrintComponents from 'react-print-components';
import { withFirebase } from '../../../auth/firebase';
import ButtonCheck from '../../../assets/check-mark.png';
import DangerButton from '../../../assets/error-mark.png';
// import LogoBrand from '../../LogoBrand';
import Logo from '../../../assets/seal-logo.png';
import Spinner from '../../Layout/Spinner';
import GetUserEmailModal from './GetUserEmailModal';
import publicIp from 'public-ip';
import axios from 'axios';
import CustomTooltip from '../../AppComponents/CustomTooltip';
import { GOOGLE_API_KEY, NOTIFICATION_PROPS, SEND_EMAIL_API_URL } from '../../../utils/constants';
import moment from 'moment';
import {view} from 'react-easy-state';
import {appEasyState} from '../../../easyStateStore';
import { store } from 'react-notifications-component';

const SealPactedDetailsScreen = (props) => {
  const [selectedTab, setselectedTab] = useState(1);
  const [documentInformation, setDocumentInformation] = useState({});
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);

  const handleSelect = (key) => {
    setselectedTab(+key);
  };

  const getText = (heading, tooltipText) => {
    return (
      <div>
        <p>{heading}</p>
        <CustomTooltip
          trigger={'hover'}
          location={'auto'}
          content={tooltipText}
        >
          <i
            style={{
              marginLeft: '3px',
              marginBottom: '3px',
            }}
            className="fa fa-info-circle"
            aria-hidden="true"
          ></i>
        </CustomTooltip>
      </div>
    );
  };

  let optionsGeoLocation = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function successGeoLocation(pos) {
    let crd = pos.coords;

    console.log('***** pos: ', pos)

    console.log("***** Your current position is:");
    console.log(`***** Latitude : ${crd.latitude}`);
    console.log(`***** Longitude: ${crd.longitude}`);
    console.log(`***** More or less ${crd.accuracy} meters.`);

    let reverse_geocode_url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${crd.latitude},${crd.longitude}
  &location_type=ROOFTOP&result_type=street_address&key=${GOOGLE_API_KEY}`

    axios.get(reverse_geocode_url).then(response => {
      if (response) {
        if (response.data) {
          if (response.data.results) {
            if (response.data.results.length > 0) {
              if ( response.data.results[0].formatted_address) {
                console.log('***** response: ', response.data.results[0].formatted_address)
                appEasyState.capturedAddressSealScan =  response.data.results[0].formatted_address;
              }
            }
          }
        }
        else {
          store.addNotification({
            title: 'Error',
            message: "Coordinates location not found",
            type: 'warning',
            ...NOTIFICATION_PROPS
          })
        }
      }
      else {
        store.addNotification({
          title: 'Error',
          message: "Coordinates location not found",
          type: 'warning',
          ...NOTIFICATION_PROPS
        })
      }
    }).catch(function (error) {
      console.log(error);
      return;
    });

  }

  function errorsGeoLocation(err) {
    console.warn(`***** ERROR(${err.code}): ${err.message}`);
  }


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            console.log(result.state);
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(successGeoLocation);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(successGeoLocation, errorsGeoLocation, optionsGeoLocation);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      store.addNotification({
        title: 'Error',
        message: 'Location not available in your device',
        type: 'warning',
        ...NOTIFICATION_PROPS
      })
    }
  }, [])

  const componentRef = useRef();
  const handleSubscribe = (email) => {
    setShow(!show);
    (async () => {
      const scanDate = await new Date().getTime();
      const ip4 = await publicIp.v4();
      // const ip6 = await publicIp.v6();
      const docData = window.location.href.split('?');
      const docDetails = docData[1].split('&');
      const details = {
        ip4: ip4,
        scanDate: scanDate,
        // ip6: ip6,
      };

      if (appEasyState.capturedAddressSealScan !== null)
        details['capturedAddressSealScan'] = appEasyState.capturedAddressSealScan

      axios
        .post(
          // 'http://localhost:5001/sealpact-d669e/us-central1/sendSubscriberEmail',
          'https://us-central1-sealpact-d669e.cloudfunctions.net/sendSubscriberEmail',
          {
            sendEmailTo: documentInformation.sealPactOriginatorEmail,
            name: documentInformation.selectedSealInfo.sealName,
            firmName: documentInformation.firmName,
            website: documentInformation.website && documentInformation.website !== '' ? documentInformation.website : 'N/A',
            projectName: documentInformation.projectName,
            projectNumber: documentInformation.projectNumber,
            projectAddress: documentInformation.projectAddress,
            scannerDate: moment(details.scanDate).format('YYYY-MM-DD HH:mm'),

            scannerIP: details.ip4,
            scannerEmail: email ? email : 'Not Provided',
          },
        )
        .then(function (response) {
          console.log('response.scannerDate', response.scannerDate);

          console.log('email sent response ===>', response);
          return;
        })
        .catch((error) => {
          console.log(`error in sending  email => `, error);
          return;
        });

      if (email) {
        details.email = email;
        details.status = true;
        axios
          .post(SEND_EMAIL_API_URL, {
            projectName: documentInformation.projectName,
            projectAddress: documentInformation.projectAddress,
            projectNumber: documentInformation.projectNumber,
            designProfessionalFirm: documentInformation.firmName,
            designProfessionalWebsite: documentInformation.website && documentInformation.website !== '' ? documentInformation.website : 'N/A',
            designProfessional: documentInformation.name,
            status: documentInformation.status,
            invlidationReason: documentInformation.invalidationReason,
            userEmail: email,
            link:
              window.location.origin +
              '/unSubscribe?' +
              docDetails[1] +
              '&' +
              parseInt(documentInformation.id) +
              '&' +
              email,
          })
          .then(function (response) {
            console.log('email sent');
            return;
          })
          .catch((error) => {
            console.log(`error in sending  email => `, error);
            return;
          });
      }
      props.firebase.saveQrCodeScanDetails(
        parseInt(docDetails[0]),
        docDetails[1],
        documentInformation.status,
        details,
      );

      console.log('docDetails ===>', docDetails);
      console.log('documentInformation ===>', documentInformation);
      console.log('details ===>', details);
    })();
  };

  useEffect(() => {
    const docData = window.location.href.split('?');
    const docDetails = docData[1].split('&');

    props.firebase
      .getInvalidatedDocument(docDetails[1], parseInt(docDetails[0]))
      .then((invalidDocData) => {
        if (invalidDocData) {
          console.log(`invalidDocData ===> `, invalidDocData);
          setLoading(false);
          setDocumentInformation(invalidDocData);
        } else {
          props.firebase
            .getSealPactedDocument(docDetails[1], parseInt(docDetails[0]))
            .then((sealPactedData) => {
              console.log(`sealPactedData ===> =====`, sealPactedData);
              setLoading(false);
              setDocumentInformation(sealPactedData);
              return;
            })
            .catch((error) => {
              console.log('error ===> ', error);
            });
        }

        return;
      })
      .catch((error) => {
        console.log('error 1 ===> ', error);
        setLoading(false);
        return;
      });
  }, []);

  let imgStyle =
    documentInformation.status === 'pacted'
      ? ButtonCheck
      : documentInformation.status === 'invalidated'
      ? DangerButton
      : null;

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className={s.sealpactDetailsHeading}>
        <Image
          className={s.sealpactLogo}
          height={45}
          width={45}
          src={Logo}
          alt="logo-sealpact"
        />
        <span className={s.sealpactDetailsText}>
          SealPact Validation Status
        </span>
      </div>

      <div className={s.topHeader}>
        <div>
          {documentInformation.status === 'pacted' ? (
            <div className={s.content}>
              <p>
                <Image width={35} className={s.icon} src={imgStyle} />
                <span>Validated</span>
              </p>
              <p className={s.sealPactIdStyle}>
                SealPact id:{' '}
                {documentInformation ? documentInformation.sealpactId : null}
              </p>
              <ul>
                <li>The digital signature keys are unique to the licensee</li>
                {/* <li>
                  The document has not been altered subsequent to the SealPact
                </li> */}
                <li>
                  The private key used for signing electronic documents is under
                  the licensee’s direct and exclusive control and has not been
                  manually invalidated by the licensee
                </li>
                <li>
                  <a href={'https://originstamp.com/verify/'} target={'_'}>
                    Click Here to see if this document has been modified
                  </a>
                </li>
              </ul>
              <p>
                Note: the checkmark above confirms that this document is valid
                and active per the <br />
                signer. Clicking this link will allow you to verify that the
                document has not been <br />
                tampered with after it was digitally signed with SealPact. No
                additional software <br />
                downloads or special readers are needed to verify - it's totally
                free! Any browser <br />
                with an internet connection should work.
              </p>
            </div>
          ) : (
            <div className={s.content}>
              <p>
                <Image width={35} className={s.icon} src={imgStyle} />
                <span>Invalidated</span>
              </p>

              <p className={s.sealPactIdStyle}>
                SealPact id:{' '}
                {documentInformation ? documentInformation.sealpactId : null}
              </p>
              <ul>
                <li>The digital signature keys are unique to the licensee</li>
                {/* <li>
                  The document has not been altered subsequent to the SealPact
                </li> */}
                <li>
                  <b style={{ color: 'red' }}>
                    The private key used for signing electronic documents is
                    under the licensee’s direct and exclusive control and has
                    been manually invalidated by the licensee
                  </b>
                </li>
                <li className={s.reasonText}>
                  <b style={{ color: 'red' }}>
                    Reason: {documentInformation.invalidationReason}
                  </b>
                </li>
              </ul>
            </div>
          )}
          <div className={s.projectDetails}>
            <span> Project Details</span>
          </div>
          {/*{documentInformation.status !== 'invalidated' && (*/}
          {/*  <div className={s.sealId}>*/}
          {/*    {documentInformation.sealPactDownloadUrl ? (*/}
          {/*      <a href={documentInformation.sealPactDownloadUrl}>*/}
          {/*        View SealPact Document*/}
          {/*      </a>*/}
          {/*    ) : (*/}
          {/*      <p>Document has been deleted by the Design Professional.</p>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*)}*/}

          <PrintComponents
            trigger={
              <Button variant="text" className={s.printBtn}>
                <Image src={PrintBtn} alt="print-btn" />
              </Button>
            }
          >
            <h3>Basic</h3>
            <span>SealPact ID:</span>
            <div className={s.sealId}>{documentInformation.sealpactId}</div>
            <br />
            <BasicTabInfo
              documentInformation={documentInformation}
              confirmationValues={documentInformation}
            />
            <div className={s.pageBreak}>
              <h3>Advanced</h3>
              <AdvancedTabInfo confirmationValues={documentInformation} />
            </div>
            <div className={s.pageBreak}>
              <h3>Quality Control</h3>
              <QualityTabInfo confirmationValues={documentInformation} />
            </div>
            {/* <div className={s.pageBreak}>
              <h3>Contractual Info</h3>
              <ContractualTabInfo confirmationValues={documentInformation} />
            </div> */}
          </PrintComponents>
        </div>
        <div />
      </div>
      <div className={s.separator} />
      <div className={s.tabsContent} ref={componentRef}>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={1}
          activeKey={selectedTab}
          onSelect={(key) => handleSelect(key)}
        >
          <Row>
            <Col sm={12}>
              <Row className={`${style.tabRow} ${s.tabRow}`}>
                <Col sm={12}>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey={1}>
                        {' '}
                        {getText(
                          '1-Basic',
                          'Items on this page were required by the user. This is public information and will be shown to anyone who has the document.',
                        )}{' '}
                      </Nav.Link>
                    </Nav.Item>
                    <div className={s.spaceDiv} />
                    <Nav.Item>
                      <Nav.Link eventKey={2}>
                        {' '}
                        {getText(
                          '2-Advanced',
                          'Items on this page were voluntary by the user.  Here’s where more detailed scope items are identified.',
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <div className={s.spaceDiv} />
                    <Nav.Item>
                      <Nav.Link eventKey={3}>
                        {' '}
                        {getText(
                          '3-Quality Control',
                          'Items on this page were voluntary by the user.  Here’s where quality control items, such as site visits, CA/SI, shop drawing requirements, etc. are displayed.',
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    {/* <div className={s.spaceDiv} />
                    <Nav.Item>
                      <Nav.Link eventKey={4}>4-Contractual Info</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey={1}>
                  <BasicTabInfo
                    documentInformation={documentInformation}
                    confirmationValues={documentInformation}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={2}>
                  <AdvancedTabInfo confirmationValues={documentInformation} />
                </Tab.Pane>
                <Tab.Pane eventKey={3}>
                  <QualityTabInfo confirmationValues={documentInformation} />
                </Tab.Pane>
                <Tab.Pane eventKey={4}>
                  <ContractualTabInfo
                    confirmationValues={documentInformation}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      <GetUserEmailModal
        show={show}
        handleSubscribe={handleSubscribe}
        // saveUserDetails={saveUserDetails}
        {...props}
      />
    </Fragment>
  );
};

export default withFirebase(view(SealPactedDetailsScreen));
