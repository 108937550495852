/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import s from './style.scss';
import { view } from 'react-easy-state';
import { LinkAuthenticationElement, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';


const CheckoutForm = (props) => {
  const { setShowPlanDetails, setShowPaymentMethod, customer, user, firebase, stripePromise } = props
  const stripe = useStripe();
  const elements = useElements()
  const [loading, setLoading] = useState(false),
    [name, setName] = useState([user.firstName, user.middleName, user.lastName].filter(Boolean).join(' ')),
    [phone, setPhone] = useState('+1' + user.phoneNumber)

  useEffect(() => {
    if (customer) {
      if (customer.name) setName(customer.name);
      if (customer.phone) setPhone(customer.phone);
    }
  }, [])

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    console.log('elements', elements)
    debugger
    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + '/subscription',
      }
    })

    console.log('card error', error)

    setLoading(false)
    if (error.message) {
      store.addNotification({
        title: 'Error!',
        message: error.message,
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
    }

  };


  return (
    <form id='payment-form' onSubmit={handlePaymentSubmit}>
      <Card className={s.confirmPlanCard}>
        <Card.Title className={s.title}>
          Add Payment Method
        </Card.Title>
        <Card.Subtitle>
          <small className={s.paymentCardDescription}  style={{ color: 'firebrick' }}>
            Adding a payment method will incur a $0.50 processing fee to ensure valid card information provided.
          </small>
        </Card.Subtitle>
        <Card.Body style={{ padding: '16px 0 0 0', width: '100%' }}>
          <LinkAuthenticationElement id='link-authentication-element'
             options={{ defaultValues: { email: customer.email } }}
          />
          <PaymentElement id='payment-element'
              options={{
                defaultValues: {
                  billingDetails: {
                    name: name,
                    email: customer.email,
                    phone: phone,
                  },
                },
              }}
          />
        </Card.Body>
        <Card.Footer style={{ padding: '0 0 0 0', background: 'white', width: '100%', border: 'none' }}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <Button
              variant="primary"
              onClick={() => {
                setShowPlanDetails(true)
                setShowPaymentMethod(false)
              }}
              className={s.goBack}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className={s.confirmButton}
              type='submit'
            >
              {!loading ? 'Add' : (
                <>
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                  Adding
                </>
              )}
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </form>
  );
};

export default view(CheckoutForm);