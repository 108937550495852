/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withFirebase } from '../../../auth/firebase';
import withAuthorization from '../../../auth/session/withAuthorization';
import Spinner from '../../Layout/Spinner';
import crypto, { privateEncrypt } from 'crypto';
import {
  SERVICE_BOT_SECRECT_KEY,
  CREATE_CUSTOMER_PORTAL_LINK,
  GET_STRIPE_PRODUCTS,
  CREATE_STRIPE_SUBSCRIPTION,
  CARD_SETUP,
  GET_CUSTOMER,
  STRIPE_PUBLISHED_KEY,
  PREVIEW_PRORATION,
  LIST_PAYMENT_METHODS,
  NOTIFICATION_PROPS, ATTACH_PAYMENT_METHOD, NOTIFICATION_PROPS_LONGER, UPDATE_SUBSCRIPTION, APPLY_COUPON_CODE,
} from '../../../utils/constants';
import axios from 'axios';
import {view} from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import Header from '../../Header';
import s from './style.scss';
import Plans from './Plans';
import PlanDetails from './PlanDetails';
import PlansForUpdate from './PlansForUpdate';
import PlansForUpdateConfirm from './PlansForUpdateConfirm';
import PaymentMethodAdd from './PaymentMethodAdd';
import BillingInformationAdd from './BillingInformationAdd';
import { loadStripe } from '@stripe/stripe-js';
import { store } from 'react-notifications-component';


const SubscriptionPortal = (props) => {
  const { user, firebase, reloadUser } = props
  const [loading, setLoading] = useState(true),
    [prices, setPrices] = useState([]),
    [products, setProducts] = useState([]),
    [showPlanDetails, setShowPlanDetails] = useState(false),
    [showUpdatePlan, setShowUpdatePlan] = useState(false),
    [showUpdatePlanConfirm, setShowUpdatePlanConfirm] = useState(false),
    [showPaymentMethod, setShowPaymentMethod] = useState(false),
    [showBillingInfo, setShowBillingInfo] = useState(false),
    [stripePromise, setStripePromise] = useState(null),
    [customerId, setCustomerId] = useState(null),
    [subscriptionId, setSubscriptionId] = useState(null),
    [customer, setCustomer] = useState({}),
    [updateCustomer, setUpdateCustomer] = useState(true),
    [confirmationPlanName, setConfirmationName] = useState(null),
    [confirmationPlan, setConfirmationPlan] = useState({}),
    [proration, setProration] = useState({}),
    [clickedPlanId, setClickedPlanId] = useState(null),
    [updatePlanLoading, setUpdatePlanLoading] = useState(false)

  useEffect(() => {
    loadStripe(STRIPE_PUBLISHED_KEY).then((stripe) => {
      console.log('loadStripe', stripe)
      setStripePromise(stripe);
    })
  }, []);

  useEffect(() => {
    const docRef = firebase.userRef(user.uid)
    const updatedSubscription = docRef.onSnapshot(async (doc) => {
      if (doc.exists) {
        const subscriptionUpdated = doc.data().subscriptionUpdated
        if (user.subscriptionUpdated !== subscriptionUpdated) {
          await reloadUser()
          window.location.reload(false);
        }
      }
    })

    return () => updatedSubscription();
  }, [])

  useEffect( () => {
    if (!stripePromise) return;

    const url = new URL(window.location);
    const clientSecret = url.searchParams.get('payment_intent_client_secret');
    if (clientSecret) {
      stripePromise.retrievePaymentIntent(clientSecret).then(paymentIntent => {
        if (paymentIntent && paymentIntent.paymentIntent) {
          const messageIndentId = localStorage.getItem('messageIndentId')
          console.log('messageIndentId', messageIndentId)
          if (!messageIndentId || messageIndentId === 'null' ||
            messageIndentId.localeCompare(paymentIntent.paymentIntent.id) !== 0) {
            console.log('paymentIntent.paymentIntent', paymentIntent.paymentIntent)
            localStorage.setItem('messageIndentId', paymentIntent.paymentIntent.id)
            if (paymentIntent.paymentIntent.payment_method) {
              axios.post(ATTACH_PAYMENT_METHOD, {
                customerId,
                paymentMethodId: paymentIntent.paymentIntent.payment_method,
                subscriptionId,
              }).then(response => {
                if (response && response.data && response.data.success) {
                  setCustomer(response.data.data)
                  store.addNotification({
                    title: 'Added Successfully!',
                    message: 'Payment method added successfully. If there is any outstanding invoice please pay in the Invoice History section below.',
                    type: 'default',
                    ...NOTIFICATION_PROPS_LONGER,
                  });
                }
              }).catch(err => {})
            }
          }
        }
      }).catch(error => {
        store.addNotification({
          title: 'Error!',
          message: error.message,
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
      });
    }
  }, [stripePromise]);

  let email_hash;
  useEffect(() => {
    console.log('user.subscriptionDetails', user.subscriptionDetails);

    if (user && user.subscriptionDetails) {
      let customerId;
      if (
        user.subscriptionDetails.customer &&
        typeof user.subscriptionDetails.customer !== 'object'
      ) {
        customerId = user.subscriptionDetails.customer;
      } else if (
        user.subscriptionDetails.customer &&
        typeof user.subscriptionDetails.customer === 'object'
      ) {
        customerId = user.subscriptionDetails.customer.id;
      } else if (user.subscriptionDetails.id) {
        customerId = user.subscriptionDetails.id;
      } else {
        customerId = user.subscriptionDetails.customer.id;
      }

      appEasyState.customerId = customerId
      appEasyState.subscriptionId = subscriptionId
      setCustomerId(customerId)
      setSubscriptionId(user.subscriptionDetails.id)

    }

    axios
      .post(
        GET_STRIPE_PRODUCTS
      ).then(response => {

        if (response && response.data) {
          const result = response.data

          if (result && result.prices && result.prices.data) {
            const _prices = result.prices.data.filter((price) => {
              return price.recurring.trial_period_days != null && price.active;
            });

            setPrices(_prices)
          }

          if (result && result.products && result.products.data) {
            setProducts(result.products.data)
          }

          if (!user.subscriptionDetails || (user.subscriptionDetails && (user.subscriptionDetails.customer || user.subscriptionDetails.id)
            && Object.keys(user.subscriptionDetails).length === 1)) {
            setLoading(false)
          }

          if (user.subscriptionDetails && Object.keys(user.subscriptionDetails).length > 1) {
            axios
              .post(
                CREATE_CUSTOMER_PORTAL_LINK,
                {
                  customer_id: user.subscriptionDetails.customer.id
                    ? user.subscriptionDetails.customer.id
                    : user.subscriptionDetails.customer,
                },
              ).then(customerPortalResponse => {
                console.log('customerPortalResponse', customerPortalResponse);
                console.log(user.subscriptionDetails.customer, customerPortalResponse);
                if (customerPortalResponse !== undefined) {

                  // window.location.replace(customerPortalResponse.data.url);
                  setShowPlanDetails(true)
                  setLoading(false)

                }
              })
              .catch((err) => {
                console.log(err);
              });

          }

          email_hash = crypto
            .createHmac(
              'sha256',
              SERVICE_BOT_SECRECT_KEY, // SECRET KEY (KEEP SAFE!)
            )
            .update(user.email)
            .digest('hex');
        }

      })
      .catch((err) => {
        console.log('error stripe =======> ', err);
      })

  }, [])

  useEffect(() => {
    if (customerId && updateCustomer) {
      axios.post(GET_CUSTOMER, { customerId: customerId })
        .then(response => {
          if (response && response.data && response.data.customer) {
            console.log('get customer response.data.customer', response.data.customer)
            setCustomer(response.data.customer)
            setUpdateCustomer(false)
          }
        }).catch(err => {
        console.log('get customer err', err)
      })
    }
  }, [updateCustomer, customerId])

  const addUserPaymentDetails = async (userPaymentDetails) => {
    await firebase.addUserSubscriptionDetails(
      user.uid,
      userPaymentDetails,
    );
  };

  const createSubscription = async (plan, name = null, promotionCode = '') => {
    setLoading(true)
    let result = (
      await axios
        .post(
          CREATE_STRIPE_SUBSCRIPTION,
          {
            email: user.email,
            phoneNumber: user.phoneNumber,
            priceId: plan.id,
            customer: user.subscriptionDetails
              ? user.subscriptionDetails.customer
                ? user.subscriptionDetails.customer
                : user.subscriptionDetails.id
                  ? user.subscriptionDetails.id
                  : undefined
              : undefined,
            coupon: promotionCode !== '' ? promotionCode : null,
          },
        )
        .catch((err) => {
          console.log('sub err', err);
          setLoading(false)
          store.addNotification({
            title: 'Error!',
            message: err.message || 'Subscription created unsuccessfully.',
            type: 'danger',
            ...NOTIFICATION_PROPS_LONGER,
          });
        })
    );

    console.log({ result });
    debugger
    if (result && result.data && result.data.success) {
      result = result.data.data

      await addUserPaymentDetails(result);

      setTimeout(() => {
        localStorage.setItem('planSubscribed', 'true')
        // this.setState({ loading: false });

        if (user.isOnboardingCompleted !== undefined) {
          localStorage.setItem('onboardingCompleted', user.isOnboardingCompleted)
          if (user.isOnboardingCompleted === false) {
            localStorage.setItem('userId', user.uid)
            appEasyState.onboardingCompleted = 'false'
            window.location.href = 'account'
          }
          else {
            window.location.reload()
          }

        }
        else {
          window.location.reload()
        }

      }, 3000);
    }
    else {
      setLoading(false)
      store.addNotification({
        title: 'Error!',
        message: result.data.message || 'Subscription created unsuccessfully.',
        type: 'danger',
        ...NOTIFICATION_PROPS_LONGER,
      });
    }
  }

  function getProductName(id) {
    for (let product of products)
      if (product.id.localeCompare(id) === 0) return product.name

    return null
  }

  function handleUpdatePlan(plan, name, promotionCode = '') {
    setClickedPlanId(plan.id)
    setUpdatePlanLoading(true)
    setConfirmationPlan(plan)
    setConfirmationName(name)

    console.log('handleUpdatePlan newPlanId', plan.id)
    console.log('handleUpdatePlan ser.subscriptionDetails.id', user.subscriptionDetails.id)
    console.log('handleUpdatePlan customerId', customerId)

   axios.post(PREVIEW_PRORATION, {
     customerId: customerId,
     subscriptionId: user.subscriptionDetails.id,
     newPlanId: plan.id,
    }).then(resp => {
      console.log('preview proration resp', resp.data)

     if (resp && resp.data) {
       setProration(resp.data)

       setUpdatePlanLoading(false)
       setShowUpdatePlanConfirm(true)
       setShowUpdatePlan(false)
     }

    }).catch(err => {
      setUpdatePlanLoading(false);
      console.log('handleUpdatePlan err', err)
    })
  }

  async function applyPromotionCode(promotionCode) {
    await axios.post(APPLY_COUPON_CODE, {
      subscriptionId,
      couponCode: promotionCode
    }).then(resp => {
      console.log('applyPromotionCode resp', resp.data)

      if (resp && resp.data) {

        if (resp.data.success) {
          store.addNotification({
            title: 'Updated Successfully!',
            message: resp.data.message,
            type: 'default',
            ...NOTIFICATION_PROPS_LONGER,
          });
        }
        else {
          store.addNotification({
            title: 'Error!',
            message: resp.data.message,
            type: 'danger',
            ...NOTIFICATION_PROPS_LONGER,
          });
        }

      }

    }).catch(err => {
      console.log('applyPromotionCode err', err)
      store.addNotification({
        title: 'Error!',
        message: err.message,
        type: 'danger',
        ...NOTIFICATION_PROPS_LONGER,
      });
    })
  }


  return loading ? <Spinner/> : (
    <React.Fragment>
      {(!showPlanDetails && !user.subscriptionDetails || (user.subscriptionDetails && (user.subscriptionDetails.customer || user.subscriptionDetails.id)
        && Object.keys(user.subscriptionDetails).length === 1)) && (
          <div>
            <Header title="Subscription" />
            <div className={s.appContainer}>
              <Plans
                prices={prices}
                getProductName={getProductName}
                createSubscription={createSubscription}
                {...props}
              />
            </div>
          </div>
      )}

      {showPlanDetails && (
        <div>
          <Header title="Plan Details" />
          <div className={s.appContainer}>
            <PlanDetails
              getProductName={getProductName}
              setShowPlanDetails={setShowPlanDetails}
              setShowUpdatePlan={setShowUpdatePlan}
              setShowPaymentMethod={setShowPaymentMethod}
              setShowBillingInfo={setShowBillingInfo}
              customer={customer}
              {...props}
            />
          </div>
        </div>
      )}

      {showUpdatePlan && (
        <div>
          <Header
            title="Update Plan"
            backButtonFunc={() => {
              setShowPlanDetails(true)
              setShowUpdatePlan(false)
            }}
          />
          <div className={s.appContainer}>
            <PlansForUpdate
              prices={prices}
              getProductName={getProductName}
              handleUpdatePlan={handleUpdatePlan}
              currentPlanId={user.subscriptionDetails.plan.id}
              updatePlanLoading={updatePlanLoading}
              clickedPlanId={clickedPlanId}
              applyPromotionCode={applyPromotionCode}
              {...props}
            />
          </div>
        </div>
      )}

      {showUpdatePlanConfirm && (
        <div>
          <Header
            title="Confirm Plan"
            backButtonFunc={() => {
              setShowUpdatePlan(true)
              setShowUpdatePlanConfirm(false)
            }}
          />
          <div className={s.appContainer}>
            <PlansForUpdateConfirm
              setShowUpdatePlan={setShowUpdatePlan}
              setShowUpdatePlanConfirm={setShowUpdatePlanConfirm}
              setShowPlanDetails={setShowPlanDetails}
              confirmationPlanName={confirmationPlanName}
              confirmationPlan={confirmationPlan}
              proration={proration}
              subscriptionId={subscriptionId}
              firebase={firebase}
              user={user}
              reloadUser={reloadUser}
              {...props}
            />
          </div>
        </div>
      )}

      {showPaymentMethod && (
        <div>
          <Header
            title="Payment Method"
            backButtonFunc={() => {
              setShowPlanDetails(true)
              setShowPaymentMethod(false)
            }}
          />
          <div className={s.appContainer}>
            <PaymentMethodAdd
              setShowPlanDetails={setShowPlanDetails}
              setShowPaymentMethod={setShowPaymentMethod}
              customer={customer}
              user={user}
              firebase={firebase}
              stripePromise={stripePromise}
              {...props}
            />
          </div>
        </div>
      )}

      {showBillingInfo && (
        <div>
          <Header
            title="Billing Information"
            backButtonFunc={() => {
              setShowPlanDetails(true)
              setShowBillingInfo(false)
            }}
          />
          <div className={s.appContainer}>
            <BillingInformationAdd
              setShowPlanDetails={setShowPlanDetails}
              setShowBillingInfo={setShowBillingInfo}
              user={user}
              firebase={firebase}
              customer={customer}
              setUpdateCustomer={setUpdateCustomer}
              {...props}
            />
          </div>
        </div>
      )}



    </React.Fragment>
  )
}

const condition = (user) => !!user;
export default withAuthorization(condition)(withFirebase(view(SubscriptionPortal)));
