import React, { useState, useReducer, useEffect } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import s from './style.scss';
import EditField from './EditField';
import EditDetails from './EditDetails';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';
import Spinner from '../../Layout/Spinner';
import { withFirebase } from '../../../auth/firebase';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';

const DetailsTable = ({ user, firebase, reloadUser, setValue, setLoading }) => {
  console.log('user in details table => ', user);
  const [userData, setUserData] = useState(user || {})
  const [residenceState, setResidenceState] = useState('');
  const [discipline, setDiscipline] = useState(user.discipline);
  const [concentration, setConcentration] = useState(
    user.discipline
      ? user.discipline !== 'landSurveyor'
        ? user.concentration
        : 'N/A'
    : 'N/A');
  const [edit, setedit] = useState(false);
  const [editDiscipline, setEditDiscipline] = useState(false);
  const [editConcentration, setEditConcentration] = useState(false);
  const [field, setField] = useState('');
  const [states, setStates] = useState([]);
  // const [canadianStates, setCanadianStates] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [concentrations, setConcentrations] = useState([]);
  const [editDetails, setEditDetails] = useState(false);

  useEffect(() => {
    console.log('detailsTable user', user)
    firebase.getStates().then((querySnapshot) => {
      if (querySnapshot) {
        const statesArr = [];
        querySnapshot.forEach((doc) => {
          statesArr.push({ label: doc.label, value: doc.value });
        });
        setStates(statesArr);
      }
    });

    // firebase.getCanadianStates().then((querySnapshot) => {
    //   const canadianStatesArr = [];
    //   querySnapshot.forEach((doc) => {
    //     canadianStatesArr.push({ label: doc.label, value: doc.value });
    //   });
    //   setCanadianStates(canadianStatesArr);
    // });

    firebase.getDisciplines().then((querySnapshot) => {
      if (querySnapshot) {
        const disciplinesArr = [];
        querySnapshot.forEach((doc) => {
          disciplinesArr.push({ label: doc.label, value: doc.value });
        });
        setDisciplines(disciplinesArr);
      }
    });

    if (discipline) {
      firebase.getConcentrations(discipline).then((concentrations) => {
        setConcentrations(concentrations);
      });
    }
  }, [firebase]);

  // useEffect(() => {
  //   firebase.updateUser(user.uid, { discipline: discipline });
  //   firebase.getConcentrations(discipline).then(concentrations => {
  //     setConcentrations(concentrations);
  //   });
  // }, [discipline, firebase, user]);

  // useEffect(() => {
  //   firebase.updateUser(user.uid, { concentration: concentration });
  // }, [concentration, firebase, user]);

  // useEffect(() => {
  //   firebase.updateUser(user.uid, { state: residenceState });
  // }, [firebase, residenceState, user]);

  const updateState = () => {
    firebase.updateUser(user.uid, { state: residenceState });
    setResidenceState('');
  };

  const updateDiscipline = () => {
    firebase.updateUser(user.uid, { discipline: discipline });
    firebase.getConcentrations(discipline).then((concentrations) => {
      console.log('concentrations: ************', concentrations)
      setConcentrations(concentrations);
    });
    setEditDiscipline(false);
  };

  const updateConcentration = () => {
    firebase.updateUser(user.uid, { concentration: concentration });
    setEditConcentration(false);
  };

  const makeEdit = (arg) => {
    setField(arg);
    setedit(true);
  };
  const [userInput, setUserInput] = useState({
      municipality: appEasyState.userObject.municipality || 'N/A',
      companyName: appEasyState.userObject.firmName,
      website: appEasyState.userObject.website || 'N/A',
      email: appEasyState.userObject.email,
      secondaryEmail: appEasyState.userObject.secondaryEmail || 'N/A',
      adminEmail: appEasyState.userObject.adminEmail,
      phoneNumber: appEasyState.userObject.phoneNumber,
      address: appEasyState.userObject.address || 'N/A',
      discipline: appEasyState.userObject.discipline,
      liability: appEasyState.userObject.otherLiability !== undefined && appEasyState.userObject.liability !== undefined && appEasyState.userObject.liability === 'Other'
        ? appEasyState.userObject.otherLiability
          ? appEasyState.userObject.otherLiability
          : 'N/A'
        : appEasyState.userObject.liability
          ? appEasyState.userObject.liability
          : 'N/A',
      companyEmail: appEasyState.userObject.companyEmail,
      state: appEasyState.userObject.state ? appEasyState.userObject.state !== 'Other' ? appEasyState.userObject.state : appEasyState.userObject.otherState ? appEasyState.userObject.otherState : 'N/A' : 'N/A',
      concentration: appEasyState.userObject.discipline
        ? appEasyState.userObject.discipline !== 'landSurveyor'
          ? appEasyState.userObject.concentration && appEasyState.userObject.concentration !== 'Other'
            ? appEasyState.userObject.concentration
            : appEasyState.userObject.otherConcentration
          : 'N/A'
        : 'N/A',
      zipCode: appEasyState.userObject.zipCode || 'N/A',
    }
  );

  const saveChanges = (name) => {
    if (name === 'discipline') {
      console.log('testing discipline :D:D::D ');
      updateDiscipline();
    }

    // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // if (
    //   name === 'companyEmail' &&
    //   !re.test(String(userInput[name]).toLowerCase())
    // ) {
    //   store.addNotification({
    //     title: 'Error',
    //     message: 'Please enter the company email in correct format.',
    //     type: 'danger',
    //     ...NOTIFICATION_PROPS,
    //   });
    //   return;
    // }
    // if (
    //   name === 'secondaryEmail' &&
    //   !re.test(String(userInput[name]).toLowerCase())
    // ) {
    //   store.addNotification({
    //     title: 'Error',
    //     message: 'Please enter the secondary email in correct format.',
    //     type: 'danger',
    //     ...NOTIFICATION_PROPS,
    //   });
    //   return;
    // }
    // setedit(false);
    // firebase
    //   .updateUser(user.uid, { [name]: userInput[name] })
    //   .then((updatedUser) => {
    //     reloadUser();
    //     return;
    //   })
    //   .catch((error) => {
    //     console.log('error', error);
    //   });
  };

  const handleChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({ [name]: newValue });
  };

  const toggleEditDetails = () => {
    setEditDetails(!editDetails);
  };

  useEffect(() => {
    if (!editDetails) {
      setUserInput({
        municipality: appEasyState.userObject.municipality || 'N/A',
        companyName: appEasyState.userObject.firmName,
        website: appEasyState.userObject.website || 'N/A',
        email: appEasyState.userObject.email,
        secondaryEmail: appEasyState.userObject.secondaryEmail || 'N/A',
        adminEmail: appEasyState.userObject.adminEmail,
        phoneNumber: appEasyState.userObject.phoneNumber,
        address: appEasyState.userObject.address || 'N/A',
        discipline: appEasyState.userObject.discipline,
        liability: appEasyState.userObject.otherLiability !== undefined && appEasyState.userObject.liability !== undefined && appEasyState.userObject.liability === 'Other'
          ? appEasyState.userObject.otherLiability
            ? appEasyState.userObject.otherLiability
            : 'N/A'
          : appEasyState.userObject.liability
            ? appEasyState.userObject.liability
            : 'N/A',
        companyEmail: appEasyState.userObject.companyEmail,
        state: appEasyState.userObject.state ? appEasyState.userObject.state !== 'Other' ? appEasyState.userObject.state : appEasyState.userObject.otherState ? appEasyState.userObject.otherState : 'N/A' : 'N/A',
        concentration: appEasyState.userObject.discipline
          ? appEasyState.userObject.discipline !== 'landSurveyor'
            ? appEasyState.userObject.concentration && appEasyState.userObject.concentration !== 'Other'
              ? appEasyState.userObject.concentration
              : appEasyState.userObject.otherConcentration
            : 'N/A'
          : 'N/A',
        zipCode: appEasyState.userObject.zipCode || 'N/A',
      })

    }
  }, [editDetails]);

  return (
    <Table responsive className={s.tbl}>
      <tbody>
        <tr className={'align-items-right'}>
          <td className={s.editTd} colSpan={5}>
            <Button onClick={() => setEditDetails(true)} variant="transparent">
              Edit
            </Button>
          </td>
        </tr>
        <EditField
          label="Company"
          edit={edit}
          fieldName="companyName"
          field={field}
          userInput={userInput.companyName}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          firebase={firebase}
          saveChanges={saveChanges}
        />
        <EditField
          label="Website"
          edit={edit}
          fieldName="website"
          field={field}
          userInput={userInput.website || ''}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          firebase={firebase}
          saveChanges={saveChanges}
        />

        <EditField
          label="Primary Email"
          edit={edit}
          fieldName="email"
          field={field}
          userInput={userInput.email}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />
        <EditField
          label="Secondary Email"
          edit={edit}
          fieldName="secondaryEmail"
          field={field}
          userInput={userInput.secondaryEmail}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />

        <EditField
          label="Administrator Email"
          edit={edit}
          fieldName="adminEmail"
          field={field}
          userInput={userInput.adminEmail}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />

        <EditField
          label="Phone Number"
          edit={edit}
          fieldName="phoneNumber"
          field={field}
          userInput={'+1' + userInput.phoneNumber}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />
        <EditField
          label="Street Address"
          edit={edit}
          fieldName="address"
          field={field}
          userInput={userInput.address}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />
        <EditField
          label="City"
          edit={edit}
          fieldName="municipality"
          field={field}
          userInput={userInput.municipality}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />

        <EditField
          label="State"
          edit={edit}
          fieldName="state"
          field={field}
          userInput={userInput.state}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />

        <EditField
          label="Zip Code"
          edit={edit}
          fieldName="zipCode"
          field={field}
          userInput={userInput.zipCode}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />

        {/* <tr>
          <th>State</th>

          <td colSpan={2} className={s.tdRow}>
            <Form.Control
              as="select"
              disabled={!edit}
              className={s.dropdown}
              onChange={(e) => setResidenceState(e.target.value)}
            >
              <option value="" selected disabled>
                Select State
              </option>
              {states.map((st, i) => (
                <option
                  key={i}
                  value={st.value}
                  selected={st.value === user.state ? 'selected' : null}
                >
                  {st.label}
                </option>
              ))}
            </Form.Control>
          </td>
          <td>
            {residenceState ? (
              <Button variant="transparent" onClick={() => updateState()}>
                Save
              </Button>
            ) : null}
          </td>
        </tr> */}

        <EditField
          label="Discipline"
          edit={edit}
          fieldName="discipline"
          field={field}
          userInput={disciplines.map((st, i) =>
            st.value === userInput.discipline ? st.label : null,
          )}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />
        {/* <tr>
          <th>Discipline</th>
          <td colSpan={2} className={s.tdRow}>
            <Form.Control
              as="select"
              className={s.dropdown}
              onChange={(e) => {
                setDiscipline(e.target.value);
                setEditDiscipline(true);
              }}
            >
              <option value="" selected disabled>
                Select Discipline
              </option>
              {disciplines.map((discipline, i) => (
                <option
                  key={i}
                  value={discipline.value}
                  selected={
                    discipline.value === user.discipline ? 'selected' : null
                  }
                >
                  {discipline.label}
                </option>
              ))}
              ;
            </Form.Control>
          </td>
          <td>
            {editDiscipline ? (
              <Button variant="transparent" onClick={() => updateDiscipline()}>
                Save
              </Button>
            ) : null}
          </td>
        </tr> */}

        <EditField
          label="Concentration"
          edit={edit}
          fieldName="concentration"
          field={field}
          userInput={
            userInput.concentration
              ? userInput.concentration
              : userInput.otherConcentration
          }
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />
        {/* <tr>
          <th>Concentration</th>
          <td colSpan={2} className={s.tdRow}>
            <Form.Control
              as="select"
              className={s.dropdown}
              onChange={(e) => {
                setConcentration(e.target.value);
                setEditConcentration(true);
              }}
            >
              <option value="" selected disabled>
                Select Concentration
              </option>
              {concentrations.map((concentration, i) => (
                <option
                  key={i}
                  value={concentrations[i]}
                  selected={
                    concentrations[i] === user.concentration ? 'selected' : null
                  }
                  updateProfilePicture
                >
                  {concentration}
                </option>
              ))}
              ;
            </Form.Control>
          </td>
          <td>
            {editConcentration ? (
              <Button
                variant="transparent"
                onClick={() => updateConcentration()}
              >
                Save
              </Button>
            ) : null}
          </td>
        </tr> */}

        <EditField
          label="Professional Liability Insurer"
          edit={edit}
          fieldName="liability"
          field={field}
          userInput={userInput.liability}
          makeEdit={makeEdit}
          setedit={setedit}
          handleChange={handleChange}
          saveChanges={saveChanges}
        />
      </tbody>
      <EditDetails
        show={editDetails}
        states={states}
        handleModal={toggleEditDetails}
        firebase={firebase}
        user={appEasyState.userObject}
        disciplines={disciplines}
        concentrations={concentrations}
        reloadUser={reloadUser}
        setValue={setValue}
        setConcentrations={setConcentrations}
        setLoading={setLoading}
      />
    </Table>
  );
};
export default withFirebase(view(DetailsTable));
