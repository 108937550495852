import React from 'react';
import s from '../style.scss';
import { Row, Col } from 'react-bootstrap';
import { USERBASICINFO } from '../../../../utils/constants';

const BasicTabInfo = ({ confirmationValues }) => {
  console.log(
    'confirmationValues.sealPacterType in basic tab info =========>',
    confirmationValues,
  );

  const getNameOfSeal = () => {
    if (confirmationValues.sealPacterType === 'seal') {
      return confirmationValues.selectedSealInfo.sealName;
    } else if (confirmationValues.sealPacterType === 'signature') {
      return confirmationValues.selectedSealInfo.signatureName;
    } else if (confirmationValues.sealPacterType === 'submittalReviewStamp' || confirmationValues.sealPacterType === 'stamp') {
      return confirmationValues.selectedSealInfo.sealName;
    }
  };

  return (
    <Row>
      <Col>
        <div className={s.wrapper}>
          <p>Name</p>
          <span>
            {getNameOfSeal() || 'N/A'}
          </span>

          <p>License Number</p>
          <span>{confirmationValues.licenseNo}</span>
          <p>Firm Name</p>
          <span>{confirmationValues.firmName}</span>
          <p>Company Website</p>
          <span>{confirmationValues.website || 'N/A'}</span>
          <p>Project Name</p>
          <span>{confirmationValues.projectName}</span>
          <p>Project Number</p>
          <span>{confirmationValues.projectNumber}</span>
          <p>Project Address</p>
          <span>{confirmationValues.projectAddress}</span>
        </div>
      </Col>
      <Col>
        <div className={s.wrapper}>
          <p>Date of SealPact</p>
          <span>
            {new Date(confirmationValues.dateSealPacted).toLocaleDateString() ||
              'N/A'}
          </span>
          {/* <p>Deliverable</p>
          <span>{documentInformation.fileType}</span> */}
        </div>
      </Col>
      <Col />
    </Row>
  );
};

export default BasicTabInfo;
