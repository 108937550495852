import React from 'react';
import ReactDOM from 'react-dom';
import App from './Containers/App';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Firebase, { FirebaseContext } from './auth/firebase';
// eslint-disable-next-line no-unused-vars
import Style from './css/App.scss';
// eslint-disable-next-line no-unused-vars
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
