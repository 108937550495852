import React from 'react';
import s from './style.scss';
import ReactTooltip from 'react-tooltip';

const ColHeading = ({ heading }) => {
  return (
    <span data-tip={heading}>
      <span className={s.tblColHeading}>{heading}</span>
      <ReactTooltip place="top" type="dark" effect="solid" />
    </span>
  );
};
export default ColHeading;
