/* eslint-disable indent */
import React, { useState, Fragment, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { StyleSheet } from '@react-pdf/renderer';
import s from './style.scss';
import Header from '../../../Header';
import { Button, Image, Form } from 'react-bootstrap';
import DocImage from '../../../../assets/docImage.png';
import { withFirebase } from '../../../../auth/firebase';
import ProgressBarComponent from '../../../Layout/progressbar';
import withAuthorization from '../../../../auth/session/withAuthorization';
import ShareImage from '../../../../assets/share.png';
import NextButton from '../../../../assets/next-button.png';
import PreviousButton from '../../../../assets/previous-button.png';
import ShareModal from './ShareModal';
import DocumentInformation from './DocumentInformationForm';
import SharedEmailDetails from './SharedEmails';
import ls from 'local-storage';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import { view } from 'react-easy-state'
import { appEasyState } from '../../../../easyStateStore';
import axios from 'axios';
import {GOOGLE_API_KEY} from '../../../../utils/constants';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const DocumentPreview = (props) => {
  const [showShareModal, setShareModal] = useState(false);
  const [showDocInfoModal, setshowDocInfoModal] = useState(false);
  const [emails, setEmails] = useState([]);
  const [previewImageData, setpreviewImageData] = useState([]);
  const [fileType, setFileType] = useState();
  let [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState();
  const [loading, setLoading] = useState(false);
  const [sealPacterType, setSealPacterType] = useState('seal');
  const [docInfo, setDocInfo] = useState();

  const {
    history: { push, replace },
    firebase,
    reloadUser,
    user,
  } = props;

  let optionsGeoLocation = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function successGeoLocation(pos) {
    let crd = pos.coords;

    console.log('***** pos: ', pos)

    console.log("***** Your current position is:");
    console.log(`***** Latitude : ${crd.latitude}`);
    console.log(`***** Longitude: ${crd.longitude}`);
    console.log(`***** More or less ${crd.accuracy} meters.`);

    let reverse_geocode_url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${crd.latitude},${crd.longitude}
  &location_type=ROOFTOP&result_type=street_address&key=${GOOGLE_API_KEY}`

    axios.get(reverse_geocode_url).then(response => {
      if (response) {
        if (response.data) {
          if (response.data.results) {
            if (response.data.results.length > 0) {
              if ( response.data.results[0].formatted_address) {
                console.log('***** response: ', response.data.results[0].formatted_address)
                appEasyState.projectAddressReverseGeocode =  response.data.results[0].formatted_address;
              }
            }
          } else {
            store.addNotification({
              title: 'Error',
              message: "Coordinates location not found",
              type: 'warning',
              ...NOTIFICATION_PROPS
            })
          }
        }
        else {
          store.addNotification({
            title: 'Error',
            message: "Data retrieved unsuccessfully",
            type: 'warning',
            ...NOTIFICATION_PROPS
          })
        }
      }
      else {
        store.addNotification({
          title: 'Error',
          message: "Data retrieved unsuccessfully",
          type: 'warning',
          ...NOTIFICATION_PROPS
        })
      }
    }).catch(function (error) {
      console.log(error);
      return;
    });

  }

  function errorsGeoLocation(err) {
    console.warn(`***** ERROR(${err.code}): ${err.message}`);
  }


  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then(function (result) {
  //         if (result.state === "granted") {
  //           console.log(result.state);
  //           //If granted then you can directly call your function here
  //           navigator.geolocation.getCurrentPosition(successGeoLocation);
  //         } else if (result.state === "prompt") {
  //           navigator.geolocation.getCurrentPosition(successGeoLocation, errorsGeoLocation, optionsGeoLocation);
  //         } else if (result.state === "denied") {
  //           //If denied then you have to show instructions to enable location
  //         }
  //         result.onchange = function () {
  //           console.log(result.state);
  //         };
  //       });
  //   } else {
  //     store.addNotification({
  //       title: 'Error',
  //       message: 'Location not available in your device',
  //       type: 'warning',
  //       ...NOTIFICATION_PROPS
  //     })
  //   }
  // }, [])

  useEffect(() => {
    // setEmails(ls.get('shareHolders'));
    if (props.location.state.acceptedFiles) {
      previewedFile(acceptedFiles);
    }
    if (props.location.state.acceptedFile) {
      setpreviewImageData(props.location.state.acceptedFile);
    }

    if (props.location.state.fileType) {
      setFileType(props.location.state.fileType);
    }
    if (props.location.state.documentInformation) {
      setEmails(props.location.state.documentInformation.shareWith);
    }
  }, []);

  if (props.location.state) {
    var {
      location: {
        state: { showEmails, acceptedFiles },
      },
    } = props;
    debugger;
  }

  const getEmails = (sharedEmails) => {
    console.log('sharedEmails ==> ', sharedEmails);
    setEmails(sharedEmails);

    // ls.set('shareHolders', sharedEmails);
  };

  const uploadSharedDocumentToFirebase = (
    sharedEmails,
    documentInformation,
  ) => {
    return firebase.uploadSharedDocument(
      previewImageData,
      documentInformation,
      sharedEmails,
    );
  };

  const nextPage = () => {
    if (pageNumber !== numPages) setPageNumber(pageNumber + 1);
  };
  const previousPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const toggleShareDocumentModal = () => {
    setShareModal(!showShareModal);
  };

  const shareDocumentWithUsers = () => {};

  const getScale = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const toggleDocInfoModal = () => setshowDocInfoModal(!showDocInfoModal);
  const handleFormSubmission = (documentInformation) => {
    setLoading(true);
    toggleDocInfoModal();
    documentInformation.fileType = fileType;
    documentInformation.totalPages = numPages || 0;
    // documentInformation.isPrimary = isPrimary;
    documentInformation.sealPacterType = sealPacterType;
    documentInformation.dateUploaded = new Date().getTime();
    documentInformation.id = new Date().getTime();
    documentInformation.sealPactOriginator = documentInformation.name;
    documentInformation.sealPactOriginatorEmail = user.email;
    console.log('previewImageData', previewImageData);
    console.log('documentInformation', documentInformation);
    setDocInfo(documentInformation);
    documentInformation.shareWith = emails;
    documentInformation.rotation = 0;

    firebase
      .uploadNewDocument(user.uid, previewImageData, documentInformation, user)
      .then((documentUploadRes) => {
        console.log('emails.length --------> ', emails.length);

        setLoading(true);

        if (emails.length > 0) {
          uploadSharedDocumentToFirebase(emails, documentInformation)
            .then((res) => {
              console.log('res ===>', res);
              setLoading(false);
              push({
                pathname: '/documentDetails',
                state: {
                  documentSubmitted: true,
                  documentDetails: documentUploadRes.downloadUrl,
                  numPages: numPages,
                  fileType: fileType,
                  documentInformation: documentInformation,
                },
              });
              return;
            })
            .catch((error) => {
              setLoading(false);
              console.log('error in uploading ===>', error);
            });
        } else {
          setLoading(false);
          push({
            pathname: '/documentDetails',
            state: {
              documentSubmitted: true,
              documentDetails: documentUploadRes.downloadUrl,
              numPages: numPages,
              fileType: fileType,
              documentInformation: documentInformation,
            },
          });
        }

        return;
      })
      .catch((error) => {
        console.log('error in uploading ===>', error);
        setLoading(false);
      });
  };

  let notShared = (
    <b className={s.noDoc}>
      Document is not shared with anyone , so not able to manage !
    </b>
  );

  const previewedFile = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      // setpreviewImageData(file);
      console.log('files for preview ====================> ', file);
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log('binaryStr ========> ', binaryStr);
        setpreviewImageData(binaryStr);
      };
      console.log('array base 64 ', reader.readAsDataURL(file));
    });
  };

  return loading ? (
    <ProgressBarComponent />
  ) : (
    <Fragment>
      <div className={s.goBack}>
        <Button variant="text" onClick={() => push('/documents')}>
          <i className={s.left} />
          My Documents
        </Button>
      </div>
      <Header title={!showEmails ? 'Document Preview' : 'Manage'} />
      <div className={s.row}>
        {/* <Button variant="text" onClick={toggleShareDocumentModal}>
          <Image src={ShareImage} />
          Share Document
        </Button> */}
        <Form.Group controlId="isPrimarySealPacter">
          <Form.Check
            className={s.checkboxLabel}
            type="radio"
            defaultChecked
            name="formHorizontalRadios"
            onChange={(e) => {
              if (e.target.checked) {
                setSealPacterType('seal');
              }
              // setisPrimary(e.target.checked)
            }}
            label="I would like to place a Seal on this Document"
          />
          <Form.Check
            className={s.checkboxLabel}
            type="radio"
            name="formHorizontalRadios"
            onChange={(e) => {
              if (e.target.checked) {
                setSealPacterType('signature');
              }
              // setisPrimary(e.target.checked)
            }}
            label="I would like to place a Signature on this Document"
          />
          <Form.Check
            className={s.checkboxLabel}
            type="radio"
            name="formHorizontalRadios"
            onChange={(e) => {
              if (e.target.checked) {
                setSealPacterType('stamp');
              }
              // setisPrimary(e.target.checked)
            }}
            label="I would like to place a Submittal Review Stamp on this Document"
          />
        </Form.Group>
        <div>
          <Button
            variant="outline-secondary"
            onClick={() => replace('/documents')}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              showEmails
                ? uploadSharedDocumentToFirebase(
                    emails,
                    props.location.state.documentInformation,
                  )
                : toggleDocInfoModal();
            }}
          >
            {showEmails ? 'Save' : 'Confirm'}
          </Button>
        </div>
      </div>

      {/* <div className={s.content}>
        <Form.Group controlId="isPrimarySealPacter">
          <Form.Check
            className={s.checkboxLabel}
            type="radio"
            defaultChecked
            name="formHorizontalRadios"
            onChange={(e) => {
              if (e.target.checked) {
                setSealPacterType('seal');
              }
              // setisPrimary(e.target.checked)
            }}
            label="I would like to place a Seal on this Document"
          />
          <Form.Check
            className={s.checkboxLabel}
            type="radio"
            name="formHorizontalRadios"
            onChange={(e) => {
              if (e.target.checked) {
                setSealPacterType('signature');
              }
              // setisPrimary(e.target.checked)
            }}
            label="I would like to place a Signature on this Document"
          />
          <Form.Check
            className={s.checkboxLabel}
            type="radio"
            name="formHorizontalRadios"
            onChange={(e) => {
              if (e.target.checked) {
                setSealPacterType('stamp');
              }
              // setisPrimary(e.target.checked)
            }}
            label="I would like to place a Submittal Review Stamp on this Document"
          />
        </Form.Group>
      </div> */}

      {showEmails ? (
        <div>
          {!!emails && emails.length > 0
            ? emails.map((item, key) => (
                <SharedEmailDetails key={key} item={item} />
              ))
            : notShared}
        </div>
      ) : fileType === 'application/pdf' ? (
        <div className={s.content}>
          {/* <Image src={previewImageData} className={s.docImg} /> */}
          {/* <div> */}
          {/* <Form.Group controlId="isPrimarySealPacter">
              <Form.Check
                className={s.checkboxLabel}
                type="radio"
                defaultChecked
                name="formHorizontalRadios"
                onChange={e => {
                  if (e.target.checked) {
                    setSealPacterType('primary');
                  }
                  // setisPrimary(e.target.checked);
                }}
                label="I'm the primary SealPacter"
              />
              <Form.Check
                className={s.checkboxLabel}
                type="radio"
                name="formHorizontalRadios"
                onChange={e => {
                  if (e.target.checked) {
                    setSealPacterType('secondary');
                  }
                  // setisPrimary(e.target.checked)
                }}
                label="I'm a secondary SealPacter"
              />
              <Form.Check
                className={s.checkboxLabel}
                type="radio"
                name="formHorizontalRadios"
                onChange={e => {
                  if (e.target.checked) {
                    setSealPacterType('viewer');
                  }
                  // setisPrimary(e.target.checked)
                }}
                label="I'm a viewer"
              />
            </Form.Group> */}

          <Document
            file={previewImageData}
            onLoadSuccess={onDocumentLoadSuccess}
            className={s.pdfDoc}
          >
            <Page
              // style={styles.page}
              // width={800}
              pageNumber={pageNumber}
              className={s.page}
            />
          </Document>
          <div className={s.centerDisplay}>
            <Image
              className={s.buttonPrevious}
              src={PreviousButton}
              onClick={previousPage}
            />
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <Image
              className={s.buttonNext}
              src={NextButton}
              onClick={nextPage}
            />
          </div>
          {/* </div> */}
        </div>
      ) : (
        <div className={s.content}>
          <Image src={previewImageData} className={s.docImg} />
        </div>
      )}
      <ShareModal
        handleModal={toggleShareDocumentModal}
        show={showShareModal}
        emails={emails}
        getEmails={getEmails}
        shareDocumentWithUsers={shareDocumentWithUsers}
      />
      <DocumentInformation
        handleModal={toggleDocInfoModal}
        props={props}
        show={showDocInfoModal}
        isFormSubmitted={handleFormSubmission}
      />
    </Fragment>
  );
};
const condition = (user) => !!user;
export default withAuthorization(condition)(withFirebase(view(DocumentPreview)));
