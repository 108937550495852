import React from 'react';
import { Button } from 'react-bootstrap';
import s from './style.scss';

const EditField = ({
  label,
  edit,
  field,
  fieldName,
  userInput,
  makeEdit,
  setedit,
  handleChange,
  saveChanges,
}) => {
  return (
    <tr>
      <th>{label}</th>
      <td>
        {edit ? (
          <input
            disabled={!edit ? true : false}
            autoFocus
            className={edit ? s.editable : s.static}
            placeholder={userInput ? userInput : ''}
            name={fieldName}
            maxLength={40}
            onChange={handleChange}
          />
        ) : (
          <span> {userInput}</span>
        )}
      </td>
      {/* <td>
        {edit && field === fieldName ? (
          <Button
            variant="transparent"
            onClick={() => saveChanges(fieldName) + setedit(false)}
          >
            Save
          </Button>
        ) : (
          <Button variant="transparent" onClick={() => makeEdit(fieldName)}>
            Edit
          </Button>
        )}
      </td> */}
    </tr>
  );
};

export default EditField;
