import React, { Fragment, useState, useEffect } from 'react';
import Header from '../../Header';
import s from './style.scss';
import { Row, Col } from 'react-bootstrap';
import { withAuthorization } from '../../../auth/session';
import { store } from 'react-notifications-component';
import Spinner from '../../Layout/Spinner';
import {
  PENDING_PRIMARY,
  PENDING_SECONDARY,
  PENDING_VIEWER,
} from '../../../utils/constants';
import UploadDocument from '../UploadDocument';
import SearchInput from '../../SearchInput';
import PendingSealsCol from './PendingSealsCol';
import ls from 'local-storage';
import { NOTIFICATION_PROPS } from '../../../utils/constants';
import SealProgressBar from '../../Layout/sealProgressBar';

const MyDashboard = (props) => {
  const [documents, setDocuments] = useState();
  const [originalData, setOriginalData] = useState();
  const [loading, setLoading] = useState(true);
  const [parsing, setParsing] = useState(false);

  const {
    user,
    firebase,
    history: { push },
  } = props;

  useEffect(() => {
    firebase
      .getAllPendingDocuments(user.uid)
      .then((documentsArr) => {
        console.log('documentsArr ===> ', documentsArr);
        setDocuments(documentsArr);
        setOriginalData(documentsArr);
        setLoading(false);
        return;
      })
      .catch((error) => {
        console.log('error ==> ', error);
        store.addNotification({
          title: 'Error',
          message: 'Error in fetching shared documents.',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });

        return;
      });
  }, []);

  const getFilteredData = (data) => {
    setDocuments(data);
  };

  // ls.clear();
  return loading ? (
    <Spinner />
  ) : parsing ? (
    <Fragment>
      <SealProgressBar
        message={'Your document is being flattened please wait.'}
      />
    </Fragment>
  ) : (
    <Fragment>
      <Header title="My Dashboard" />
      <Row noGutters className={`align-items-center ${s.features}`}>
        <Col className="text-left">
          <UploadDocument uploading={(status) => setParsing(status)} />
        </Col>
        <Col xs={12} sm={8} md={5} lg={3}>
          <SearchInput
            array={originalData}
            getFilteredData={getFilteredData}
            searchTarget="name"
          />
        </Col>
      </Row>
      <div className={s.parentDiv}>
        <Row>
          <Col xs={12} sm={6} md={4}>
            <PendingSealsCol
              array={
                document
                  ? documents.filter((e) => e.sealPacterType === 'seal')
                  : null
              }
              docType="Primary"
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <PendingSealsCol
              array={
                document
                  ? documents.filter((e) => e.sealPacterType === 'signature')
                  : null
              }
              docType="Secondary"
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <PendingSealsCol
              array={
                document
                  ? documents.filter((e) => e.sealPacterType === 'stamp')
                  : null
              }
              docType="Viewer"
            />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
const condition = (user) => !!user;
export default withAuthorization(condition)(MyDashboard);
