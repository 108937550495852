/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { store } from 'react-notifications-component';
import moment from 'moment';
import { Button, Image, Spinner } from 'react-bootstrap';
import s from './style.scss';
import SealActivationModal from '../SealActivationModal';
import DeactivationModal from '../DeactivationModal';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import DeleteSeal from '../DeleteSeal';

const ShowAssets = (props) => {
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showDeactivationModal, setShowDeactivationModal] = useState(false);
  const [deactivationType, setDeactivationType] = useState('');
  const [activateSignature, setActivateSignature] = useState(false);
  const [activateStamp, setActivateStamp] = useState(false);

  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [deletionType, setDeletionType] = useState('');

  const [isSealInfo, setIsSealInfo] = useState(false);

  const [selectedItem, setSelectedItem] = useState({
    approvalStatus: null,
    status: null,
    type: null,
    imageSrc: null,
    state: null,
    expirationDate: null,
  });


  const renderBtnTitle = (item) => {
    let { sealName, status, type } = item;
    // console.log('item ===> ', item);
    if (type === 'submittalReviewStamp') {
      if (status) {
        return 'Deactivate Stamp';
      } else return 'Activate Stamp';
    } else if (type === 'stamp' || type === 'seal') {
      if (status) {
        return 'Deactivate Seal';
      } else return 'Activate Seal';
    } else if (type === 'signature') {
      if (status) {
        return 'Deactivate Signature';
      } else return 'Activate Signature';
    }
  };
  const handleBtnClick = ({
    id,
    status,
    type,
    imageSrc,
    state,
    sealName,
    signatureName,
    data,
    newLicenseNo,
    expirationDate,
    approvalStatus,
  }) => {
    const object = {
      id: id,
      status: status,
      type: type,
      data: data,
      state: state,
      sealName: sealName,
      signatureName: signatureName,
      newLicenseNo: newLicenseNo,
      expirationDate: expirationDate,
      approvalStatus: approvalStatus,
    };
    setSelectedItem(object);
    setIsSealInfo(false);

    if (type === 'stamp' || type === 'seal') {
      if (status) {
        setDeactivationType('Seal');
        setShowDeactivationModal(true);
      } else {
        setShowActivationModal(true);
      }
    } else if (type === 'signature') {
      if (status) {
        setDeactivationType('Signature');
        setShowDeactivationModal(true);
      } else {
        setShowDeactivationModal(true);
        setActivateSignature(true);
      }
    } else if (type === 'submittalReviewStamp') {
      if (status) {
        setDeactivationType('SubmittalReviewStamp');
        setShowDeactivationModal(true);
      } else {
        setActivateStamp(true);
        setActivateSignature(true);
        setShowDeactivationModal(true);
      }
    }
  };

  const hideActivationModal = () => {
    setShowActivationModal(false);
    // setTimeout(() => {
    //   setShowActivationModal(false);
    //   window.location.href = 'seals';
    // }, 2000)
  };

  const cancelActivationModal = () => {
    setShowActivationModal(false);
    // window.location.href = 'seals';
  }

  const hideDeactivationModal = () => setShowDeactivationModal(false);
  const hideDeletionModal = () => setShowDeletionModal(false);

  const [sealImageCropped, setSealImageCropped] = useState(false);

  const handleDeletebtn = ({
    id,
    status,
    type,
    imageSrc,
    state,
    sealName,
    signatureName,
    data,
    newLicenseNo,
    expirationDate,
    approvalStatus,
  }) => {
    const object = {
      id: id,
      status: status,
      type: type,
      data: data,
      state: state,
      sealName: sealName,
      signatureName: signatureName,
      newLicenseNo: newLicenseNo,
      expirationDate: expirationDate,
      approvalStatus: approvalStatus,
    };
    setSelectedItem(object);

    console.log(object);
    if (type === 'stamp') {
      setDeletionType('Seal');
      setShowDeletionModal(true);
    } else if (type === 'signature') {
      setDeletionType('Signature');
      setShowDeletionModal(true);
    } else if (type === 'submittalReviewStamp') {
      setDeletionType('SubmittalReviewStamp');
      setShowDeletionModal(true);
    }
  };
  const confirmDeactivation = async () => {
    setShowDeactivationModal(false);
    setActivateSignature(false);
    // props.showSpinner();
    console.log('selectedItem =====> ', selectedItem);
    if (selectedItem.type === String('stamp')) {
      await props.firebase
        .updateSealAndSignStatus(
          props.user.uid,
          selectedItem.id,
          selectedItem,
          true,
          isSealInfo,
        )
        .then((res) => {
          // props.stopSpinner();
          props.setRefreshSeals(true)
          store.addNotification({
            title: 'Updated Successfully!',
            message: 'Seal updated successfully.',
            type: 'default',
            ...NOTIFICATION_PROPS,
          });
          return;
        })
        .catch((error) => {
          props.stopSpinner();
          return;
        });
    } else if (selectedItem.type === String('submittalReviewStamp')) {
      await props.firebase
        .activateOrDeactivateSubmittalReviewStamp(
          props.user.uid,
          selectedItem.id,
          selectedItem,
        )
        .then((res) => {
          // props.stopSpinner();
          props.setRefreshSeals(true)
          store.addNotification({
            title: 'Updated Successfully!',
            message: 'Submittal Review Stamp updated successfully.',
            type: 'default',
            ...NOTIFICATION_PROPS,
          });
          return;
        })
        .catch((error) => {
          // props.stopSpinner();
         console.log('Submittal Review Stamp deac error', error)
          return;
        });
    } else {
      props.firebase
        .updateSealAndSignStatus(
          props.user.uid,
          selectedItem.id,
          selectedItem,
          false,
          isSealInfo,
        )
        .then((res) => {
          // props.stopSpinner();
          props.setRefreshSeals(true)
          store.addNotification({
            title: 'Updated Successfully!',
            message: 'Signature updated successfully.',
            type: 'default',
            ...NOTIFICATION_PROPS,
          });
          return;
        })
        .catch((error) => {
          // props.stopSpinner();
          return;
        });
    }
    props.getSelectedItemId(selectedItem, isSealInfo);
  };
  const confirmActivation = async (e) => {
    e.preventDefault();

    if (!sealImageCropped) {
      if (selectedItem.type === 'stamp' || selectedItem.type === 'seal') {
        if (
          moment(selectedItem.expirationDate).isBefore(moment(new Date()), 'day')
        ) {
          store.addNotification({
            title: 'Error',
            message: 'Please update the expiration date.',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
          return;
        }
        await props.firebase
          .updateSealAndSignStatus(
            props.user.uid,
            selectedItem.id,
            selectedItem,
            true,
            isSealInfo,
          )
          .then((res) => {
            props.setRefreshSeals(true)
            store.addNotification({
              title: 'Updated Successfully!',
              message: 'Seal updated successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            // setTimeout(() => {
            //   window.location.href = '/seals'
            //   // props.stopSpinner();
            // }, 1000);
            // history.push('/seals');
          })
          .catch((error) => {
            console.log('seal error', error)
            store.addNotification({
              title: 'Error',
              message: 'Error in updating Seal',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            debugger
            setTimeout(() => {
              window.location.href = 'seals'
            }, 4500)
          });
      } else if (selectedItem.type === 'submittalReviewStamp') {
        // if (
        //   moment(selectedItem.expirationDate).isBefore(moment(new Date()), 'day')
        // ) {
        //   store.addNotification({
        //     title: 'Error',
        //     message: 'Please update the expiration date.',
        //     type: 'danger',
        //     ...NOTIFICATION_PROPS,
        //   });
        //   return;
        // }
        await props.firebase
          .updateSubmittalReviewStamp(
            props.user.uid,
            selectedItem.id,
            selectedItem,
          )
          .then((res) => {
            props.setRefreshSeals(true)
            store.addNotification({
              title: 'Updated Successfully!',
              message: 'Submittal Review Stamp updated successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            // props.showSpinner();
            // setTimeout(() => {
            //   window.location.href = '/seals'
            //   // props.stopSpinner();
            // }, 1000);
            // history.push('/seals');
          })
          .catch((error) => {
            console.log('stamp error', error)
            store.addNotification({
              title: 'Error',
              message: 'Error in updating Submittal Review Stamp',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            debugger
            setTimeout(() => {
              window.location.href = 'seals'
            }, 4500)
          });
      } else {
        await props.firebase
          .updateSealAndSignStatus(
            props.user.uid,
            selectedItem.id,
            selectedItem,
            false,
            isSealInfo,
          )
          .then((res) => {
            props.setRefreshSeals(true)
            store.addNotification({
              title: 'Updated Successfully!',
              message: 'Signature updated successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            // props.showSpinner();
            // setTimeout(() => {
            //   window.location.href = '/seals'
            //   // props.stopSpinner();
            // }, 1000);
            // history.push('/seals');
          })
          .catch((error) => {
            console.log('signature error', error)
            store.addNotification({
              title: 'Error',
              message: 'Error in updating Signature',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            debugger
            setTimeout(() => {
              window.location.href = 'seals'
            }, 4500)
          });
      }
    }
    else {

      if (selectedItem.type === 'stamp' || selectedItem.type === 'seal') {
        if (
          moment(selectedItem.expirationDate).isBefore(moment(new Date()), 'day')
        ) {
          store.addNotification({
            title: 'Error',
            message: 'Please update the expiration date.',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
          return;
        }

        await props.firebase
          .updateSealAndSignStatusWithImage(
            props.user.uid,
            selectedItem.id,
            selectedItem,
            true,
            isSealInfo,
          )
          .then((res) => {
            props.setRefreshSeals(true)
            store.addNotification({
              title: 'Updated Successfully!',
              message: 'Seal updated successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            store.addNotification({
              title: '',
              message: 'Please wait while the page is being updated.',
              type: 'info',
              ...NOTIFICATION_PROPS,
            });
            // props.showSpinner()

            console.log('updateSealAndSignStatusWithImage props.user.uid', props.user.uid)
            console.log('updateSealAndSignStatusWithImage selectedItem.id', selectedItem.id)
            console.log('updateSealAndSignStatusWithImage selectedItem', selectedItem)
            console.log('updateSealAndSignStatusWithImage isSealInfo', isSealInfo)
            console.log('updateSealAndSignStatusWithImage res', res)

            // alert('in updateSealAndSignStatusWithImage')
            // props.showSpinner();
          })
          .catch((error) => {
            console.log('seal error', error)
            store.addNotification({
              title: 'Error',
              message: 'Error in updating Seal',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            debugger
            setTimeout(() => {
              window.location.href = 'seals'
            }, 4500)
          });
      } else if (selectedItem.type === 'submittalReviewStamp') {
        // if (
        //   moment(selectedItem.expirationDate).isBefore(moment(new Date()), 'day')
        // ) {
        //   store.addNotification({
        //     title: 'Error',
        //     message: 'Please update the expiration date.',
        //     type: 'danger',
        //     ...NOTIFICATION_PROPS,
        //   });
        //   return;
        // }
        await props.firebase
          .updateSubmittalReviewStampWithImage(
            props.user.uid,
            selectedItem.id,
            selectedItem,
          )
          .then((res) => {
            console.log('res updateSubmittalReviewStampWithImage', res)
            props.setRefreshSeals(true)
            store.addNotification({
              title: 'Updated Successfully!',
              message: 'Submittal Review Stamp updated successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            store.addNotification({
              title: '',
              message: 'Please wait while the page is being updated.',
              type: 'info',
              ...NOTIFICATION_PROPS,
            });
            // props.showSpinner();
          })
          .catch((error) => {
            console.log('stamp error', error)
            store.addNotification({
              title: 'Error',
              message: 'Error in updating Submittal Review Stamp',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            debugger
            setTimeout(() => {
              window.location.href = 'seals'
            }, 4500)
          });
      } else {
        // props.showSpinner();
        await props.firebase
          .updateSealAndSignStatusWithImage(
            props.user.uid,
            selectedItem.id,
            selectedItem,
            false,
            isSealInfo,
          )
          .then((res) => {
            console.log('res updateSealAndSignStatusWithImage', res)
            // props.showSpinner();
            props.setRefreshSeals(true)
            store.addNotification({
              title: 'Updated Successfully!',
              message: 'Signature updated successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            store.addNotification({
              title: '',
              message: 'Please wait while the page is being updated.',
              type: 'info',
              ...NOTIFICATION_PROPS,
            });
            // props.showSpinner()
          })
          .catch((error) => {
            // props.stopSpinner();
            console.log('signature error', error)
            store.addNotification({
              title: 'Error',
              message: 'Error in updating Signature',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            debugger
            setTimeout(() => {
              window.location.href = 'seals'
            }, 4500)
          });
      }


      // window.location.href = '/seals'



    }

    setSealImageCropped(false);

    props.getSelectedItemId(selectedItem, isSealInfo);
  };

  const onSealSelect = (item) => {
    console.log('item: &&&&&', item)
    props.getselectedSealInfo(item);
  };
  const confirmDeletion = () => {
    setShowDeletionModal(false);
    // setActivateSignature(false);
    // props.showSpinner();
    console.log('selectedItem to delete =====> ', selectedItem);
    const { type } = selectedItem;
    const assetText =
      type === 'submittalReviewStamp'
        ? 'Submittal Review Stamp'
        : type.charAt(0).toUpperCase() + type.substring(1);
    props.firebase
      .deleteSeal(props.user.uid, selectedItem)
      .then((res) => {
        // props.stopSpinner();
        props.removeSeletedItem(selectedItem);
        props.setRefreshSeals(true)
        store.addNotification({
          title: 'Deleted Successfully!',
          message: `${assetText} deleted successfully.`,
          type: 'default',
          ...NOTIFICATION_PROPS,
        });
        // props.showSpinner()

      })
      .catch((error) => {
        console.log('error ==> ', error);
        // props.stopSpinner();

        store.addNotification({
          title: 'Error',
          message: 'Not Found.  Could not delete object',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        // return;
      });
  };
  const formatDate = (date) => {
    //console.log('date == = = = =  >', date ? date.toDate() : date);
    const dateObj = new Date(date);
    //console.log('dateObj =============>', dateObj);
    var year = dateObj.getFullYear();
    var month = (1 + dateObj.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = dateObj.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  };

  const { enableControl = true, assetArray } = props;
  return (
    <div className={s.wrapper}>
      {assetArray.map((item, key) => {
        // console.log('item ===> ', item);
        // let da;
        // console.log('item.expirationDate ===> ', item.expirationDate.toDate());
        // item.expirationDate
        //   ? (da = item.expirationDate.toDate())
        //   : (item.expirationDate = item.expirationDate);
        // console.log('da ====>', da);
        return (
          <div
            key={key}
            className={[
              enableControl
                ? item.status
                  ? s.objectLinked
                  : s.objectOpacity
                : null,
            ]}
            onClick={enableControl ? undefined : () => onSealSelect(item)}
          >
            <div key={key} className={[s.flexCol]}>
              {!props.hideSealDeleteBtn && (
                <div className={s.deleteBtn}>
                  <Button variant="text" onClick={() => handleDeletebtn(item)}>
                    <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                  </Button>
                </div>
              )}
              {item.type === 'signature' ? (
                <Image src={item.data} alt="sign" className={s.signStyle} />
              ) : item.type === 'submittalReviewStamp' ? (
                <Image
                  src={item.data}
                  alt="Submittal Review Stamp"
                  className={s.imgStyle}
                  onClick={() => {
                    console.log('item ===>', item);
                    setIsSealInfo(true);
                    setSelectedItem(item);
                    setShowActivationModal(true);
                  }}
                />
              ) : (
                <Image
                  src={item.data}
                  alt="seal-picture"
                  className={s.imgStyle}
                  onClick={() => {
                    console.log('item ===>', item);
                    setIsSealInfo(true);
                    setSelectedItem(item);
                    setShowActivationModal(true);
                  }}
                />
              )}
              {/* {item.stype == 'signature' ? null : (
                <p className={s.overlaySignature}>John Doe</p>
              )} */}
            </div>
            <div
              className={
                item.type === 'signature'
                  ? s.sigBlock
                  : item.type === 'submittalReviewStamp'
                  ? s.imgDetails
                  : s.imgDetails
              }
            >
              {item.type === 'stamp' && (
                <>
                  <p>{item.sealName}</p>
                  <p>{item.state ? item.state : <span>&nbsp;</span>}</p>
                  <p>{item.newLicenseNo}</p>
                  {/* <p>{item.state ? item.state : item.sealName}</p> */}
                  <p>{formatDate(item.expirationDate)}</p>
                </>
              )}
              {item.type === 'submittalReviewStamp' && (
                <>
                  <p>{item.sealName}</p>
                  <p>{item.approvalStatus}</p>
                  <p>{<span>&nbsp;</span>}</p>
                  <p>{<span>&nbsp;</span>}</p>
                </>
              )}
              {enableControl && (
                <div className={s.centeredBtn}>
                  <Button variant="text" onClick={() => handleBtnClick(item)}>
                    {renderBtnTitle(item)}
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      })}
      <SealActivationModal
        confirmActivation={confirmActivation}
        hideModal={hideActivationModal}
        cancelModal={cancelActivationModal}
        showModal={showActivationModal}
        selectedSeal={selectedItem}
        isSealInfo={isSealInfo}
        firebase={props.firebase}
        sealImageCropped={sealImageCropped}
        setSealImageCropped={setSealImageCropped}
      />
      <DeactivationModal
        activateSignature={activateSignature}
        deactivationType={deactivationType}
        confirmDeactivation={confirmDeactivation}
        hideModal={hideDeactivationModal}
        activateStamp={activateStamp}
        showModal={showDeactivationModal}
      />
      <DeleteSeal
        hideModal={hideDeletionModal}
        confirmDelete={confirmDeletion}
        showModal={showDeletionModal}
        deleteionType={deletionType}
      />
    </div>
  );
};

export default ShowAssets;
