import React from 'react';
import DynamicModal from '../../../DynamicModal';
import { Container, Button } from 'react-bootstrap';
import s from './style.scss';

const ConfirmSealModal = ({
  hideModal,
  isSealConfirmed,
  showModal,
  toggleConfirmModal,
}) => {
  return (
    <DynamicModal
      show={showModal}
      title="SealPact - Confirmation"
      hideModal={hideModal}
      size="lg"
    >
      <Container>
        <p>
          By clicking Confirm, I agree that the SealPact will be the electronic
          representation of my signature and/or seal.
        </p>
        <p>
          I also agree that any and all of the information that I have provided
          to SealPact is true and accurate to the best of my knowledge and take
          full responsibility for compliance with any applicable state statutes
          regarding this signature and/or seal.
        </p>
        <div className={s.footer}>
          <Button variant="outline-secondary" onClick={toggleConfirmModal}>
            View Document
          </Button>
          <Button onClick={isSealConfirmed}>Confirm</Button>
        </div>
      </Container>
    </DynamicModal>
  );
};

export default ConfirmSealModal;
